/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observable, computed, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link } from '@material-ui/core';
import Api, * as api from 'api';
import { inject, WithUserStore, WithSettingStore } from 'stores';
import { Account, Filter, FilterItem } from 'models';

import { states } from 'services';

import DashboardLayout from 'containers/DashboardLayout';
import FilterBar from 'components/FilterBar';
import styles from './styles';
import { paths } from 'routes';
import { setTitle } from 'services';
import { adaptForDataGrid } from 'services';

import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import ChipStatusTag from 'components/ChipStatusTag';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import Title from 'components/Title/Title';

const PAGE_TITLE = 'Accounts';

type AccountsProps = WithStyles<typeof styles> &
  WithUserStore &
  WithSettingStore &
  RouteComponentProps;

function annotateAccount(account: Account) {
  return {
    ...account,
    // This is a temporary fix because datagrid doesn't display zeros
    userCount: account.userCount ? account.userCount!.toString() : '0',
    locationCount: account.locationCount ? account.locationCount!.toString() : '0',
    isActive: account.isActive ? 'ACTIVE' : 'INACTIVE',
    primaryOwner: account.primaryOwner
      ? `${account.primaryOwner.firstName} ${account.primaryOwner.lastName}`
      : '',
  };
}

/**
 * Renders accounts table and related filters.
 * Only users with admin scope can access this view.
 */
@inject('userStore', 'settingStore')
@observer
class Accounts extends React.Component<AccountsProps> {
  public constructor(props: AccountsProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** Is current user an admin */
  @computed public get isAdmin(): boolean {
    return this.props.userStore!.isAdmin;
  }

  @computed public get stateFilterItems(): FilterItem[] {
    return Object.entries(states).map((e) => ({ value: e[0], label: e[1] }));
  }

  /** Fetches accounts */
  @action.bound public fetchAccounts = adaptForDataGrid(
    (rmd: api.RequestMetaData) =>
      Api.core.getAccounts({ ...rmd, filters: { ...this.activeFilters } }),
    annotateAccount,
  );

  /** List of available filters for FilterBar component */
  filters: Filter[] = [
    // { display: 'Owner Email', id: 'ownerEmail', label: 'Starts with', type: 'text' },
    { display: 'Name', id: 'name', label: 'Contains', type: 'text' },
    { display: 'City', id: 'city', label: 'Starts with', type: 'text' },
    {
      display: 'State',
      id: 'state',
      label: 'Starts with',
      type: 'autocomplete',
      items: this.stateFilterItems,
    },
    { display: 'Code', id: 'code', label: 'Matches', type: 'text' },
    {
      display: 'Status',
      id: 'isActive',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'ACTIVE', value: 'true' },
        { label: 'INACTIVE', value: 'false' },
      ],
    },
  ];

  renderAccountNameCell = (params: any) => (
    <Link component={RouterLink} to={paths.accountDetails(params.id).info()}>
      {params.value}
    </Link>
  );

  getFullName({ value }: any) {
    return `${value?.firstName || ''} ${value?.lastName || ''}`;
  }

  renderCellStatus({ value }: any) {
    return <ChipStatusTag status={value} />;
  }

  @action.bound async getAccountData(rmd: api.RequestMetaData) {
    const res: any = await Api.core.getAccounts({
      ...rmd,
      filters: { ...this.activeFilters },
    });
    return {
      rows: res.data.data,
      sortable: res.data.sortable,
      totalElements: res.data.count,
    };
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  gridColumns = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 200,
      renderCell: this.renderAccountNameCell,
      flex: 1,
    },
    { headerName: 'City', field: 'city', minWidth: 150, flex: 1 },
    { headerName: 'State', field: 'state', minWidth: 90, flex: 1 },
    { headerName: 'Code', field: 'code', minWidth: 100, flex: 1, sortable: false },
    {
      headerName: 'Owner',
      field: 'primaryOwner',
      minWidth: 150,
      flex: 1,
      valueGetter: this.getFullName,
      sortable: false,
    },
    { headerName: 'Locations', field: 'locationCount', minWidth: 110, flex: 1 },
    { headerName: 'Users', field: 'userCount', minWidth: 100, flex: 1 },
    {
      headerName: 'Status',
      field: 'isActive',
      minWidth: 150,
      sortable: false,
      renderCell: this.renderCellStatus,
    },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Title mb={3}>{PAGE_TITLE}</Title>
        </Box>
        <Box pb={10}>
          <FilterBar
            filters={this.filters}
            onChange={this.handleFiltersOnChange}
            locationMap={this.isAdmin ? { isActive: false } : undefined}
          />
          {this.filtersInitReady && (
            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.getAccountData}
              refetchKey={this.activeFilters}
              disableColumnMenu
              pathname={this.props.location.pathname}
            />
          )}
        </Box>
        <PlusFabButton
          component={RouterLink}
          to={paths.adminAccountSignup().root()}
          data-cy={'add-account'}
        />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Accounts);
