/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';

import {
  observable,
  action,
  reaction,
  flow,
  IReactionDisposer,
  makeObservable,
  computed,
} from 'mobx';
import { observer } from 'mobx-react';

import Api, { ApiResponse, getErrorMsg, RequestMetaData } from 'api';
import { AxiosResponse } from 'axios';
import { inject, WithToastStore, WithAnalyticsStore, WithSettingStore } from 'stores';
import { paths } from 'routes';
import { adaptForDataGridPro, numericStringToUsd, setTitle } from 'services';
import moment from 'moment-timezone';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid, Link } from '@material-ui/core';
import { AccountGroupOutline, Percent } from 'mdi-material-ui';

import styles from '../styles';

import { Filter } from 'models';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';

import { HorizontalStatCard } from 'containers/UserDetails';
import { AssuredWorkloadRounded } from '@mui/icons-material';

const PAGE_TITLE = 'Conversions by Affiliates';

/** Stats panel data interface */
interface Stats {
  count: number;
  percent: number;
  total: number;
}

/** Single affiliate conversion row */
interface AffiliateConversion {
  id: number;
  firstName: string;
  lastName: string;
  count: string;
  percentTotal: string;
  percentAffiliates: string;
  commission: string;
  totalCommissions: string;
  lastSignup: string;
}

function annotateConversions(c: AffiliateConversion) {
  return {
    ...c,
    count: parseInt(c.count),
    percentTotal:
      parseFloat(c.percentTotal) !== 0 ? `${parseFloat(c.percentTotal).toFixed(2)}%` : '',
    percentAffiliates:
      parseFloat(c.percentAffiliates) !== 0 ? `${parseFloat(c.percentAffiliates).toFixed(2)}%` : '',
    commission: numericStringToUsd(c.commission),
    totalCommissions: numericStringToUsd(c.totalCommissions),
  };
}

/** Here we define what kind of props this component takes */
interface ByAffiliateProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithAnalyticsStore,
    WithSettingStore,
    RouteComponentProps {}

@inject('toastStore', 'analyticsStore', 'settingStore')
@observer
class ByAffiliate extends React.Component<ByAffiliateProps> {
  constructor(props: ByAffiliateProps) {
    super(props);
    makeObservable(this);
    // Fetch new stats when selected date range changes
    this.disposers.push(
      reaction(
        () => this.activeFilters?.fromDate || this.activeFilters?.toDate,
        () => {
          this.fetchStats();
        },
      ),
    );
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  @observable public stats?: Stats;

  @action.bound public fetchStats = flow(function* (this: ByAffiliate) {
    const extraReqData = {
      fromDate: this.activeFilters.fromDate as string,
      toDate: this.activeFilters.toDate as string,
    };
    try {
      const resp: AxiosResponse<ApiResponse<Stats>> =
        yield Api.analytics.conversion.byAffiliates.statsData(
          extraReqData.fromDate,
          extraReqData.toDate,
        );
      if (resp?.data) {
        this.stats = resp.data.data;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public fetchAffiliateConversions = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      if (!this.filtersInitReady) {
        return Promise.resolve({ data: [] } as AxiosResponse);
      }

      return await Api.analytics.conversion.byAffiliates.tableData({
        ...rmd,
        filters: { ...this.activeFilters },
      });
    },
    annotateConversions,
  );

  renderCellAffiliate = ({ row }: any) => (
    <Link component={RouterLink} to={paths.userDetails(row.userId).root()}>
      {row.firstName} {row.lastName}
    </Link>
  );

  renderCellLastSignup = ({ row }: any) => (
    <>{moment(row.lastSignup).isValid() ? moment(row.lastSignup).format('MMM DD, YYYY') : ''}</>
  );

  gridColumns = [
    {
      headerName: 'Affiliate',
      field: 'name',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellAffiliate,
    },
    { headerName: 'Count', field: 'count', minWidth: 120, flex: 1 },
    {
      headerName: '% Affiliates',
      field: 'percentAffiliates',
      minWidth: 150,
      flex: 1,
    },
    { headerName: '% Total', field: 'percentTotal', minWidth: 150, flex: 1 },
    { headerName: 'Commission', field: 'commission', minWidth: 150, flex: 1 },
    {
      headerName: 'Total Commissions',
      field: 'totalCommissions',
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: 'Last Signup',
      field: 'lastSignup',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellLastSignup,
    },
  ];

  filters: Filter[] = [{ display: 'Affiliate', id: 'name', label: 'Contains', type: 'text' }];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  @computed private get dateRange(): DateRangeExternalPicker.IDateRange | undefined {
    return this.activeFilters.fromDate && this.activeFilters.toDate
      ? { fromDate: this.activeFilters.fromDate, toDate: this.activeFilters.toDate }
      : { toDate: new Date().toString() };
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
    this.fetchStats();
  }

  /** Before unmounting the component, dispose of all reactions created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    return (
      <>
        <Box mt={3}>
          <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} showDateRange />
        </Box>
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <HorizontalStatCard
                icon={AccountGroupOutline}
                duration={1}
                title="Count"
                color="primary"
                dateRange={this.dateRange}>
                {this.stats && this.stats.count}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <HorizontalStatCard
                icon={Percent}
                duration={1}
                title="% of all signups"
                color="yellow"
                suffix="%"
                dateRange={this.dateRange}
                decimals={2}
                separator={`.`}>
                {this.stats && this.stats.percent}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <HorizontalStatCard
                icon={AssuredWorkloadRounded}
                duration={1}
                title="Total signups"
                color="secondary"
                dateRange={this.dateRange}>
                {this.stats && this.stats.total}
              </HorizontalStatCard>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchAffiliateConversions}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(ByAffiliate);
