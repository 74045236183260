import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, Route, Switch, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { ManagerScope } from 'stores/UserStore';

import { paths } from 'routes';

import { setTitle } from 'services';

import DashboardLayout from 'containers/DashboardLayout';

import Title from 'components/Title';
import TabBar from 'components/TabBar/TabBar';
import { TipsReportChildProps, ETipsReportTab } from './Tabs/common';
import TipsReportByAccount from './Tabs/TipsReportByAccount';
import TipsReportByLocation from './Tabs/TipsReportByLocation';
import TipsReportByTalent from './Tabs/TipsReportByTalent';
import { useStores } from 'containers/App/App';

const PAGE_TITLE = 'Stats';

const TipsReport = () => {
  const { userStore } = useStores();
  const { pathname } = useLocation();

  const [isMobile, setIsMobile] = useState(false);

  const childProps = useMemo((): TipsReportChildProps => {
    const isOwner = userStore.scope.kind === 'owner';
    const isManager = userStore.scope.kind === 'manager';

    return {
      isAdmin: userStore.authUser.isAdmin,
      isOwner,
      isGlobalOwner: userStore.scope.kind === 'global_owner',
      isManager: isManager,
      accountId: isOwner ? userStore.currentAccount?.id : undefined,
      locationId: isManager ? (userStore.scope as ManagerScope).locationId : undefined,
      isMobile,
    };
  }, [isMobile, userStore.authUser.isAdmin, userStore.currentAccount?.id, userStore.scope]);

  const tabs = useMemo(() => {
    return [
      {
        label: 'By Account',
        component: RouterLink,
        to: paths.analytics().tipsReportByAccount(),
        selected: pathname.includes(ETipsReportTab.ACCOUNT),
      },
      {
        label: 'By Location',
        component: RouterLink,
        to: paths.analytics().tipsReportByLocation(),
        selected: pathname.includes(ETipsReportTab.LOCATION),
      },
      {
        label: 'By Employees',
        component: RouterLink,
        to: paths.analytics().tipsReportByEmployees(),
        selected: pathname.includes(ETipsReportTab.TALENT),
      },
    ];
  }, [pathname]);

  useEffect(() => {
    setTitle(PAGE_TITLE, { noSuffix: false });
    const handleResize = () => setIsMobile(window.matchMedia('(max-width:680px)').matches);

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DashboardLayout>
      <>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="flex-start">
            <Title mb={3}>{PAGE_TITLE}</Title>
          </Box>
        </Box>
        <TabBar mb={3} hide={!childProps.isAdmin && !childProps.isGlobalOwner} tabs={tabs} />

        <Switch>
          <Route
            path={paths.analytics().tipsReportByAccount()}
            render={(props) => <TipsReportByAccount {...props} {...childProps} />}
          />
          <Route
            path={paths.analytics().tipsReportByLocation()}
            render={(props) => <TipsReportByLocation {...props} {...childProps} />}
          />
          <Route
            path={paths.analytics().tipsReportByEmployees()}
            render={(props) => <TipsReportByTalent {...props} {...childProps} />}
          />
        </Switch>
      </>
    </DashboardLayout>
  );
};

export default TipsReport;
