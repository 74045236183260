import { useMemo } from 'react';
import { Link as RouterLink, Route, Switch, useLocation } from 'react-router-dom';
import DashboardLayout from 'containers/DashboardLayout';
import { paths } from 'routes';
import FeeSchedule from './FeeSchedule/FeeSchedule';
import Partners from './Partners/Partners';
import Title from 'components/Title';
import { ACL } from 'types';
import TabBar from 'components/TabBar/TabBar';
import { useStores } from 'containers/App/App';
import { observer } from 'mobx-react';

const RevShare = observer(() => {
  const { userStore } = useStores();
  const { hasPermission } = userStore;

  const { pathname } = useLocation();
  const conversionPaths = paths.revshare();

  const canPerformActions = useMemo(() => hasPermission(ACL.REV_SHARE), [hasPermission]);

  const tabs = useMemo(
    () => [
      {
        label: 'Fee Schedule',
        component: RouterLink,
        to: conversionPaths.feeSchedule(),
        selected: pathname === conversionPaths.feeSchedule(),
      },
      {
        label: 'Partners',
        component: RouterLink,
        to: conversionPaths.partners(),
        selected: pathname === conversionPaths.partners(),
      },
    ],
    [conversionPaths, pathname],
  );

  return (
    <DashboardLayout>
      <Title mb={3}>RevShare</Title>
      <TabBar mb={3} tabs={tabs} />
      <Switch>
        <Route
          path={conversionPaths.feeSchedule()}
          render={(props) => <FeeSchedule {...props} canPerformActions={canPerformActions} />}
        />
        <Route
          path={conversionPaths.partners()}
          render={(props) => <Partners {...props} canPerformActions={canPerformActions} />}
        />
      </Switch>
    </DashboardLayout>
  );
});

export default RevShare;
