import { useStores } from 'containers/App/App';
import { useScope } from 'hooks/useScope';
import { useEffect } from 'react';

const useNotifications = () => {
  const { notificationStore, uiStore } = useStores();
  const {
    setCanShowNotifications,
    numberOfNotifications,
    initializeNotificationSystem,
    setOpenNotification,
  } = notificationStore;
  const [isScope] = useScope();

  useEffect(() => {
    setCanShowNotifications(true);
  }, []);

  const trigger = uiStore?.appBar.trigger;
  const mobileView = uiStore?.mobileView;
  const handleOpenNotifications = () => {
    initializeNotificationSystem();
    setOpenNotification(!!numberOfNotifications);
  };

  return { isScope, numberOfNotifications, handleOpenNotifications, trigger, mobileView };
};

export default useNotifications;
