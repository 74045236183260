import React, { useState, useCallback, useEffect, useRef } from 'react';
import { AutocompleteComponent } from './AutocompleteComponent';
import { ChipsList } from './ChipsList';
import { ITagsAutocompleteOptions } from 'models';

interface TagsAutocompleteProps {
  disableCloseOnSelect: boolean;
  disabled?: boolean;
  popperFullWidth?: boolean;
  fetchOnInit?: boolean;
  optionList?: Record<string, string | number | Record<string, string>[]>[];
  options: ITagsAutocompleteOptions;
  selectedTags: (options?: Record<string, string | number>[] | { [x: number]: string }) => void;
  placeholder?: string;
  tags: Record<string, string | number>[];
  label: string;
  humanizedChip?: boolean;
  required?: boolean;
}

const TagsAutocomplete = ({
  disableCloseOnSelect,
  disabled,
  popperFullWidth,
  fetchOnInit,
  optionList,
  options,
  selectedTags,
  placeholder,
  tags,
  label,
  humanizedChip,
  required,
}: TagsAutocompleteProps) => {
  const [value, setValue] = useState<Record<string, string | number>[] | undefined>(tags || []);
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState(optionList || []);
  const [popperWidth, setPopperWidth] = useState(0);

  const keySearch = React.useMemo(
    () => options.displayField?.keySearch,
    [options?.displayField?.keySearch],
  );

  const getUniqueListBy = (value: Record<string, string | number>[] = [], key: string) => {
    const collection = Array.isArray(value) ? value : [value];
    if (collection.length === 0) {
      return undefined;
    }
    return [...new Map(collection.map((c) => [c[key], c])).values()];
  };

  const handleChange = useCallback(
    (
      e: React.ChangeEvent<{}>,
      list: Record<string, string | number | Record<string, string>[]>[],
    ) => {
      const keyboardEvent = e as React.KeyboardEvent<HTMLInputElement>;

      const val: Record<string, string | number>[] | undefined =
        !!keySearch?.phrase && typeof list[0] === 'string' && keyboardEvent.key === 'Enter'
          ? [{ [keySearch?.phrase]: list[0] }]
          : getUniqueListBy(list as Record<string, string | number>[], keySearch?.name);

      selectedTags(val);
      setValue(val || []);
    },
    [keySearch?.name, keySearch?.phrase],
  );

  const fetchCollection = useCallback(async (e?: any, newValue?: string) => {
    if (newValue === '') {
      if (e.nativeEvent.inputType === 'deleteContentBackward') {
        setCollection([]);
      }
      return;
    }
    setLoading(true);
    try {
      if (options.fetch) {
        const result = await options.fetch(newValue);
        setCollection(result);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (fetchOnInit) {
      fetchCollection();
    }
  }, [fetchCollection, fetchOnInit]);

  // Set dynamic width to Autocomplete Popper, considering filter block width
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const current = ref.current as Element;

    const observer = new ResizeObserver((entries) => {
      setPopperWidth(entries[0].contentRect.width);
    });
    observer.observe(current);
    return () => current && observer.unobserve(current);
  }, []);

  return (
    <div ref={ref}>
      <ChipsList
        values={value && Array.isArray(value) ? value.map((it) => it.name as string) : []}
        onDelete={(name: string) => {
          const res = value && Array.isArray(value) ? value.filter((it) => name !== it.name) : [];
          setValue(res);
          selectedTags(res);
        }}
        humanizedChip={humanizedChip}
      />

      <AutocompleteComponent
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        inputChangeHandler={!optionList ? fetchCollection : () => null}
        handleChange={handleChange}
        value={value || []}
        mapper={options}
        options={!optionList ? collection : optionList}
        loading={loading}
        popperWidth={popperWidth}
        popperFullWidth={popperFullWidth}
        label={!required ? label : `${label}*`}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TagsAutocomplete;
