import React from 'react';
import { Link as RouterLink, RouteComponentProps, Redirect, Route, Switch } from 'react-router-dom';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import { paths } from 'routes';

import DashboardLayout from 'containers/DashboardLayout';

import styles from '../styles';

import Title from 'components/Title/Title';
import TabBar from 'components/TabBar/TabBar';
import LocationsRevenue from './LocationsRevenue';
import StatesRevenue from './StatesRevenue';

const PAGE_TITLE = 'Revenue';

type RevenueOverviewProps = WithStyles<typeof styles> & RouteComponentProps;

/**
 * Container that displays overall Tippy revenue data for a selected date
 * range using a chart and two tables, each under its own tab (states, locations).
 * Chart shows overall Tippy revenue. States table is a list of all states that
 * have any revenue for the selected date range. Locations table is a list of
 * all registered Tippy salons and their respective revenue values.
 */
@observer
class RevenueOverview extends React.Component<RevenueOverviewProps> {
  constructor(props: RevenueOverviewProps) {
    super(props);
    makeObservable(this);
  }

  @computed public get tabs() {
    const pathStates = paths.analytics().statesRevenue();
    const pathLocations = paths.analytics().locationsRevenue();

    return [
      {
        label: 'States',
        component: RouterLink,
        to: pathStates,
        selected: this.props.location.pathname === pathStates,
      },
      {
        label: 'Locations',
        component: RouterLink,
        to: pathLocations,
        selected: this.props.location.pathname === pathLocations,
      },
    ];
  }

  render() {
    const pathStates = paths.analytics().statesRevenue();
    const pathLocations = paths.analytics().locationsRevenue();
    return (
      <DashboardLayout>
        <Title mb={3}> {PAGE_TITLE} </Title>
        <TabBar mb={3} tabs={this.tabs} />
        <Switch>
          <Route exact path={pathStates} component={StatesRevenue} />
          <Route exact path={pathLocations} component={LocationsRevenue} />
          <Route>
            <Redirect to={pathStates} />
          </Route>
        </Switch>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(RevenueOverview);
