import { Paper, IconButton, Box, Tooltip } from '@material-ui/core';
import { paths } from 'routes';
import { Link as RouterLink } from 'react-router-dom';
import { Map, FormatListBulleted } from 'mdi-material-ui';

import clsx from 'clsx';
import { filterPaperStyling } from '../styles';

interface FilterBarLocationsMapProps {
  isActive: boolean;
  mobileView: boolean;
}

export const FilterBarLocationsMap = ({ isActive, mobileView }: FilterBarLocationsMapProps) => (
  <Box sx={filterPaperStyling(mobileView)}>
    <Paper className={clsx('paperBoxShadow', 'paperRoot')}>
      <Box className="root">
        <Tooltip
          title={isActive ? 'Locations list' : 'Locations map'}
          placement="top"
          enterDelay={500}>
          <IconButton
            style={{ cursor: 'pointer' }}
            color="default"
            size="small"
            component={RouterLink}
            to={isActive ? paths.locations() : paths.locationMap()}>
            {isActive ? <FormatListBulleted fontSize="small" /> : <Map fontSize="small" />}
          </IconButton>
        </Tooltip>
      </Box>
    </Paper>
  </Box>
);
