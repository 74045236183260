import React from 'react';
import { action, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import * as models from 'models';
import { inject, WithModalStore, WithToastStore, WithUserStore, WithSettingStore } from 'stores';
import Api, { RequestMetaData, getErrorMsg } from 'api';
import Overlay from 'components/Overlay';

import { adaptForDataGrid, adaptForDataGridPro } from 'services';
import { paths } from 'routes';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import theme from 'containers/App/theme';

import styles from './styles';
import { DeleteOutline, Eye, Send, Pencil, CartArrowRight } from 'mdi-material-ui';
import { Filter } from 'models';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import Button from 'components/Button/Dialog/Button';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';

type PendingProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithUserStore &
  WithSettingStore &
  WithModalStore &
  WithToastStore & {};

function annotate(
  cart: Pick<models.Cart, 'account' | 'accountId' | 'id' | 'uid' | 'owner' | 'cartItem'>,
) {
  return {
    ...cart,
    name: cart.account.name,
    resend: cart.account.id,
    firstName: cart?.owner?.firstName || '',
    lastName: cart?.owner?.lastName || '',
    ownerEmail: cart?.owner?.email || '',
    uid: cart.uid,
    locationId: cart?.cartItem?.locationId,
  };
}

/**
 * The container for pending sales tab.
 */
@inject('userStore', 'toastStore', 'modalStore', 'settingStore')
@observer
class Pending extends React.Component<PendingProps> {
  constructor(props: PendingProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  public fetchPendingCartsData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.billing.getPendingCarts({
        ...rmd,
        filters: { ...this.activeFilters },
      }),
    annotate,
  );

  public fetchPendingCarts = adaptForDataGrid(Api.billing.getPendingCarts, annotate);
  @observable private refetchKey = Date.now();

  @observable public resendDialogOpen = false;
  @observable public accountIdToResend?: number;
  @observable public email = '';
  @observable public resendingEmail = false;
  @observable public success = false;
  @observable public cart?: models.Cart;
  @observable public viewingCart = false;
  @observable public ownerFirstName = '';

  @action.bound public openResendDialog(
    accountId: number,
    ownerEmail: string,
    ownerFirstName: string,
  ) {
    this.email = ownerEmail;
    this.accountIdToResend = accountId;
    this.ownerFirstName = ownerFirstName;
    this.resendDialogOpen = true;
  }

  @action.bound public closeCartView() {
    this.cart = undefined;
    this.viewingCart = false;
  }

  @action.bound public updateEmail(e: React.ChangeEvent<HTMLInputElement>) {
    this.email = e.target.value;
  }

  @action.bound public resendEmail = flow(function* (this: Pending) {
    try {
      this.resendingEmail = true;
      yield Api.billing.sendPurchaseOrder(this.accountIdToResend!, this.email, this.ownerFirstName);
      this.closeResendDialog();
      this.props.toastStore!.success('Purchase order successfully sent!');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.resendingEmail = false;
    }
  });

  @action.bound public viewCart = flow(function* (this: Pending, cart: models.Cart) {
    try {
      this.viewingCart = true;
      const resp = yield Api.billing.getCart(cart.accountId);
      this.cart = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.resendEmail();
  };

  @action.bound public closeResendDialog() {
    this.email = '';
    this.accountIdToResend = undefined;
    this.resendDialogOpen = false;
    this.ownerFirstName = '';
    this.success = false;
    this.resendingEmail = false;
  }

  @action.bound public handleClickDeleteRow = flow(function* (this: Pending, row: any) {
    const actionText = 'Delete';
    const name = row.account.name;
    const modalTitle = `${actionText} Purchase Order`;
    const modalMessage = `Are you sure you want to ${actionText.toLowerCase()} ${name} purchase order?`;
    const confirmed = yield this.props.modalStore!.confirm(modalTitle, modalMessage, {
      confirmLabel: actionText,
    });

    if (confirmed) {
      try {
        yield Api.billing.deletePendingSale(row.account.id);
        this.props.toastStore!.success(`${name} successfully ${actionText.toLowerCase()}d`);
        this.refetchKey = Date.now();
        this.activeFilters = { ...this.activeFilters };
      } catch (e: any) {
        this.props.toastStore!.error(getErrorMsg(e));
      }
    }
  });

  @action.bound public handleClickFinalizeOrder = flow(function* (this: Pending, row: any) {
    const actionText = 'Finalize';
    const name = row.account.name;
    const modalTitle = `${actionText} Purchase Order`;
    const modalMessage = `Are you sure you want to ${actionText.toLowerCase()} ${name} purchase order?`;
    const confirmed = yield this.props.modalStore!.confirm(modalTitle, modalMessage, {
      confirmLabel: actionText,
    });

    if (confirmed) {
      try {
        yield Api.billing.finalizeCart(
          row.accountId,
          row.ownerEmail,
          row.firstName,
          row.locationId,
        );
        this.props.toastStore!.success(`${name} successfully ${actionText.toLowerCase()}d`);
        this.refetchKey = Date.now();
        this.activeFilters = { ...this.activeFilters };
      } catch (e: any) {
        this.props.toastStore!.error(getErrorMsg(e));
      }
    }
  });

  renderCellActions = ({ row }: any) => {
    return (
      <>
        <IconButton
          color="primary"
          component={RouterLink}
          to={paths.adminAccountSignup().locations(row.accountId)}>
          <Tooltip title="Edit">
            <Pencil fontSize="small" />
          </Tooltip>
        </IconButton>
        <IconButton
          color="primary"
          component={RouterLink}
          to={
            row.requiresCreditCard
              ? paths.checkoutPreview(row.uid)
              : paths.adminAccountSignup().review(row.accountId)
          }>
          <Tooltip title="Checkout preview">
            <Eye fontSize="small" />
          </Tooltip>
        </IconButton>
        {row.requiresCreditCard && (
          <IconButton
            color="primary"
            onClick={() => this.openResendDialog(row.accountId, row.ownerEmail, row.firstName)}>
            <Tooltip title="Resend purchase order">
              <Send fontSize="small" />
            </Tooltip>
          </IconButton>
        )}
        {!row.requiresCreditCard && (
          <IconButton color="primary" onClick={() => this.handleClickFinalizeOrder(row)}>
            <Tooltip title="Finalize purchase order">
              <CartArrowRight fontSize="small" />
            </Tooltip>
          </IconButton>
        )}
        <IconButton onClick={() => this.handleClickDeleteRow(row)}>
          <Tooltip title="Delete row">
            <DeleteOutline style={{ color: theme.menu.main }} fontSize="small" />
          </Tooltip>
        </IconButton>
      </>
    );
  };

  gridColumns = [
    { headerName: 'Account name', field: 'name', minWidth: 250, flex: 1 },
    { headerName: 'First name', field: 'firstName', minWidth: 150, flex: 1 },
    { headerName: 'Last name', field: 'lastName', minWidth: 160, flex: 1 },
    { headerName: 'Owner email', field: 'ownerEmail', minWidth: 250, flex: 1 },
    {
      headerName: 'Actions',
      field: 'resend',
      type: 'actions',
      minWidth: 200,
      renderCell: this.renderCellActions,
    },
  ];

  filters: Filter[] = [{ display: 'Name', id: 'name', label: 'Contains', type: 'text' }];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  /** Display results in datagrid */
  render() {
    const classes = this.props.classes;
    const { dialogActions } = classes;
    return (
      <Box>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.resendDialogOpen}
          onClose={this.closeResendDialog}
          className={classes.emailDialog}>
          <DialogTitle>
            <Typography style={{ fontSize: 28, fontWeight: 400 }}>Resend purchase order</Typography>
          </DialogTitle>
          <form onSubmit={this.handleSubmit}>
            <DialogContent className={classes.dialogContent}>
              <Overlay display={this.resendingEmail}>
                <CircularProgress />
              </Overlay>
              <Overlay display={this.success}>Email successfully sent!</Overlay>
              <DialogContentText style={{ marginBottom: theme.spacing(3) }}>
                Enter the email to which you want the purchase order to be resent.
              </DialogContentText>
              <OutlinedInput
                autoFocus
                value={this.email}
                onChange={this.updateEmail}
                fullWidth
                label="Email"
              />
            </DialogContent>
            <DialogActions className={dialogActions}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={this.resendingEmail}>
                Resend order
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mt={3}>
              <FilterBar
                filters={this.filters}
                onChange={this.handleFiltersOnChange}
                showDateRange
              />
              {this.filtersInitReady && (
                <DataGridInfiniteScroll
                  columns={this.gridColumns}
                  fetch={this.fetchPendingCartsData}
                  refetchKey={this.activeFilters}
                  disableColumnMenu
                  pathname={this.props.location.pathname}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(Pending);
