import { faChartLine } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Paper, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { filterPaperStyling } from '../styles';

export interface FilterBarGraphProps {
  isActive: boolean;
  onActive: () => void;
  mobileView?: boolean;
}

export const FilterBarGraph = ({ isActive, onActive, mobileView = false }: FilterBarGraphProps) => (
  <Box sx={filterPaperStyling(mobileView)}>
    <Paper
      className={clsx(
        isActive ? 'paperBoxShadowActive' : 'paperBoxShadow',
        'graphButton',
        'root',
        'paperRoot',
      )}
      onClick={onActive}>
      <Tooltip title={isActive ? 'Hide graph' : 'Show graph'} placement="top" enterDelay={500}>
        <FontAwesomeIcon icon={faChartLine} fontSize={23} />
      </Tooltip>
    </Paper>
  </Box>
);
