import { action, autorun, makeObservable, observable, reaction } from 'mobx';
import BaseStore from './BaseStore';
import RootStore from './RootStore';
import * as LocalStorage from 'services/localStorage';
import { EDateRangeType, IDateRange, getDateRange } from 'components/DateRangeExternalPicker';
import { ScopeType } from 'stores';

type ValueScope = {
  kind: ScopeType;
};

type KeyValue = {
  [x: string]: any;
};

interface ObjectState {
  source: string;
  value: KeyValue;
}

interface Scope {
  scope: ValueScope;
  status: ObjectState[];
}

interface Setting {
  user: number | undefined;
  options: Scope[];
}

interface ValueOptions {
  keyName: string;
  value: Setting;
}

enum EStoreDataTypes {
  DATE = 'date',
  FILTERS = 'filters',
  GRID = 'grid',
}

export default class SettingStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable public setting!: Setting;
  @observable public scope: Scope = {
    scope: this.rootStore.userStore.scope,
    status: [],
  };

  @action.bound init(): void {
    autorun(() => {
      this.setting = this.getSetting();
    });

    reaction(
      () => this.setting,
      () => {
        this.scope = this.getScope();
      },
    );
  }

  getAll(): ValueOptions[] | undefined {
    const keyNameSettings = Object.keys(localStorage).filter((v) => v.includes('setting-'));
    if (keyNameSettings.length > 0) {
      return keyNameSettings.map((key) => ({
        keyName: key,
        value: LocalStorage.get(key) as Setting,
      }));
    }
    return undefined;
  }

  setAll(value: ValueOptions[] | undefined) {
    if (Array.isArray(value)) {
      value.forEach(({ keyName, value }: ValueOptions) => {
        LocalStorage.set(keyName, value);
      });
    }
  }

  private setSetting(value: Setting) {
    LocalStorage.set(`setting-${value.user}`, value);
  }

  private getSetting(): Setting {
    const defaultSetting: Setting = {
      user: this.rootStore.userStore.user?.id,
      options: [],
    };
    const value = LocalStorage.get(`setting-${defaultSetting.user}`) as Setting;

    if (value) {
      const notStatus = value.options.some((setting) => !setting.status);
      if (notStatus) {
        this.setSetting(defaultSetting);
        return defaultSetting;
      }
      return value;
    } else {
      this.setSetting(defaultSetting);
      return defaultSetting;
    }
  }

  private setScope(scopeValue: Scope) {
    const scopes = this.setting?.options.filter(
      ({ scope }) => JSON.stringify(scope) !== JSON.stringify(scopeValue.scope),
    );
    this.setting.options = [...scopes, scopeValue];
    this.setSetting(this.setting);
  }

  private getScope(): Scope {
    const initialScope = {
      scope: this.rootStore.userStore.scope,
      status: [],
    };
    const scope = this.setting?.options.find(
      ({ scope }) => JSON.stringify(scope) == JSON.stringify(initialScope.scope),
    );
    if (scope) {
      return scope;
    } else {
      this.setScope(initialScope);
      return initialScope;
    }
  }

  private getState(sourceName: string): ObjectState | undefined {
    return this.getScope().status.find(({ source }) => source === sourceName);
  }

  private setStates(sourceName: string, typeName: EStoreDataTypes, value: unknown) {
    const scope = this.getScope();
    const otherStatus = scope.status.filter(({ source }) => source !== sourceName);
    const currentState = scope.status.find(({ source }) => source === sourceName);

    let _value = { [typeName]: value };
    if (typeName === EStoreDataTypes.GRID) {
      const { mobileView } = this.rootStore.uiStore;
      if (currentState?.value?.grid) {
        const grid = currentState?.value?.grid;
        _value = { [typeName]: { ...grid, [mobileView ? 'mobile' : 'desktop']: value } };
      }
    }

    const newState: ObjectState = {
      source: sourceName,
      value: Object.assign(currentState?.value ?? {}, _value),
    };

    scope.status = [...otherStatus, newState];
    this.setScope(scope);
  }

  getSource(sourceName: string, key?: EStoreDataTypes) {
    const source = this.getState(sourceName)?.value;
    if (!source) return;

    if (!key) return source;
    if (key !== EStoreDataTypes.GRID) return source[key];

    const { mobileView } = this.rootStore.uiStore;
    if (source[key]) return source[key][mobileView ? 'mobile' : 'desktop'];
  }

  //utilities for saved history data

  getDate = (sourceName: string, defaultType?: EDateRangeType): IDateRange => {
    const date = this.getSource(sourceName, EStoreDataTypes.DATE) as IDateRange | undefined;
    if (date) {
      switch (date.dateRangeType) {
        case EDateRangeType.DATE_TIMER:
          return date;
        default:
          return getDateRange(date.dateRangeType);
      }
    }
    return getDateRange(defaultType);
  };

  @action.bound setDate = (source: string, value: IDateRange): void => {
    this.setStates(source, EStoreDataTypes.DATE, value);
  };

  getGrid(sourceName: string) {
    return this.getSource(sourceName, EStoreDataTypes.GRID);
  }

  setGrid(source: string, params: { colDef: { field: string }; width: number }) {
    const sizes = this.getSource(source, EStoreDataTypes.GRID);
    this.setStates(
      source,
      EStoreDataTypes.GRID,
      Object.assign(sizes ?? {}, {
        [params.colDef.field]: params.width,
      }),
    );
  }
}

export interface WithSettingStore {
  settingStore?: SettingStore;
}
