import { OutlinedInputVariant } from 'components/Input/OutlinedInput/OutlinedInput';
import { EInput } from 'models/Input';
import { ReactNode } from 'react';
import { UseControllerProps } from 'react-hook-form';

/**
 * Form fields Names
 */
export enum EFormFields {
  EMAIL = 'email',
  NAME = 'name',
  PHONE = 'phone',
  LOCATION = 'location',
  PRODUCTS = 'products',
  INVOICE_TYPE = 'invoiceType',
  PAYMENT_METHODS = 'paymentMethods',
  AMOUNT = 'amount',
  NOTE = 'note',
  BILLING_ENTITY_ID = 'billingEntityId',
}

/**
 * Form fields Labels
 */
export enum EFormFieldLabels {
  EMAIL = 'Email',
  FIRST_NAME = 'First Name',
  NAME = 'Name',
  PHONE = 'Phone',
  LOCATION = 'Location',
  PRODUCTS = 'Products',
  INVOICE_TYPE = 'Invoice Type',
  SEARCH_LOCATION = 'Search Location',
  PAYMENT_METHODS = 'Payment Methods',
  CORRECTION_AMOUNT = '$ Correction Amount',
  CORRECTION_NOTE = 'Your Note for Correction',
  BILLING_GROUP = 'Billing Group',
}

/**
 * Form select options
 */
export interface ISelectOptions {
  label: string;
  value: string | number;
}

/**
 * Form field Config, used in Form Builder
 */
export interface IFormFieldsConfig {
  name: string;
  rules?: UseControllerProps['rules'];
  label: EFormFieldLabels;
  select?: boolean;
  optionsNodes?: ReactNode;
  optionsList?: ISelectOptions[];
  fetch?: (value?: string) => Promise<any[]>;
  getLabel?: (value?: any) => string;
  type?: EInput;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: OutlinedInputVariant;
  size?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  tagsSelected?: any[];
  className?: string;
  note?: string;
}

export interface ISelectOptions {
  label: string;
  value: string | number;
  options?: ReactNode;
}

/**
 * Form fields Error Messages
 */
export const ErrorMessagesForms = {
  FIELD_REQUIRED: (field: EFormFieldLabels) => `${field} is required.`,
  AMOUNT_NOT_VALID: 'Amount is not valid',
  EMAIL_NOT_VALID: 'Email is not valid',
  PASSWORDS_NO_MATCH: 'The passwords do not match.',
  PHONE_NOT_VALID: 'Phone number is not valid',
  TEXT_NOT_VALID: 'Confirmation text is not valid',
  URL_NOT_VALID: 'Not valid URL',
};
