import { Box, Link, Tooltip } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { RequestMetaData } from 'api';
import OptionsMenu from 'components/OptionsMenu';
import { action, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Affiliate, Filter } from 'models';
import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { paths } from 'routes';
import { numericStringToUsd } from 'services';
import { adaptForDataGridPro } from 'services/datagrid';
import { inject, WithModalStore, WithToastStore } from 'stores';
import styles from './styles';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import { valueOrNA } from 'utils/helper';

interface AffiliatesTableProps
  extends WithStyles<typeof styles>,
    WithModalStore,
    WithToastStore,
    RouteComponentProps {
  affiliates?: Affiliate[];
}

interface IBadgeConfig {
  [key: string]: string;
}

const badgeConfig: IBadgeConfig = {
  CREATED: ChipStatusColors.PURPLE,
  APPROVED: ChipStatusColors.GREEN,
  REJECTED: ChipStatusColors.RED,
  TERMINATED: ChipStatusColors.GREY,
};

/**
 * Truncates long strings to specified length (proposed workaround
 * until DataGrid can again support ellipsis)
 * @param input The string to truncate
 * @param max Number of characters before ellipsis
 */
function ellipsize(input: string, max: number) {
  return input?.length > max ? `${input.substring(0, max)}...` : valueOrNA(input);
}

/** Annotates affiliate with additional data */
function annotateAffiliate(affiliate: Affiliate) {
  let organizationName = undefined;
  const region = affiliate.region;
  if (region) {
    const organization = region.organization;
    if (organization) {
      organizationName = organization.name;
    }
  }
  return {
    ...affiliate,
    firstName: affiliate.user!.firstName,
    lastName: affiliate.user!.lastName,
    organizationName,
    email: affiliate.user!.email,
    phone: affiliate.user!.phone,
    status: affiliate.status.replace(/_/gi, ' ').toUpperCase(),
    commissionFee: numericStringToUsd(affiliate.commissionFee),
  };
}

/**
 * Displays a paginated, searchable list of affiliates
 */
@inject('modalStore', 'toastStore')
@observer
class AffiliatesTable extends React.Component<AffiliatesTableProps> {
  constructor(props: AffiliatesTableProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  public fetchAffiliatesData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.marketing.getAffiliates({
        ...rmd,
        filters: { ...this.activeFilters },
      }),
    annotateAffiliate,
  );

  setStatusMenuOptions = (affiliateId: number) => {
    return [
      {
        label: 'Approve',
        onClick: () => {
          this.updateAffiliateStatus(affiliateId, 'approved');
        },
      },
      {
        label: 'Reject',
        onClick: () => {
          this.updateAffiliateStatus(affiliateId, 'rejected');
        },
      },
    ];
  };

  renderStatusActions = ({ row }: any) => {
    const affiliateId = row.id;
    const isApprovable = row.status === 'CREATED';
    return <>{isApprovable && <OptionsMenu items={this.setStatusMenuOptions(affiliateId)} />}</>;
  };

  @observable datagridKey = Date.now();

  /** Displays modal confirmation window */
  @action.bound public confirmAffiliateStatusChange = flow(function* (
    this: AffiliatesTable,
    status: string,
  ) {
    const dialogTitle = `Confirm status update`;
    const dialogBody = `Are you sure you wish to set this affiliate's status to ${status.toUpperCase()}?`;
    const options = {
      confirmLabel: 'YES',
      cancelLabel: 'NO',
    };
    return yield this.props.modalStore!.confirm(dialogTitle, dialogBody, options);
  });

  @action.bound public updateAffiliateStatus = flow(function* (
    this: AffiliatesTable,
    id: number,
    status: string,
  ) {
    const toastStore = this.props.toastStore!;
    const confirmed = yield this.confirmAffiliateStatusChange(status);
    if (!confirmed) {
      return;
    }
    try {
      yield Api.marketing.setAffiliateStatus({ id, status });
      toastStore.push({
        type: 'success',
        message: `Affiliate status successfully set to ${status.toUpperCase()}`,
      });
    } catch (error: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: error.response && error.response.data && error.response.data.error.message,
      });
    } finally {
      this.datagridKey = Date.now();
    }
  });

  renderCellName = ({ row, value }: any) => {
    return (
      <Link component={RouterLink} to={paths.userDetails(row.userId).root()}>
        {value}
      </Link>
    );
  };

  renderCellEmailAddress = ({ row }: any) => {
    const email = row.email;
    return (
      <Tooltip title={email}>
        <Box>{ellipsize(email, 20)}</Box>
      </Tooltip>
    );
  };

  renderCellStatus = ({ value }: any) => {
    const background = badgeConfig[value as keyof IBadgeConfig] as ChipStatusColors;
    return <ChipStatusTag label={value} color={background} />;
  };

  gridColumns = [
    {
      headerName: 'First Name',
      field: 'firstName',
      minWidth: 80,
      flex: 1,
      renderCell: this.renderCellName,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      minWidth: 80,
      flex: 1,
      renderCell: this.renderCellName,
    },
    {
      headerName: 'Organization',
      field: 'organizationName',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Email',
      field: 'email',
      minWidth: 250,
      flex: 1,
      renderCell: this.renderCellEmailAddress,
    },
    { headerName: 'Phone', field: 'phone', minWidth: 150, flex: 1, sortable: false },
    { headerName: 'Code', field: 'code', minWidth: 120, flex: 1, sortable: false },
    {
      headerName: 'Commission',
      field: 'commissionFee',
      minWidth: 150,
      flex: 1,
      type: 'number',
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 200,
      renderCell: this.renderCellStatus,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      minWidth: 80,
      renderCell: this.renderStatusActions,
      sortable: false,
    },
  ];

  filters: Filter[] = [
    { display: 'First Name', id: 'firstName', label: 'Contains', type: 'text' },
    { display: 'Last Name', id: 'lastName', label: 'Contains', type: 'text' },
    { display: 'Organization', id: 'organizationName', label: 'Contains', type: 'text' },
    { display: 'Email', id: 'email', label: 'Exactly', type: 'text' },
    { display: 'Phone', id: 'phone', label: 'Exactly', type: 'text' },
    { display: 'Code', id: 'code', label: 'Exactly', type: 'text' },
    {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'CREATED', value: 'created' },
        { label: 'APPROVED', value: 'approved' },
        { label: 'REJECTED', value: 'rejected' },
        { label: 'TERMINATED', value: 'terminated' },
      ],
    },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <Box mb={3}>
        <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} />
        {this.filtersInitReady && (
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchAffiliatesData}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(AffiliatesTable);
