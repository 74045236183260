import { useState } from 'react';
import { rootStore } from 'containers/App/App';
import { useWorkspaceContext } from './WorkspaceContext';
import { EManagerPermission } from 'types';

const useWorkspace = () => {
  const { error, locationUsers, accountSections, filteredLocationUsers, getUserLocations } =
    useWorkspaceContext();
  const [open, setOpenDialog] = useState(false);

  const { userStore, managerPermissionsStore } = rootStore;
  const { isAdmin, isManagerScope, isOwnerScope, isGlobalOwnerScope } = userStore;
  const currentLocationUser = filteredLocationUsers && filteredLocationUsers[0];
  const isLocationManager = locationUsers?.some((user) => user.isManager);
  const showLoader =
    ((isAdmin || isOwnerScope || isGlobalOwnerScope) && !accountSections) ||
    (isManagerScope && !currentLocationUser);
  const hasManageEmployeeIdPermission = managerPermissionsStore.hasPermission(
    EManagerPermission.MANAGE_EMPLOYEE_IDS,
  );

  return {
    isManagerScope,
    isLocationManager,
    currentLocationUser,
    hasManageEmployeeIdPermission,
    accountSections,
    locationUsers,
    error,
    open,
    showLoader,
    setOpenDialog,
    getUserLocations,
  };
};

export default useWorkspace;
