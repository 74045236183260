// core libraries/third party libraries
import { Component, Fragment } from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { AxiosResponse } from 'axios';
// components/containers
import DashboardLayout from 'containers/DashboardLayout';
// services/models/store
import { inject, WithToastStore, WithSettingStore } from 'stores';
import Api, { ApiResponse, getErrorMsg, RequestMetaData } from 'api';
import * as models from 'models';
import { setTitle, adaptForDataGridPro } from 'services';
import { EDateFormat } from '../../utils/helper';
import { numericStringToUsd } from 'services/currency';
// Styles/Material UI
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles';

import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { v4 as uuidv4 } from 'uuid';
import { RouteComponentProps } from 'react-router-dom';
import Title from 'components/Title';
import { Box } from '@material-ui/core';
import { LiabiltySummary } from './LiabiltySummary.tsx/LiabiltySummary';
import { GridColDef } from '@mui/x-data-grid-pro';

const PAGE_TITLE = 'Liability';

type LiabilityLedgerProps = WithStyles<typeof styles> &
  WithToastStore &
  WithSettingStore &
  RouteComponentProps;
@inject('toastStore', 'settingStore')
@observer
class LiabilityLedger extends Component<LiabilityLedgerProps> {
  constructor(props: LiabilityLedgerProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  // Data for summary card
  @observable private summaryData?: models.LiabilityLedgerSummary;

  /** Fetch data for summary card */
  @action.bound private fetchSummary = async () => {
    try {
      const resp: AxiosResponse<ApiResponse<models.LiabilityLedgerSummary>> =
        await Api.analytics.liability.getSummary(
          this.activeFilters.fromDate as string,
          this.activeFilters.toDate as string,
        );

      if (resp && resp.data && resp.data.data) {
        this.summaryData = resp.data.data;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  @action.bound public fetchLiabilityLedger = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    return Api.analytics.getLiabilityLedger({
      ...rmd,
      filters: { ...this.activeFilters },
    });
  }, this.annotate);

  /** Annotates refund with extra data */
  annotate(liability: any) {
    return {
      id: uuidv4(),
      ...liability,
    };
  }

  async componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
    await this.fetchSummary();
  }

  renderValue(value: string) {
    const { classes } = this.props;
    const numberValue = Number(value);
    return (
      <span className={numberValue < 0 ? classes.negativeNumber : classes.positiveNumber}>
        {numericStringToUsd(value)}
      </span>
    );
  }

  renderCellRow = ({ value }: any) => {
    return <Fragment>{this.renderValue(value)}</Fragment>;
  };

  gridColumns: GridColDef[] = [
    {
      headerName: 'Date',
      field: 'date',
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format(EDateFormat.DEFAULT),
    },
    {
      headerName: 'Tips',
      field: 'tips',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellRow,
    },
    {
      headerName: 'Refunds',
      field: 'refunds',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellRow,
    },
    {
      headerName: 'ACH',
      field: 'achPayouts',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellRow,
      sortable: false,
    },
    {
      headerName: 'Failed ACH',
      field: 'achFailedPayouts',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellRow,
      sortable: false,
    },
    {
      headerName: 'RT',
      field: 'instantPayouts',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellRow,
      sortable: false,
    },
    {
      headerName: 'Failed RT',
      field: 'instantFailedPayouts',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellRow,
      sortable: false,
    },
    {
      headerName: 'Corrections',
      field: 'corrections',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellRow,
      sortable: false,
    },
    {
      headerName: 'Balance',
      field: 'balance',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellRow,
    },
  ];

  filters: models.Filter[] = [];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <DashboardLayout>
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center">
            <Box display="flex" justifyContent="flex-start">
              <Title mb={3}>{PAGE_TITLE}</Title>
            </Box>
          </Box>
          <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} showDateRange />

          {/* Summary cards */}
          {this.summaryData && <LiabiltySummary summaryData={this.summaryData} />}

          <Box mt={3}>
            {this.filtersInitReady && (
              <DataGridInfiniteScroll
                columns={this.gridColumns}
                fetch={this.fetchLiabilityLedger}
                refetchKey={this.activeFilters}
                disableColumnMenu
                pathname={this.props.location.pathname}
              />
            )}
          </Box>
        </>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(LiabilityLedger);
