import { Filter } from 'models';
import { FilterBarChipsSingle } from './FilterBarChipsSingle';

interface FilterBarChipsListProps {
  filters: Filter[];
  onOpenFilter: (filter: Filter) => void;
  onRemoveFilter: (id: string) => void;
}

export const FilterBarChipsList = ({
  filters,
  onOpenFilter,
  onRemoveFilter,
}: FilterBarChipsListProps) => {
  return (
    <>
      {filters
        .filter((filter) => filter.value)
        .map((filter) => (
          <FilterBarChipsSingle
            key={filter.id}
            filter={filter}
            onOpenFilter={onOpenFilter}
            onRemoveFilter={onRemoveFilter}
          />
        ))}
    </>
  );
};
