import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  EFormFieldLabels,
  EFormFields,
  FORM_AUTOCOMPLETE,
  FORM_SELECT,
  IFormFieldsConfig,
} from 'utils/forms';
import Api, { getErrorMsg } from 'api';
import { rootStore, useStores } from 'containers/App/App';
import * as models from 'models';
import { EInvoiceItemType, INVOICE_ITEM_TYPES } from 'types';
import { IBillingGeneralPanelProps } from '../BillingGeneralPanel';

export interface IAddLicenseForm {
  [EFormFields.LOCATION]: models.Location;
  [EFormFields.PRODUCTS]: number;
  [EFormFields.INVOICE_TYPE]: EInvoiceItemType;
}

type TUseAddLicenseDialog = Pick<IBillingGeneralPanelProps, 'accountId'> & {
  setCart: React.Dispatch<React.SetStateAction<models.Cart | undefined>>;
  numberOfKiosks: number;
};

const useAddLicenseDialog = ({ setCart, accountId, numberOfKiosks }: TUseAddLicenseDialog) => {
  const { toastStore } = useStores();
  const [products, setProducts] = useState<models.Product[]>([]);
  const [addingLicenseToCart, setAddingLicensToCart] = useState(false);

  const searchLocationField: IFormFieldsConfig = useMemo(
    (): IFormFieldsConfig => ({
      ...FORM_AUTOCOMPLETE({
        name: EFormFields.LOCATION,
        label: EFormFieldLabels.SEARCH_LOCATION,
        fetch: async (search = ''): Promise<models.Location[]> => {
          try {
            const resp = await Api.core.searchAccountLocations(accountId, { name: search });
            return resp.data ? resp.data.data : [];
          } catch (e: any) {
            toastStore!.push({ type: 'error', message: getErrorMsg(e) });
            return [];
          }
        },
        getLabel: (location: models.Location): string => location?.name || '',
        disabled: addingLicenseToCart,
      }),
    }),
    [accountId, toastStore, addingLicenseToCart],
  );

  const productsField: IFormFieldsConfig = useMemo(
    (): IFormFieldsConfig => ({
      ...FORM_SELECT({
        name: EFormFields.PRODUCTS,
        label: EFormFieldLabels.PRODUCTS,
        optionsList: products.map((p) => ({ label: p.name, value: p.id })),
        disabled: addingLicenseToCart,
      }),
    }),
    [products, addingLicenseToCart],
  );

  const invoiceTypeField = useMemo(
    (): IFormFieldsConfig => ({
      ...FORM_SELECT({
        name: EFormFields.INVOICE_TYPE,
        label: EFormFieldLabels.INVOICE_TYPE,
        optionsList: Object.keys(INVOICE_ITEM_TYPES).map((key) => ({
          value: key,
          label: INVOICE_ITEM_TYPES[key],
        })),
        disabled: addingLicenseToCart,
      }),
    }),
    [addingLicenseToCart],
  );

  const form = useForm<IAddLicenseForm>({
    defaultValues: {
      [searchLocationField.name]: '',
      [productsField.name]: '',
      [invoiceTypeField.name]: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    async function fetchProducts() {
      try {
        const { data } = await Api.billing.getProducts();
        const _products = data?.data || [];
        setProducts(_products);
        /** If there is only 1 product, set the value of products field to this product */
        if (_products.length === 1) {
          form.setValue(EFormFields.PRODUCTS, _products[0].id);
        }
      } catch (e) {
        rootStore.toastStore!.error(getErrorMsg(e));
      }
    }

    fetchProducts();
  }, [form]);

  const handleFormReset = () => {
    form.reset();
  };

  const fields = [searchLocationField, productsField, invoiceTypeField];

  /** Adds a location to the cart */
  const addLicenseToCart = (data: IAddLicenseForm) => {
    const addToCart = async (formData: IAddLicenseForm) => {
      try {
        const { location, products: productId, invoiceType } = formData;
        setAddingLicensToCart(true);
        const { data } = await Api.billing.addToCart({
          accountId,
          productId: productId,
          invoiceItemType: invoiceType,
          quantity: numberOfKiosks,
          locationId: location!.id,
        });

        form.reset();

        /** If there is only 1 product, set the value of products field to this product */
        if (products.length === 1) {
          form.setValue(EFormFields.PRODUCTS, products[0].id);
        }

        numberOfKiosks = 1;
        // Set the cart to be equal to the response
        setCart(data.data);
      } catch (e: any) {
        toastStore!.error(getErrorMsg(e));
      } finally {
        setAddingLicensToCart(false);
      }
    };
    addToCart(data);
  };

  return { form, fields, addingLicenseToCart, addLicenseToCart, handleFormReset };
};

export default useAddLicenseDialog;
