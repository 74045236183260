import { Chip } from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import { useMemo } from 'react';
import { Filter, FilterItem } from 'models';

interface FilterBarChipsSingleProps {
  filter: Filter;
  onOpenFilter: (filter: Filter) => void;
  onRemoveFilter: (id: string) => void;
}

export const FilterBarChipsSingle = ({
  filter,
  onOpenFilter,
  onRemoveFilter,
}: FilterBarChipsSingleProps) => {
  const getValue = useMemo((): string => {
    if (filter.items) {
      /** SELECT */
      return filter.items.filter(
        (i: FilterItem) => filter.value && filter.value[0].id === i.value,
      )[0]?.label;
    }

    if (filter.fields) {
      const values = filter.value;

      if (values) {
        return Object.values(values)
          .map((it) => it.id)
          .join(' - ');
      }
    }

    /** RANGE */
    if (filter.interval && filter.value) {
      return `${filter.value[0].id || ''} > ${filter.value[1].id || ''}`;
    }

    /** AUTOCOMPLETE */
    if (filter.value?.length) {
      return `"${filter.value.length} item${filter.value.length > 1 ? 's' : ''}"`;
    }

    return `"${filter.value}"`;
  }, [filter.fields, filter.interval, filter.items, filter.value]);

  return (
    <Chip
      className="filterChip"
      label={`${filter.display}: ${getValue}`}
      color="primary"
      onClick={() => onOpenFilter(filter)}
      onDelete={() => onRemoveFilter(filter.id)}
      deleteIcon={<Close className="closeIconStyles" />}
    />
  );
};
