import { AxiosResponse } from 'axios';
import { RequestMetaData, PagedApiResponse } from '../api';
import { Tooltip, Typography } from '@material-ui/core';

/**
 * This is an updated copy of Column model description from datagrid.
 * We've added size property which is missing in datagrid Column model and is
 * thus unusable.
 * TODO: Update datagrid and use its Column model.
 */
export interface Column {
  id: string;
  label: string;
  filterable?: boolean;
  filterOptions?: { label: string; value: string }[];
  numeric?: boolean;
  editable?: boolean | string;
  select?: any[];
  totals?: boolean;
  currency?: string;
  visible?: boolean;
  long?: boolean;
  date?: string;
  size?: 'small' | 'large';
  badge?: Record<string, string>;
  width?: number;
  minWidth?: number;
  render?: (rowIndex?: number, name?: string, value?: any, row?: any) => JSX.Element;
}

/**
 * Takes an api function that works with request meta data and returns a
 * paged response and adapts it so that it can be passed to datagrid.
 * Also takes an optional map function to apply to the data.
 * @param apiFetch The API fetch function
 * @param mapFunc The function to map over the data
 */
export function adaptForDataGrid<T, Z>(
  apiFetch: (rmd: RequestMetaData) => Promise<AxiosResponse<PagedApiResponse<T>>>,
  mapFunc?: (ts: T) => Z,
): (
  mtd: RequestMetaData,
) => Promise<{ data: { data: { items: Z[]; sortable: string[]; count: number } } }> {
  function id<T, Z>(t: T): Z {
    return t as unknown as Z;
  }
  const mf = mapFunc || id;
  const f = async (r: RequestMetaData) => {
    const resp = await apiFetch(r);
    const { data, sortable, count } = resp.data;
    return {
      data: {
        data: {
          items: (data || []).map(mf),
          sortable,
          count,
        },
      },
    };
  };
  return f;
}

export function adaptForDataGridPro<T, Z>(
  apiFetch: (rmd: RequestMetaData) => Promise<AxiosResponse<PagedApiResponse<T>>>,
  mapFunc?: (ts: T) => Z,
): (mtd: RequestMetaData) => Promise<{ rows: Z[]; sortable: string[]; totalElements: number }> {
  function id<T, Z>(t: T): Z {
    return t as unknown as Z;
  }

  const mf = mapFunc || id;
  return async (r: RequestMetaData) => {
    const resp = await apiFetch(r);
    const { data, sortable, count } = resp.data;
    return {
      rows: (data || []).map(mf),
      sortable,
      totalElements: count || (data || []).length,
    };
  };
}

interface EllipsizeProps {
  value: string;
  max?: number;
}
/**
 * react render function for displaying ellipsized value
 * @param value String variable to be ellipsized
 * @param max Number maximum number of displayed characters
 */
export function EllipsizedValue({ value, max = 20 }: EllipsizeProps) {
  // const displayValue = value.length > max ? `${value.substring(0, max)}...` : value;
  // return (
  //   <Tooltip title={value}>
  //     <Box>{displayValue}</Box>
  //   </Tooltip>
  // );
  return (
    <Tooltip title={value}>
      <Typography
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
        {value}
      </Typography>
    </Tooltip>
  );
}
