import { Button, Box } from '@material-ui/core';
import ListButton from 'components/Button/ListButton/ListButton';
import { EMobileWrapperDialogStep } from './MobileViewWrapperDialog';
import { List } from '@mui/material';
import { mobileWrapperFilterStyling } from '../../styles';
import { ReactNode } from 'react';
import { Filter } from 'models';

interface MobileViewStepAddFilterProps {
  visibleFilters: Filter[];
  setFilter: (id: string) => void;
  setMobileWrapperDialogStep: (value: EMobileWrapperDialogStep) => void;
  getFilterDisplayValue: (value: Filter) => ReactNode;
  showClearFilters: boolean;
  clearAllFilters: () => void;
  activeFiltersLength?: number;
}

export const MobileViewStepAddFilter = ({
  visibleFilters,
  setFilter,
  setMobileWrapperDialogStep,
  getFilterDisplayValue,
  showClearFilters,
  clearAllFilters,
  activeFiltersLength,
}: MobileViewStepAddFilterProps) => (
  <Box sx={mobileWrapperFilterStyling}>
    <List className="list">
      {visibleFilters.map((filter) => (
        <ListButton
          key={filter.id}
          onClick={() => {
            setFilter(filter.id);
            setMobileWrapperDialogStep(EMobileWrapperDialogStep.FILTER);
          }}
          secondaryAction={{
            children: getFilterDisplayValue(filter),
          }}>
          {filter.display}
        </ListButton>
      ))}
    </List>
    {showClearFilters && (
      <Button
        variant="text"
        size="small"
        color="primary"
        style={{ float: 'right' }}
        className="clear-filters"
        onClick={clearAllFilters}>
        Clear All ({activeFiltersLength})
      </Button>
    )}
  </Box>
);
