import TagsAutocomplete from './TagsAutocomplete';
import TagsInputField from './TagsInputField';
import { IFilterValue } from 'models';

const TagInputComponent = ({ options, ...otherProps }: any) => {
  return (
    <>
      {options ? (
        <TagsAutocomplete options={options} {...otherProps} />
      ) : (
        <TagsInputField
          {...{ ...otherProps, tags: (otherProps.tags || []).map((it: IFilterValue) => it.id) }}
        />
      )}
    </>
  );
};

export default TagInputComponent;
