import React, { ReactNode } from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import Api, { RequestMetaData } from 'api';
import { paths } from 'routes';

import moment from 'moment-timezone';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link, Chip } from '@material-ui/core';

import * as models from 'models';

import { numericStringToUsd } from 'services/currency';

import { adaptForDataGridPro } from 'services/datagrid';

import styles from './../styles';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import FilterBar from 'components/FilterBar';
import { EDateFormat } from 'utils/helper';

/** Define props for this component */
type InitiationDetailsChunksProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & {
    initiationId: number;
    payoutCompletedNode: ReactNode;
  };

function annotateChunksList(chunk: models.Chunk) {
  return {
    ...chunk,
    status: chunk.status.toUpperCase(),
    amount: numericStringToUsd(chunk.amount.toString()),
  };
}

/**
 * Show list of payouts and chunks belonging to a single payment. Payment reference is
 * passed via match params and list is displayed with datagrid.
 */
@observer
class InitiationDetailsChunks extends React.Component<InitiationDetailsChunksProps> {
  public constructor(props: InitiationDetailsChunksProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @action.bound public fetchInitiationChunks = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    return await Api.tips.getInitiationChunks(this.props.initiationId, {
      ...rmd,
      filters: { ...this.activeFilters },
    });
  }, annotateChunksList);

  renderLink = ({ value, row }: any) => {
    return (
      <Link
        component={RouterLink}
        to={`${paths.deposits().chunkDetails(this.props.initiationId, row.id)}`}>
        {value}
      </Link>
    );
  };

  renderStatus = ({ value }: any) => {
    const { chipCompleted, chipProcessed, chipPending, chipFailed } = this.props.classes;
    const colors: Record<any, any> = {
      COMPLETED: chipCompleted,
      PROCESSED: chipProcessed,
      PENDING: chipPending,
      FAILED: chipFailed,
    };

    return <Chip label={value} style={{ width: '100%' }} classes={{ root: colors[value] }} />;
  };

  gridChunksColumns = [
    {
      headerName: 'Date',
      field: 'createdAt',
      width: 210,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format(EDateFormat.DEFAULT),
    },
    {
      headerName: 'Identifier',
      field: 'identifier',
      width: 200,
      flex: 1,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      width: 200,
      flex: 1,
      renderCell: this.renderLink,
    },
    { headerName: 'Count', field: 'count', width: 180, flex: 1 },
    { headerName: 'Status', field: 'status', width: 180, renderCell: this.renderStatus },
  ];

  filters: models.Filter[] = [
    {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'COMPLETED', value: 'completed' },
        { label: 'PROCESSED', value: 'processed' },
        { label: 'PENDING', value: 'pending' },
        { label: 'FAILED', value: 'failed' },
      ],
    },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <>
        <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} />

        <Box mt={2} mb={2}>
          {this.props.payoutCompletedNode}
        </Box>

        {this.filtersInitReady && (
          <DataGridInfiniteScroll
            columns={this.gridChunksColumns}
            fetch={this.fetchInitiationChunks}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(InitiationDetailsChunks);
