import Title from 'components/Title';
import DashboardLayout from 'containers/DashboardLayout';
import RefundsHistory from './RefundsHistory';
import TabBar from 'components/TabBar/TabBar';
import { useMemo } from 'react';
import { Link as RouterLink, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { paths } from 'routes';
import { RefundRequestsTable } from 'components/RefundRequests/RefundRequestsTable';
import { useStores } from 'containers/App/App';
import { EManagerPermission } from 'types';

export default function Refunds() {
  const { pathname } = useLocation();
  const pathHistory = paths.refunds().history();
  const pathRequests = paths.refunds().requests();
  const { managerPermissionsStore, userStore } = useStores();
  const { isManagerScope } = userStore;
  const hideTabItems = useMemo(
    () =>
      isManagerScope
        ? !managerPermissionsStore.hasPermission(EManagerPermission.MANAGE_REFUND_REQUESTS)
        : false,
    [isManagerScope, managerPermissionsStore],
  );

  const tabs = useMemo(
    () => [
      {
        label: 'Refunds',
        component: RouterLink,
        to: pathHistory,
        hide: hideTabItems,
        selected: pathname === pathHistory,
      },
      {
        label: 'Refund Requests',
        component: RouterLink,
        to: pathRequests,
        hide: hideTabItems,
        selected: pathname === pathRequests,
      },
    ],
    [pathHistory, pathRequests, pathname, hideTabItems],
  );

  return (
    <DashboardLayout>
      <Title mb={3} size={'large'}>
        Refunds
      </Title>
      <TabBar mb={3} tabs={tabs} />

      <Switch>
        <Route exact path={pathHistory} component={RefundsHistory} />
        {!hideTabItems && <Route exact path={pathRequests} component={RefundRequestsTable} />}
        <Route>
          <Redirect to={pathHistory} />
        </Route>
      </Switch>
    </DashboardLayout>
  );
}
