import { useCallback, useMemo, useState } from 'react';

import { Box, Paper } from '@material-ui/core';
import { useStores } from 'containers/App/App';
import { FilterBarExternalDateRangePicker } from './FilterBarComponents/FilterBarExternalDateRangePicker';
import { FilterBarLocationsMap } from './FilterBarComponents/FilterBarLocationsMap';
import { FilterBarGraph, FilterBarGraphProps } from './FilterBarComponents/FilterBarGraph';
import { filterBarStyling } from './styles';
import { observer } from 'mobx-react';
import { ExternalDateRangeProps, Filter, IFilterValue } from 'models';
import { FilterBarMobile } from './FilterBarMobile/FilterBarMobile';
import { FilterBarDesktop } from './FilterBarDesktop/FilterBarDesktop';

interface FilterBarLayoutProps {
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
  externalDateRange?: ExternalDateRangeProps;
  locationMap?: {
    isActive: boolean;
  };
  graph?: FilterBarGraphProps;
  activeFilters?: Pick<Filter, 'id' | 'value' | 'options'>[];
}

const FilterBarLayout = observer(
  ({ filters, setFilters, externalDateRange, locationMap, graph }: FilterBarLayoutProps) => {
    const { uiStore } = useStores();
    const { mobileView } = uiStore;

    /** Filter that is currently being edited */
    const [selectedFilter, setSelectedFilter] = useState<Filter>();
    const [filterValue, setFilterValue] = useState<IFilterValue[]>();

    const setFilter = useCallback(
      (filterId: string) => {
        const filter = filters.find((f) => f.id === filterId);
        setSelectedFilter(filter);
        setFilterValue(filter?.value);
      },
      [filters],
    );

    const clearAllFilters = useCallback(() => {
      setFilters && setFilters(filters.map((f) => ({ ...f, value: undefined })));
    }, [filters, setFilters]);

    /** Clears selected filter and input value */
    const clearSelectedFilter = useCallback(() => {
      setSelectedFilter(undefined);
      setFilterValue(filterValue);
    }, [filterValue, setFilterValue, setSelectedFilter]);

    const hasFilters = useMemo((): boolean => !!filters.length, [filters.length]);

    const renderFilters = useMemo(
      (): boolean => hasFilters || !!(mobileView && externalDateRange),
      [externalDateRange, hasFilters, mobileView],
    );

    const activeFilters = useMemo(() => filters.filter((filter) => filter.value), [filters]);

    return (
      <Box sx={filterBarStyling(mobileView)}>
        {renderFilters && (
          <Paper className="paper">
            <Box className="root">
              {mobileView ? (
                <FilterBarMobile
                  filters={filters}
                  selectedFilter={selectedFilter}
                  filterValue={filterValue}
                  hasFilters={hasFilters}
                  showClearFilters={!!activeFilters.length}
                  activeFilters={activeFilters}
                  externalDateRange={externalDateRange}
                  setFilter={setFilter}
                  setFilters={(v) => setFilters && setFilters(v)}
                  clearAllFilters={clearAllFilters}
                  setFilterValue={setFilterValue}
                  clearSelectedFilter={clearSelectedFilter}
                />
              ) : (
                <FilterBarDesktop
                  filters={filters}
                  selectedFilter={selectedFilter}
                  filterValue={filterValue}
                  hasFilters={hasFilters}
                  showClearFilters={!!activeFilters.length}
                  visibleFilters={filters.filter((filter) => !filter.value)} // Display only unselected filters
                  setFilter={setFilter}
                  setFilters={setFilters}
                  clearAllFilters={clearAllFilters}
                  setFilterValue={setFilterValue}
                  setSelectedFilter={setSelectedFilter}
                  clearSelectedFilter={clearSelectedFilter}
                />
              )}
            </Box>
          </Paper>
        )}

        {externalDateRange && !mobileView && (
          <FilterBarExternalDateRangePicker externalDateRange={externalDateRange} />
        )}

        {/* Show Locations map */}
        {locationMap && (
          <FilterBarLocationsMap isActive={locationMap.isActive} mobileView={mobileView} />
        )}

        {/* Show Graph */}
        {graph && (
          <FilterBarGraph
            isActive={graph.isActive}
            onActive={graph.onActive}
            mobileView={mobileView}
          />
        )}
      </Box>
    );
  },
);

export default FilterBarLayout;
