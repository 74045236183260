import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, computed, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Box, Link, Paper } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore, WithToastStore, WithAnalyticsStore } from 'stores';

import { paths } from 'routes';

import { AxiosResponse } from 'axios';
import Api, * as api from 'api';
import { states, adaptForDataGridPro } from 'services';
import { v4 as uuidv4 } from 'uuid';
import * as models from 'models';

import DashboardLayout from 'containers/DashboardLayout';

import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';

import Chart from 'components/Chart';

import styles from '../styles';
import Title from 'components/Title/Title';

interface StateRevenueMatchParams {
  stateLabel: string;
}

type StateRevenueProps = WithStyles<typeof styles> &
  RouteComponentProps<StateRevenueMatchParams> &
  WithUserStore &
  WithAnalyticsStore &
  WithToastStore;

/**
 * Container that displays revenue data for a specific state and selected
 * date range. Chart shows overall revenue for the state, while table data
 * is represented as a list of individual locations and their revenues
 * within that state. State label is passed through match params which
 * is then used as a filter on both api fetch calls (table and chart data)
 * along with selected date range.
 */
@inject('userStore', 'analyticsStore', 'toastStore')
@observer
class StateRevenue extends React.Component<StateRevenueProps> {
  public constructor(props: StateRevenueProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @observable public matchParams: StateRevenueMatchParams;

  @computed public get stateLabel(): string {
    return this.matchParams.stateLabel;
  }

  @computed public get stateName(): string {
    return states[this.matchParams.stateLabel];
  }

  /** Chart data */
  @observable private chartData?: models.ChartData;

  /** Annotate location revenues table data */
  @action.bound private annotateLocationRevenues = (locationRevenue: models.LocationRevenue) => {
    return {
      id: uuidv4(),
      ...locationRevenue,
      name: locationRevenue.location ? locationRevenue.location.name : '',
      locationId: locationRevenue.location ? locationRevenue.location.id : null,
      account: locationRevenue.account ? locationRevenue.account.name : '',
    };
  };

  @action.bound public fetchRevenuesByLocation = adaptForDataGridPro(
    async (rmd: api.RequestMetaData) => {
      this.fetchChartData();
      return await Api.analytics.getRevenues('location', {
        ...rmd,
        filters: {
          state: this.stateLabel,
          ...this.activeFilters,
        },
      });
    },
    this.annotateLocationRevenues,
  );

  /** Fetch aggregated data for tip analytics chart */
  @action.bound public async fetchChartData() {
    try {
      const extraReqData = {
        // Add selected state to filters so we only get chart data for that state
        state: this.stateLabel,
      };
      const resp: AxiosResponse<api.ApiResponse<models.ChartData>> =
        await Api.analytics.getRevenuesChartData(extraReqData);
      this.chartData = resp.data && resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: api.getErrorMsg(e) });
    }
  }

  componentDidMount() {
    this.fetchChartData();
  }

  /** Render's the location name */
  renderLocation = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.analytics().locationRevenue(row.locationId)}>
        {row.name}
      </Link>
    );
  };

  gridColumns = [
    {
      headerName: 'Location',
      field: 'name',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderLocation,
    },
    { headerName: 'Account', field: 'account', minWidth: 150, flex: 1 },
    { headerName: 'Gross', field: 'gross', minWidth: 150, flex: 1 },
    { headerName: 'Net', field: 'net', minWidth: 150, flex: 1 },
    {
      headerName: 'Subscription',
      field: 'subscription',
      minWidth: 200,
      flex: 1,
    },
    { headerName: 'Shipping', field: 'shipping', minWidth: 150, flex: 1 },
  ];

  filters: models.Filter[] = [
    { display: 'Account', id: 'account', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'location', label: 'Contains', type: 'text' },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <DashboardLayout>
        <Title mb={3}>{this.stateName}</Title>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
          />
        </Box>
        <>
          <div style={{ maxWidth: '100%' }}>
            <Chart data={this.chartData} />
            <Box mt={3}>
              <Paper>
                {this.filtersInitReady && (
                  <DataGridInfiniteScroll
                    columns={this.gridColumns}
                    fetch={this.fetchRevenuesByLocation}
                    refetchKey={this.activeFilters}
                    disableColumnMenu
                    pathname={this.props.location.pathname}
                  />
                )}
              </Paper>
            </Box>
          </div>
        </>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(StateRevenue);
