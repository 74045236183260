import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import moment from 'moment';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Dialog, Link } from '@material-ui/core';

import Api, { ApiResponse, RequestMetaData, getErrorMsg } from 'api';
import { inject, WithUserStore, WithToastStore } from 'stores';
import * as models from 'models';
import { EllipsizedValue } from 'services/datagrid';
import { usdToNumericString, adaptForDataGridPro } from 'services';

import { Filter } from 'models';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';

import { VerticalStatCard } from 'containers/UserDetails/Stats';
import styles from './styles';

import { RouteComponentProps } from 'react-router-dom';
import { ACL } from 'types';
import {
  faBadgePercent,
  faCalendarStar,
  faMoneyBillTransfer,
  faReceipt,
  faWallet,
} from '@fortawesome/pro-regular-svg-icons';
import { AxiosResponse } from 'axios';
import { EDateFormat } from 'utils/helper';
import { CreateCorrectionDialog } from './CreateCorrectionDialog/CreateCorrectionDialog';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

/** Here we define what kind of props this component takes */
interface BillingProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithToastStore,
    RouteComponentProps {
  accountId: number;
  account?: models.Account;
}

/** Annotates transactions with additional data */
function annotateTransactions(transaction: models.Transaction) {
  return {
    ...transaction,
    invoiceNumber: transaction.invoice && transaction.invoice.number,
    processorPaymentId: transaction.charge && transaction.charge.processorPaymentId,
    invoiceRefund: transaction.invoiceRefund && transaction.invoiceRefund.processorRefundId,
    reason: transaction.note,
    createdAt: transaction.createdAt,
  };
}

@inject('userStore', 'toastStore')
@observer
class Billing extends React.Component<BillingProps> {
  constructor(props: BillingProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** Billing history stats */
  @observable public billingHistoryStats?: models.BillingHistoryStats;

  /** Payment methods received from /payments api */
  @observable public paymentMethods: models.IBillingEntity[] = [];

  /** Currently selected billing entity id from payment methods */
  @observable public billingEntityId: number | undefined = undefined;

  /** Correction modal state */
  @observable public correctionModalOpen = false;

  public fetchBillingHistory = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const { billingEntityId, ...restActiveFilters } = this.activeFilters;

    let extraData;
    if (billingEntityId) {
      extraData = { billingEntityId };
    }
    return await Api.billing.getBillingHistory(
      this.props.accountId,
      {
        ...rmd,
        filters: {
          ...restActiveFilters,
        },
      },
      extraData,
    );
  }, annotateTransactions);

  /** Billing history stats*/
  @action.bound private fetchBillingHistoryStats = flow(function* (
    this: Billing,
    extraData?: Record<string, number>,
  ) {
    try {
      const { billingEntityId }: Record<string, unknown> = this.activeFilters;

      if (billingEntityId && billingEntityId !== 'all') {
        extraData = { billingEntityId: parseInt(billingEntityId as string) };
      }

      const res = yield Api.billing.getBillingHistoryStats(this.props.accountId, extraData);
      if (res !== undefined) {
        this.billingHistoryStats = res.data && res.data.data;
      }
    } catch (error: unknown) {
      this.props.toastStore!.error(getErrorMsg(error));
    }
  });

  @action.bound private fetchPaymentMethods = flow(function* (this: Billing) {
    try {
      const { data }: AxiosResponse<ApiResponse<models.IBillingEntity[]>> =
        yield Api.billing.getBillingEntities(this.props.accountId);

      this.paymentMethods = data.data || [];

      const newFilters: Filter[] = this.filters.map((filter: Filter) => {
        if (filter.id === 'billingEntityId') {
          const items = this.paymentMethods.map(({ id, code, paymentMethod }) => ({
            label: `${paymentMethod.brand} ${paymentMethod.lastFour} | ${
              paymentMethod.createdAt &&
              moment(new Date(paymentMethod.createdAt)).format(EDateFormat.DEFAULT)
            } | ${code}`,
            value: id.toString(),
          }));
          return Object.assign(filter, { items });
        }
        return filter;
      });

      this.filters = newFilters;
    } catch (error: unknown) {
      this.props.toastStore!.error(getErrorMsg(error));
    }
  });

  /**
   * To make a correction, an authenticated user needs to have correct permission
   */
  @computed private get canMakeCorrection() {
    return (
      this.props.userStore && this.props.userStore.hasPermission(ACL.POST_ACCOUNT_LEDGER_CORRECTION)
    );
  }

  /**
   * On modal open, inside payment method select box,
   * Set currently selected payment method from filters, or default if none is selected
   */
  @action.bound private openCorrectionModal() {
    if (this.activeFilters.billingEntityId) {
      this.billingEntityId = this.activeFilters.billingEntityId as number;
    } else {
      const primary = this.paymentMethods.find((it) => it.isPrimary);
      if (primary) {
        this.billingEntityId = primary.id;
      }
    }

    this.correctionModalOpen = true;
  }

  @action.bound private closeCorrectionModal(updateFilters = true) {
    this.correctionModalOpen = false;

    if (updateFilters) {
      this.activeFilters = { ...this.activeFilters };
    }
  }

  /** On datagridRefetchKey change datagrid will refetch the data */
  @observable private datagridRefetchKey = Date.now();

  componentDidMount() {
    this.fetchPaymentMethods();
  }

  getLabelAndIconByKey(key: string) {
    // defualt icon is because StatItem expect it
    let item = {
      label: '',
      labelIcon: faWallet,
    };
    switch (key) {
      case 'balance':
        return (item = { label: 'Balance', labelIcon: faWallet });
      case 'charged':
        return (item = { label: 'Charged', labelIcon: faReceipt });
      case 'discounts':
        return (item = { label: 'Discounts', labelIcon: faBadgePercent });
      case 'freeMonths':
        return (item = { label: 'Free Months', labelIcon: faCalendarStar });
      case 'refunded':
        return (item = { label: 'Refunded', labelIcon: faMoneyBillTransfer });
      default:
        return item;
    }
  }

  renderCellColoredValue({ value }: GridRenderCellParams) {
    const numberValue = parseFloat(usdToNumericString(value as string));
    return <Typography color={numberValue >= 0 ? 'primary' : 'error'}>{value}</Typography>;
  }

  renderCellEllipsizedValue = ({ value }: GridRenderCellParams) => {
    const { classes } = this.props;
    return (
      <Box width="100%">
        {this.props.userStore!.isAdmin ? (
          <Link
            href={`https://dashboard.stripe.com/payments/${value}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes && classes.chargeLink}>
            <EllipsizedValue value={value as string} max={4} />
          </Link>
        ) : (
          <EllipsizedValue value={value as string} max={4} />
        )}
      </Box>
    );
  };

  gridColumns = [
    {
      headerName: 'Created At',
      field: 'createdAt',
      minWidth: 200,
      flex: 1,
      type: 'createdAt',
      valueGetter: ({ value }: GridRenderCellParams) =>
        value && moment(new Date(value)).format(EDateFormat.DATE_TIME_FULL),
    },
    {
      headerName: 'Invoice',
      field: 'invoiceNumber',
      minWidth: 120,
      flex: 1,
      sortable: false,
    },
    {
      headerName: 'Charge',
      field: 'processorPaymentId',
      minWidth: 120,
      flex: 1,
      renderCell: this.renderCellEllipsizedValue,
      sortable: false,
    },
    {
      headerName: 'Refund',
      field: 'invoiceRefund',
      minWidth: 120,
      flex: 1,
      renderCell: this.renderCellEllipsizedValue,
      sortable: false,
    },
    { headerName: 'Reason', field: 'note', minWidth: 150, flex: 1, sortable: false },
    {
      headerName: 'Amount',
      field: 'amount',
      minWidth: 120,
      flex: 1,
      renderCell: this.renderCellColoredValue,
    },
    {
      headerName: 'Entity Balance',
      field: 'billingEntityBalance',
      minWidth: 180,
      flex: 1,
      sortable: false,
    },
    {
      headerName: 'Account Balance',
      field: 'balance',
      minWidth: 180,
      flex: 1,
      renderCell: this.renderCellColoredValue,
      sortable: false,
    },
  ];

  @observable public filters: Filter[] = [
    { display: 'Invoice', id: 'invoiceNumber', label: 'Contains', type: 'text' },
    {
      display: 'Amount',
      id: 'chargeAmount',
      label: 'Contains',
      type: 'range',
      interval: {
        from: { label: 'From Amount', value: 'fromAmount' },
        to: { label: 'To Amount', value: 'toAmount' },
        type: 'number',
      },
    },
  ];

  renderBillingHistoryStats(): ReactElement[] | [] {
    const gridItems = [];

    if (this.billingHistoryStats) {
      for (const [key, value] of Object.entries(this.billingHistoryStats)) {
        // StatItem expects number in children amount property
        let data = { amount: 0 };
        let node;
        const { label, labelIcon } = this.getLabelAndIconByKey(key);
        // 'freeMonths' is without currency prefix and decimals
        if (key === 'freeMonths') {
          data = { amount: parseFloat(value) };
          node = (
            <Grid item xs={12} md={4} lg>
              <VerticalStatCard iconSize={24} fontAwesomeIcon={labelIcon} title={label}>
                {data.amount}
              </VerticalStatCard>
            </Grid>
          );
        } else {
          data = { amount: parseFloat(usdToNumericString(value)) };
          node = (
            <Grid item xs={12} md={4} lg>
              <VerticalStatCard prefix="$" iconSize={24} fontAwesomeIcon={labelIcon} title={label}>
                {data.amount}
              </VerticalStatCard>
            </Grid>
          );
        }
        gridItems.push(node);
      }
    }
    return gridItems;
  }

  @action.bound public refreshDataHandler() {
    this.fetchBillingHistoryStats();
    this.datagridRefetchKey = Date.now();
  }

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.fetchBillingHistoryStats();
    this.filtersInitReady = true;
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mt={0}>
            <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} />
          </Box>
          <Box mt={3}>
            <Grid container direction={'row'} spacing={3}>
              {this.billingHistoryStats && this.renderBillingHistoryStats()}
            </Grid>
          </Box>
          <Box mt={3}>
            {this.filtersInitReady && (
              <DataGridInfiniteScroll
                columns={this.gridColumns}
                fetch={this.fetchBillingHistory}
                refetchKey={this.activeFilters}
                actions={
                  this.canMakeCorrection
                    ? {
                        onAdd: {
                          name: 'Make Correction',
                          action: this.openCorrectionModal,
                        },
                      }
                    : {}
                }
                disableColumnMenu
                pathname={this.props.location.pathname}
              />
            )}
          </Box>
        </Grid>
        <Dialog open={this.correctionModalOpen} onClose={() => this.closeCorrectionModal(false)}>
          <CreateCorrectionDialog
            accountId={this.props.accountId}
            paymentMethods={this.paymentMethods}
            billingEntityId={this.billingEntityId}
            dialogClose={() => (this.correctionModalOpen = false)}
            refreshData={this.refreshDataHandler}
          />
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles)(Billing);
