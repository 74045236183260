/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';

import {
  observable,
  action,
  flow,
  IReactionDisposer,
  makeObservable,
  computed,
  reaction,
} from 'mobx';
import { observer } from 'mobx-react';

import Api, { ApiResponse, getErrorMsg, RequestMetaData } from 'api';
import { AxiosResponse } from 'axios';
import { inject, WithToastStore, WithAnalyticsStore, WithSettingStore } from 'stores';
import { paths } from 'routes';
import { adaptForDataGridPro, setTitle } from 'services';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid, Link } from '@material-ui/core';
import { AccountGroupOutline } from 'mdi-material-ui';
import { AssuredWorkloadRounded, Percent } from '@mui/icons-material';

import { Filter } from 'models';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import moment from 'moment-timezone';

import { HorizontalStatCard } from 'containers/UserDetails';

import styles from '../styles';
import { IDateRange } from 'components/DateRangeExternalPicker';

const PAGE_TITLE = 'Conversions by Employees';

/** Stats panel data interface */
interface Stats {
  count: number;
  percent: number;
  total: number;
}

/** Single employee conversion row */
interface EmployeeConversion {
  id: number;
  firstName: string;
  lastName: string;
  count: string;
  percentTotal: string;
  percentEmployees: string;
  lastSignup: string;
}

function annotateConversions(c: EmployeeConversion) {
  return {
    ...c,
    count: parseInt(c.count),
    percentTotal:
      parseFloat(c.percentTotal) !== 0 ? `${parseFloat(c.percentTotal).toFixed(2)}%` : '',
    percentEmployees:
      parseFloat(c.percentEmployees) !== 0 ? `${parseFloat(c.percentEmployees).toFixed(2)}%` : '',
  };
}

/** Here we define what kind of props this component takes */
interface ByEmployeeProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithAnalyticsStore,
    WithSettingStore,
    RouteComponentProps {}

@inject('toastStore', 'analyticsStore', 'settingStore')
@observer
class ByEmployee extends React.Component<ByEmployeeProps> {
  constructor(props: ByEmployeeProps) {
    super(props);
    makeObservable(this);
    // Fetch stats when selected date range changes
    this.disposers.push(
      reaction(
        () => this.activeFilters.fromDate || this.activeFilters.toDate,
        () => {
          this.fetchStats();
        },
      ),
    );
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  @observable public stats?: Stats;

  @action.bound public fetchStats = flow(function* (this: ByEmployee) {
    const extraReqData = {
      fromDate: this.activeFilters.fromDate as string,
      toDate: this.activeFilters.toDate as string,
    };
    try {
      const resp: AxiosResponse<ApiResponse<Stats>> =
        yield Api.analytics.conversion.byEmployees.statsData(
          extraReqData.fromDate,
          extraReqData.toDate,
        );
      if (resp && resp.data) {
        this.stats = resp.data.data;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public fetchEmployeeConversions = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      if (!this.filtersInitReady) {
        return Promise.resolve({ data: [] } as AxiosResponse);
      }

      return await Api.analytics.conversion.byEmployees.tableData({
        ...rmd,
        filters: { ...this.activeFilters },
      });
    },
    annotateConversions,
  );

  @computed private get dateRange(): IDateRange | undefined {
    return this.activeFilters.fromDate && this.activeFilters.toDate
      ? { fromDate: this.activeFilters.fromDate, toDate: this.activeFilters.toDate }
      : { toDate: new Date().toString() };
  }

  renderCellEmployee = ({ row }: any) => {
    return row.id === 0 ? (
      <>
        {row.firstName} {row.lastName}
      </>
    ) : (
      <Link component={RouterLink} to={paths.userDetails(row.id).root()}>
        {row.firstName} {row.lastName}
      </Link>
    );
  };

  renderCellLastSignup = ({ row }: any) => (
    <>{moment(row.lastSignup).isValid() ? moment(row.lastSignup).format('MMM DD, YYYY') : ''}</>
  );

  gridColumns = [
    {
      headerName: 'Employee',
      field: 'name',
      minWidth: 250,
      flex: 1,
      renderCell: this.renderCellEmployee,
    },
    { headerName: 'Count', field: 'count', minWidth: 250, flex: 1 },
    {
      headerName: '% Employees',
      field: 'percentEmployees',
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: 'Last Signup',
      field: 'lastSignup',
      minWidth: 250,
      flex: 1,
      renderCell: this.renderCellLastSignup,
    },
  ];

  filters: Filter[] = [{ display: 'Employee', id: 'name', label: 'Contains', type: 'text' }];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
    this.fetchStats();
  }

  /** Before unmounting the component, dispose of all reactions created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    return (
      <>
        <Box mt={3}>
          <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} showDateRange />
        </Box>
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <HorizontalStatCard
                icon={AccountGroupOutline}
                duration={1}
                title="Count"
                color="primary"
                dateRange={this.dateRange}>
                {this.stats && this.stats.count}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <HorizontalStatCard
                icon={Percent}
                duration={1}
                title="% of all signups"
                color="yellow"
                suffix="%"
                dateRange={this.dateRange}
                decimals={2}
                separator={`.`}>
                {this.stats && this.stats.percent}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <HorizontalStatCard
                icon={AssuredWorkloadRounded}
                duration={1}
                title="Total signups"
                color="secondary"
                dateRange={this.dateRange}>
                {this.stats && this.stats.total}
              </HorizontalStatCard>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchEmployeeConversions}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(ByEmployee);
