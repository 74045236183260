import PageNotFound from 'containers/PageNotFound';
import { Route, Switch } from 'react-router-dom';
import { paths } from 'routes';
import VerifyToken from './VerifyToken/VerifyToken';

const TippyMobileFallback = () => {
  return (
    <Switch>
      {/* TODO: Rewrite VerifyToken component when we know what to put in it */}
      <Route path={paths.tippyMobile().verifyToken()} exact render={() => <VerifyToken />}></Route>
      <Route component={PageNotFound} />
    </Switch>
  );
};
export default TippyMobileFallback;
