import CustomPicker, {
  IDateRange,
  ECustomPickerViewMode,
  isoDateFormat,
} from 'components/DateRangeExternalPicker/CustomPicker';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { ExternalDateRangeProps } from 'models';
import { EMobileWrapperDialogStep } from './MobileViewWrapperDialog';

interface IMobileViewDateRangePickerProps {
  externalDateRange?: ExternalDateRangeProps;
  value: IDateRange;
  onChange: (range: IDateRange) => void;
  handleOpen: (open: boolean) => void;
  mobileWrapperDialogStep: EMobileWrapperDialogStep;
}

export const MobileViewDateRangePicker = ({
  externalDateRange,
  value,
  mobileWrapperDialogStep,
  onChange,
  handleOpen,
}: IMobileViewDateRangePickerProps) => {
  const [viewMode, setViewMode] = useState(ECustomPickerViewMode.MENU);

  useEffect(() => {
    if (mobileWrapperDialogStep === EMobileWrapperDialogStep.ADD_DATE_FILTER)
      setViewMode(ECustomPickerViewMode.MENU);
    else if (mobileWrapperDialogStep === EMobileWrapperDialogStep.DATE_FILTER)
      setViewMode(ECustomPickerViewMode.DATE_TIMER);
  }, [mobileWrapperDialogStep]);

  const handleOnChange = (range: IDateRange) => {
    const _onChange = externalDateRange?.onChange;
    if (range.dateRangeType == 'date-timer') {
      const value = {
        fromDate: moment(range.fromDate).format(isoDateFormat),
        toDate: moment(range.toDate).format(isoDateFormat),
        type: range.dateRangeType,
      };
      _onChange && _onChange(value);
      onChange(value);
    } else {
      _onChange && _onChange(range);
      onChange(range);
    }
  };

  if (!externalDateRange) return null;

  return (
    <CustomPicker
      onChange={handleOnChange}
      handleOpened={handleOpen}
      selected={value}
      viewMode={viewMode}
    />
  );
};
