/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import { Box, Link } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithToastStore } from 'stores';

import { paths } from 'routes';

import { AxiosResponse } from 'axios';
import Api, { ApiResponse, RequestMetaData } from 'api';
import { downloadCsvFile } from '../../../utils/helper';

import { states, adaptForDataGridPro, usdToNumericString, setTitle } from 'services';

import * as models from 'models';

import styles from '../styles';

import _ from 'lodash';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import Chart from 'components/Chart';
import FilterBar from 'components/FilterBar';

const PAGE_TITLE = 'Revenue';

const SETTING_STORE_KEY = 'revenue/location';

type LocationsRevenueProps = WithStyles<typeof styles> & RouteComponentProps & WithToastStore;

/**
 * Container that displays overall Tippy revenue data for a selected date
 * range using a chart and two tables, each under its own tab (states, locations).
 * Chart shows overall Tippy revenue. States table is a list of all states that
 * have any revenue for the selected date range. Locations table is a list of
 * all registered Tippy salons and their respective revenue values.
 */
@inject('toastStore')
@observer
class LocationsRevenue extends React.Component<LocationsRevenueProps> {
  constructor(props: LocationsRevenueProps) {
    super(props);
    makeObservable(this);
  }

  @observable private chartActive = false;

  /** Chart data */
  @observable private chartData?: models.ChartData;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @observable private isMobile = window.matchMedia('(max-width:680px)').matches;

  @action.bound private annotateRevenues = (revenues: any) => {
    return {
      id: uuidv4(),
      account: revenues.account ? revenues.account.name : '',
      location: revenues.location ? revenues.location.name : '',
      locationId: revenues.location ? revenues.location.id : null,
      gross: usdToNumericString(revenues.gross),
      net: usdToNumericString(revenues.net),
      shipping: usdToNumericString(revenues.shipping),
      subscription: usdToNumericString(revenues.subscription),
    };
  };

  @action.bound public fetchRevenues = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const data = await Api.analytics.getRevenues('location', {
      ...rmd,
      filters: { ...this.activeFilters },
    });

    return data;
  }, this.annotateRevenues);

  /** Fetch aggregated data for tip analytics chart */
  @action.bound public fetchChartData = flow(function* (this: LocationsRevenue) {
    this.chartData = undefined;
    const extraReqData = { ...this.activeFilters };
    const resp: AxiosResponse<ApiResponse<models.ChartData>> =
      yield Api.analytics.getRevenuesChartData(extraReqData);
    if (resp?.data?.data) {
      this.chartData = resp.data.data;
    }
  });

  @action.bound private onChartActive() {
    this.chartActive = !this.chartActive;
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
    window.matchMedia('(max-width:680px)').addEventListener('change', (e) => {
      this.isMobile = e.matches;
    });
  }

  getCountryCode = (filters: Record<any, any>) => {
    if (filters.state) {
      const selectedState = _.startCase(_.toLower(filters.state as string));
      return Object.keys(states).find((key) => {
        if (states[key] === selectedState) return key;
      });
    }
  };

  /** Render location row items as links to location specific revenue */
  renderCellLocation = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.analytics().locationRevenue(row.locationId)}>
        {row.location}
      </Link>
    );
  };

  /** Download CSV report */
  @action downloadCsvReport = async () => {
    try {
      const resp = await Api.analytics.revenues.reportByLocation({
        filters: { ...this.activeFilters },
      });

      if (resp && resp.data) {
        const { data: responseData, fileName } = resp.data.data;
        downloadCsvFile(responseData, fileName);
      }
    } catch (error: any) {
      this.props.toastStore!.push({
        message: 'Unable to download requested report',
        type: 'error',
      });
    }
  };

  exportElements = [
    {
      name: 'Download as CSV',
      action: this.downloadCsvReport,
    },
  ];

  columns = [
    {
      headerName: 'Location',
      field: 'location',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellLocation,
    },
    { headerName: 'Account', field: 'account', minWidth: 150, flex: 1 },
    { headerName: 'Gross', field: 'gross', minWidth: 150, flex: 1 },
    { headerName: 'Net', field: 'net', minWidth: 150, flex: 1 },
    {
      headerName: 'Subscription',
      field: 'subscription',
      minWidth: 200,
      flex: 1,
    },
    { headerName: 'Shipping', field: 'shipping', minWidth: 150, flex: 1 },
  ];

  /** List of available filters for FilterBar component */
  filters: models.Filter[] = [
    { display: 'Account', id: 'account', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'location', label: 'Contains', type: 'text' },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    const countryCode = this.getCountryCode(filters);
    if (countryCode) {
      filters = { ...filters, state: countryCode };
    }
    this.activeFilters = filters;
    this.fetchChartData();
    this.filtersInitReady = true;
  }

  render() {
    return (
      <>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={this.handleFiltersOnChange}
            showDateRange
            dateRangeLocalStoragePath={SETTING_STORE_KEY}
            graph={
              !this.isMobile
                ? {
                    isActive: this.chartActive,
                    onActive: this.onChartActive,
                  }
                : undefined
            }
          />
        </Box>
        {this.chartActive && !this.isMobile && (
          <Chart data={this.chartData} singleYAxis singleYAxisFormat="currency" />
        )}
        <Box mt={3}>
          {this.filtersInitReady && (
            <DataGridInfiniteScroll
              columns={this.columns}
              fetch={this.fetchRevenues}
              refetchKey={this.activeFilters}
              disableColumnMenu
              actions={{
                onExport: this.exportElements,
              }}
              pathname={this.props.location.pathname}
            />
          )}
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(LocationsRevenue);
