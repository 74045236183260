import { faPlus, faCalendar, faCaretDown } from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/Button/Button';
import { List } from '@mui/material';
import ListButton from 'components/Button/ListButton/ListButton';
import moment from 'moment';
import { EDateFormat } from 'utils/helper';
import { EMobileWrapperDialogStep } from './MobileViewWrapperDialog';
import { IDateRange, OptionDate } from 'components/DateRangeExternalPicker';
import { Box } from '@material-ui/core';
import { mobileWrapperFilterStyling } from '../../styles';

interface MobileViewStepFiltersProps {
  hasFilters: boolean;
  setMobileWrapperDialogStep: (value: EMobileWrapperDialogStep) => void;
  showClearFilters: boolean;
  clearAllFilters: () => void;
  activeFiltersLength?: number;
  isExternalDateRange: boolean;
  rangeType?: OptionDate;
  dateRangeValue: IDateRange;
}

export const MobileViewStepFilters = ({
  hasFilters,
  setMobileWrapperDialogStep,
  showClearFilters,
  clearAllFilters,
  activeFiltersLength,
  isExternalDateRange,
  rangeType,
  dateRangeValue,
}: MobileViewStepFiltersProps) => (
  <Box sx={mobileWrapperFilterStyling}>
    <List className="list">
      {hasFilters && (
        <ListButton
          onClick={() => setMobileWrapperDialogStep(EMobileWrapperDialogStep.ADD_FILTER)}
          secondaryAction={{
            children: showClearFilters && (
              <Button
                variant="text"
                size="small"
                color="primary"
                style={{ float: 'right' }}
                className="clear-filters"
                onClick={clearAllFilters}>
                Clear All ({activeFiltersLength})
              </Button>
            ),
          }}
          startIcon={{ icon: faPlus }}
          endIcon={{ hide: true }}>
          Add a filter
        </ListButton>
      )}

      {isExternalDateRange && (
        <ListButton
          onClick={() => setMobileWrapperDialogStep(EMobileWrapperDialogStep.ADD_DATE_FILTER)}
          startIcon={{ icon: faCalendar }}
          endIcon={{ icon: faCaretDown, dark: true }}>
          {rangeType &&
            (!(rangeType.type === 'date-timer') ? (
              <>{rangeType.label}</>
            ) : (
              <>
                {moment(dateRangeValue.fromDate).format(EDateFormat.FULL_MONTH_DAY)} -{' '}
                {moment(dateRangeValue.toDate).format(EDateFormat.FULL_MONTH_DAY)}
              </>
            ))}
        </ListButton>
      )}
    </List>
  </Box>
);
