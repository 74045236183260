import React from 'react';
import { observable, action, computed, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Route, RouteComponentProps, Link as RouterLink, Switch } from 'react-router-dom';
import Api from 'api';
import { paths } from 'routes';

import moment from 'moment-timezone';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

import { inject, WithUserStore } from 'stores';

import * as models from 'models';

import DashboardLayout from 'containers/DashboardLayout';
import StatsPanel from 'components/StatsPanel';
import TabBar from 'components/TabBar/TabBar';

import styles from './styles';
import InitiationDetailsChunks from './Tabs/InitiationDetailsChunks';
import InitiationDetailsPayouts from './Tabs/InitiationDetailsPayouts';

/** The variables that are matched in the URL (match.params) */
interface InitiationDetailsMatchParams {
  id: string;
}

/** Define props for this component */
type InitiationDetailsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps<InitiationDetailsMatchParams> & // Adds the router props (history, match, location)
  WithUserStore; // Adds the userStore prop

enum EInitiationTab {
  CHUNKS = 'chunks',
  PAYOUTS = 'payouts',
}

/**
 * Show list of payouts and chunks belonging to a single payment. Payment reference is
 * passed via match params and list is displayed with datagrid.
 */
@inject('userStore')
@observer
class InitiationDetails extends React.Component<InitiationDetailsProps> {
  public constructor(props: InitiationDetailsProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  @observable public matchParams: InitiationDetailsMatchParams;

  @observable public initiationDetails?: models.Initiation;

  @observable public initiationStats?: models.InitiationStats;

  @observable public payoutCompletedNode?: JSX.Element;

  /** Get the payment reference from match params */
  @computed public get initiationId(): number {
    return parseInt(this.matchParams.id);
  }

  @action.bound public getInitiationStats = flow(function* (this: InitiationDetails) {
    if (this.initiationId) {
      const resp = yield Api.tips.getInitiationStats(this.initiationId);
      this.initiationStats = resp.data.data;

      this.getPayoutCompleted();
    }
  });

  @action.bound public getInitiationDetails = flow(function* (this: InitiationDetails) {
    const resp = yield Api.tips.getInitiationDetails(this.initiationId);
    this.initiationDetails = resp.data.data;
  });

  @computed public get tabs() {
    const { pathname } = this.props.location;
    return [
      {
        label: 'Chunks',
        component: RouterLink,
        to: paths.deposits().initiationDetailsChunks(this.initiationId),
        selected: pathname.includes(EInitiationTab.CHUNKS),
      },
      {
        label: 'Payouts',
        component: RouterLink,
        to: paths.deposits().initiationDetailsPayouts(this.initiationId),
        selected: pathname.includes(EInitiationTab.PAYOUTS),
      },
    ];
  }

  componentDidMount() {
    this.getInitiationDetails();
    this.getInitiationStats();
  }

  @action.bound getPayoutCompleted() {
    const date =
      this.initiationDetails &&
      moment(this.initiationDetails!.date).tz('America/New_York').format('MMM D, YYYY');
    const startHour =
      this.initiationDetails &&
      moment(this.initiationDetails!.createdAt).tz('America/New_York').format('H:mm A');
    const finishHour =
      this.initiationDetails &&
      moment(this.initiationDetails!.finishedAt).tz('America/New_York').format('H:mm A');

    const completedDate = `${date || 'N/A'} ${startHour || 'N/A'} - ${finishHour || 'N/A'}`;

    const status = this.initiationDetails ? this.initiationDetails.status : '';

    const total = {
      sum: this.initiationStats ? parseFloat(this.initiationStats.totalSum) : 0,
      count: this.initiationStats ? parseFloat(this.initiationStats.totalCount) : 0,
    };
    const processed = {
      sum: this.initiationStats ? parseFloat(this.initiationStats.processedSum) : 0,
      count: this.initiationStats ? parseFloat(this.initiationStats.processedCount) : 0,
    };
    const failed = {
      sum: this.initiationStats ? parseFloat(this.initiationStats.failedSum) : 0,
      count: this.initiationStats ? parseFloat(this.initiationStats.failedCount) : 0,
    };

    this.payoutCompletedNode = (
      <StatsPanel>
        <StatsPanel.StaticCell mainText={status} auxText={completedDate} />
        <StatsPanel.CountupCell value={total.sum} auxText={`${total.count} total`} />
        <StatsPanel.CountupCell value={processed.sum} auxText={`${processed.count} sent`} />
        <StatsPanel.CountupCell value={failed.sum} auxText={`${failed.count} failed`} />
      </StatsPanel>
    );
  }

  render() {
    return (
      <DashboardLayout>
        <Typography variant="h4" component="h1" gutterBottom>
          Initiation Stats
        </Typography>

        <TabBar mb={3} tabs={this.tabs} />

        {this.payoutCompletedNode && (
          <Switch>
            <Route
              path={paths.deposits().initiationDetailsChunks(this.initiationId)}
              render={(props) => (
                <InitiationDetailsChunks
                  {...props}
                  initiationId={this.initiationId}
                  payoutCompletedNode={this.payoutCompletedNode}
                />
              )}
            />
            <Route
              path={paths.deposits().initiationDetailsPayouts(this.initiationId)}
              render={(props) => (
                <InitiationDetailsPayouts
                  {...props}
                  initiationId={this.initiationId}
                  payoutCompletedNode={this.payoutCompletedNode}
                />
              )}
            />
          </Switch>
        )}

        {/* footer */}
        <Box mt={1} ml={2}>
          <Typography variant="subtitle2">All times are displayed in EST/DST timezone</Typography>
        </Box>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(InitiationDetails);
