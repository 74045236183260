import { MobileViewStepAddFilter } from './MobileViewStepAddFilter';
import { MobileViewStepFilters } from './MobileViewStepFilters';
import { EMobileWrapperDialogStep } from './MobileViewWrapperDialog';
import { ReactNode } from 'react';
import { ExternalDateRangeProps, Filter } from 'models';
import { MobileViewDateRangePicker } from './MobileViewDateRangerPicker';
import { IDateRange, OptionDate } from 'components/DateRangeExternalPicker';

interface MobileViewFilterStepsProps {
  mobileWrapperDialogStep?: EMobileWrapperDialogStep;
  hasFilters: boolean;
  setMobileWrapperDialogStepAction: () => void;
  showClearFilters: boolean;
  clearAllFilters: () => void;
  activeFiltersLength: number;
  isExternalDateRange: boolean;
  rangeType?: OptionDate;
  dateRangeValue: IDateRange;
  visibleFilters: Filter[];
  setFilter: (id: string) => void;
  getFilterDisplayValue: (filter: Filter) => ReactNode;
  onDateRangeValueChange: (range: unknown) => void;
  handleOpenMobileDateRangePicker: (open: boolean) => void;
  externalDateRange?: ExternalDateRangeProps;
}

export const MobileViewFilterSteps = ({
  mobileWrapperDialogStep,
  hasFilters,
  setMobileWrapperDialogStepAction,
  showClearFilters,
  clearAllFilters,
  activeFiltersLength,
  isExternalDateRange,
  rangeType,
  dateRangeValue,
  visibleFilters,
  setFilter,
  getFilterDisplayValue,
  onDateRangeValueChange,
  handleOpenMobileDateRangePicker,
  externalDateRange,
}: MobileViewFilterStepsProps) => {
  return (
    <>
      {mobileWrapperDialogStep === EMobileWrapperDialogStep.FILTERS && (
        <MobileViewStepFilters
          hasFilters={hasFilters}
          setMobileWrapperDialogStep={setMobileWrapperDialogStepAction}
          showClearFilters={showClearFilters}
          clearAllFilters={clearAllFilters}
          activeFiltersLength={activeFiltersLength}
          isExternalDateRange={isExternalDateRange}
          rangeType={rangeType}
          dateRangeValue={dateRangeValue}
        />
      )}

      {mobileWrapperDialogStep === EMobileWrapperDialogStep.ADD_FILTER && (
        <MobileViewStepAddFilter
          visibleFilters={visibleFilters}
          setFilter={setFilter}
          setMobileWrapperDialogStep={setMobileWrapperDialogStepAction}
          getFilterDisplayValue={getFilterDisplayValue}
          showClearFilters={showClearFilters}
          clearAllFilters={clearAllFilters}
          activeFiltersLength={activeFiltersLength}
        />
      )}

      {mobileWrapperDialogStep &&
        [EMobileWrapperDialogStep.ADD_DATE_FILTER, EMobileWrapperDialogStep.DATE_FILTER].includes(
          mobileWrapperDialogStep,
        ) && (
          <MobileViewDateRangePicker
            value={dateRangeValue}
            onChange={onDateRangeValueChange}
            handleOpen={handleOpenMobileDateRangePicker}
            externalDateRange={externalDateRange}
            mobileWrapperDialogStep={mobileWrapperDialogStep}
          />
        )}
    </>
  );
};
