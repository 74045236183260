import React, { useEffect, useMemo, useState } from 'react';
import { default as DialogButton } from 'components/Button/Dialog/Button';
import {
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import {
  Alert,
  NewAlertRequest,
  AlertTargetType,
  AlertStepsDialog,
  KioskMode,
  AlertTarget,
} from 'models';
import Api from 'api';
import TagsAutocomplete from 'components/TagInputComponent/TagsAutocomplete';
import { toJS } from 'mobx';
import { humanize } from 'utils/helper';

interface Props {
  classes: any;
  onUpdateAlertStepsDialog: Function;
  onUpdateNewAlert: Function;
  currentAlert?: Partial<Alert> | Partial<NewAlertRequest>;
  editAction?: string;
}

type OptionsFromEnums = {
  [key: string]: string | number;
};

const processorsList = [
  {
    id: 'square',
    name: 'square',
    display: 'Square',
    kioskMode: [KioskMode.STANDARD, KioskMode.POOLS, KioskMode.CUSTOM_ONLY],
  },
  {
    id: 'square-web',
    name: 'square-web',
    display: 'Square Web',
    kioskMode: [KioskMode.STANDARD, KioskMode.POOLS, KioskMode.CUSTOM_ONLY],
  },
  {
    id: 'rosy',
    name: 'rosy',
    display: 'Rosy',
    kioskMode: [KioskMode.INTEGRATED],
  },
  {
    id: 'meevo',
    name: 'meevo',
    display: 'Meevo',
    kioskMode: [KioskMode.INTEGRATED],
  },
  {
    id: 'envision',
    name: 'envision',
    display: 'Envision',
    kioskMode: [KioskMode.INTEGRATED],
  },
  {
    id: 'stx',
    name: 'stx',
    display: 'Stx',
    kioskMode: [KioskMode.INTEGRATED],
  },
];

const TargetDialogForm: React.FC<Props> = ({
  classes,
  onUpdateAlertStepsDialog,
  currentAlert,
  onUpdateNewAlert,
}) => {
  const [alertTargetType, setAlertTargetType] = useState<AlertTargetType | undefined>(undefined);
  const [kioskModeSelected, setKioskModeSelected] = useState<OptionsFromEnums[]>([]);
  const [appsSelected, setAppsSelected] = useState<OptionsFromEnums[]>([]);
  const [processorSelected, setprocessorSelected] = useState<OptionsFromEnums[]>([]);
  const [accountsSelected, setaccountsSelected] = useState<OptionsFromEnums[]>([]);
  const [locationsSelected, setlocationsSelected] = useState<OptionsFromEnums[]>([]);

  useEffect(() => {
    if (currentAlert?.target) {
      setAlertTargetType(currentAlert.target.targetType);
      setKioskModeSelected((toJS(currentAlert?.target?.kioskMode) as OptionsFromEnums[]) || []);
      setAppsSelected((toJS(currentAlert?.target?.app) as OptionsFromEnums[]) || []);
      setprocessorSelected((toJS(currentAlert?.target?.processor) as OptionsFromEnums[]) || []);
      setaccountsSelected((toJS(currentAlert?.target?.accountId) as OptionsFromEnums[]) || []);
      setlocationsSelected((toJS(currentAlert?.target?.locationId) as OptionsFromEnums[]) || []);
    }
  }, [currentAlert]);

  useEffect(() => {}, [accountsSelected]);

  const isIntegrated = useMemo(
    () => String(kioskModeSelected[0]?.id).toLocaleLowerCase() === KioskMode.INTEGRATED,
    [kioskModeSelected],
  );

  const getLocationsFiltered = async () => {
    const { data } = await Api.core.searchAllLocations(
      {},
      { accountIds: accountsSelected.map((e) => e?.id) },
    );
    return data.data.map(
      (location: { id: string; name: string; address: string; [key: string]: string }) => {
        return { id: location.id, name: location.name };
      },
    );
  };

  const getAccounts = async (query?: string) => {
    const { data } = await Api.core.getAccounts({}, { name: query });
    const filteredData = data.data.map(
      (account: { id: string; name: string; address: string; [key: string]: string }) => {
        return { id: account.id, name: account.name };
      },
    );
    return filteredData;
  };

  const getApps = async () => {
    const { data } = await Api.developer.getAllApps();
    if (!data?.data) {
      return [];
    }

    return data.data.map(({ id, name }) => ({
      id,
      name,
    }));
  };

  const getOptionFromEnum = (options: typeof KioskMode): Record<string, string>[] => {
    const result = (Object.keys(options) as (keyof typeof options)[]).map((key) => {
      return { id: key, name: options[key], display: humanize(options[key]) };
    });
    return result;
  };

  const isKioskModeSelected = useMemo(() => kioskModeSelected?.length > 0, [kioskModeSelected]);

  const getProcessorByKioskMode = (kioskMode: KioskMode): Record<string, string | any[]>[] => {
    return processorsList.filter((processor) => processor.kioskMode.includes(kioskMode));
  };

  const handleBack = () => {
    onUpdateAlertStepsDialog(AlertStepsDialog.FORM);
  };

  const handleOnUpdateNewAlert = () => {
    onUpdateNewAlert({
      target: {
        targetType: alertTargetType,
        ...(isKioskModeSelected && { kioskMode: kioskModeSelected }),
        ...(isKioskModeSelected && { app: appsSelected }),
        ...(isKioskModeSelected && { processor: processorSelected }),
        ...(isKioskModeSelected && { accountId: accountsSelected }),
        ...(isKioskModeSelected && { locationId: locationsSelected }),
      } as AlertTarget,
    });
    onUpdateAlertStepsDialog(AlertStepsDialog.SUMMARY);
  };

  const handleOnUpdateKioskModeSelected = (options: OptionsFromEnums[]) => {
    if (!options.length) {
      setAppsSelected([]);
      setprocessorSelected([]);
    }
    setKioskModeSelected(options);
  };

  return (
    <Box minWidth={460} display="flex" flexDirection="column">
      <Box mt={1} mb={1}>
        <FormControl margin="none">
          <FormLabel className={classes.formLabel} component="legend">
            Who would you like to target?
          </FormLabel>
        </FormControl>
      </Box>
      <Box mb={3}>
        <RadioGroup row>
          <FormControlLabel
            value="issue"
            control={
              <Radio
                size="small"
                color="primary"
                checked={alertTargetType === AlertTargetType.ALL}
                onClick={() => setAlertTargetType(AlertTargetType.ALL)}
              />
            }
            label="All Devices"
          />
          <FormControlLabel
            value="issue"
            control={
              <Radio
                color="primary"
                size="small"
                checked={alertTargetType === AlertTargetType.CUSTOM}
                onClick={() => setAlertTargetType(AlertTargetType.CUSTOM)}
              />
            }
            label="Custom Devices"
          />
        </RadioGroup>
      </Box>

      {alertTargetType && alertTargetType === AlertTargetType.CUSTOM && (
        <>
          <Box mb={3}>
            <TagsAutocomplete
              disableCloseOnSelect={false}
              popperFullWidth={true}
              disabled={isKioskModeSelected}
              selectedTags={(options) =>
                Array.isArray(options) && handleOnUpdateKioskModeSelected(options)
              }
              label={'Kiosk mode'}
              tags={kioskModeSelected}
              optionList={getOptionFromEnum(KioskMode)}
              options={{
                displayField: {
                  keySearch: { name: 'id' },
                  value: 'display',
                },
                haveMultiple: true,
              }}
              humanizedChip
            />
          </Box>
          {isKioskModeSelected && !isIntegrated && (
            <Box mb={3}>
              <FormLabel className={classes.formLabel} component="legend">
                This kiosk mode does not contain an integrated application!
              </FormLabel>
            </Box>
          )}
          {isKioskModeSelected && isIntegrated && (
            <Box mb={3}>
              <TagsAutocomplete
                disableCloseOnSelect={false}
                selectedTags={(options) => Array.isArray(options) && setAppsSelected(options)}
                tags={appsSelected}
                label={'Integration app'}
                options={{
                  fetch: getApps,
                  displayField: {
                    keySearch: { name: 'id' },
                    value: 'name',
                  },
                  haveMultiple: true,
                }}
                humanizedChip
              />
            </Box>
          )}
          {isKioskModeSelected && !isIntegrated && (
            <Box mb={3}>
              <TagsAutocomplete
                disableCloseOnSelect={false}
                selectedTags={(options) => Array.isArray(options) && setprocessorSelected(options)}
                tags={processorSelected}
                label={'Processor'}
                optionList={getProcessorByKioskMode(
                  KioskMode[kioskModeSelected[0]?.id as keyof typeof KioskMode],
                )}
                options={{
                  displayField: {
                    keySearch: { name: 'id' },
                    value: 'display',
                    display: 'display',
                  },
                  haveMultiple: true,
                }}
                humanizedChip
              />
            </Box>
          )}
          <Box mb={3}>
            <TagsAutocomplete
              disableCloseOnSelect={false}
              selectedTags={(options) => Array.isArray(options) && setaccountsSelected(options)}
              tags={accountsSelected}
              label={'Account name'}
              options={{
                fetch: getAccounts,
                displayField: {
                  keySearch: { name: 'id' },
                  value: 'name',
                },
                haveMultiple: true,
              }}
            />
          </Box>
          {accountsSelected?.length > 0 && (
            <Box mb={3}>
              <TagsAutocomplete
                disableCloseOnSelect={false}
                selectedTags={(options) => Array.isArray(options) && setlocationsSelected(options)}
                tags={locationsSelected}
                label={'Location name'}
                fetchOnInit
                options={{
                  fetch: getLocationsFiltered,
                  displayField: {
                    keySearch: { name: 'id' },
                    value: 'name',
                  },
                  haveMultiple: true,
                }}
              />
            </Box>
          )}
        </>
      )}
      <Box className={classes.customDialogActions}>
        <DialogButton variant="outlined" onClick={handleBack} color="primary">
          Back
        </DialogButton>
        <DialogButton
          variant="contained"
          onClick={handleOnUpdateNewAlert}
          color="primary"
          disabled={
            alertTargetType === undefined ||
            (alertTargetType === AlertTargetType.CUSTOM &&
              kioskModeSelected?.length === 0 &&
              appsSelected?.length === 0 &&
              processorSelected?.length === 0 &&
              accountsSelected?.length === 0 &&
              locationsSelected?.length === 0)
          }>
          Next
        </DialogButton>
      </Box>
    </Box>
  );
};

export default TargetDialogForm;
