import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography /* Drawer */ } from '@material-ui/core';

import { paths } from 'routes';
import Api, { RequestMetaData, getErrorMsg } from 'api';
import {
  inject,
  WithUserStore,
  WithSettingStore,
  UserScopes,
  WithToastStore,
  WithManagerPermissionsStore,
} from 'stores';

import * as models from 'models';

import { adaptForDataGridPro } from 'services/datagrid';
import styles from './styles';

import { setTitle } from 'services';

import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { WithRouterStore } from 'stores/RouterStore';
import { ChipStatusColors } from 'components/ChipStatusTag';
import RefundStats from './RefundStats/RefundStats';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import TipDetailsDrawer from './TipDetailsDrawer/TipDetailsDrawer';
import { capitalize } from 'utils/helper';
import DataGridCell from 'components/DataGridCell/DataGridCell';
import { EManagerPermission } from 'types';

const PAGE_TITLE = 'Refunds';
interface Refund {
  id: number;
  paymentId: number;
  net: string;
  gross: string;
  isPartial: boolean;
  processor: string;
  processorFee: string;
  ourFee: string;
  transactionId: number;
  status: string;
  createdAt: string;
  reason: string;
}

enum RefundStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed',
  NOT_FOUND = 'not_found',
  REJECTED = 'rejected',
}

export interface IRefundHistoryStats {
  count: number;
  netSum: string;
  grossSum: string;
  processorFeeSum: string;
  ourFeeSum: string;
}

interface IBadgeConfig {
  [key: string]: string;
}

const badgeConfig: IBadgeConfig = {
  PENDING: ChipStatusColors.YELLOW,
  COMPLETED: ChipStatusColors.GREEN,
  FAILED: ChipStatusColors.RED,
  NOT_FOUND: ChipStatusColors.BLUE,
  REJECTED: ChipStatusColors.GREY,
};

/** Define props for this component */
type RefundsHistoryProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & // Adds the router props (history, match, location)
  WithManagerPermissionsStore &
  WithSettingStore &
  WithUserStore &
  WithRouterStore & // Adds the userStore prop
  WithToastStore;

/** Annotates refund with extra data */
function annotate(refund: Refund & { payment: models.Payment }) {
  return {
    ...refund,
    status: refund.status && refund.status.toUpperCase(),
    statusType: refund.status,
    reference: refund?.payment?.reference,
  };
}

const SETTING_STORE_KEY = '/refunds';

/**
 * The refunds container, restricted to admin users.
 */
@inject('userStore', 'settingStore', 'routerStore', 'toastStore', 'managerPermissionsStore')
@observer
class RefundsHistory extends React.Component<RefundsHistoryProps> {
  public constructor(props: RefundsHistoryProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @observable public statsLoading = false;

  @observable public isRefundMethodDrawerOpen? = false;

  @observable public paymentDetails?: models.Payment;

  @observable private stats?: IRefundHistoryStats;

  @observable private isAdmin = this.props.userStore?.isAdmin;

  @observable private scope = this.props.userStore?.scope;

  @observable public tip?: models.Tip;

  @observable public openDrawer = false;

  @observable public error = false;

  @action.bound public setRefundStats = () => {
    this.statsLoading = true;
    Api.tips
      .getRefundStats({ ...this.activeFilters })
      .then(({ data }) => {
        this.stats = data.data;
      })
      .catch((err: any) => {
        this.props.toastStore?.error(getErrorMsg(err));
      })
      .finally(() => {
        this.statsLoading = false;
      });
  };

  @action.bound public fetchRefundsData = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    return await this.getRefunds(rmd);
  }, annotate);

  @action.bound private async getTipDetails(id: models.TipDetailsResponseRefunds['id']) {
    try {
      this.error = false;
      const { data } = await Api.tips.getTipDetails(id);
      const tipDetailsResponse = data?.data;
      if (!tipDetailsResponse?.parent) return;
      //We are showing original tip which was refunded
      const { parent, user } = tipDetailsResponse;
      this.tip = { ...parent, user: user || ({} as models.User) };
    } catch (error) {
      this.error = true;
      this.props.toastStore?.error(getErrorMsg(error));
    }
  }

  @action.bound private openTipDetailsDrawer(id: models.Tip['id']) {
    this.resetTip();
    this.openDrawer = true;
    this.getTipDetails(id);
  }

  @action.bound public closeTipDetailsDrawer() {
    this.openDrawer = false;
  }

  @action.bound private resetTip() {
    this.tip = undefined;
  }

  async getRefunds(rmd: RequestMetaData) {
    const body: RequestMetaData = {
      ...rmd,
      filters: { ...this.activeFilters },
    };

    if (this.isAdmin) {
      return Api.tips.getRefunds(body);
    }

    const scopeKind = this.scope?.kind;
    if (scopeKind === UserScopes.OWNER) {
      body.filters = { ...body.filters, accountId: this.props.userStore?.currentAccount?.id };
    }

    // If user is manager and has view_refunds permission, we add accountId and locationId to filters
    const managerStore = this.props.managerPermissionsStore;
    if (
      scopeKind === UserScopes.MANAGER &&
      managerStore?.hasPermission(EManagerPermission.VIEW_REFUNDS)
    ) {
      body.filters = {
        ...body.filters,
        accountId: managerStore.accountId,
        locationId: this.scope?.locationId,
      };
    }

    return Api.analytics.getRefundsForOwnerOrManager(body);
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  renderCellIsPartial({ row }: any) {
    if (!('isPartial' in row)) return null;
    return <Typography>{row.isPartial ? 'Yes' : 'No'}</Typography>;
  }

  renderCellReason = ({ value }: any) => {
    if (!value) return null;
    return capitalize(value.split('_').join(' '));
  };

  getStatusBackground = (value: any) => {
    return badgeConfig[value as keyof IBadgeConfig] as ChipStatusColors;
  };

  @computed public get gridColumns() {
    let columns: GridColDef[] = [
      {
        headerName: 'Reference',
        field: 'reference',
        minWidth: 200,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => (
          <DataGridCell.Route value={value} path={paths.paymentTips(value)} />
        ),
        sortable: false,
      },
      {
        headerName: 'Date',
        field: 'createdAt',
        minWidth: 150,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => <DataGridCell.Date value={value} />,
      },
      {
        headerName: 'Reason',
        field: 'reason',
        minWidth: 120,
        flex: 1,
        sortable: false,
        renderCell: this.renderCellReason,
      },
      {
        headerName: 'Gross',
        field: 'gross',
        minWidth: 100,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => <DataGridCell.Amount amount={value} />,
      },
      {
        headerName: 'Our Loss',
        field: 'ourFee',
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ value }: GridRenderCellParams) => <DataGridCell.Amount amount={value} />,
      },
      {
        headerName: 'Processor Loss',
        field: 'processorFee',
        minWidth: 180,
        flex: 1,
        sortable: false,
        renderCell: ({ value }: GridRenderCellParams) => <DataGridCell.Amount amount={value} />,
      },
      {
        headerName: 'Is Partial',
        field: 'isPartial',
        minWidth: 150,
        flex: 1,
        renderCell: this.renderCellIsPartial,
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 200,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => (
          <DataGridCell.Status label={value} background={this.getStatusBackground(value)} />
        ),
        sortable: false,
      },
    ];

    if (this.isAdmin) return columns;

    const ownerColumns: GridColDef[] = [
      {
        headerName: 'Amount',
        field: 'net',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row, value }: GridRenderCellParams) => (
          <DataGridCell.Button
            value={value && `$${value}`}
            onClick={() => this.openTipDetailsDrawer(row?.tipId)}
          />
        ),
      },
      ...columns.filter((c) => ['status', 'createdAt', 'reason', 'isPartial'].includes(c.field)),
    ];
    return ownerColumns;
  }

  //TODO: implement new filter definitions
  @computed public get filters(): models.Filter[] {
    const scopeKind = this.scope?.kind;
    const adminFilters: models.Filter[] = [
      { display: 'Reference', id: 'reference', label: 'Contains', type: 'text' },
      {
        display: 'Status',
        id: 'status',
        label: 'One of',
        type: 'select',
        items: [
          { label: 'COMPLETED', value: RefundStatus.COMPLETED },
          { label: 'PENDING', value: RefundStatus.PENDING },
          { label: 'FAILED', value: RefundStatus.FAILED },
          { label: 'NOT_FOUND', value: RefundStatus.NOT_FOUND },
          { label: 'REJECTED', value: RefundStatus.REJECTED },
        ],
      },
    ];

    const ownerFilters: models.Filter[] = [...adminFilters.filter((f) => f.id === 'status')];
    if (this.scope?.kind === UserScopes.OWNER) return ownerFilters;

    if (scopeKind === UserScopes.GLOBAL_OWNER) {
      return [...adminFilters.filter((f) => ['reference', 'accountId'].includes(f.id))];
    }

    if (scopeKind === UserScopes.MANAGER) {
      return [...adminFilters.filter((f) => ['reference', 'status'].includes(f.id))];
    }

    return adminFilters;
  }

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    if (this.isAdmin) {
      this.setRefundStats();
    }
    this.filtersInitReady = true;
  }

  render() {
    return (
      <>
        {this.isAdmin && <RefundStats loading={this.statsLoading} stats={this.stats} />}
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={this.handleFiltersOnChange}
            showDateRange
            dateRangeLocalStoragePath={SETTING_STORE_KEY}
          />
        </Box>
        <Box mt={3}>
          {this.filtersInitReady && (
            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.fetchRefundsData}
              refetchKey={this.activeFilters}
              sortDirection={'DESC'}
              disableColumnMenu
              pathname={this.props.location.pathname}
            />
          )}
        </Box>
        <TipDetailsDrawer
          open={this.openDrawer}
          anchor="right"
          tip={this.tip}
          error={this.error}
          closeDrawer={this.closeTipDetailsDrawer}
        />
      </>
    );
  }
}

export default withStyles(styles)(RefundsHistory);
