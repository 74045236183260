import React from 'react';
import styles from '../styles';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { computed, observable, action, makeObservable } from 'mobx';
import Api, { RequestMetaData } from '../../../../api';
import { v4 as uuidv4 } from 'uuid';
import { adaptForDataGridPro, numericStringToUsd, setTitle } from '../../../../services';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';

import PartnerWalletCorrectionModal from './PartnerWalletCorrectionModal';
import moment from 'moment';
import { IWalletTransaction } from 'models';
import { EDateFormat } from 'utils/helper';

interface WalletMatchParams {
  partnerId: string;
}

type WalletProps = WithStyles<typeof styles> & RouteComponentProps<WalletMatchParams>;

@observer
class PartnerDetailsWallet extends React.Component<WalletProps> {
  constructor(props: WalletProps) {
    super(props);
    makeObservable(this);
  }

  @observable private refetchKey = Date.now();
  @observable private partner?: any;
  @observable public openCorrectionModal = false;

  @computed public get partnerId(): number {
    return parseInt(this.props.match.params.partnerId);
  }

  @computed public get walletId(): number | undefined {
    return this.canMakeCorrection ? this.partner.walletId : undefined;
  }

  @computed get canMakeCorrection(): boolean {
    return !!this.partner?.walletId;
  }

  componentDidMount() {
    this.fetchPartner().then(() => {
      this.refetchKey = Date.now();
      if (this.partner) {
        setTitle(this.partner.name, { noSuffix: false });
      }
    });
  }

  @action.bound public getTransactions = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      return await Api.tips.getWalletTransactions(this.partner.walletId, rmd);
    },
    (walletTransaction: IWalletTransaction) => ({
      id: uuidv4(),
      date: moment(walletTransaction.date).format(EDateFormat.DEFAULT),
      time: moment(walletTransaction.date).format(EDateFormat.TIME),
      amount: numericStringToUsd(walletTransaction.amount),
      reason: walletTransaction.reason,
      balance: numericStringToUsd(walletTransaction.balance),
    }),
  );

  private async fetchPartner() {
    const resp = await Api.tips.getPartner(this.partnerId);
    this.partner = resp.data.data;
  }

  gridColumns = [
    { headerName: 'Date', field: 'date', minWidth: 150, flex: 1 },
    { headerName: 'Time', field: 'time', minWidth: 150, flex: 1, sortable: false },
    { headerName: 'Amount', field: 'amount', minWidth: 150, flex: 1 },
    { headerName: 'Reason', field: 'reason', minWidth: 150, flex: 1 },
    { headerName: 'Balance', field: 'balance', minWidth: 150, flex: 1 },
  ];

  setOpenCorrectionModal = (): void => {
    this.openCorrectionModal = true;
  };

  setCloseCorrectionModal = (): void => {
    this.openCorrectionModal = false;
  };

  refetchTransactions = (): void => {
    this.refetchKey = Date.now();
  };

  render() {
    return (
      <Box mt={3}>
        {this.partner && (
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.getTransactions}
            refetchKey={this.refetchKey}
            disableColumnMenu
            actions={{
              onAdd: {
                name: 'Add New',
                action: this.canMakeCorrection ? this.setOpenCorrectionModal : undefined,
              },
            }}
            pathname={this.props.location.pathname}
          />
        )}
        <PartnerWalletCorrectionModal
          open={this.openCorrectionModal}
          close={this.setCloseCorrectionModal}
          onCorrectionSuccess={this.refetchTransactions}
          walletId={this.walletId}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(PartnerDetailsWallet);
