import Api, { ApiResponse } from 'api';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { isEmpty } from 'lodash';
import { action, autorun, computed, flow, observable, reaction, makeObservable } from 'mobx';
import { now } from 'mobx-utils';
import * as models from 'models';
import * as LocalStorage from 'services/localStorage';
import { ACL } from 'types';
import BaseStore from './BaseStore';
import RootStore from './RootStore';
import { refreshJwtToken } from 'utils/refreshTokenHelpers';
import getOrCreateFingerprint from 'components/Fingerprint/bl';
import { NOTIFICATIONS_LS_KEY } from './NotificationStore';

type SsoLogin = {
  email: string;
  token: string;
};

type DefaultLogin = {
  email: string;
  password: string;
};

export interface ISsoProvider extends Omit<models.ISsoProviderResponse, 'ssoProvider'> {
  name: string;
  originUrl: string;
  loginRedirectUrl: string;
}

type LoginProps = SsoLogin | DefaultLogin;

const ONE_DAY = 24 * 60 * 60 * 1000;
const STORE_NAME = 'userStore';

export enum UserScopes {
  TALENT = 'talent',
  OWNER = 'owner',
  GLOBAL_OWNER = 'global_owner',
  MANAGER = 'manager',
  ADMIN = 'admin',
  AFFILIATE = 'affiliate',
  NONE = 'none',
}
export interface LoginData {
  jwt: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: Record<string, any>;
  exp: number;
  refreshToken: string;
}

export type ScopeType =
  | 'talent'
  | 'owner'
  | 'global_owner'
  | 'manager'
  | 'admin'
  | 'affiliate'
  | 'none';
// Since every user can be affiliate, affiliate scope is only set when other scopes are not valid and user is an actual affiliate
export interface AffiliateScope {
  kind: 'affiliate';
}
export interface TalentScope {
  kind: 'talent';
}
export interface OwnerScope {
  kind: 'owner';
  accountId: number;
}
export interface GlobalOwnerScope {
  kind: 'global_owner';
}
export interface ManagerScope {
  kind: 'manager';
  locationId: number;
}
export interface AdminScope {
  kind: 'admin';
}
/** Represent as what kind of user someone is currently viewing the dashboard */
export type Scope =
  | AffiliateScope
  | TalentScope
  | OwnerScope
  | GlobalOwnerScope
  | ManagerScope
  | AdminScope
  | { kind: 'none' };

/** The 2FA security token for user identity verification */
export interface IdentityVerificationToken {
  code: string;
  expiresAt: number;
  isActive: boolean;
  isValid: boolean;
}

/**
 * The UserStore contains data and logic regarding the current
 * logged-in user.
 */
export default class UserStore extends BaseStore {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /** The object that represents the current user */
  @observable public user?: models.User;

  /** When the JWT token expires, in epoch ms */
  @observable private exp?: number;

  /** The encoded JWT */
  @observable private jwt?: string;

  /** Refresh token */
  @observable public refreshToken?: string;

  /** The accounts of the currently logged-in user */
  @observable public accounts: models.Account[] = [];

  /** Global owners with more than 5 accounts should see only 5 accounts at a time */
  @observable public accountsToDisplay: models.Account[] = [];

  /** The LocationUser objects for the currently logged-in user */
  @observable public locationUsers: models.LocationUser[] = [];

  /** The affiliate object for the currently logged-in user */
  @observable public affiliate?: models.Affiliate;

  /** Whether the user is currently logging in */
  @observable public loggingIn = false;

  /** The current user's scope */
  @observable public scope: Scope = { kind: UserScopes.NONE };

  /** We use the UserStore to store some data about the affiliate signup process */
  @observable public affiliateSignupData: { orgCode?: string } = {};

  /** We use the UserStore to store some data about the bank signup process */
  @observable public bankSignupData: { instant?: boolean } = {};

  /** We use the UserStore to store some data about the POS integrations signup process */
  @observable public integrationApps: any[] = [];

  /** Whether the user is currently coming in from a web-view */
  @observable public webView = false;

  @observable public industry?: models.Industry;

  @observable public ssoProvider?: ISsoProvider;

  /**
   * The security token, if present. Use the computed value securityToken
   * for accessing this.
   */
  @observable private identityVerificationToken_?: IdentityVerificationToken;

  /**
   * Returns the security token if it exists and isn't expired,
   * otherwise returns undefined.
   */
  @computed public get identityVerificationToken(): IdentityVerificationToken | undefined {
    if (!this.identityVerificationToken_) {
      return undefined;
    }
    if (
      !this.identityVerificationToken_.expiresAt ||
      now() > this.identityVerificationToken_.expiresAt
    ) {
      return undefined;
    }
    return this.identityVerificationToken_;
  }

  @computed public get isPhoneConfirmed() {
    return this.user?.isPhoneConfirmed;
  }

  @action.bound public setIdentityVerificationToken(t: IdentityVerificationToken) {
    this.identityVerificationToken_ = t;
  }

  @action.bound initAccountsToDisplay() {
    const accounts = _.orderBy(this.accounts, [(account) => account.name.toLowerCase()], ['asc']);
    return (this.accountsToDisplay = [...accounts]);
  }

  @action.bound public setAccountsToDisplay(account: models.Account) {
    if (!account) return;
    let accountsToDisplay = [...this.accountsToDisplay].filter(
      (acc: models.Account) => acc.id !== account.id,
    );
    accountsToDisplay = [account, ...accountsToDisplay];
    this.accountsToDisplay = accountsToDisplay;
    this.scope = { kind: 'owner', accountId: account.id };
    this.rootStore.routerStore.history.push('/dashboard');
  }

  @computed public get loggedIn(): boolean {
    return Boolean(this.user);
  }

  /**
   * Represents the current authenticated user. Accessing throws an error if the current
   * user is not authenticated, so be careful.
   */
  @computed public get authUser(): models.User {
    if (!this.user) {
      throw new Error('Accessing auth user when user is not logged in');
    }
    return this.user;
  }
  /**
   * The locations where the current user is the manager.
   */
  @computed public get managedLocations(): models.Location[] {
    return this.locationUsers
      .filter((locationUser) => locationUser.isManager)
      .map((locationUser) => locationUser.location!);
  }

  /**
   * The locations where the current user performs services as an SP
   */
  @computed public get spLocations(): models.Location[] {
    return this.locationUsers
      .filter((locationUser) => locationUser.isTalent)
      .map((locationUser) => locationUser.location!);
  }

  /**
   * The user's locations.
   */
  @computed public get locations(): models.Location[] {
    return this.locationUsers.map((locationUser) => locationUser.location!);
  }

  /**
   * Whether the current selected scope is valid. Useful in the case that when
   * fetch new locations and accounts, we have to check if the selected scope
   * is still valid and if it's not, select a new one.
   */
  @computed public get scopeValid() {
    // If the scope's kind is owner, the scope is valid
    // if the scope's id exists in our current accounts
    if (this.scope.kind === 'owner') {
      return Boolean(
        this.accounts &&
          this.accounts.filter((account) => account.id === (this.scope as OwnerScope).accountId)
            .length > 0,
      );
    }
    // If the scope's kind is global_owner, the scope is valid
    // if the scope's ids exist in our current accounts
    if (this.scope.kind === 'global_owner') {
      return Boolean(this.accounts && this.accounts.length > 1);
    }
    // If the scope's kind is manager, the scope is valid
    // if the scope's id exists in our current managed locations
    if (this.scope.kind === 'manager') {
      return Boolean(
        this.managedLocations &&
          this.managedLocations.filter(
            (location) => location.id === (this.scope as ManagerScope).locationId,
          ).length > 0,
      );
    }
    // The talent scope is valid if the current user has locations where they are
    // an SP
    if (this.scope.kind === 'talent') {
      return Boolean(this.spLocations && this.spLocations.length > 0);
    }
    // The admin scope is valid if the current user is an admin
    if (this.scope.kind === 'admin') {
      return this.isAdmin;
    }
    return false;
  }

  /**
   * Whether the current user works as an SP somewhere.
   */
  @computed public get isSp(): boolean {
    return Boolean(this.spLocations.length > 0);
  }

  /** Whether the current user is a manager somewhere */
  @computed public get isManager(): boolean {
    return Boolean(this.managedLocations.length > 0);
  }

  /** Whether the current user is an account owner */
  @computed public get isOwner(): boolean {
    return Boolean(this.accounts.length >= 1);
  }

  /** Whether the current user is a global account owner */
  @computed public get isGlobalOwner(): boolean {
    return Boolean(this.accounts.length > 1);
  }

  /** Whether the current user is an admin */
  @computed public get isAdmin(): boolean {
    return Boolean(this.user && this.user.isAdmin);
  }

  /** Whether the current user scope is manager */
  @computed public get isManagerScope(): boolean {
    return Boolean(this.scope.kind === 'manager');
  }

  /** Whether the current user scope is owner */
  @computed public get isOwnerScope(): boolean {
    return Boolean(this.scope.kind === 'owner');
  }

  /** Whether the current user scope is global owner */
  @computed public get isGlobalOwnerScope(): boolean {
    return Boolean(this.scope.kind === 'global_owner');
  }

  /**
   * Returns the current selected account in the scope.
   */
  @computed public get currentAccount(): models.Account | undefined {
    if (this.isOwner || this.isGlobalOwner) {
      return this.accounts.filter(
        (acc) => this.scope.kind === 'owner' && acc.id === this.scope.accountId,
      )[0];
    }
    return undefined;
  }

  /**
   * Returns the current selected managed location in the scope.
   */
  @computed public get currentManagedLocation(): models.Location | undefined {
    if (this.isManager) {
      return this.managedLocations.filter(
        (location) => this.scope.kind === 'manager' && location.id === this.scope.locationId,
      )[0];
    }
    return undefined;
  }

  /** Returns the user's full name */
  @computed public get fullName(): string | undefined {
    return _.join(_.compact([this.authUser.firstName, this.authUser.lastName]), ' ');
  }

  /** Returns the name of the current scope */
  @computed public get scopeName(): string | undefined {
    const scopeKind = this.scope.kind;
    if (scopeKind === 'talent') {
      return this.fullName;
    } else if (scopeKind === 'manager') {
      return this.currentManagedLocation && this.currentManagedLocation.name;
    } else if (scopeKind === 'owner') {
      return this.currentAccount && this.currentAccount.name;
    } else if (scopeKind === 'global_owner') {
      return 'Global';
    }
    return undefined;
  }

  /** Whether the user has a first name and last name */
  @computed public get hasName(): boolean {
    return Boolean(
      this.user &&
        typeof this.user.firstName === 'string' &&
        typeof this.user.lastName === 'string',
    );
  }

  /**
   * Initializes the store by reading data from the local storage and
   * sets up watchers to synchronize the AuthStore with local storage.
   */
  @action.bound public init() {
    // Initialize the store with the values that are currently in local storage
    this.initLocalStorage();
    // Set up watchers to update the local storage whenever user values change
    autorun(() => LocalStorage.set(`${STORE_NAME}/user`, this.user));
    autorun(() => LocalStorage.set(`${STORE_NAME}/locationUsers`, this.locationUsers));
    autorun(() => LocalStorage.set(`${STORE_NAME}/accounts`, this.accounts));
    autorun(() => LocalStorage.set(`${STORE_NAME}/accountsToDisplay`, this.accountsToDisplay));
    autorun(() => LocalStorage.set(`${STORE_NAME}/scope`, this.scope));
    autorun(() => LocalStorage.set(`${STORE_NAME}/affiliate`, this.affiliate));
    autorun(() => LocalStorage.set(`${STORE_NAME}/integrations`, this.integrationApps));

    this.refreshToken = localStorage.getItem(LocalStorage.ELocalStorageItem.REFRESH_TOKEN) as
      | string
      | undefined;

    // Set up watchers to sync JWT token values to localStorage
    reaction(
      () => this.user,
      (user) => {
        LocalStorage.set(`${STORE_NAME}/user`, user);
      },
    );
    reaction(
      () => this.exp,
      (exp) => LocalStorage.setExp(exp),
    );
    reaction(
      () => this.jwt,
      (jwt) => LocalStorage.setJwt(jwt),
    );
    reaction(
      () => this.refreshToken,
      (refreshToken) => {
        if (refreshToken) {
          LocalStorage.setRefreshToken(refreshToken);
        }
      },
    );

    // If the user is logged in, get fresh user data
    if (this.loggedIn) {
      this.getRelatedData();
    }
  }

  /** Initializes the local storage */
  @action.bound public initLocalStorage() {
    this.jwt = LocalStorage.getJwt();
    this.exp = LocalStorage.getExp();
    // We get the user data from local storage so they don't have to wait for it
    // on initial page load.
    this.user = (LocalStorage.get(`${STORE_NAME}/user`) as models.User) || this.user;
    this.accounts = (LocalStorage.get(`${STORE_NAME}/accounts`) as models.Account[]) || [];
    this.accountsToDisplay =
      (LocalStorage.get(`${STORE_NAME}/accountsToDisplay`) as models.Account[]) || [];
    this.locationUsers =
      (LocalStorage.get(`${STORE_NAME}/locationUsers`) as models.LocationUser[]) || [];
    this.scope = (LocalStorage.get(`${STORE_NAME}/scope`) as Scope) || this.scope;
    this.affiliate =
      (LocalStorage.get(`${STORE_NAME}/affiliate`) as models.Affiliate) || this.affiliate;
    this.integrationApps =
      (LocalStorage.get(`${STORE_NAME}/integrations`) as any[]) || this.integrationApps;
  }

  /**
   * Fetches the user's related data, such as the user's locations and accounts
   */
  @action.bound public getRelatedData = flow(function* (this: UserStore) {
    // Fetch the user's locations and accounts. If the user is an admin, they
    // can see all the accounts, so we don't fetch them now
    if (!this.authUser.isAdmin) {
      yield Promise.all([this.getLocationUsers(), this.getAccounts(), this.getAffiliate()]);
    }
    // Now we have an updated list of locations and accounts. Check
    // if the current selected scope is valid. If it isn't, choose
    // a new scope.
    if (!this.scopeValid) {
      // If the user has more than one account, save all accounts. Otherwise,
      // if they have managed locations, user the first one. Otherwise,
      // use the user scope.
      if (this.accounts.length > 0) {
        this.initAccountsToDisplay();
        if (this.accounts.length > 1) {
          this.setScope({ kind: 'global_owner' });
        } else {
          this.setScope({ kind: 'owner', accountId: this.accounts[0].id });
        }
      } else if (this.managedLocations.length > 0) {
        this.setScope({ kind: 'manager', locationId: this.managedLocations[0].id });
      } else if (this.spLocations.length > 0) {
        this.setScope({ kind: 'talent' });
      } else if (this.isAdmin) {
        this.setScope({ kind: 'admin' });
      } else {
        if (this.affiliate) {
          this.setScope({ kind: 'affiliate' });
        } else {
          this.setScope({ kind: 'none' });
        }
      }
    }
  });

  /**
   * Logs the user out.
   */
  @action.bound public logout(replaceHistory?: string) {
    this.rootStore.notificationStore.onLogout();
    const allSettings = this.rootStore.settingStore.getAll();
    const expiredPaymentMethods = LocalStorage.getProperties(NOTIFICATIONS_LS_KEY);
    const pendingIntegrations = LocalStorage.getProperties('pendingIntegrations');

    this.exp = undefined;
    this.jwt = undefined;
    this.refreshToken = undefined;

    this.accounts = [];
    this.accountsToDisplay = [];
    this.locationUsers = [];
    this.scope = { kind: 'none' };
    this.user = undefined;
    this.ssoProvider = undefined;

    LocalStorage.clear();

    allSettings && this.rootStore.settingStore.setAll(allSettings);
    expiredPaymentMethods && LocalStorage.setProperties(expiredPaymentMethods);
    pendingIntegrations && LocalStorage.setProperties(pendingIntegrations);
    this.rootStore.payoutSourceStore.clear();
    // TODO: We use this when user finishes singup flow and clicks ‘Or login to the dasboard’ or ‘sign in’.
    //If we didn’t do this, user would be redirected to last screen of signup flow when they logged in.
    // We need to find a better way to achieve the same result when we refactor the signup flow
    if (replaceHistory) {
      this.rootStore.routerStore.history.replace(replaceHistory);
    }
  }

  /**
   * Calls the API endpoint to refresh the token and updates the auth
   * data with the new jwt token, user, and expiration.
   */
  @action.bound public refreshTokenAndUpdateUserData = flow(function* (
    this: UserStore,
    email?: string,
  ) {
    const resp = yield refreshJwtToken(email);
    this.setAuthData(resp.data.data);
  });

  /**
   * Logs the user in with the provided email and password.
   * TODO: Error handling
   * @param props The user's email and password or token
   */
  @action.bound public login = flow(function* (this: UserStore, props: LoginProps) {
    const { email } = props;
    try {
      this.loggingIn = true;
      yield this.checkFingerprintAndCreate();
      // Call the login API endpoint
      let resp;
      if ('token' in props) {
        resp = yield Api.core.loginSso(email, props.token);
      } else {
        resp = yield Api.core.login(email, props.password);
      }

      if (resp.status === 403) {
        throw new Error('Invalid email or password');
      }
      if (resp.status !== 200) {
        throw new Error('Something went wrong');
      }

      // Get the data from the login and set the AuthStore values
      yield this.loginWithData(resp.data.data);

      this.rootStore.payoutSourceStore.init();
      this.rootStore.notificationStore.onLogin();
      // eslint-disable-next-line no-useless-catch
    } catch (e: any) {
      throw e;
    } finally {
      this.loggingIn = false;
    }
  });

  /**
   * Wrapper for checkFingerprintAndCreate so that we can use yield in login flow
   */
  @action.bound private async checkFingerprintAndCreate() {
    await getOrCreateFingerprint();
  }

  /**
   * Logs the user in with the provided login data.
   * @param data The login data returned from a login-like endpoint
   */
  @action.bound public loginWithData = flow(function* (this: UserStore, data: LoginData) {
    this.setAuthData(data);
    // Get the user's accounts and other data
    yield this.getRelatedData();
  });

  /**
   * Fetches the current user's accounts.
   */
  @action.bound public getAccounts = flow(function* (this: UserStore) {
    const resp = yield Api.core.getAccounts();
    this.accounts = resp.data.data;
  });

  /** Fetches the LocationUser objects for the current user */
  @action.bound public getLocationUsers = flow(function* (this: UserStore) {
    const resp = yield Api.core.getUserLocationsV1(this.authUser.id);
    this.locationUsers = resp.data.data;
  });

  /**
   * Gets the affiliate object for the user
   */
  @action.bound public getAffiliate = flow(function* (this: UserStore) {
    try {
      const resp: AxiosResponse<ApiResponse<models.Affiliate | {}>> =
        yield Api.marketing.getAffiliateForUser(this.user!.id);
      if (!isEmpty(resp.data.data) && (resp.data.data as models.Affiliate).id) {
        this.affiliate = resp.data.data as models.Affiliate;
      } else {
        this.affiliate = undefined;
      }
      // eslint-disable-next-line no-empty
    } catch (e: any) {}
  });

  /** Sets the current user's scope */
  @action.bound public setScope(scope: Scope) {
    this.scope = scope;
  }

  /**
   * Sets the JWT, expiration and user object in the store according to `data`.
   * @param data The data returned by Api.core.login
   */
  @action.bound public setAuthData(data: LoginData) {
    const { jwt, user, exp, refreshToken } = data;
    this.jwt = jwt;
    this.exp = exp;
    this.refreshToken = refreshToken ? refreshToken : this.refreshToken;
    this.setUserData(user as models.User);
  }

  /** Updates the user object with the provided data */
  @action.bound public setUserData(user: models.User) {
    this.user = {
      ...user,
    };
  }

  /** Sets the affiliate signup organization code */
  @action.bound public setAffiliateOrgCode(code: string) {
    this.affiliateSignupData.orgCode = code;
  }

  /** Set the webView observable */
  @action.bound public setWebView(to = true) {
    this.webView = to;
  }

  /** Checks whether the user has the provided permission */
  public hasPermission = (p: ACL) => {
    return Boolean(this.user?.permissions?.includes(p));
  };

  /** Sets the bank signup instant payment support */
  @action.bound public setBankInstant(instant: boolean) {
    this.bankSignupData.instant = instant;
  }

  /** Sets the bank signup instant payment support */
  @action.bound public setNonIntegratedApps(integrations: any[]) {
    this.integrationApps = integrations;
  }

  @action.bound public setSsoProvider(provider: models.ISsoProviderResponse | undefined) {
    if (!provider) return;
    let { ssoProvider: name, loginRedirectUrl } = provider;
    let originUrl = '';
    if (loginRedirectUrl) {
      originUrl = new URL(loginRedirectUrl).origin;
    }
    this.ssoProvider = { name, loginRedirectUrl, originUrl };
  }

  @action.bound public async getSsoProvider(
    email: string,
  ): Promise<models.ISsoProviderResponse | undefined> {
    const { data } = await Api.core.getUserSsoProvider(email);
    const ssoProvider = data.data;
    this.setSsoProvider(ssoProvider);
    return ssoProvider;
  }
}

export interface WithUserStore {
  userStore?: UserStore;
}
