/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { observable, action, makeObservable, reaction } from 'mobx';
import { observer } from 'mobx-react';

import Api, { RequestMetaData } from 'api';
import { inject, WithSettingStore } from 'stores';
import { paths } from 'routes';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { IconButton, Box, Tooltip, Link, Drawer } from '@material-ui/core';
import { Eye } from 'mdi-material-ui';

import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { adaptForDataGridPro } from 'services/datagrid';
import styles from './styles';
import { Filter } from 'models';
import FilterBar from 'components/FilterBar';
import CardsDrawer from './CardsDrawer';
import moment from 'moment-timezone';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import theme from 'containers/App/theme';

interface Colors {
  [key: string]: string;
}

const colors: Colors = {
  PROCESSED: ChipStatusColors.GREEN,
  FAILED: ChipStatusColors.RED,
  PENDING: ChipStatusColors.YELLOW,
  CREATED: ChipStatusColors.PURPLE,
  CANCELED: ChipStatusColors.RED,
  VOIDED: ChipStatusColors.GREY,
};

export interface ITransferDetails {
  date: string;
  amount: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  processor: string;
  destination: string;
  status: string;
  createdAt: string;
  finishedAt: string;
  transactionId: string;
  name: string;
  time: string;
}

function annotateCardTransfer(transfer: ITransferDetails) {
  const formattedAmount = parseFloat(transfer.amount || '0').toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const firstName = transfer.user.firstName.trim();
  const lastName = transfer.user.lastName.trim();
  const name = `${firstName} ${lastName}`;
  const status = transfer.status.toUpperCase();
  const date = `${moment(transfer.createdAt).format('MMM DD, YYYY')}`;
  const time = `${moment(transfer.createdAt)
    .tz('America/New_York')
    .format('h:mm A')
    .toUpperCase()}`;

  return {
    ...transfer,
    name,
    status,
    date,
    time,
    amount: formattedAmount,
  };
}

type CardsProps = WithStyles<typeof styles> &
  WithSettingStore &
  RouteComponentProps & { isOpen: boolean; filters?: Record<string, unknown> };

@inject('userStore', 'settingStore')
@observer
class Cards extends React.Component<CardsProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);

    reaction(
      () => this.props.isOpen,
      (isOpen) => {
        this.isOpen = isOpen;
      },
    );
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** Active filters as returned by FilterBar */
  @observable private currentRow: ITransferDetails | undefined = undefined;
  @observable private isOpen = this.props.isOpen ?? false;

  /**
   * Used for forcing datagrid to reload and display last initiation when
   * its status changes from 'running' to 'stopped' or 'completed'
   */
  @observable datagridKey = Date.now();

  renderActions = ({ row }: any) => {
    return (
      <IconButton color="primary" onClick={() => this.openModal(row)}>
        <Tooltip title="Transfer details">
          <Eye fontSize="small" />
        </Tooltip>
      </IconButton>
    );
  };

  renderStatus = ({ value, row }: any) => {
    const color = colors[value.toUpperCase() as keyof Colors] as ChipStatusColors;
    return <ChipStatusTag label={value} color={color} />;
  };

  renderUser = ({ row }: any) => (
    <Link component={RouterLink} to={paths.userDetails(row.user.id).root()}>
      {row.name}
    </Link>
  );

  renderAmount = ({ row }: any) => (
    <span style={{ color: theme.palette.primary.main }}>{row.amount}</span>
  );

  public fetchCardTransfers = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.tips.getCardTransfers({
        ...rmd,
        filters: { ...this.activeFilters },
      }),
    annotateCardTransfer,
  );

  /** List of available filters for FilterBar component */
  filters: Filter[] = [
    { display: 'User ID', id: 'userId', label: 'Contains', type: 'text' },
    { display: 'Name', id: 'user', label: 'Contains', type: 'text' },
    {
      display: 'Processor',
      id: 'processor',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'dwolla', value: 'dwolla' },
        { label: 'netspend', value: 'netspend' },
        { label: 'branch', value: 'branch' },
      ],
    },
    {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'PROCESSED', value: 'processed' },
        { label: 'FAILED', value: 'failed' },
        { label: 'PENDING', value: 'pending' },
        { label: 'CREATED', value: 'created' },
        { label: 'CANCELED', value: 'canceled' },
        { label: 'VOIDED', value: 'voided' },
      ],
    },
  ];

  gridColumns = [
    { headerName: 'Name', field: 'name', minWidth: 200, flex: 1, renderCell: this.renderUser },
    {
      headerName: 'Date',
      field: 'createdAt',
      minWidth: 170,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    { headerName: 'Processor', field: 'processor', minWidth: 150, flex: 1 },
    {
      headerName: 'Amount',
      field: 'amount',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderAmount,
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 200,
      filterable: false,
      renderCell: this.renderStatus,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      minWidth: 90,
      flex: 1,
      filterable: false,
      renderCell: this.renderActions,
    },
  ];

  @action.bound private closeModal = () => {
    this.isOpen = false;
  };

  @action.bound private openModal = (row: any) => {
    this.currentRow = { ...row };
    this.isOpen = true;
  };

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <>
        <Box mt={3}>
          <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} showDateRange />
          {this.filtersInitReady && (
            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.fetchCardTransfers}
              refetchKey={this.activeFilters}
              disableColumnMenu
              pathname={this.props.location.pathname}
            />
          )}
        </Box>
        {this.currentRow && (
          <Drawer open={this.isOpen} onClose={this.closeModal} anchor="right" variant="temporary">
            <CardsDrawer closeModal={this.closeModal} row={this.currentRow} />
          </Drawer>
        )}
      </>
    );
  }
}

export default withStyles(styles)(Cards);
