import { Box, Paper } from '@material-ui/core';

import DateRangePicker from 'components/DateRangeExternalPicker';
import clsx from 'clsx';
import { filterPaperStyling } from '../styles';
import { ExternalDateRangeProps } from 'models';

interface FilterBarExternalDateRangePickerProps {
  externalDateRange: ExternalDateRangeProps;
}

export const FilterBarExternalDateRangePicker = ({
  externalDateRange,
}: FilterBarExternalDateRangePickerProps) => {
  return (
    <Box sx={filterPaperStyling(false)}>
      <Paper className={clsx('paperBoxShadow', 'paperRoot', 'externalDateRange')}>
        <Box className="root">
          <DateRangePicker
            onChange={externalDateRange.onChange}
            predefinedRange={externalDateRange.value}
            isFormField
          />
        </Box>
      </Paper>
    </Box>
  );
};
