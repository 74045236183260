import { Link } from '@material-ui/core';
import Api from 'api';
import { paths } from 'routes';
import { Link as RouterLink } from 'react-router-dom';
import { Filter, TipReport } from 'models';
import { v4 as uuidv4 } from 'uuid';
import { EllipsizedValue } from 'services';
import { GridColDef } from '@mui/x-data-grid-pro';

export enum ETipsReportTab {
  TALENT = 'talent',
  LOCATION = 'location',
  ACCOUNT = 'account',
}

export interface TipsReportChildProps {
  isAdmin?: boolean;
  isOwner: boolean;
  isGlobalOwner: boolean;
  isManager: boolean;
  accountId?: number;
  locationId?: number;
  isMobile: boolean;
}

export const getGlobalOwnerFetch = (tab: ETipsReportTab) => {
  switch (tab) {
    case ETipsReportTab.ACCOUNT:
      return Api.analytics.global.tipsStats.byAccount;
    case ETipsReportTab.LOCATION:
      return Api.analytics.global.tipsStats.byLocation;
    case ETipsReportTab.TALENT:
      return Api.analytics.global.tipsStats.byTalent;
  }
};

export const getGlobalOwnerCsvFetch = (tab: ETipsReportTab) => {
  switch (tab) {
    case ETipsReportTab.ACCOUNT:
      return Api.analytics.global.tipsCsvReport.byAccount;
    case ETipsReportTab.LOCATION:
      return Api.analytics.global.tipsCsvReport.byLocation;
    case ETipsReportTab.TALENT:
      return Api.analytics.global.tipsCsvReport.byTalent;
  }
};

export const getAdminFetch = (tab: ETipsReportTab) => {
  switch (tab) {
    case ETipsReportTab.ACCOUNT:
      return Api.analytics.stats.tipsStats.byAccount;
    case ETipsReportTab.LOCATION:
      return Api.analytics.stats.tipsStats.byLocation;
    case ETipsReportTab.TALENT:
      return Api.analytics.stats.tipsStats.byTalent;
  }
};

export const getAdminCsvFetch = (tab: ETipsReportTab) => {
  switch (tab) {
    case ETipsReportTab.ACCOUNT:
      return Api.analytics.stats.tipsCsvReport.byAccount;
    case ETipsReportTab.LOCATION:
      return Api.analytics.stats.tipsCsvReport.byLocation;
    case ETipsReportTab.TALENT:
      return Api.analytics.stats.tipsCsvReport.byTalent;
  }
};

const renderCellLocation = ({ row }: any) => {
  if (row.locationId) {
    return (
      <Link
        component={RouterLink}
        to={paths.locationDetails(row.locationId)}
        style={{ width: '100%' }}>
        <EllipsizedValue value={row.locationName} max={20} />
      </Link>
    );
  }
  return <></>;
};

/** Renders the user's name */
const renderCellName = ({ row }: any) => {
  const label =
    row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : 'Undistributed';
  return (
    <Link component={RouterLink} to={paths.userDetails(row.userId).info()}>
      {label}
    </Link>
  );
};

const renderCellAccount = ({ row }: any) => {
  if (row.accountId) {
    return (
      <Link component={RouterLink} to={paths.accountDetails(row.accountId).root()}>
        {row.accountName}
      </Link>
    );
  }
  return <></>;
};

const renderCellNetTip = ({ row }: any) => {
  return `${row.percentAvg}%`;
};

export const commonColumns = [
  { headerName: 'Count', field: 'count', minWidth: 150, flex: 1 },
  { headerName: 'Tip Avg', field: 'netAvg', minWidth: 150, flex: 1 },
  { headerName: 'Net Tip', field: 'netSum', minWidth: 150, flex: 1 },
  { headerName: 'Service', field: 'serviceAmountSum', minWidth: 150, flex: 1 },
  {
    headerName: 'Net Tip',
    field: 'percentAvg',
    minWidth: 150,
    flex: 1,
    renderCell: renderCellNetTip,
  },
  { headerName: 'Svc Avg', field: 'serviceAmountAvg', minWidth: 150, flex: 1 },
];

export const byTalentColumns = [
  { headerName: 'Name', field: 'name', minWidth: 150, flex: 1, renderCell: renderCellName },
];

export const byLocationColumns = (filterable: boolean) => [
  {
    headerName: 'Location',
    field: 'location',
    minWidth: 150,
    flex: 1,
    renderCell: renderCellLocation,
    filterable,
  },
];

export const byAccountColumns = [
  { headerName: 'Account', field: 'account', width: 150, renderCell: renderCellAccount },
];

export const ownerColumns = [
  { headerName: 'Low (%)', field: 'lowPercentage', width: 150 },
  { headerName: 'Medium (%)', field: 'mediumPercentage', width: 150 },
  { headerName: 'High (%)', field: 'highPercentage', width: 150 },
  { headerName: 'Custom (%)', field: 'customPercentage', width: 150 },
];

const getOptions = (fields: string[]) => {
  const options: Filter[] = [
    { display: 'Name', id: 'name', label: 'Contains', type: 'text' },
    { display: 'Account', id: 'account', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'location', label: 'Contains', type: 'text' },
  ];

  return fields.reduce((acc: Filter[], field: string) => {
    const option = options.find((f) => f.id == field);
    if (option) {
      acc.push(option);
    }
    return acc;
  }, []);
};

export const getFilterOptions = ({
  tab,
  isGlobalOwner,
  isAdmin,
}: {
  tab: ETipsReportTab;
  isGlobalOwner: boolean;
  isAdmin?: boolean;
}): Filter[] => {
  switch (tab) {
    case ETipsReportTab.TALENT:
      return getOptions(isAdmin || isGlobalOwner ? ['name', 'account', 'location'] : ['location']);
    case ETipsReportTab.LOCATION:
      return getOptions(['account', 'location']);
    default:
      return getOptions(['account']);
  }
};

export const getColumnsOptions = ({
  tab,
  isGlobalOwner,
  isOwner,
  isAdmin,
}: {
  tab: ETipsReportTab;
  isGlobalOwner: boolean;
  isOwner: boolean;
  isAdmin?: boolean;
}): GridColDef[] => {
  let columns = [];
  const isFilterable = isAdmin || isOwner || isGlobalOwner;

  switch (tab) {
    case ETipsReportTab.TALENT:
      columns = [
        ...byTalentColumns,
        ...byAccountColumns,
        ...byLocationColumns(isFilterable),
        ...commonColumns,
      ];
      break;
    case ETipsReportTab.LOCATION:
      columns = [...byAccountColumns, ...byLocationColumns(isFilterable), ...commonColumns];
      break;
    default:
      columns = [...byAccountColumns, ...commonColumns];
  }

  if (isGlobalOwner || isOwner) {
    columns = [...columns, ownerColumns];
  }
  return columns as GridColDef[];
};

export const annotateTipsPerTab = (tip: TipReport, tab: ETipsReportTab) => {
  if (tab === ETipsReportTab.TALENT) {
    return {
      ...tip,
      id: uuidv4(),
      name: `${tip.firstName} ${tip.lastName}`,
      accountName: tip.account ? tip.account.name : '',
      accountId: tip.account ? tip.account.id : null,
      locationName: tip.location ? tip.location.name : '',
      locationId: tip.location ? tip.location.id : null,
    };
  }
  if (tab === ETipsReportTab.LOCATION) {
    return {
      ...tip,
      id: uuidv4(),
      accountName: tip.account ? tip.account.name : '',
      accountId: tip.account ? tip.account.id : null,
      locationName: tip.location ? tip.location.name : '',
      locationId: tip.location ? tip.location.id : null,
    };
  }
  if (tab === ETipsReportTab.ACCOUNT) {
    return {
      ...tip,
      id: uuidv4(),
      accountName: tip.account ? tip.account.name : '',
      accountId: tip.account ? tip.account.id : null,
    };
  }
  return tip;
};
