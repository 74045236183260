import { Account } from './Account';
export interface Wallet {
  id: number;
  /**
   * Id of the user who this wallet belongs to
   */
  userId: number;
  /**
   * Id of the user who this wallet belongs to
   */
  bankAccountId?: number;
  /**
   * Address of this wallet for receiving and sending funds
   */
  address?: string;
  /**
   * The user given name for this wallet
   */
  name?: string;
  /**
   * The type of this wallet
   */
  type?: string;
  /**
   * Name of the payout processor connected to this wallet.
   */
  processor?: string;

  /**
   * Processor token used to identify funding source at the processor side.
   */
  destination?: string;

  /**
   * Payout source to identify the destination at the processor side.
   */
  payoutSource?: string;

  /**
   * Wallet balance
   */
  balance?: string;

  /**
   * Indicates if wallet is active
   */
  isActive: boolean;

  /**
   * Wallet creation date
   */
  createdAt?: string;
  debitCardId?: number;
}

export interface WalletStats {
  correctionsBalance: string;
  correctionsCount: number;
  inTransfersAmount: string;
  inTransfersCount: number;
  outTransfersAmount: string;
  outTransfersCount: number;
  payoutsAmount: string;
  payoutsCount: number;
  refundsAmount: string;
  refundsCount: number;
  splitsInitiatedAmount: string;
  splitsInitiatedCount: number;
  splitsReceivedAmount: string;
  splitsReceivedCount: number;
  tipsReceivedAmount: string;
  tipsReceivedCount: number;
  totalTipsReceivedAmount: string;
  totalTipsReceivedCount: number;
  walletId: number;
}

/** A single ledger transaction */
export interface Transaction {
  account?: Account;
  accountId?: number;
  amount?: string;
  balance?: string;
  balanceValue?: string;
  billingEntity?: {
    id: number;
    code: string;
  };
  billingEntityBalance?: string;
  billingEntityBalanceValue?: string;
  billingEntityId?: number;
  charge?: {
    id: number;
    processorPaymentId: string;
  };
  invoice?: {
    id: number;
    number: string;
  };
  invoiceRefund?: {
    id: number;
    processorRefundId: string;
  };
  chargeId?: number;
  createdAt?: string;
  date?: string;
  reason?: string;
  id?: number;
  note?: string;
  tipId?: number;
  type?: string;
  refundId?: number;
  payoutId?: number;
  walletTransferId?: number;
  paymentReference?: string;
}

export enum LedgerChangeReason {
  TIP_RECEIVED = 'tip_received',
  BALANCE_CORRECTED = 'balance_corrected',
  TIP_REFUNDED = 'tip_refunded',
  TIP_SPLIT_OUT = 'split_out',
  TIP_SPLIT_IN = 'split_in',
  PAYOUT_COMPLETED = 'payout_completed',
  PAYOUT_CORRECTED = 'payout_corrected',
  WALLET_TRANSFER_IN = 'transfer_in',
  WALLET_TRANSFER_OUT = 'transfer_out',
  USERS_MERGE_IN = 'users_merge_in',
  USERS_MERGE_OUT = 'users_merge_out',
  INSTANT_PAYOUT_COMPLETED = 'instant_payout_completed',
  INSTANT_PAYOUT_CORRECTED = 'instant_payout_corrected',
  TRANSFER_TIP_IN = 'transfer_tip_in',
  TRANSFER_TIP_OUT = 'transfer_tip_out',
  REV_SHARE_FEE_IN = 'revenue_share_in',
  REV_SHARE_FEE_OUT = 'revenue_share_out',
  REV_SHARE_REFUNDED = 'revenue_share_refunded',
  INACTIVITY_FEE = 'inactivity_fee',
  TEMP_WALLET_TRANSFER = 'temp_wallet_transfer',
  REFERRAL_REWARD = 'referral_reward',
}

export interface IWalletTransaction {
  amount: string;
  balance: string;
  date: string;
  reason: LedgerChangeReason;
}
