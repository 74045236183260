import { useCallback, useEffect, useState } from 'react';

import { Box, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TagInputComponent from '../../TagInputComponent';
import OutlinedInput from 'components/Input/OutlinedInput';

import { Filter, FilterItem, IFilterValue } from 'models';
import { FilterBarItemsWrapper } from './FilterBarItemsWrapper';
import { FilterBarRangeField } from './FilterBarRangeField';

interface FilterBarFieldsProps {
  filters: Filter[];
  selectedFilter: Filter;
  setFilters: (filters: Filter[]) => void;
  filterValue?: IFilterValue[];
  setFilterValue: (filter?: IFilterValue[]) => void;
  clearSelectedFilter: () => void;
  closeFiltersMenu?: () => void;
  mobileView: boolean;
}

export const FilterBarFields = ({
  filters,
  selectedFilter,
  setFilters,
  filterValue,
  setFilterValue,
  clearSelectedFilter,
  closeFiltersMenu,
  mobileView,
}: FilterBarFieldsProps) => {
  const [tempValue, setTempValue] = useState<IFilterValue[]>(filterValue || []);

  const updateInputItems = useCallback(
    (items: any[]) => {
      if (selectedFilter.options) {
        setFilterValue(items.length ? items : undefined);
      } else {
        setFilterValue(items.map((it) => ({ id: it, name: it })));
      }
    },
    [selectedFilter.options, setFilterValue],
  );

  const setFilterValueAction = useCallback(() => {
    const updatedFilters: Filter[] = filters.map((filter) => {
      if (selectedFilter?.id === filter.id) {
        if (filter.id === 'fields') {
          return {
            ...filter,
            value: tempValue,
          };
        }

        const updatedFilter = [
          ...(filterValue as IFilterValue[])?.map((it) => ({
            id: String(it.id),
            name: it.name,
          })),
        ];

        return {
          ...filter,
          value: updatedFilter,
        };
      }
      return filter;
    });

    setFilters(updatedFilters);
    clearSelectedFilter();

    closeFiltersMenu && closeFiltersMenu();
  }, [
    clearSelectedFilter,
    closeFiltersMenu,
    filterValue,
    filters,
    selectedFilter?.id,
    setFilters,
    tempValue,
  ]);

  const updateRangeValue = useCallback(
    (range: IFilterValue) => {
      const filter = [...tempValue];

      const thisItem = filter.find((it) => it.name === range.name);
      if (thisItem) {
        thisItem.id = range.id;
      } else {
        filter.push(range);
      }

      setTempValue(filter);
    },
    [setFilterValue, tempValue, filterValue],
  );

  useEffect(() => {
    if (selectedFilter.type === 'range') {
      if (tempValue.length >= 1 && tempValue.find((it) => it.id)) {
        setFilterValue(tempValue);
      } else {
        setFilterValue(undefined);
      }
      return;
    }

    const length = selectedFilter.type === 'fields' ? 2 : 1;
    if (tempValue.length === length && tempValue.every((it) => it.id != null && it.id !== '')) {
      setFilterValue(tempValue);
    } else {
      setFilterValue(undefined);
    }
  }, [tempValue]);

  return (
    <FilterBarItemsWrapper
      filterValue={filterValue}
      selectedFilter={selectedFilter}
      setFilterValue={setFilterValueAction}
      mobileView={mobileView}
      clearSelectedFilter={clearSelectedFilter}>
      <Box>
        {/* TEXT */}
        {selectedFilter.type === 'text' && (
          <OutlinedInput
            value={tempValue[0] ? tempValue[0].id : ''}
            onChange={(e) => {
              setTempValue([{ id: e.target.value, name: e.target.value }]);
            }}
            label={selectedFilter.label}
            fullWidth
            inputRef={(input) => {
              setTimeout(() => {
                input && input.focus();
              }, 100);
            }}
          />
        )}

        {/* FIELDS */}
        {selectedFilter.type === 'fields' && selectedFilter.fields && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
            }}>
            {selectedFilter.fields.map((field) => {
              return (
                <OutlinedInput
                  value={tempValue.find((it) => it.name === field.id)?.id}
                  onChange={(e) => {
                    const res = tempValue;

                    const thisObj = res.find((it) => it.name === field.id);
                    if (thisObj) {
                      thisObj.id = e.target.value;
                    } else {
                      res.push({ id: e.target.value, name: field.id });
                    }

                    setTempValue([...res]);
                  }}
                  label={field.label}
                  name={field.id}
                  fullWidth
                  key={field.id}
                />
              );
            })}
          </Box>
        )}

        {/* TAGS */}
        {selectedFilter.type === 'tags' && (
          <TagInputComponent
            options={selectedFilter.options}
            selectedTags={updateInputItems}
            tags={filterValue}
            inputRef={(input: HTMLElement) => {
              setTimeout(() => {
                input && input.focus();
              }, 300);
            }}
            placeholder={'add'}
            label={selectedFilter.label}
          />
        )}

        {/* SELECT */}
        {selectedFilter.type === 'select' && (
          <FormControl component="fieldset">
            <RadioGroup
              value={filterValue ? filterValue[0].id : ' '}
              onChange={(e) => setFilterValue([{ id: e.target.value }])}>
              {selectedFilter.items &&
                selectedFilter.items.map((item: FilterItem) => (
                  <FormControlLabel
                    className="formControlLabel"
                    key={item.label}
                    value={item.value}
                    control={<Radio className="radio" color="primary" />}
                    label={item.label}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        )}

        {/* AUTOCOMPLETE */}
        {selectedFilter.type === 'autocomplete' && (
          <Autocomplete
            options={selectedFilter.items || []}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(o) => o.label || ''}
            onChange={(_, i: FilterItem | null) => {
              i?.value && setFilterValue([{ id: i.value, name: i.label }]);
            }}
            renderInput={(params) => (
              <OutlinedInput
                {...params}
                fullWidth
                label={selectedFilter.value && selectedFilter.value[0].name}
                value={selectedFilter.value && selectedFilter.value[0].id}
              />
            )}
          />
        )}

        {/* RANGE */}
        {selectedFilter.interval && selectedFilter.type === 'range' && (
          <FilterBarRangeField
            interval={selectedFilter.interval}
            value={filterValue || []}
            onChange={updateRangeValue}
          />
        )}
        {selectedFilter.type === 'date' && <> {selectedFilter.type} :: Cooming soon</>}
      </Box>
    </FilterBarItemsWrapper>
  );
};
