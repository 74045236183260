import React, { useCallback, useEffect } from 'react';
import { IconButton, Divider, Box } from '@material-ui/core';
import moment from 'moment';

import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { EDateRangeType, IDateRange, isoDateFormat } from './CustomPicker';
import OutlinedInput from 'components/Input/OutlinedInput';
import { default as DialogButton } from 'components/Button/Dialog/Button';
import theme from 'containers/App/theme';
import Button from 'components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { IntervalOptions } from 'models';

interface TimerPickerProps {
  onChangeMode: (mode: string) => void;
  onChangeValue: (date: MuiDateRange<Date>) => void;
  onChange: (range: IDateRange) => void;
  onOpen?: (open: boolean) => void;
  value: MuiDateRange<Date>;
  children: React.ReactNode;
  interval?: IntervalOptions;
  isMobile: boolean;
}
const TimerPicker = ({
  value,
  onChangeMode,
  onChangeValue,
  onChange,
  onOpen,
  interval,
  classes,
  children,
  isMobile,
}: TimerPickerProps & WithStyles<typeof styles>) => {
  const isValid = moment(value[0]).isValid() && moment(value[1]).isValid();

  const handleTimerRange = useCallback(
    (comp: string, val: Date | null) => {
      if (comp === 'start' && moment(val).isValid()) {
        const endDate = moment(value[1]).isValid() ? value[1] : null;
        onChangeValue([val, endDate]);
      }
      if (comp === 'end' && moment(val).isValid() && moment(value[0]).isValid()) {
        const isAfter = moment(val).isAfter(value[0]);
        onChangeValue([value[0], isAfter ? val : value[0]]);
      }
    },
    [value, onChangeValue],
  );

  useEffect(() => {
    isMobile && onOpen && onOpen(true);
  }, []);

  const handleChange = () => {
    onChange({
      fromDate: value[0],
      toDate: value[1],
      dateRangeType: EDateRangeType.DATE_TIMER,
    });
    onOpen && onOpen(false);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
      <Box display="flex" alignItems="center" width={'100%'} py={isMobile ? 0 : 2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box className={classes.actionMenuContainer}>
            {!isMobile && (
              <Box>
                <IconButton onClick={() => onChangeMode('menu')}>
                  <FontAwesomeIcon fontSize={20} icon={faChevronLeft} />
                </IconButton>
              </Box>
            )}
            <Box mb={isMobile ? 4 : 0} className={classes.timerPickerContainer}>
              <Box width={isMobile ? '100%' : '45%'}>
                <DateTimePicker
                  label="Start"
                  open={false}
                  inputFormat={isoDateFormat}
                  value={value[0]}
                  onChange={(newValue) => {
                    handleTimerRange('start', newValue);
                  }}
                  renderInput={(params) => {
                    const labelStart = interval ? interval.from.label : params.label;
                    return (
                      <OutlinedInput
                        fullWidth
                        inputProps={params.inputProps}
                        InputProps={{
                          endAdornment: (
                            <FontAwesomeIcon icon={faCalendar} className={classes.calendarIcon} />
                          ),
                        }}
                        label={labelStart}
                        value={params.value}
                        onChange={params.onChange}
                      />
                    );
                  }}
                />
              </Box>
              {!isMobile && (
                <Box width={'10%'} px={2} alignSelf={'center'} style={{ textAlign: 'center' }}>
                  to
                </Box>
              )}

              <Box width={isMobile ? '100%' : '45%'}>
                <DateTimePicker
                  label="End"
                  open={false}
                  inputFormat={isoDateFormat}
                  value={value[1]}
                  onChange={(newValue) => {
                    handleTimerRange('end', newValue);
                  }}
                  renderInput={(params) => {
                    const labelEnd = interval ? interval.to.label : params.label;
                    return (
                      <OutlinedInput
                        fullWidth
                        inputProps={params.inputProps}
                        InputProps={{
                          endAdornment: (
                            <FontAwesomeIcon icon={faCalendar} className={classes.calendarIcon} />
                          ),
                        }}
                        label={labelEnd}
                        value={params.value}
                        onChange={params.onChange}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
      {!isMobile && <Divider />}
      {children}
      {!isMobile && <Divider />}
      {!isMobile ? (
        <Box>
          <DialogButton
            style={{ margin: theme.spacing(2) }}
            variant="contained"
            disabled={!isValid}
            className={classes.buttonAction}
            color="primary"
            onClick={handleChange}>
            Apply
          </DialogButton>
        </Box>
      ) : (
        <Button
          fullWidth
          variant="contained"
          disabled={!isValid}
          onClick={handleChange}
          style={{ marginTop: 'auto' }}>
          Apply
        </Button>
      )}
    </Box>
  );
};

export default withStyles(styles)(TimerPicker);
