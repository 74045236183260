import React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { Box } from '@material-ui/core';
import { AxiosResponse } from 'axios';

import { inject, WithSettingStore, WithUserStore } from 'stores';
import * as models from 'models';
import Api, { ApiResponse, RequestMetaData } from 'api';

import TransactionTable from '../TransactionTable';
import styles from '../styles';
import FilterBar from 'components/FilterBar';
import { Stats } from './Stats';
import { RouteComponentProps } from 'react-router-dom';

interface WalletProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithSettingStore,
    RouteComponentProps {
  userId: number;
}

const SETTING_STORE_KEY = '/users/wallet';

@inject('userStore', 'settingStore')
@observer
class WalletComponent extends React.Component<WalletProps> {
  constructor(props: WalletProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** Whether the stats are being loaded */
  @observable public loading = true;

  /** The wallet object, currently represents the primary wallet */
  @observable public wallet?: models.Wallet;

  /** The wallet stats object */
  @observable public walletStats?: models.WalletStats;

  /** The cash stats */
  @observable public cashStats?: { amount: string; count: number };

  /** Gets the user's primary wallet */
  @action.bound public getPrimaryWallet = flow(function* (this: WalletComponent) {
    const resp: AxiosResponse<ApiResponse<models.Wallet>> = yield Api.tips.getPrimaryWallet(
      this.props.userId,
    );
    this.wallet = resp.data.data;
  });

  /** Gets the wallet stats for the provided wallet id */
  @action.bound public getWalletStats = flow(function* (this: WalletComponent, walletId: number) {
    const resp: AxiosResponse<ApiResponse<models.WalletStats>> = yield Api.tips.getWalletStats(
      walletId,
    );
    this.walletStats = resp.data.data;
    return this.walletStats;
  });

  @action.bound public getCashStats = flow(function* (this: WalletComponent) {
    const resp = yield Api.analytics.stats.cashStats.getCashStats(this.props.userId);
    const { total, count } = resp.data.data;
    this.cashStats = {
      amount: total,
      count,
    };
  });

  /** Inits the component */
  @action.bound public init = flow(function* (this: WalletComponent) {
    yield this.getPrimaryWallet();
    yield Promise.all([this.getWalletStats(this.wallet!.id), this.getCashStats()]);
    this.loading = false;
  });

  /** The computed stats that can be passed to the Stats component */
  @computed public get stats() {
    if (!this.wallet || !this.walletStats || !this.cashStats) {
      return undefined;
    }
    return {
      balance: parseFloat(this.wallet.balance!),
      tips: {
        count: this.walletStats.tipsReceivedCount,
        amount: parseFloat(this.walletStats.tipsReceivedAmount),
      },
      payouts: {
        count: this.walletStats.payoutsCount,
        amount: parseFloat(this.walletStats.payoutsAmount),
      },
      splitIn: {
        count: this.walletStats.splitsReceivedCount,
        amount: parseFloat(this.walletStats.splitsReceivedAmount),
      },
      splitOut: {
        count: this.walletStats.splitsInitiatedCount,
        amount: parseFloat(this.walletStats.splitsInitiatedAmount),
      },
      inTransfer: {
        count: this.walletStats.inTransfersCount,
        amount: parseFloat(this.walletStats.inTransfersAmount),
      },
      outTransfer: {
        count: this.walletStats.outTransfersCount,
        amount: parseFloat(this.walletStats.outTransfersAmount),
      },
      cashTips: {
        count: this.cashStats.count,
        amount: parseFloat(this.cashStats.amount),
      },
      refunds: {
        count: this.walletStats.refundsCount,
        amount: parseFloat(this.walletStats.refundsAmount),
      },
    };
  }

  /** The getTransactions function to be passed to Datagrid. */
  public getTransactions = async (rmd: RequestMetaData) => {
    if (!this.wallet?.id) {
      return;
    }

    return Api.tips.getWalletTxns(this.wallet.id, {
      ...rmd,
      filters: { ...this.activeFilters },
    });
  };

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  componentDidMount() {
    this.init();
  }
  render() {
    if (!this.wallet?.id) {
      return null;
    }

    return (
      <>
        <Box>
          <FilterBar
            filters={[]}
            onChange={this.handleFiltersOnChange}
            showDateRange
            dateRangeLocalStoragePath={SETTING_STORE_KEY}
          />
        </Box>
        <Stats>{this.stats}</Stats>
        {this.filtersInitReady && (
          <Box mt={3}>
            <TransactionTable
              fetch={this.getTransactions}
              datagridRefetchKey={this.activeFilters}
              wallet={this.wallet}
              refetchWallet={this.getPrimaryWallet}
              {...this.props}
            />
          </Box>
        )}
      </>
    );
  }
}

export default withStyles(styles)(WalletComponent);
