import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Box, IconButton, Tooltip, Dialog } from '@material-ui/core';
import { Android } from 'mdi-material-ui';
import { Device, Filter } from 'models';

import { inject, WithUserStore, WithToastStore } from 'stores';
import Api, { RequestMetaData } from 'api';
import { adaptForDataGridPro } from 'services';
import LinkDevice from './LinkDevice';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import AppleIcon from '@mui/icons-material/Apple';

import styles from './styles';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';

interface KiosksProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithToastStore,
    RouteComponentProps {}

/**
 * The container for the devices page kiosks tab.
 */
@inject('userStore', 'toastStore')
@observer
class Kiosk extends React.Component<KiosksProps> {
  constructor(props: KiosksProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** The key for the datagrid table */
  @observable public dataGridKey = Date.now();

  public getKioskItemsData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.core.getKiosks({
        ...rmd,
        filters: {
          ...this.activeFilters,
        },
      }),
    (device: Device) => ({
      ...device,
      accountName: device.location?.account?.name,
      locationName: device.location?.name,
      locationAddress:
        device.location &&
        `${device.location.address} ${device.location.city} ${device.location.state} ${device.location.zip}`,
      tablet: device.tablet?.serial,
      reader: device.reader?.serial,
      updatedAt: device.updatedAt && moment(device.updatedAt).format('MMM DD, YYYY h:mm:ss A'),
    }),
  );

  /** The device being linked, if not null then the dialog is open */
  @observable public linkingDeviceId: number | null = null;

  /** Closes the linking dialog */
  @action.bound public stopLinkingDevice() {
    this.linkingDeviceId = null;
  }

  /** Closes the dialog and refreshes the datagrid data */
  @action.bound public finishLinking() {
    this.dataGridKey = Date.now();
    this.stopLinkingDevice();
  }

  renderOS = (row: any) => {
    return (
      <IconButton color="primary" onClick={() => null}>
        <Tooltip title="OS">
          {row.row.os === 'ios' ? <AppleIcon /> : <Android fontSize="small" />}
        </Tooltip>
      </IconButton>
    );
  };

  gridColumns = [
    { headerName: 'Account', field: 'accountName', minWidth: 200, flex: 1 },
    { headerName: 'Location', field: 'locationName', minWidth: 200, flex: 1 },
    { headerName: 'Address', field: 'locationAddress', minWidth: 200, flex: 1, sortable: false },
    {
      headerName: 'Tablet',
      field: 'tablet',
      minWidth: 150,
      filterable: false,
      sortable: false,
    },
    {
      headerName: 'OS',
      field: 'os',
      minWidth: 80,
      filterable: false,
      renderCell: this.renderOS,
    },
    { headerName: 'OS Version', field: 'osVersion', minWidth: 150, flex: 1, filterable: false },
    { headerName: 'Last Update ', field: 'updatedAt', minWidth: 150, flex: 1, filterable: false },
  ];

  filters: Filter[] = [
    { display: 'Account', id: 'accountName', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'locationName', label: 'Contains', type: 'text' },
    { display: 'OS', id: 'os', label: 'Contains', type: 'text' },
    { display: 'OS Version', id: 'osVersion', label: 'Contains', type: 'text' },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  /** Display results in datagrid */
  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Dialog open={Boolean(this.linkingDeviceId)} onClose={this.stopLinkingDevice}>
            <Box className={classes.linkDeviceRoot}>
              <LinkDevice deviceId={this.linkingDeviceId!} onComplete={this.finishLinking} />
            </Box>
          </Dialog>
          <Box mt={3}>
            <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} />
            {this.filtersInitReady && (
              <DataGridInfiniteScroll
                columns={this.gridColumns}
                fetch={this.getKioskItemsData}
                refetchKey={this.activeFilters}
                disableColumnMenu
                pathname={this.props.location.pathname}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Kiosk);
