import React from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { Transaction, Wallet, AssociatedLocation } from 'models';
import { ApiResponse, RequestMetaData } from 'api';
import { Link as RouterLink } from 'react-router-dom';

import Api, { getErrorMsg } from 'api';
import { inject, WithToastStore, WithUserStore } from 'stores';

import { useStyles } from './styles';

import validatorjs from 'validatorjs';
import { isCurrency } from 'services/validators';

import moment from 'moment-timezone';

import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { v4 as uuidv4 } from 'uuid';
import { RouteComponentProps } from 'react-router-dom';
import styles from './styles';

import {
  Box,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  MenuItem,
  WithStyles,
  withStyles,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { paths } from 'routes';
import { adaptForDataGridPro } from 'services';
import LoadingSpinner from 'components/LoadingSpinner';
import { AxiosResponse } from 'axios';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Dialog/Button';
import { ACL } from 'types';

type MobxForm = any;

/** Definition of form fields for create correction modal form */
const fields = [
  {
    name: 'amount',
    label: 'Correction Amount',
    rules: ['required', 'currency'],
    hooks: {
      onChange: (field: any) => {
        const prefix = '-';
        const delimiter = '.';
        const inputString = field.value;

        // Allowed input characters are numbers, minus sign, and the dot
        const lastChar = inputString[inputString.length - 1];
        const isValidChar = new RegExp(/^[0-9.-]$/).test(lastChar);
        if (!isValidChar) {
          field.set(inputString.slice(0, -1));
        }
        // Negative prefix can only appear as the first character and only once
        const indexOfPrefix = inputString.indexOf(prefix);
        const prefixCount = inputString.split(prefix).length - 1;
        if ((inputString.includes(prefix) && indexOfPrefix !== 0) || prefixCount > 1) {
          field.set(inputString.slice(0, -1));
        }
        // Dot decimal delimiter cannot appear twice
        const delimiterCount = inputString.split(delimiter).length - 1;
        if (delimiterCount > 1) {
          field.set(inputString.slice(0, -1));
        }
        // There can only be two digits behind a dot decimal delimiter
        const indexOfDelimiter = inputString.indexOf(delimiter);
        if (inputString.includes(delimiter) && indexOfDelimiter < inputString.length - 3) {
          field.set(inputString.slice(0, -1));
        }
      },
    },
  },
  {
    name: 'customReason',
    label: 'Your Note for Correction',
    rules: ['required'],
    hooks: {
      onChange: (field: any) => field.validate(),
    },
  },
  {
    name: 'associatedLocation',
    label: 'Associated location for correction',
    rules: ['required'],
    hooks: {
      onChange: (field: any) => field.validate(),
    },
  },
];

const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any }) => {
      validator.register('currency', isCurrency, 'Please enter a valid dollar amount (e.g. -9.32)');
    },
  }),
};

interface FormHooks {
  onSuccess: (form: MobxForm) => void;
  onClear: (form: MobxForm) => void;
}

interface TransactionTableProps
  extends WithToastStore,
    WithUserStore,
    WithStyles<typeof styles>,
    RouteComponentProps {
  fetch(metaData: RequestMetaData): any;
  datagridRefetchKey: Record<string, any>;
  wallet?: Wallet;
  refetchWallet?: () => void;
}

const getType = (txn: Transaction) => {
  if (txn.refundId) {
    return 'REFUND';
  }
  if (txn.tipId) {
    return 'TIP';
  }
  if (txn.payoutId) {
    return 'PAYOUT';
  }
  if (txn.walletTransferId) {
    return 'TRANSFER';
  }
};

export function annotateWalletTransactions(transaction: Transaction) {
  return {
    ...transaction,
    date: moment(transaction.date).tz('America/New_York'),
    time: moment(transaction.date).tz('America/New_York'),
    type: getType(transaction),
    reason: transaction.note ? transaction.note.replace(/_/g, ' ') : '',
    id: uuidv4(),
  };
}

/** The component for displaying an amount */
function Amount({ children }: { children?: string }) {
  const classes = useStyles();
  const number = parseFloat(children || '0');
  const d = number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  return <span className={clsx(number >= 0 ? classes.positive : classes.negative)}>{d}</span>;
}

/** Passed to datagrid for rendering a transaction type */
const renderCellTransactionType = ({ row }: any) => {
  if (row.type === 'TIP' && row.paymentReference) {
    return (
      <Link component={RouterLink} to={paths.paymentTips(row.paymentReference)}>
        {row.type}
      </Link>
    );
  }
  if (row.type === 'PAYOUT' && row.payoutId) {
    return (
      <Link component={RouterLink} to={paths.payoutsTransactions(row.payoutId)}>
        {row.type}
      </Link>
    );
  }
  return <span>{row.type}</span>;
};

/** Passed to datagrid for rendering an amount */
const renderCellAmount = ({ row }: any) => {
  return <Amount>{row.amount}</Amount>;
};

/** Passed to datagrid for rendering a balance */
const renderCellBalance = ({ row }: any) => {
  return <Amount>{row.balance}</Amount>;
};

/** Passed to datagrid for rendering the 'Note' field*/
const renderCellNote = ({ row }: any) => {
  return <Box style={{ textTransform: 'capitalize' }}>{row.reason}</Box>;
};

/** Displays a datagrid table with the transactions for user with id `userId` */
@inject('toastStore', 'userStore')
@observer
class TransactionTable extends React.Component<TransactionTableProps> {
  constructor(props: TransactionTableProps) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** The form object for payment refund */
  @observable private form: MobxForm;

  private hooks: FormHooks = {
    onSuccess: () => this.submitCorrection(),
    onClear: () => {
      this.form.reset();
      this.toggleCorrectionModal();
    },
  };

  /** Is correction modal open? */
  @observable public correctionModalOpen = false;

  @observable private refetchKey = Date.now();

  /** Whether the form is currently being submitted */
  @observable public submitting = false;

  /**
   * List of locations that this talent is associated with.
   * When creating a correction on talent's wallet/ledger, admin has to select
   * associated location for that correction. This helps us track correction
   * destination when calculating pay roll run for locations and or accounts
   */
  @observable public associatedLocations: AssociatedLocation[] = [];
  /**
   * `locationStatus` tracks the current status of the user's location.
   * Possible values are:
   * - 'active' - for active locations
   * - 'inactive' - for inactive locations
   *
   * The default value is 'active', and the status changes based on the user's selection.
   */
  @observable locationStatus: 'active' | 'inactive' = 'active';

  /**
   * To make a correction, wallet has to be passed down via props
   * and authenticated user has to have correct permission
   */
  @computed private get canMakeCorrection() {
    return this.props.wallet && this.props.userStore!.hasPermission(ACL.POST_CORRECTION);
  }

  /** Open or close the correction modal based on its current state */
  @action.bound private toggleCorrectionModal() {
    this.form.clear();
    // If modal is being opened, we need to fetch associated locations for this talent
    !this.correctionModalOpen && this.fetchAssociatedLocations();
    this.correctionModalOpen = !this.correctionModalOpen;
  }

  /**
   * Sets the location status to either 'active' or 'inactive' and fetches the associated locations.
   *
   * When the status is changed, the function updates `locationStatus` and calls `fetchAssociatedLocations()`
   * to retrieve the locations that match the selected status.
   */
  @action.bound private setLocationStatus(status: 'active' | 'inactive') {
    this.locationStatus = status;
    this.fetchAssociatedLocations();
  }

  @action.bound private fetchAssociatedLocations = flow(function* (this: TransactionTable) {
    if (!this.props.wallet) return;
    try {
      const userId = this.props.wallet.userId;
      /**
       * Determines whether the current location status is 'inactive'.
       * The variable `isInactive` is set to `true` if `locationStatus` is 'inactive', and `false` otherwise.
       */
      const isInactive = this.locationStatus === 'inactive';
      const resp: AxiosResponse<ApiResponse<AssociatedLocation[]>> =
        yield Api.core.getAssociatedLocations(userId, isInactive ? true : undefined);
      if (resp && resp.data && resp.data.data) {
        this.associatedLocations = resp.data.data;
        // If there is only one associated location
        // for this talent, just set that as associated
        // location form field value and validate it:
        if (this.associatedLocations.length === 1) {
          this.form.$('associatedLocation').set(this.associatedLocations[0].id);
          this.form.$('associatedLocation').validate();
        }
      }
    } catch (error: any) {
      this.props.toastStore!.error(getErrorMsg(error));
    }
  });

  /** Submits the correction form */
  @action.bound public submitCorrection = flow(function* (this: TransactionTable) {
    try {
      const wallet = this.props.wallet;
      if (!wallet) return;
      this.submitting = true;
      const amount = this.form.$('amount').value;
      const reason = this.form.$('customReason').value;
      const locationId = this.form.$('associatedLocation').value;
      yield Api.tips.createWalletCorrection(wallet.id, amount, reason, locationId);
      this.props.toastStore!.push({
        type: 'success',
        message: `Correction for ${amount} dollars was successfully applied`,
      });
      this.props.refetchWallet && this.props.refetchWallet();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      // Refetch table data because we want to display the new wallet entry right away
      this.refetchKey = Date.now();
      this.toggleCorrectionModal();
      this.submitting = false;
    }
  });

  gridColumns = [
    {
      headerName: 'Date',
      field: 'date',
      minWidth: 200,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    {
      headerName: 'Time',
      field: 'time',
      minWidth: 200,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('h:mm A'),
      sortable: false,
    },
    {
      headerName: 'Type',
      field: 'type',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellTransactionType,
      sortable: false,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellAmount,
    },
    {
      headerName: 'Note',
      field: 'reason',
      minWidth: 150,
      flex: 1,
      renderCell: renderCellNote,
    },
    {
      headerName: 'Balance',
      field: 'balance',
      minWidth: 180,
      flex: 1,
      renderCell: renderCellBalance,
    },
  ];

  @action.bound public fetchTransactions = adaptForDataGridPro(
    this.props.fetch,
    annotateWalletTransactions,
  );

  render() {
    const fields = {
      amount: this.form.$('amount'),
      customReason: this.form.$('customReason'),
      associatedLocation: this.form.$('associatedLocation'),
    };
    return (
      <>
        <DataGridInfiniteScroll
          key={this.refetchKey}
          columns={this.gridColumns}
          fetch={this.fetchTransactions}
          refetchKey={this.props.datagridRefetchKey}
          disableColumnMenu
          actions={{
            onAdd: {
              name: 'Add New',
              action: this.canMakeCorrection ? this.toggleCorrectionModal : undefined,
            },
          }}
          pathname={this.props.location.pathname}
        />
        <Box mt={1} ml={2}>
          <Typography variant="subtitle2">All times are displayed in EST/DST timezone</Typography>
        </Box>
        <Dialog open={this.correctionModalOpen} onClose={this.toggleCorrectionModal}>
          <form onSubmit={this.form.onSubmit}>
            <Box minWidth={380}>
              <DialogTitle>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography style={{ fontSize: 28, fontWeight: 400 }}>
                    Create Correction
                  </Typography>
                  {this.submitting && <LoadingSpinner size={24} />}
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box>
                  <OutlinedInput
                    {...fields.amount.bind()}
                    error={fields.amount.error}
                    required={fields.amount.rules.includes('required')}
                    fullWidth
                    autoFocus
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  <Box mt={2}>
                    <OutlinedInput
                      label="Reason for refund"
                      {...fields.customReason.bind()}
                      error={fields.customReason.error}
                      required={fields.customReason.rules.includes('required')}
                      multiline
                      fullWidth
                    />
                  </Box>
                  <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography variant="subtitle1" style={{ fontSize: 16 }}>
                      Location
                    </Typography>
                  </Box>
                  <Box mt={2} style={{ marginTop: 10 }}>
                    <RadioGroup
                      row
                      value={this.locationStatus}
                      onChange={(e) => {
                        this.setLocationStatus(e.target.value as 'active' | 'inactive');
                        this.form.$('associatedLocation').set('');
                      }}>
                      <FormControlLabel
                        value="active"
                        control={<Radio color="primary" />}
                        label="Active"
                      />
                      <FormControlLabel
                        value="inactive"
                        control={<Radio color="primary" />}
                        label="Inactive"
                      />
                    </RadioGroup>
                  </Box>

                  {this.associatedLocations.length > 0 && (
                    <Box mt={2}>
                      <OutlinedInput
                        select
                        {...fields.associatedLocation.bind()}
                        error={fields.associatedLocation.error}
                        required={fields.associatedLocation.rules.includes('required')}
                        fullWidth>
                        {this.associatedLocations.map((l) => (
                          <MenuItem key={l.id} value={l.id}>
                            {l.name}
                          </MenuItem>
                        ))}
                      </OutlinedInput>
                    </Box>
                  )}
                </Box>
              </DialogContent>
              <DialogActions className={this.props.classes.dialogActions}>
                <Button onClick={this.form.onClear} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!this.form.isValid || !this.form.$('associatedLocation').value}>
                  Create
                </Button>
              </DialogActions>
            </Box>
          </form>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(TransactionTable);
