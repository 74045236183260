/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { observable, computed, action, makeObservable, flow, IReactionDisposer } from 'mobx';
import { observer } from 'mobx-react';

import {
  Box,
  Link,
  Typography,
  Drawer,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import {
  inject,
  WithUserStore,
  WithToastStore,
  WithSettingStore,
  OwnerScope,
  WithManagerPermissionsStore,
} from 'stores';

import { paths } from 'routes';

import Api, { getErrorMsg, RequestMetaData } from 'api';
import { adaptForDataGridPro, EllipsizedValue, numericStringToUsd, setTitle } from 'services';
import moment from 'moment-timezone';
import { Filter, Tip } from 'models';

import DashboardLayout from 'containers/DashboardLayout';
import PaymentDetailsDrawer from 'components/PaymentDetailsDrawer';

import styles from './styles';

import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { downloadCsvFile, getFileNameFromResponseHeaders } from 'utils/helper';
import { CreditCardRefund } from 'mdi-material-ui';
import { CreateRefundRequestData } from 'models/Refunds';
import { RefundForm } from './RefundForm/RefundForm';
import CreditCardIcon from 'components/CreditCardIcon';
import Title from 'components/Title';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import ActionsMenu, { PositionMenu } from 'components/ActionsMenu';
import * as models from 'models';
import SplitTip from 'components/SplitTip';
import { default as DialogComponent } from 'components/Dialog/Dialog';
import { rootStore } from 'containers/App/App';
import { GridColDef } from '@mui/x-data-grid-pro';
import { EManagerPermission } from 'types';
import FilterBar from 'components/FilterBar/FilterBar';

interface TipsOverviewParams {
  accountId: string;
}
interface IBadgeConfig {
  [key: string]: string;
}

const badgeConfig: IBadgeConfig = {
  CONFIRMED: ChipStatusColors.GREEN,
  PENDING: ChipStatusColors.YELLOW,
  VOID: ChipStatusColors.RED,
};

const PAGE_TITLE = 'Tips';
/** Here we define what kind of props this component takes */
type TipsOverviewProps = WithStyles<typeof styles> & // Adds the classes prop
  WithUserStore &
  WithManagerPermissionsStore &
  WithSettingStore &
  RouteComponentProps<TipsOverviewParams> &
  WithToastStore; // Adds the userStore prop

let abortController = new AbortController();

/**
 * Container for displaying and downloading tip reports.
 * Accessible to admin, owner and manager scope.
 */
@inject('userStore', 'toastStore', 'settingStore', 'managerPermissionsStore')
@observer
class TipsOverview extends React.Component<TipsOverviewProps> {
  constructor(props: TipsOverviewProps) {
    super(props);
    makeObservable(this);
  }

  @observable public disposers: IReactionDisposer[] = [];

  /** Payment reference of selected payment for PaymentDetailsDrawer */
  @observable public paymentRef?: string;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @observable private isOwner = this.props.userStore!.scope.kind === 'owner';

  @observable private isGlobalOwner = this.props.userStore!.scope.kind === 'global_owner';

  @observable private isAdmin = this.props.userStore!.scope.kind === 'admin';

  @observable private isManager = this.props.userStore!.scope.kind === 'manager';

  @observable private refundRequest?: Partial<CreateRefundRequestData>;

  /** Selected Tip */
  @observable public selectedTip?: models.Tip;

  /** Split Tip Dialog state */
  @observable private splitTipDialogState = false;

  /** If API call is in progress */
  @observable private isLoading = false;

  /** Users who should split the Tip */
  @observable private splitTipsData?: models.IUserTipList;

  @computed get openDialog() {
    return this.refundRequest ? true : false;
  }

  @action.bound openRefundDialog(tipId: number, accountId: number, total: string, name: string) {
    this.refundRequest = {
      tipId,
      accountId,
      total,
      name,
    };
  }

  @action.bound closeRefundDialog() {
    this.refundRequest = undefined;
  }

  @action.bound handleRefund(status: string) {
    if (status !== 'approved') return;
    this.activeFilters = { ...this.activeFilters };
  }

  /** Annotates tip report fields with extra data based on selected tab */
  @action.bound private annotateTips = (tip: Tip) => {
    return {
      ...tip,
      name: tip.user ? `${tip.user && tip.user.firstName} ${tip.user && tip.user.lastName}` : '',
      accountName: tip.location && tip.location.account ? tip.location.account.name : '',
      locationName: tip.location ? tip.location.name : '',
      reference: tip.payment ? tip.payment.reference : '',
      customerName: tip.customer ? `${tip.customer.firstName} ${tip.customer.lastName}` : '',
      serviceAmount: tip.serviceAmount ? numericStringToUsd(tip.serviceAmount) : '',
      gross: tip.gross ? numericStringToUsd(tip.gross) : '',
      net: tip.net ? numericStringToUsd(tip.net) : '',
      level: tip.level ? tip.level.toUpperCase() : '',
      status: tip.status ? tip.status.toUpperCase() : '',
      statusType: tip.status,
      refundRequest: undefined,
      canRefund: tip.canRefund,
      customerCard: tip.payment && tip.payment.customerCard,
      transactionId: tip?.payment?.transactionId,
    };
  };

  @action.bound public getTips = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    let filters: Record<string, unknown> = { ...this.activeFilters };

    let fetch = Api.tips.getTips;
    if (this.isOwner) {
      fetch = Api.tips.getGloballyOwnedTips;
      filters = {
        ...filters,
        accountId: this.props.userStore!.currentAccount?.id,
      };
    } else if (this.isGlobalOwner) {
      fetch = Api.tips.getGloballyOwnedTips;
    } else if (this.isManager) {
      fetch = Api.tips.getTipsForLocation;
      if (!filters?.locationId) {
        filters = {
          locationIds: this.props.userStore!.currentManagedLocation?.id,
          ...filters,
        };
      }
    }

    return await fetch(
      {
        ...rmd,
        filters,
      },
      // Pass this params to get "canRefund" value
      {
        refundInfo: true,
      },
    );
  }, this.annotateTips);

  @action.bound private openPaymentDetailsDrawer = (paymentRef: string) => {
    this.paymentRef = paymentRef;
  };

  @action.bound private closePaymentDetailsDrawer = () => {
    this.paymentRef = undefined;
  };

  /** Sets state of the Selected Tip and controls Dialog for it */
  @action.bound public handleItemsDialogState(row?: models.Tip) {
    this.splitTipDialogState = !!row;
    this.splitTipsData = undefined;
    this.selectedTip = row;
    this.isLoading = false;
  }

  /** Split Tip API handler */
  @action.bound public splitTipsConfirm = flow(function* (this: TipsOverview) {
    if (!this.selectedTip?.id || !this.splitTipsData) {
      return;
    }

    try {
      this.isLoading = true;
      const { mfaDialogStore } = rootStore;
      const { withMfaDialog } = mfaDialogStore;
      const callback = {
        verify: () => {
          this.props.toastStore!.success('This Tip was successfully splitted');
          this.activeFilters = { ...this.activeFilters };
        },
      };
      const response = yield withMfaDialog(
        () =>
          Api.tips.splitTips(this.selectedTip!.id, {
            deviceTime: new Date().toISOString(),
            users: this.splitTipsData!.users,
          }),
        {
          callback,
        },
      );
      if (response instanceof Error) {
        rootStore.toastStore.error(getErrorMsg(response));
        return;
      }
      this.handleItemsDialogState();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.isLoading = false;
    }
  });

  abortApiCallAndSetUpNewController(): void {
    abortController.abort();
    abortController = new AbortController();
  }

  private getLocations = async (query?: string) => {
    const accountId = this.isOwner
      ? (this.props.userStore!.scope as OwnerScope).accountId
      : undefined;
    this.abortApiCallAndSetUpNewController();

    try {
      const { data } = await Api.core.searchAllLocations(
        {},
        { name: query, accountId },
        abortController,
      );
      const filteredData = data.data.map(
        (location: { id: string; name: string; address: string; [key: string]: string }) => {
          return { id: location.id, name: location.name, address: location.address };
        },
      );
      return filteredData;
    } catch (e: any) {
      return [];
    }
  };

  /** Download Csv report */
  @action.bound public async downloadCsvReport() {
    try {
      const accountId = this.props.userStore!.currentAccount?.id;
      const { fromDate, toDate } = this.activeFilters;
      const filters = { ...this.activeFilters, fromDate, toDate, accountId };
      const res = await Api.tips.getGloballyOwnedTipsReport({ filters });
      if (res && res.data) {
        const filename = getFileNameFromResponseHeaders(res);
        downloadCsvFile(res.data, filename);
      }
    } catch (error) {
      this.props.toastStore!.push({
        message: 'Unable to download requested report',
        type: 'error',
      });
    }
  }

  @computed get getGridColumns() {
    const refundsAction = {
      headerName: 'Request refund',
      field: 'requestRefund',
      minWidth: 200,
      renderCell: this.renderCellRefundRequest,
    };

    if (this.isAdmin) {
      // Request refund action for all Admins
      return [...this.gridColumns, refundsAction];
    }

    const gridColumns = this.gridColumns.filter(
      ({ headerName }: GridColDef) => !['Payment', 'Card', 'Transaction Id'].includes(headerName!),
    );

    if (this.isOwner || this.isGlobalOwner) {
      return [...gridColumns, refundsAction];
    }

    if (
      this.isManager &&
      this.props.managerPermissionsStore!.hasPermission(EManagerPermission.MANAGE_REFUND_REQUESTS)
    ) {
      return [...gridColumns, refundsAction];
    }

    return gridColumns;
  }

  @computed get getFilters(): Filter[] {
    if (this.isAdmin) return this.filters;

    const hiddenFilters = ['reference', 'status'];

    // If the user is a manager, we shouldn't show the location filter.
    if (this.isManager) {
      hiddenFilters.push('locationId');
    }

    // Status and Payment filters to be shown only for admins.
    return this.filters.filter((filter: Filter) => !hiddenFilters.includes(filter.id));
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  /** Before unmounting the component, dispose of all auto-runs and reactions */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  renderCellName = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.userDetails(row.userId).info()}>
        {row.name}
      </Link>
    );
  };

  renderCellAccount = ({ row }: any) => {
    if (row.location.accountId) {
      return (
        <Link component={RouterLink} to={paths.accountDetails(row.location.accountId).root()}>
          <EllipsizedValue value={row.accountName} max={10} />
        </Link>
      );
    }
    return <></>;
  };

  renderCellLocation = ({ row }: any) => {
    if (row.locationId) {
      return (
        <Link
          component={RouterLink}
          to={paths.locationDetails(row.locationId)}
          style={{ width: '100%' }}>
          <EllipsizedValue value={row.locationName} max={20} />
        </Link>
      );
    }
    return <></>;
  };

  renderCellReference = ({ value }: any) => {
    const { classes } = this.props;
    return (
      <Link
        className={classes.cursorPointer}
        onClick={() => this.openPaymentDetailsDrawer(value)}
        style={{ width: '100%' }}>
        <EllipsizedValue value={value} max={20} />
      </Link>
    );
  };

  renderCellStatus = ({ value }: any) => {
    const background = badgeConfig[value as keyof IBadgeConfig] as ChipStatusColors;

    return <ChipStatusTag label={value} color={background} />;
  };

  renderCellRefundRequest = ({ row }: any) => {
    const { location, id, gross, customerName, canRefund } = row;
    const accountId = location?.accountId;
    const total = gross.replace(/[^\d.-]/g, '');

    if (!canRefund) return;

    return (
      <IconButton
        onClick={() => this.openRefundDialog(id, accountId, total, customerName)}
        data-cy={`tip-${id}-refund-action`}>
        <CreditCardRefund color="primary" />
      </IconButton>
    );
  };

  renderCustomerCard = ({ value }: any) => {
    if (!value) return;
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <CreditCardIcon style={{ marginTop: 3 }} brand={value.brand} />
        <Box ml={1}>
          <Typography>****{value.lastFour}</Typography>
        </Box>
      </Box>
    );
  };

  renderActionsColumn = ({ row }: any) => {
    const options = [
      {
        label: 'Split Tip',
        action: () => this.handleItemsDialogState(row),
        disabled: !row.canSplit,
      },
    ];
    return <ActionsMenu options={options} position={PositionMenu.VERTICAL} />;
  };

  gridColumns = [
    {
      headerName: 'Date',
      field: 'createdAt',
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    { headerName: 'Name', field: 'name', minWidth: 150, flex: 1, renderCell: this.renderCellName },
    {
      headerName: 'Location',
      field: 'location',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellLocation,
    },
    {
      headerName: 'Payment',
      field: 'reference',
      minWidth: 180,
      flex: 1,
      renderCell: this.renderCellReference,
      sortable: false,
    },
    {
      headerName: 'Transaction Id',
      field: 'transactionId',
      minWidth: 150,
      flex: 1,
      sortable: false,
    },
    {
      headerName: 'Card',
      field: 'customerCard',
      minWidth: 180,
      flex: 1,
      renderCell: this.renderCustomerCard,
      sortable: false,
    },
    { headerName: 'Customer', field: 'customerName', minWidth: 150, flex: 1 },
    { headerName: 'Service', field: 'serviceAmount', minWidth: 120, flex: 1 },
    { headerName: 'Gross', field: 'gross', minWidth: 100, flex: 1 },
    { headerName: 'Net', field: 'net', minWidth: 90, flex: 1 },
    { headerName: 'Level', field: 'level', minWidth: 120, flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 150,
      renderCell: this.renderCellStatus,
    },
    {
      headerName: 'Actions',
      field: 'action',
      minWidth: 120,
      renderCell: this.renderActionsColumn,
      sortable: false,
    },
  ];

  filters: Filter[] = [
    {
      display: 'Location',
      id: 'locationId',
      label: 'Contains',
      type: 'tags',
      options: {
        fetch: this.getLocations,
        displayField: {
          value: 'name',
          additional: {
            value: 'address',
          },
          keySearch: { name: 'id', phrase: 'locationName' },
        },
      },
    },
    { display: 'Payment', id: 'reference', label: 'Contains', type: 'text' },
    {
      display: 'Account Id',
      id: 'accountId',
      label: 'Exactly',
      type: 'text',
    },
    { display: 'Transaction Id', id: 'transactionId', label: 'Contains', type: 'tags' },
    {
      display: 'Level',
      id: 'level',
      label: 'One of',
      type: 'select',
      items: [
        { value: 'low', label: 'LOW' },
        { value: 'medium', label: 'MEDIUM' },
        { value: 'high', label: 'HIGH' },
        { value: 'median', label: 'MEDIAN' },
        { value: 'custom', label: 'CUSTOM' },
        { value: 'unknown', label: 'UNKNOWN' },
      ],
    },
    {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items: [
        { value: 'confirmed', label: 'CONFIRMED' },
        { value: 'pending', label: 'PENDING' },
        { value: 'void', label: 'VOID' },
      ],
    },
  ];

  exportData = [
    {
      name: 'Download as CSV',
      action: () => {
        this.downloadCsvReport();
      },
    },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    const { paymentDrawer } = this.props.classes;
    const canDownloadReport = this.isGlobalOwner || this.isOwner;
    const total = this.refundRequest?.total || '';
    return (
      <DashboardLayout>
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center">
            <Box display="flex" justifyContent="flex-start">
              <Title mb={3}>{PAGE_TITLE}</Title>
            </Box>
          </Box>
          <DialogComponent
            open={this.splitTipDialogState}
            title="Split Tip"
            content={
              this.selectedTip ? (
                <SplitTip
                  tip={this.selectedTip}
                  setSplitTipsData={(data) => (this.splitTipsData = data)}
                />
              ) : (
                <></>
              )
            }
            onCancel={() => this.handleItemsDialogState()}
            confirmActionName="Continue"
            onConfirm={this.splitTipsConfirm}
            disabled={!this.splitTipsData}
            loading={this.isLoading}
          />
          <FilterBar
            filters={this.getFilters}
            onChange={this.handleFiltersOnChange}
            showDateRange
          />
          <Box mt={3} mb={3}>
            <Paper>
              {this.filtersInitReady && (
                <DataGridInfiniteScroll
                  columns={this.getGridColumns}
                  fetch={this.getTips}
                  refetchKey={this.activeFilters}
                  sortDirection={'DESC'}
                  disableColumnMenu
                  actions={canDownloadReport ? { onExport: this.exportData } : {}}
                  pathname={this.props.location.pathname}
                />
              )}
              <Drawer
                className={paymentDrawer}
                open={Boolean(this.paymentRef)}
                onClose={this.closePaymentDetailsDrawer}
                PaperProps={{ style: { width: 419 } }}
                anchor="right"
                variant="temporary">
                <PaymentDetailsDrawer
                  onClose={this.closePaymentDetailsDrawer}
                  paymentReference={this.paymentRef}
                />
              </Drawer>
            </Paper>
          </Box>
          <Dialog open={this.openDialog}>
            <DialogTitle disableTypography>
              <Typography variant="h5">Refund request</Typography>
            </DialogTitle>
            <DialogContent>
              <RefundForm
                total={total}
                onRefund={this.handleRefund}
                closeModal={this.closeRefundDialog}
                refundRequest={this.refundRequest}
              />
            </DialogContent>
          </Dialog>
        </>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(TipsOverview);
