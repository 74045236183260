import { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { Box, Chip, Typography, ButtonBase } from '@material-ui/core';
import { IDateRange, listOptionDate, OptionDate } from '../../DateRangeExternalPicker/CustomPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import MobileViewWrapperDialog, {
  EMobileWrapperDialogStep,
} from './MobileViewComponents/MobileViewWrapperDialog';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import { MobileViewFilterSteps } from './MobileViewComponents/MobileViewFilterSteps';
import { ExternalDateRangeProps, Filter, IFilterValue } from 'models';
import { FilterBarFields } from '../FilterBarComponents/FilterBarFields';

interface FilterBarMobileProps {
  filters: Filter[];
  selectedFilter?: Filter;
  filterValue?: IFilterValue[];
  hasFilters: boolean;
  showClearFilters: boolean;
  activeFilters: Pick<Filter, 'id' | 'value' | 'options'>[];
  externalDateRange?: ExternalDateRangeProps;
  setFilter: (filterId: string) => void;
  setFilters: (filters: Filter[]) => void;
  clearAllFilters: () => void;
  setFilterValue: (filter?: IFilterValue[]) => void;
  clearSelectedFilter: () => void;
}

export const FilterBarMobile = ({
  filters,
  selectedFilter,
  filterValue,
  hasFilters,
  showClearFilters,
  activeFilters,
  externalDateRange,
  setFilter,
  setFilters,
  clearAllFilters,
  setFilterValue,
  clearSelectedFilter,
}: FilterBarMobileProps) => {
  const [mobileWrapperDialogStep, setMobileWrapperDialogStep] =
    useState<EMobileWrapperDialogStep>();
  const [dateRangeValue, setDateRangeValue] = useState<IDateRange>({} as IDateRange);

  /** Range type of currently selected date range value eg. 'lastWeek' */
  const rangeType = useMemo(
    (): OptionDate | undefined =>
      dateRangeValue &&
      listOptionDate.find((option) => option.type === dateRangeValue.dateRangeType),
    [dateRangeValue],
  );

  const setMobileWrapperDialogStepAction = useCallback(
    (step?: EMobileWrapperDialogStep) => {
      if (step === EMobileWrapperDialogStep.FILTERS) {
        clearSelectedFilter();
      }
      setMobileWrapperDialogStep(step);
    },
    [clearSelectedFilter],
  );

  const openFiltersMenu = useCallback(() => {
    setMobileWrapperDialogStepAction(EMobileWrapperDialogStep.FILTERS);
  }, [setMobileWrapperDialogStepAction]);

  const closeFiltersMenu = useCallback(() => {
    setMobileWrapperDialogStepAction(undefined);
  }, [setMobileWrapperDialogStepAction]);

  /** Whether the MobileWrapperDialog should be open or not */
  const openMobileWrapperDialog = useMemo(
    () => !!mobileWrapperDialogStep,
    [mobileWrapperDialogStep],
  );

  /** Mobile wrapper dialog props based on the current step */
  const mobileWrapperDialogProps = useMemo(() => {
    let title = '';
    let back;

    const goBack = () => {
      switch (mobileWrapperDialogStep) {
        case EMobileWrapperDialogStep.FILTER:
          setMobileWrapperDialogStepAction(EMobileWrapperDialogStep.ADD_FILTER);
          clearSelectedFilter();
          break;
        case EMobileWrapperDialogStep.ADD_FILTER:
        case EMobileWrapperDialogStep.ADD_DATE_FILTER:
          setMobileWrapperDialogStepAction(EMobileWrapperDialogStep.FILTERS);
          break;
        case EMobileWrapperDialogStep.DATE_FILTER:
          setMobileWrapperDialogStepAction(EMobileWrapperDialogStep.ADD_DATE_FILTER);
          break;
        default:
          setMobileWrapperDialogStepAction(EMobileWrapperDialogStep.FILTERS);
      }
    };

    switch (mobileWrapperDialogStep) {
      case EMobileWrapperDialogStep.FILTERS:
        title = 'Filters';
        break;
      case EMobileWrapperDialogStep.ADD_FILTER:
        title = 'Add a Filter';
        back = goBack;
        break;
      case EMobileWrapperDialogStep.ADD_DATE_FILTER:
      case EMobileWrapperDialogStep.DATE_FILTER:
        title = 'Date Picker';
        back = goBack;
        break;
      case EMobileWrapperDialogStep.FILTER:
        title = selectedFilter?.display || '';
        back = goBack;
        break;
      default:
        title = selectedFilter?.display || '';
    }

    const onClose = () => {
      clearSelectedFilter();
      closeFiltersMenu();
    };

    return {
      open: openMobileWrapperDialog,
      title,
      back,
      onClose,
    };
  }, [
    clearSelectedFilter,
    closeFiltersMenu,
    mobileWrapperDialogStep,
    openMobileWrapperDialog,
    selectedFilter?.display,
    setMobileWrapperDialogStepAction,
  ]);

  const handleOpenMobileDateRangePicker = (open: boolean) => {
    if (open) {
      return setMobileWrapperDialogStepAction(EMobileWrapperDialogStep.DATE_FILTER);
    }
    closeFiltersMenu();
  };

  const getFilterDisplayValue = useCallback((filter: Filter) => {
    let value = filter?.value;
    if (!value) return '';

    if (value.constructor === Array) return `(${value.length})`;
    return value;
  }, []);

  const showActiveFiltersIcon = useMemo(
    (): boolean => !!activeFilters.length || dateRangeValue?.dateRangeType !== 'all',
    [activeFilters.length, dateRangeValue?.dateRangeType],
  );

  const onDateRangeValueChange = (range: unknown) => {
    setDateRangeValue(range as IDateRange);
  };

  useEffect(() => {
    onDateRangeValueChange(externalDateRange?.value);
  }, [externalDateRange?.value]);

  return (
    <>
      <Chip
        clickable={false}
        className={clsx('filterChip', 'addFilterChip')}
        onClick={openFiltersMenu}
        label={<Typography className="selectLabel">Filters</Typography>}
      />

      <MobileViewWrapperDialog {...mobileWrapperDialogProps}>
        <>
          <MobileViewFilterSteps
            mobileWrapperDialogStep={mobileWrapperDialogStep}
            hasFilters={hasFilters}
            setMobileWrapperDialogStepAction={setMobileWrapperDialogStepAction}
            showClearFilters={showClearFilters}
            clearAllFilters={clearAllFilters}
            activeFiltersLength={activeFilters.length}
            isExternalDateRange={!!externalDateRange}
            rangeType={rangeType}
            dateRangeValue={dateRangeValue}
            visibleFilters={filters}
            setFilter={setFilter}
            getFilterDisplayValue={getFilterDisplayValue}
            onDateRangeValueChange={onDateRangeValueChange}
            handleOpenMobileDateRangePicker={handleOpenMobileDateRangePicker}
            externalDateRange={externalDateRange}
          />

          {/* Filter details panel: */}
          {selectedFilter && (
            <FilterBarFields
              filters={filters}
              selectedFilter={selectedFilter}
              setFilters={setFilters}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              clearSelectedFilter={clearSelectedFilter}
              closeFiltersMenu={closeFiltersMenu}
              mobileView={true}
            />
          )}
        </>
      </MobileViewWrapperDialog>

      {/* Active filters icon and chevron */}
      <Box display={'flex'} alignItems={'center'} gridGap={8}>
        {showActiveFiltersIcon && <FontAwesomeIcon icon={faCircle} className="circleIcon" />}
        <ButtonBase onClick={openFiltersMenu}>
          <FontAwesomeIcon icon={faChevronRight} fontSize={18} />
        </ButtonBase>
      </Box>
    </>
  );
};
