import { mobileSize } from 'components/MobileView/useMobileView';
import theme from 'containers/App/theme';

const rootStyling = (mobileView: boolean) => ({
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  justifyContent: mobileView ? 'space-between' : 'flex-start',
  padding: theme.spacing(1.5, mobileView ? 2 : 3),
});

export const filterPaperStyling = (mobileView: boolean) => ({
  display: 'block',
  marginLeft: 'auto',
  '& .paperBoxShadow': {
    boxShadow: 'none',
  },
  '& .paperBoxShadowActive': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  '& .paperRoot': {
    minHeight: 56,
    alignSelf: 'flex-start',
  },
  '& .externalDateRange': {
    marginLeft: 'auto',
    display: 'flex',
  },
  '& .graphButton': {
    color: theme.palette.text.primary,
    justifyContent: 'center !important',
  },
  '& .root': rootStyling(mobileView),
});

export const mobileWrapperFilterStyling = {
  display: 'block',
  '& .list': {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  '& .clear-filters': {
    textTransform: 'initial',
    fontSize: theme.typography.body1.fontSize,
  },
};

export const filterBarStyling = (mobileView: boolean) => ({
  marginBottom: theme.spacing(3),
  gap: theme.spacing(2),
  display: 'flex',
  '& .paper': {
    boxShadow: 'none',
    width: '100%',
  },
  '& .root': rootStyling(mobileView),
  '& .select': {
    border: 'none',
    height: 'auto',
    padding: 0,
    borderRadius: 0,
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiSelect-root': {
      backgroundColor: 'transparent !important',
      padding: 0,
    },
    '&.MuiInput-root': {
      paddingBottom: '0 !important',
    },
  },
  '& .addFilterChip': {
    backgroundColor: 'transparent',
    '& .MuiChip-icon': {
      marginLeft: 0,
      color: theme.palette.text.primary,
    },
  },
  '& .filterChip': {
    cursor: 'pointer',
    '& .MuiChip-label': {
      fontSize: '14px',
      fontWeight: 400,
      color: theme.palette.text.primary,
      [theme.breakpoints.down(mobileSize)]: {
        paddingLeft: 0,
      },
    },
    '& .MuiChip-deleteIconColorPrimary': {
      color: theme.palette.text.primary,
    },
  },
  '& .closeIconStyles': {
    width: 22,
    height: 22,
    paddingRight: theme.spacing(0.5),
  },
  '& .selectLabel': {
    color: theme.palette.text.primary,
  },
  '& .formControlLabel': {
    '& .MuiFormControlLabel-label': {
      color: theme.menu.main,
    },
  },
  '& .radio': {
    paddingLeft: theme.spacing(3),
  },
  '& .clearFilters': {
    textTransform: 'initial',
    fontSize: theme.typography.body1.fontSize,
  },
  '& .circleIcon': {
    fontSize: 8,
    color: theme.palette.primary.main,
  },
});
