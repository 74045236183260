import { useStores } from 'containers/App/App';
import React, { useState } from 'react';
import Api, { getErrorMsg, RequestMetaData } from 'api';
import { Link as RouterLink } from 'react-router-dom';
import { adaptForDataGridPro, numericStringToUsd } from 'services';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { Filter } from 'models';
import { Box, Button, IconButton, Link, Tooltip } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import theme from 'containers/App/theme';
import ActionsMenu, { PositionMenu } from 'components/ActionsMenu';
import { paths } from 'routes';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import { ACL, EManagerPermission } from 'types';
import { IRefundRequest, RefundRequestStatus } from 'models/Refunds';
import { ManagerScope } from 'stores';
import { EDateFormat } from 'utils/helper';

interface IBadgeConfig {
  [key: string]: ChipStatusColors;
}

type GridColumns = GridColDef[];

const badgeConfig: IBadgeConfig = {
  CREATED: ChipStatusColors.PURPLE,
  APPROVED: ChipStatusColors.GREEN,
  REJECTED: ChipStatusColors.RED,
  TERMINATED: ChipStatusColors.GREY,
  CANCELED: ChipStatusColors.ORANGE,
};

enum RefundAction {
  ACCEPT = 'accept',
  CANCEL = 'cancel',
  REJECT = 'reject',
}

async function getAccounts(query: string) {
  const { data } = await Api.core.getAccounts({}, { name: query });
  const filteredData = data.data.map(
    (account: { id: string; name: string; address: string; [key: string]: string }) => {
      return { id: account.id, name: account.name, address: account.address };
    },
  );
  return filteredData;
}

function annotateRefundRequests(refundRequest: IRefundRequest) {
  let { createdAt, resolvedAt, tip, amount, reason } = refundRequest;
  createdAt = moment(createdAt).format(EDateFormat.DEFAULT);
  resolvedAt = resolvedAt && moment(resolvedAt).format(EDateFormat.DEFAULT);

  return {
    ...refundRequest,
    createdAt,
    resolvedAt,
    reason: reason ? reason.replace(/_/g, ' ').toLowerCase() : '',
    tipAmount: tip.gross ? numericStringToUsd(tip.gross) : '',
    amount: amount ? numericStringToUsd(amount) : '',
    accountName: tip.location && tip.location.account && tip.location.account.name,
    locationName: tip.location && tip.location.name,
  };
}

export interface IActionDialog {
  refundRequestId: number;
  title: string;
  message: string; // please specify rejection reason below??
  successMessage: string;
  type: RefundAction;
  loading: boolean;
  rejectionReason?: string;
  cancel: () => void;
  action: (actionDialog: IActionDialog) => Promise<void>;
}

type UseRefundRequestsTable = [
  string | undefined,
  IActionDialog | undefined,
  (e: React.ChangeEvent<HTMLInputElement>) => void,
  () => GridColumns,
  () => Filter[],
  Record<string, unknown>,
  string,
  (mtd: RequestMetaData) => Promise<{
    rows: {
      createdAt: string;
      resolvedAt?: string;
      id: number;
      accountId: number;
      ownerId: number;
      tipId: number;
      amount?: string | undefined;
      reason: string;
      status: RefundRequestStatus;
      rejectionReason?: string | undefined;
      resolvedBy?: number | undefined;
    }[];
    sortable: string[];
    totalElements: number;
  }>,
  (value: Record<string, unknown>) => void,
  () => void,
];

const SETTING_STORE_KEY = '/refunds/refund_requests';

export function useRefundRequestsTable(): UseRefundRequestsTable {
  const { userStore, toastStore, routerStore, notificationStore, managerPermissionsStore } =
    useStores();
  const [activeFilters, setActiveFilters] = useState<Record<string, unknown>>({});
  const [actionDialog, setActionDialog] = useState<IActionDialog | undefined>();
  const [paymentReference, setPaymentReference] = useState<IActionDialog | undefined>();

  function renderStatus({ value }: GridRenderCellParams) {
    value = (value as string).toUpperCase();
    const background = badgeConfig[value as keyof IBadgeConfig];
    return <ChipStatusTag label={value} color={background} />;
  }

  function closeDetailsDialog() {
    setPaymentReference(undefined);
  }

  function cancel() {
    setActionDialog(undefined);
  }

  function updateRejectionReason(e: React.ChangeEvent<HTMLInputElement>) {
    if (!actionDialog) return;
    const rejectionReason = e.target.value;
    setActionDialog({ ...actionDialog, rejectionReason });
  }

  async function handleRefundRequestAction(type: RefundAction, id: number) {
    let refundRequestId = id;
    let title;
    let message;
    let successMessage;
    let action;
    if (type === RefundAction.ACCEPT) {
      title = 'Accept refund request';
      message = 'Are you sure you want to accept this refund request?';
      successMessage = 'accepted';
      action = handleAction;
    } else if (type === RefundAction.CANCEL) {
      title = 'Cancel refund request';
      message = 'Are you sure you want to cancel this refund request?';
      successMessage = 'canceled';
      action = handleAction;
    } else if (type === RefundAction.REJECT) {
      title = 'Reject refund request';
      message = 'Are you sure you want to reject this refund request?';
      successMessage = 'rejected';
      action = handleAction;
    } else {
      return;
    }

    setActionDialog({
      refundRequestId,
      title,
      message,
      successMessage,
      type,
      loading: false,
      action,
      cancel,
    });

    async function handleAction(actionDialog: IActionDialog) {
      if (!actionDialog) return;

      const { refundRequestId, rejectionReason } = actionDialog;
      try {
        setActionDialog({ ...actionDialog, loading: true });
        if (type === RefundAction.ACCEPT) {
          await Api.tips.approveRefundRequest(refundRequestId);
        } else if (type === RefundAction.CANCEL) {
          await Api.tips.cancelRefundRequest(refundRequestId);
        } else if (type === RefundAction.REJECT) {
          await Api.tips.rejectRefundRequest(refundRequestId, rejectionReason);
        }
        toastStore.success(`Request ${actionDialog.successMessage} successfully`);
      } catch (error) {
        toastStore.error(getErrorMsg(error));
      } finally {
        setActiveFilters({ ...activeFilters });
        refetchCreatedRefundRequests();
        removeShowCreatedFilter();
        cancel();
      }
    }
  }

  const refetchCreatedRefundRequests = async () => {
    if (!userStore.isAdmin) return;
    try {
      await notificationStore.getCreatedRefundRequests();
    } catch (error) {
      toastStore.error(getErrorMsg(error));
    }
  };

  /**
   * If there is no refund requests with status CREATED left and showCreated query parameter is set to true,
   * remove the filter
   */
  function removeShowCreatedFilter() {
    if (!notificationStore.refundRequests || !notificationStore.refundRequests.length) {
      setActiveFilters({ ...activeFilters, status: undefined });
    }
  }

  function renderAdminActions({ row }: GridRenderCellParams) {
    const disabled = row.status !== RefundRequestStatus.CREATED;
    let title = disabled ? `Cannot modify ${row.status} request` : '';
    const hasRequiredPermissions = userStore!.hasPermission(ACL.ISSUE_REFUND);
    if (!disabled && !hasRequiredPermissions) {
      title = 'Missing refund permissions';
    }

    const options = [
      {
        label: 'Accept',
        action: () => handleRefundRequestAction(RefundAction.ACCEPT, row.id),
      },
      {
        label: 'Reject',
        action: () => handleRefundRequestAction(RefundAction.REJECT, row.id),
      },
    ];
    return (
      <Tooltip title={title}>
        <Box component="span">
          <ActionsMenu
            disabled={disabled || !hasRequiredPermissions}
            options={options}
            position={PositionMenu.VERTICAL}
            dataCy={`refund-request-${row.id}-action`}
          />
        </Box>
      </Tooltip>
    );
  }

  function renderOwnerOrManagerActions({ row }: GridRenderCellParams) {
    const disabled = row.status !== RefundRequestStatus.CREATED;
    const title = disabled ? `Cannot cancel ${row.status} refund request` : 'Cancel refund request';
    const color = disabled ? theme.palette.grey[400] : theme.palette.error.main;
    return (
      <Tooltip title={title}>
        <Box component={'span'}>
          <IconButton
            disabled={disabled}
            onClick={() => handleRefundRequestAction(RefundAction.CANCEL, row.id)}>
            <Close style={{ color, cursor: 'pointer' }} />
          </IconButton>
        </Box>
      </Tooltip>
    );
  }

  function renderAccount({ row, value }: GridRenderCellParams) {
    if (!value) return 'N/A';
    return (
      <Link component={RouterLink} to={paths.accountDetails(row.accountId).info()}>
        {value}
      </Link>
    );
  }

  function renderAmount({ row, value }: GridRenderCellParams) {
    if (!userStore.isAdmin) return value;
    const color = theme.palette.primary.main;
    const { reference } = row;
    return (
      <Link component={Button} style={{ color }} onClick={() => setPaymentReference(reference)}>
        {value}
      </Link>
    );
  }

  function renderLocation({ row, value }: GridRenderCellParams) {
    if (!value) return 'N/A';
    return (
      <Link component={RouterLink} to={paths.locationDetails(row.tip.locationId)}>
        {value}
      </Link>
    );
  }

  const getRefundRequests = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const accountIds = [userStore.currentAccount?.id];
    const accountId = managerPermissionsStore?.accountId;
    let locationId = undefined;
    if (
      userStore.isManagerScope &&
      managerPermissionsStore.hasPermission(EManagerPermission.VIEW_REFUNDS)
    ) {
      locationId = (userStore?.scope as ManagerScope).locationId;
    }

    return await Api.tips.getRefundRequests({
      ...rmd,
      filters: {
        accountId,
        accountIds,
        locationId,
        ...activeFilters,
      },
    });
  }, annotateRefundRequests);

  function getColumns(): GridColumns {
    const isAdmin = userStore.user?.isAdmin;
    let gridColumns = [
      {
        headerName: 'Account Name',
        field: 'accountName',
        minWidth: 200,
        flex: 1,
        type: 'string',
        renderCell: renderAccount,
      },
      {
        headerName: 'Location Name',
        field: 'locationName',
        minWidth: 200,
        flex: 1,
        type: 'string',
        renderCell: renderLocation,
        sortable: false,
      },
      {
        headerName: 'Tip Amount',
        field: 'tipAmount',
        minWidth: 200,
        flex: 1,
        type: 'string',
        renderCell: renderAmount,
      },
      {
        headerName: 'Refund Amount',
        field: 'amount',
        minWidth: 200,
        flex: 1,
        type: 'string',
      },
      {
        headerName: 'Reason',
        field: 'reason',
        minWidth: 200,
        flex: 1,
        type: 'string',
        sortable: false,
      },
      {
        headerName: 'Created On',
        field: 'createdAt',
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: 'Resolved On',
        field: 'resolvedAt',
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: 'Rejection Reason',
        field: 'rejectionReason',
        minWidth: 200,
        flex: 1,
        type: 'string',
        sortable: false,
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 200,
        flex: 1,
        type: 'string',
        renderCell: renderStatus,
      },
      {
        headerName: 'Actions',
        field: 'action',
        minWidth: 150,
        flex: 0,
        type: 'string',
        renderCell: isAdmin ? renderAdminActions : renderOwnerOrManagerActions,
        sortable: false,
      },
    ];

    return gridColumns;
  }

  function getFilters(): Filter[] {
    const isGlobalOwner = userStore!.scope.kind === 'global_owner';
    const isAdmin = userStore!.user?.isAdmin;

    let filters: Filter[] = [
      {
        display: 'Status',
        id: 'status',
        label: 'One of',
        type: 'select',
        items: [
          { label: 'CREATED', value: RefundRequestStatus.CREATED },
          { label: 'APPROVED', value: RefundRequestStatus.APPROVED },
          { label: 'REJECTED', value: RefundRequestStatus.REJECTED },
          { label: 'CANCELED', value: RefundRequestStatus.CANCELED },
        ],
      },
    ];
    if (isGlobalOwner || isAdmin) {
      filters = [
        ...filters,
        {
          display: 'Account',
          id: 'accountId',
          label: 'Contains',
          type: 'tags',
          options: {
            fetch: getAccounts,
            displayField: {
              value: 'name',
              additional: {
                value: 'address',
              },
              keySearch: { name: 'id' },
            },
          },
        },
      ];
    }
    return filters;
  }

  return [
    paymentReference as string | undefined,
    actionDialog,
    updateRejectionReason,
    getColumns,
    getFilters,
    activeFilters,
    SETTING_STORE_KEY,
    getRefundRequests,
    setActiveFilters,
    closeDetailsDialog,
  ];
}
