import React, { useState, useCallback, useEffect } from 'react';
import {
  List,
  ListItem,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import { filter } from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import { v4 as uuidv4 } from 'uuid';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import styles, { useCustomPickerStyles } from './styles';
import TimerPicker from './TimerPicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { rootStore } from 'containers/App/App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { EDateFormat } from 'utils/helper';
import { IntervalOptions } from 'models';

// export const isoDateFormat = 'YYYY-MM-DD, H:mm A';
export const isoDateFormat = 'YYYY-MM-DD';

export interface OptionDate {
  label: string;
  type: EDateRangeType;
  extend?: boolean;
}

export enum EDateRangeType {
  DATE_TIMER = 'date-timer',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'thisWeek',
  LAST_WEEK = 'lastWeek',
  THIS_MONTH = 'thisMonth',
  LAST_MONTH = 'lastMonth',
  THIS_YEAR = 'thisYear',
  LAST_YEAR = 'lastYear',
  LAST_THIRTY_DAYS = 'lastThirtyDays',
  ALL = 'all',
}

export const listOptionDate: OptionDate[] = [
  { label: 'Custom', type: EDateRangeType.DATE_TIMER, extend: true },
  { label: 'Today', type: EDateRangeType.TODAY },
  { label: 'Yesterday', type: EDateRangeType.YESTERDAY },
  { label: 'This Week', type: EDateRangeType.THIS_WEEK },
  { label: 'Last Week', type: EDateRangeType.LAST_WEEK },
  { label: 'This Month', type: EDateRangeType.THIS_MONTH },
  { label: 'Last Month', type: EDateRangeType.LAST_MONTH },
  { label: 'This Year', type: EDateRangeType.THIS_YEAR },
  { label: 'Last Year', type: EDateRangeType.LAST_YEAR },
  { label: 'Last Thirty Days', type: EDateRangeType.LAST_THIRTY_DAYS },
  { label: 'All', type: EDateRangeType.ALL },
];

export interface IDateRange {
  fromDate?: any;
  toDate?: any;
  dateRangeType?: EDateRangeType;
}

export enum ECustomPickerViewMode {
  DATE_TIMER = 'date-timer',
  MENU = 'menu',
}

interface CustomPickerProps {
  onChange: (range: IDateRange) => void;
  handleOpened?: (status: boolean) => void;
  selected: IDateRange;
  interval?: IntervalOptions;
  //This was added to accomodate back functionality for date picker on mobile views
  viewMode?: ECustomPickerViewMode;
}

const getViewMode = (selectedRange: IDateRange) => {
  if (rootStore.uiStore.mobileView) return 'menu';
  return selectedRange.dateRangeType === 'date-timer' ? 'date-timer' : 'menu';
};

export const getDateRange = (type = EDateRangeType.THIS_WEEK): IDateRange => {
  switch (type) {
    case EDateRangeType.TODAY:
      return {
        fromDate: moment().format(isoDateFormat),
        toDate: moment().format(isoDateFormat),
        dateRangeType: type,
      };
    case EDateRangeType.YESTERDAY:
      return {
        fromDate: moment().subtract(1, 'days').format(isoDateFormat),
        toDate: moment().subtract(1, 'days').format(isoDateFormat),
        dateRangeType: type,
      };
    case EDateRangeType.THIS_WEEK:
      return {
        fromDate: moment().startOf('isoWeek').format(isoDateFormat),
        toDate: moment().format(isoDateFormat),
        dateRangeType: type,
      };
    case EDateRangeType.LAST_WEEK:
      return {
        fromDate: moment().startOf('isoWeek').subtract(1, 'weeks').format(isoDateFormat),
        toDate: moment().endOf('isoWeek').subtract(1, 'weeks').format(isoDateFormat),
        dateRangeType: type,
      };
    case EDateRangeType.THIS_MONTH:
      return {
        fromDate: moment().startOf('month').format(isoDateFormat),
        toDate: moment().format(isoDateFormat),
        dateRangeType: type,
      };
    case EDateRangeType.LAST_MONTH:
      return {
        fromDate: moment().subtract(1, 'month').startOf('month').format(isoDateFormat),
        toDate: moment().subtract(1, 'month').endOf('month').format(isoDateFormat),
        dateRangeType: type,
      };
    case EDateRangeType.THIS_YEAR:
      return {
        fromDate: moment().startOf('year').format(isoDateFormat),
        toDate: moment().format(isoDateFormat),
        dateRangeType: type,
      };

    case EDateRangeType.LAST_YEAR:
      return {
        fromDate: moment().subtract(1, 'year').startOf('year').format(isoDateFormat),
        toDate: moment().subtract(1, 'year').endOf('year').format(isoDateFormat),
        dateRangeType: type,
      };

    case EDateRangeType.LAST_THIRTY_DAYS:
      return {
        fromDate: moment().subtract(30, 'days').format(isoDateFormat),
        toDate: moment().format(isoDateFormat),
        dateRangeType: type,
      };
    default:
      return {
        fromDate: undefined,
        toDate: undefined,
        dateRangeType: type,
      };
  }
};

function CustomPicker({
  onChange,
  handleOpened,
  selected,
  interval,
  classes,
  viewMode: _viewMode,
}: CustomPickerProps & WithStyles<typeof styles>) {
  const [viewMode, setViewMode] = useState(() => getViewMode(selected));
  const { listItemStyles, text, checkIcon, chevronIcon, dateRange } = useCustomPickerStyles();

  const [custom, setCustom] = useState<MuiDateRange<Date>>(
    selected.dateRangeType === 'date-timer' ? [selected.fromDate, selected.toDate] : [null, null],
  );

  const mobileView = rootStore.uiStore?.mobileView;
  const displayStaticWrapperAs = mobileView ? 'mobile' : 'desktop';

  const handleSetMode = useCallback(
    (mode) => {
      setViewMode(mode);
    },
    [setViewMode],
  );

  const handleSetCustom = useCallback(
    (date: MuiDateRange<Date>) => {
      setCustom(date);
    },
    [setCustom],
  );

  const handleCustomDateRange = useCallback(
    (value: MuiDateRange<Date>) => {
      let startValue = value[0];
      let endValue = value[1];

      if (startValue !== null) {
        const newStartDate = moment(startValue).format();
        startValue = new Date(newStartDate);
      }

      if (endValue !== null) {
        const newEndDate = moment(endValue).format();
        endValue = new Date(newEndDate);
      }

      handleSetCustom(value);
    },
    [handleSetCustom],
  );

  const handleDateRange = useCallback(
    (type: EDateRangeType) => {
      const value = getDateRange(type);
      handleOpened && handleOpened(false);
      onChange && onChange(value);
    },
    [handleOpened, onChange],
  );

  useEffect(() => {
    return () => {
      handleSetCustom([null, null]);
    };
  }, []);

  //This was added to accomodate back functionality for date picker on mobile views
  useEffect(() => {
    if (!_viewMode || _viewMode !== ECustomPickerViewMode.MENU || _viewMode === viewMode) return;
    setViewMode(_viewMode);
  }, [_viewMode]);

  const isDateTimerType = selected?.dateRangeType === 'date-timer';

  return (
    <List disablePadding style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {viewMode === 'menu' &&
        listOptionDate &&
        filter(listOptionDate, ({ type }) => type).map(({ label, type, extend }: any) => (
          <Box key={uuidv4()}>
            <ListItem
              button
              disableRipple={mobileView}
              classes={{ root: clsx(mobileView && listItemStyles) }}
              selected={selected?.dateRangeType === type}
              onClick={() => (extend ? handleSetMode(type) : handleDateRange(type))}
              data-cy={`${label}-date-range-option`.toLowerCase().replaceAll(' ', '-')}>
              <ListItemText
                disableTypography={selected?.dateRangeType === type && !isDateTimerType}
                className={clsx(
                  { textTransform: 'capitalize', marginRight: '48px' },
                  mobileView && text,
                )}
                primary={label}
              />
              {extend && (
                <>
                  {mobileView && selected && isDateTimerType && (
                    <Typography component="span" className={dateRange}>
                      {moment(selected.fromDate).format(EDateFormat.FULL_MONTH_DAY)} -{' '}
                      {moment(selected.toDate).format(EDateFormat.FULL_MONTH_DAY)}
                    </Typography>
                  )}
                  <FontAwesomeIcon icon={faChevronRight} className={chevronIcon} />
                </>
              )}
              {selected && selected.dateRangeType === type && !isDateTimerType && mobileView && (
                <ListItemSecondaryAction>
                  <FontAwesomeIcon icon={faCheck} className={checkIcon} />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </Box>
        ))}
      {viewMode === 'date-timer' && (
        <TimerPicker
          classes={{ root: classes.timerPicker }}
          isMobile={mobileView}
          onChangeMode={handleSetMode}
          onChangeValue={handleSetCustom}
          onChange={onChange}
          onOpen={handleOpened}
          interval={interval}
          value={custom}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDateRangePicker
              displayStaticWrapperAs={displayStaticWrapperAs}
              className={classes.dateRange}
              value={custom}
              inputFormat={isoDateFormat}
              onChange={handleCustomDateRange}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </TimerPicker>
      )}
    </List>
  );
}

export default withStyles(styles)(CustomPicker);
