import { observer } from 'mobx-react';
import NotificationItem from '../NotificationItem/NotificationItem';
import { rootStore } from 'containers/App/App';
import { paths } from 'routes';
import { Typography } from '@material-ui/core';
import { IRefundRequestNotificationItem } from 'stores';

interface IRefundRequestItemProps {
  transferRequests: IRefundRequestNotificationItem;
}

function goToTransferRequests() {
  const { notificationStore, routerStore } = rootStore;

  if (!notificationStore) {
    return;
  }

  notificationStore.setForceClose(true);
  notificationStore.setOpenNotification(false);
  routerStore.history.push(paths.tipTransfers().tipTransfersDynamic('status', 'created'));
}

const TransferTipRequestItem = ({ transferRequests }: IRefundRequestItemProps) => {
  const { numberOfRequests: length } = transferRequests;
  return (
    <NotificationItem
      title={'Tip transfer request'}
      body={
        <Typography variant="body1">
          {`${length} tip transfer request${
            length > 1 ? 's are' : ' is'
          } in need of your attention.`}
        </Typography>
      }
      buttons={[
        {
          label: 'Manage',
          dataCy: 'manage-tip-transfer-requests-button',
          onClick: goToTransferRequests,
        },
      ]}
    />
  );
};

export default observer(TransferTipRequestItem);
