import React from 'react';
import {
  observable,
  action,
  flow,
  computed,
  makeObservable,
  IReactionDisposer,
  reaction,
} from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Api, { ApiResponse } from 'api';
import * as models from 'models';

import { inject, WithUserStore, WithModalStore, WithToastStore, WithSettingStore } from 'stores';

import Stats from 'containers/UserDetails/Stats';

import styles from './styles';

import { AxiosResponse } from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import TransactionsTable from './TransactionsTable/TransactionsTable';
import FilterBar from 'components/FilterBar';
import ReferralPanel from 'components/ReferralPanel';

type TalentHomeProps = WithStyles<typeof styles> &
  WithUserStore &
  WithModalStore &
  WithToastStore &
  WithSettingStore &
  RouteComponentProps;

/**
 * The talent scoped home page/dashboard, displayed when talent logs in.
 */
@inject('userStore', 'modalStore', 'toastStore', 'settingStore')
@observer
class TalentHome extends React.Component<TalentHomeProps> {
  constructor(props: TalentHomeProps) {
    super(props);
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.activeFilters,
        () => {
          this.init();
        },
      ),
    );
  }

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];
  /** Whether the stats are being loaded */
  @observable public loading = true;

  /** The wallet object, currently represents the primary wallet */
  @observable public wallet?: models.Wallet;

  /** The wallet stats object */
  @observable public walletStats?: models.WalletStats;

  /** The cash stats */
  @observable public cashStats?: { amount: string; count: number };

  /** Gets the user's primary wallet */
  @action.bound public getPrimaryWallet = flow(function* (this: TalentHome) {
    const userId = this.props.userStore!.user!.id;
    const resp: AxiosResponse<ApiResponse<models.Wallet>> = yield Api.tips.getPrimaryWallet(userId);
    this.wallet = resp.data.data;
  });

  @action.bound public getCashStats = flow(function* (this: TalentHome) {
    const userId = this.props.userStore!.user!.id;
    const resp = yield Api.analytics.stats.cashStats.getCashStats(userId);
    const { total, count } = resp.data.data;
    this.cashStats = {
      amount: total,
      count,
    };
  });

  /** Gets the wallet stats for the provided wallet id with optional date range */
  @action.bound public getWalletStats = flow(function* (this: TalentHome, walletId: number) {
    if (!walletId) return;
    const resp: AxiosResponse<ApiResponse<models.WalletStats>> = yield Api.tips.getWalletStats(
      walletId,
      this.activeFilters,
    );
    this.walletStats = resp?.data?.data;
    return this.walletStats;
  });

  /** Inits the component */
  @action.bound public init = flow(function* (this: TalentHome) {
    this.loading = true;
    yield this.getCashStats();
    yield this.getPrimaryWallet();
    yield this.getWalletStats(this.wallet!.id);
    this.loading = false;
  });

  /** The computed stats that can be passed to the Stats component */
  @computed public get stats() {
    return {
      balance: this.parseValueToFloat(this.wallet?.balance),
      tips: {
        count: this.walletStats?.tipsReceivedCount,
        amount: this.parseValueToFloat(this.walletStats?.tipsReceivedAmount),
      },
      payouts: {
        count: this.walletStats?.payoutsCount,
        amount: this.parseValueToFloat(this.walletStats?.payoutsAmount),
      },
      splitIn: {
        count: this.walletStats?.splitsReceivedCount,
        amount: this.parseValueToFloat(this.walletStats?.splitsReceivedAmount),
      },
      splitOut: {
        count: this.walletStats?.splitsInitiatedCount,
        amount: this.parseValueToFloat(this.walletStats?.splitsInitiatedAmount),
      },
      inTransfer: {
        count: this.walletStats?.inTransfersCount,
        amount: this.parseValueToFloat(this.walletStats?.inTransfersAmount),
      },
      outTransfer: {
        count: this.walletStats?.outTransfersCount,
        amount: this.parseValueToFloat(this.walletStats?.outTransfersAmount),
      },
      cashTips: {
        count: this.cashStats?.count,
        amount: this.parseValueToFloat(this.cashStats?.amount),
      },
      refunds: {
        count: this.walletStats?.refundsCount,
        amount: this.parseValueToFloat(this.walletStats?.refundsAmount),
      },
    };
  }

  parseValueToFloat = (value: string | undefined) => {
    if (!value) return;
    return parseFloat(value);
  };

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <>
        <Box>
          <FilterBar filters={[]} onChange={this.handleFiltersOnChange} showDateRange />
        </Box>
        <Box mb={3}>
          <Stats>{this.stats}</Stats>
        </Box>
        {this.filtersInitReady && (
          <TransactionsTable walletId={this.wallet?.id} dateRange={this.activeFilters} />
        )}
        {/* Referral Panel (LD flags) */}
        <ReferralPanel />
      </>
    );
  }
}

export default withStyles(styles)(TalentHome);
