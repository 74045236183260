import { Box, ButtonBase, Link, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import useVerifyToken from './useVerifyToken';
import { verifyTokenStyles } from './styles';
import * as models from 'models';
import StoreQRCodes from 'components/StoreQRCodes/StoreQRCodes';
import Title from 'components/Title/Title/Title';
import TippyLogo from 'components/TippyLogo/TippyLogo';
import { paths } from 'routes';
import appStoreImg from '../../../images/custom_download_on_appstore.svg';
import playStoreImg from '../../../images/custom_get_it_on_google_play.svg';
import { Link as RouterLink } from 'react-router-dom';

// Copy of Home.tsx component. Rewrite this component when we know what to put in it.
const VerifyToken = observer(() => {
  const theme = useTheme();

  const { showQrCodes, storeQrCodesProps, showStoreLink, storeButtonProps, mobileView } =
    useVerifyToken();

  const { wrapper, innerWrapper } = verifyTokenStyles({ theme, mobileView });

  return (
    <Box sx={wrapper}>
      <Box sx={innerWrapper}>
        <TippyLogo width={mobileView ? 132 : 235} />
        <Title fontWeight={400} center>
          To continue, please download Tippy Mobile App
        </Title>
        {showQrCodes && <StoreQRCodes {...storeQrCodesProps} />}
        {showStoreLink && <StoreButton {...storeButtonProps} />}
        <ContinueToDashboard />
      </Box>
    </Box>
  );
});

export default VerifyToken;

interface IStoreButtonProps {
  osType: models.EOsType;
  goToStore: VoidFunction;
}
const StoreButton = ({ osType, goToStore }: IStoreButtonProps) => {
  const src = osType === models.EOsType.ANDROID ? playStoreImg : appStoreImg;
  return (
    <>
      <ButtonBase sx={{ mt: 4, borderRadius: 3 }} onClick={goToStore}>
        <Box component="img" draggable={false} src={src} />
      </ButtonBase>
    </>
  );
};

const ContinueToDashboard = () => (
  <Typography sx={{ mt: 4 }} variant="body2" align="center" alignItems={'center'}>
    Continue to{' '}
    <Link component={RouterLink} to={paths.root()}>
      Dashboard
    </Link>
  </Typography>
);
