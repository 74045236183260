import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Link, MenuItem } from '@material-ui/core';
import { AxiosResponse } from 'axios';

import Api, { getErrorMsg, ApiResponse } from 'api';
import * as models from 'models';
import OutlinedInput from 'components/Input/OutlinedInput';
import { useStores } from 'containers/App/App';
import theme from 'containers/App/theme';

interface PoSSearchProps {
  value: models.Pos | undefined;
  onChange: (pos: { id?: number; name: string } | undefined) => void;
}

const PoSSearch = ({ value, onChange }: PoSSearchProps) => {
  const { toastStore } = useStores();
  const [posSystems, setPosSystems] = useState<models.Pos[]>([]);
  const [addNewPos, setAddNewPos] = useState(false);

  useEffect(() => {
    async function getPosSystems() {
      try {
        const { data }: AxiosResponse<ApiResponse<models.Pos[]>> = await Api.core.getPosSystems();
        if (data?.data) {
          setPosSystems(data.data);
        }
      } catch (e: any) {
        toastStore.error(getErrorMsg(e));
      }
    }

    getPosSystems();
  }, [toastStore]);

  const linkText = useMemo(
    () => (addNewPos ? 'Select Existing POS System' : 'Add New POS System'),
    [addNewPos],
  );

  const handleToggle = useCallback(() => {
    onChange(undefined);
    setAddNewPos(!addNewPos);
  }, [addNewPos, onChange]);

  return (
    <Box sx={posSearchStyles}>
      {!addNewPos ? (
        <OutlinedInput
          select
          label="Integration App"
          fullWidth
          value={value?.id || ''}
          onChange={(e) => {
            const thisPos = posSystems.find((it) => it.id === Number(e.target.value));
            onChange(thisPos);
          }}
          dataCy="integration-app">
          {posSystems.map((it) => (
            <MenuItem key={it.id} value={it.id}>
              {it.name}
            </MenuItem>
          ))}
        </OutlinedInput>
      ) : (
        <OutlinedInput
          label="Add New POS System"
          fullWidth
          value={value?.name || ''}
          onChange={(e) => {
            onChange({ name: e.target.value });
          }}
          dataCy="new-pos"
        />
      )}
      <Box display="flex" justifyContent="right">
        <Link onClick={handleToggle} className="custom-input-link">
          {linkText}
        </Link>
      </Box>
    </Box>
  );
};

const posSearchStyles = {
  display: 'block',
  '& .custom-input-link': {
    fontSize: theme.typography.subtitle1.fontSize,
    cursor: 'pointer',
  },
};

export default PoSSearch;
