/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import * as stores from 'stores';
import Api, { getErrorMsg } from 'api';
import DP from 'components/DashPanel';
import { action, computed, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Invitation, Location, UserInvitationType } from 'models';
import moment from 'moment';
import React from 'react';
import styles from './styles';

interface InvitationsListPanelProps
  extends WithStyles<typeof styles>,
    stores.WithToastStore,
    stores.WithUserStore {
  invitations?: Invitation[];
  loadingInvitations?: boolean;
  showOwn?: boolean;
  showLoadMore?: boolean;
  invitationsCount?: number | 0;
  onUpdate?: () => void;
  onLoadMore?: () => void;
  onChangeInvitationsType?: (status: UserInvitationType) => void;
}

/**
 * Component for listing pending and rejected invitations. By default we show
 * pending invitations. User can also toggle list of rejected invitations.
 *
 * @param invitations array of invitations
 * @param onRevoke revoke invitation callback function, accepts invitation id
 */
@stores.inject('userStore', 'toastStore')
@observer
class InvitationsListPanel extends React.Component<InvitationsListPanelProps> {
  constructor(props: InvitationsListPanelProps) {
    super(props);
    makeObservable(this);
  }

  @observable private inProgress = false;

  /** last scrolltop position for control reverse scrolling */
  @observable private scrollTop = 0;

  /** Showing rejected or pending invitations? */
  @observable private showRejected = false;

  @action.bound private toggleShowRejected() {
    this.showRejected = !this.showRejected;
    this.props.onChangeInvitationsType &&
      this.props.onChangeInvitationsType(
        this.showRejected ? UserInvitationType.REJECTED : UserInvitationType.SENT,
      );
  }

  @computed public get scope(): stores.ScopeType {
    return this.props.userStore!.scope.kind;
  }

  /**
   * Location dialog object that gets set if user wants to accept account scoped
   * invitation and needs to select a specific location
   */
  @observable private selectLocationDialog?: {
    locations: Pick<Location, 'id' | 'name'>[];
    locationId: string;
    confirmLocation: () => void;
    closeDialog: () => void;
  };

  @observable private selectLocationDialogOpen = false;

  /**
   * Prompt the user to select a location by initiating location dialog object
   * and setting dialogs event handlers as a resolution to the promise.
   */
  @action.bound public locationPrompt(
    locations: Pick<Location, 'id' | 'name'>[],
  ): Promise<number | void> {
    this.selectLocationDialogOpen = true;
    return new Promise((resolve) => {
      this.selectLocationDialog = {
        locations,
        locationId: '',
        confirmLocation: () => {
          if (this.selectLocationDialog) resolve(parseInt(this.selectLocationDialog.locationId));
        },
        closeDialog: () => resolve(),
      };
    });
  }

  @action.bound public accept = async (invitation: Invitation) => {
    // Check if user is accepting account based invitation (invitation does not have
    // locationId). If locationId is missing, we need to prompt user to select a location
    let locationId;
    if (!invitation.location) {
      if (invitation.locations) {
        locationId = await this.locationPrompt(invitation.locations);
        // Close the dialog by un-setting the dialog object:
        this.selectLocationDialog = undefined;
        // If we don't have locationId, user closed the dialog; don't do anything else
        if (!locationId) return;
      } else {
        throw new Error('Invitation is missing locations');
      }
    }
    try {
      await Api.core.acceptInvitation(invitation.id, locationId);
      this.props.toastStore!.push({
        type: 'success',
        message: `Invitation accepted`,
      });
      // Accepting the invitation adds user to a location. This action might
      // change her scope, dashboard appearance and available functionality.
      // To reflect the changes we wait a bit and reload the dashboard:
      setTimeout(() => window.location.reload(), 1500);
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  @action.bound public reject = async (invitationId: number) => {
    try {
      await Api.core.rejectInvitation(invitationId);
      this.props.toastStore!.push({
        type: 'success',
        message: 'Invitation was rejected',
      });
      this.props.onUpdate && this.props.onUpdate();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  @action.bound public revoke = async (invitationId: number) => {
    try {
      await Api.core.revokeInvitation(invitationId);
      this.props.toastStore!.push({
        type: 'success',
        message: 'Invitation revoked successfully',
      });
      this.props.onUpdate && this.props.onUpdate();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  renderListItem = (i: Invitation) => {
    const expiryDate = moment(i.expiresAt).format('MMM, DD');
    const message = `Invited to join ${i.location ? i.location.name : ''} ${
      i.account ? `${i.account.name}` : ''
    } | Expires on ${expiryDate}`;

    const menu =
      !this.props.showOwn && !this.showRejected
        ? [
            {
              label: 'Revoke',
              onClick: () => this.revoke(i.id),
            },
          ]
        : this.props.showOwn && i.status === UserInvitationType.SENT
        ? [
            {
              label: 'Accept',
              onClick: () => this.accept(i),
            },
            {
              label: 'Reject',
              onClick: () => this.reject(i.id),
            },
          ]
        : undefined;

    return (
      <DP.ListItem
        key={i.id}
        primary={this.props.showOwn ? message : i.email}
        secondary={!this.props.showOwn && message}
        menu={menu}
      />
    );
  };

  render() {
    const title = this.props.showOwn ? 'Invitations' : this.showRejected ? 'Rejected' : 'Pending';
    if (!this.props.invitations || this.inProgress) {
      return (
        <DP>
          <DP.Header>
            <DP.Title>{title}</DP.Title>
          </DP.Header>
          <DP.Body>
            <DP.Loading size="large" items={4} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <>
        <DP>
          <DP.Header>
            <DP.Title count={this.props.invitationsCount}>{title}</DP.Title>
            {!this.props.showOwn && (
              <DP.Actions>
                <Button color="primary" onClickCapture={this.toggleShowRejected}>
                  {this.showRejected ? 'show pending' : 'show rejected'}
                </Button>
              </DP.Actions>
            )}
          </DP.Header>
          <DP.List>
            {this.props.invitations.map((invitation) => this.renderListItem(invitation))}
            {this.props.showLoadMore ? (
              <Grid item xs={12}>
                <Box
                  position="relative"
                  display="flex"
                  flexDirection={'column'}
                  justifyContent="center"
                  alignItems={'center'}
                  mt={1}>
                  {/* If we're filtering paginated responses, we have no way of knowing count progress: */}
                  {this.props.loadingInvitations ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <Button color="primary" size="large" onClick={this.props.onLoadMore}>
                      Load more
                    </Button>
                  )}
                  <Box mt={1}>
                    <Typography variant="subtitle2">
                      Loaded {this.props.invitations?.length} out of {this.props.invitationsCount}{' '}
                      users
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ) : null}
          </DP.List>
        </DP>
        {this.selectLocationDialog && (
          <Dialog
            open={Boolean(this.selectLocationDialog)}
            onClose={this.selectLocationDialog.closeDialog}>
            <Box minWidth={380}>
              <DialogTitle>Choose location</DialogTitle>
              <DialogContent>
                <Select
                  fullWidth
                  value={this.selectLocationDialog.locationId}
                  onChange={(event) => {
                    this.selectLocationDialog!.locationId = event.target.value as string;
                  }}>
                  {this.selectLocationDialog.locations.map((l) => (
                    <MenuItem key={l.id} value={l.id}>
                      {l.name}
                    </MenuItem>
                  ))}
                </Select>
                <DialogActions>
                  <Button onClick={this.selectLocationDialog.closeDialog} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={this.selectLocationDialog.confirmLocation}
                    color="primary"
                    autoFocus>
                    Accept
                  </Button>
                </DialogActions>
              </DialogContent>
            </Box>
          </Dialog>
        )}
      </>
    );
  }
}

export default withStyles(styles)(InvitationsListPanel);
