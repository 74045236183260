import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import { action, observable, makeObservable } from 'mobx';
import BaseStore from './BaseStore';
import RootStore from './RootStore';
// import moment from 'moment';

// const isoDateFormat = 'YYYY-MM-DD';

/** Default range for analytics related containers is last 30 days */
// const defaultRange = {
//   from: moment().subtract(30, 'days').format(isoDateFormat),
//   to: moment().format(isoDateFormat),
// };

/**
 * Contains analytics related data that
 * should persist between page changes
 */
export default class AnalyticsStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable public dateRange: DateRangeExternalPicker.IDateRange =
    DateRangeExternalPicker.getDateRange();

  @action.bound public updateDateRange(range: DateRangeExternalPicker.IDateRange) {
    this.dateRange = range;
  }

  public init() {}
}

export interface WithAnalyticsStore {
  analyticsStore?: AnalyticsStore;
}
