/* eslint-disable react/react-in-jsx-scope */
import { Component, Fragment } from 'react';
import { observable, action, makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';

import { inject, WithToastStore, WithAnalyticsStore, WithSettingStore } from 'stores';
import { adaptForDataGridPro } from 'services';
import Api, { RequestMetaData } from 'api';
import { paths } from 'routes';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link } from '@material-ui/core';

import FilterBar from 'components/FilterBar';
import { Filter } from 'models';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles';
import moment from 'moment';
import qs from 'qs';
import { EDateFormat } from 'utils/helper';
import { GridColDef } from '@mui/x-data-grid-pro';

interface AccountLastActivityMatchParams {
  accountId: string;
}

type ActivityDevicesProps = WithStyles<typeof styles> & // Adds the classes prop
  WithAnalyticsStore &
  WithToastStore & // Adds the userStore prop
  WithSettingStore &
  RouteComponentProps<AccountLastActivityMatchParams>;

@inject('userStore', 'toastStore', 'analyticsStore', 'settingStore')
@observer
class AccountLastActivity extends Component<ActivityDevicesProps> {
  constructor(props: ActivityDevicesProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  /** We store the account id from the router into this observable */
  @observable public matchParams: AccountLastActivityMatchParams;

  /** Object with numbers of active or inactive devices */
  @observable public devices?: Record<string, string>;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** The account id as captured by the URL params */
  @computed public get accountId(): number {
    return parseInt(this.matchParams.accountId);
  }

  /** The account id as captured by the URL params */
  @computed public get accountIdFromQs(): number {
    // Get the account id from the query string
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    // Return the account id as an integer
    return query?.accountId ? parseInt(query.accountId as string) : 0;
  }

  /** Fetch device count by location */
  @action.bound public fetchLastActivity = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      return await Api.analytics.getAccountsLastActivity({
        ...rmd,
        filters: {
          ...this.activeFilters,
          ...(this.accountIdFromQs && { accountId: this.accountIdFromQs }),
        },
      });
    },
    (devicesByLocation: any) => {
      return {
        id: uuidv4(),
        ...devicesByLocation,
      };
    },
  );

  removeAccountId = () => {
    // Remove the 'accountId' parameter from the URL
    const { history, location } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    delete query.accountId;
    const newSearch = qs.stringify(query, { addQueryPrefix: true });
    history.replace({ ...location, search: newSearch });
  };

  async componentDidMount() {
    const accountId = this.accountIdFromQs;
    if (accountId) {
      this.matchParams.accountId = accountId.toString();
      this.removeAccountId();
    }
  }

  renderLocation({ row, value }: any) {
    return (
      <Link component={RouterLink} to={paths.locationDetails(row.locationId)}>
        {value}
      </Link>
    );
  }

  renderAccount({ row, value }: any) {
    return (
      <Link component={RouterLink} to={paths.accountDetails(row.accountId).root()}>
        {value}
      </Link>
    );
  }

  @computed get gridColumns(): GridColDef[] {
    const gridColumns: GridColDef[] = [
      {
        headerName: 'Account',
        field: 'account',
        minWidth: 100,
        flex: 1,
        renderCell: this.renderAccount,
      },
      {
        headerName: 'Location',
        field: 'location',
        minWidth: 100,
        flex: 1,
        renderCell: this.renderLocation,
      },
      {
        headerName: 'Tips Count',
        headerAlign: 'center',
        field: 'count',
        minWidth: 150,
        align: 'center',
      },
      {
        headerName: 'First Tip',
        field: 'first',
        minWidth: 100,
        flex: 1,
        valueGetter: ({ value }: any) =>
          value && moment(new Date(value)).format(EDateFormat.DATE_TIME_FULL),
      },
      {
        headerName: 'Last Tip',
        field: 'last',
        minWidth: 100,
        flex: 1,
        valueGetter: ({ value }: any) =>
          value && moment(new Date(value)).format(EDateFormat.DATE_TIME_FULL),
      },
    ];
    return gridColumns;
  }

  /** List of available filters for FilterBar component */
  filters: Filter[] = [
    { display: 'Account', id: 'accountName', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'locationName', label: 'Contains', type: 'text' },
    {
      display: 'Tips Count',
      id: 'tipsCount',
      label: 'Contents',
      type: 'range',
      interval: {
        from: { label: 'From', value: 'fromAmount' },
        to: { label: 'To', value: 'toAmount' },
        type: 'number',
      },
    },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <Fragment>
        <Box>
          <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} />
        </Box>
        {this.filtersInitReady && (
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchLastActivity}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(AccountLastActivity);
