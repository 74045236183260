import React from 'react';
import { observable, action, makeObservable, flow } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Box, Chip } from '@material-ui/core';
import ActionsMenu, { PositionMenu } from 'components/ActionsMenu';
import { inject, WithUserStore, WithToastStore } from 'stores';
import Api, { getErrorMsg, RequestMetaData } from 'api';
import { adaptForDataGridPro } from 'services';
import * as models from 'models';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';

import styles from './styles';
import { HorizontalStatCard } from 'containers/UserDetails';
import { AttachMoneyRounded, NumbersRounded } from '@mui/icons-material';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import { RouteComponentProps } from 'react-router-dom';
import { formatGridTitleDate } from 'utils/helper';

interface InventoryProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithToastStore,
    RouteComponentProps {}

interface IBadgeConfig {
  [key: string]: string;
}

const badgeConfig: IBadgeConfig = {
  AVAILABLE: ChipStatusColors.BLUE,
  RETURNED: ChipStatusColors.YELLOW,
  LOST: ChipStatusColors.RED,
  BROKEN: ChipStatusColors.RED,
  IN_USE: ChipStatusColors.GREEN,
  RETURNING: ChipStatusColors.PURPLE,
};

/**
 * The container for the inventory tab in the devices section.
 */
@inject('userStore', 'toastStore')
@observer
class Inventory extends React.Component<InventoryProps> {
  constructor(props: InventoryProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** Whether the `Add inventory` dialog window is open */
  @observable public addInventoryDialogOpen = false;

  /** Whether the current inventories are loading */
  @observable public loading = true;

  /** Whether the current inventories are loading */
  @observable public costStats: models.CostStats = {
    available: '0',
    returned: '0',
    lost: '0',
    broken: '0',
    in_use: '0',
  };

  /** The key for the datagrid, so that we can refresh it */
  @observable public dataGridKey = Date.now();

  @action.bound public async updateInventoryStatus(
    id: number,
    status: models.InventoryStatus,
  ): Promise<void> {
    await Api.fulfillment.updateInventoryStatus({ id: id, status: status });
  }

  private createPingRequest = async (id: number) => {
    try {
      await Api.fulfillment.createPingRequest(id);
      this.props.toastStore!.push({
        type: 'success',
        message: `Ping successful`,
      });
    } catch (e: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: e.response && e.response.data && e.response.data.error.message,
      });
    }
  };

  public getInventoryItemsData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.fulfillment.getInventoryItems({
        ...rmd,
        filters: { ...this.activeFilters },
      }),
    (i: models.Inventory) => ({
      ...i,
      modelName: i.hardware && i.hardware.model,
      manufacturer: i.hardware && i.hardware.manufacturer,
      purchasedOn: formatGridTitleDate(i.purchasedOn),
      cost: parseFloat(i.cost || '0').toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }),
  );

  @action.bound public getInventoryCostStats = flow(function* (this: Inventory) {
    try {
      this.loading = true;
      const resp = yield Api.fulfillment.getInventoryCostStats();
      this.costStats = resp.data.data;
    } catch (e: unknown) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loading = false;
    }
  });

  /** Renders if last initiation is completed or stopped */
  renderStatsPanel() {
    if (this.costStats) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <HorizontalStatCard prefix="$" duration={1} icon={NumbersRounded} title={'Available'}>
              {parseInt(this.costStats.available)}
            </HorizontalStatCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <HorizontalStatCard
              color="yellow"
              prefix="$"
              duration={1}
              icon={AttachMoneyRounded}
              title={'In use'}>
              {parseInt(this.costStats.in_use)}
            </HorizontalStatCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <HorizontalStatCard
              color="secondary"
              prefix="$"
              duration={1}
              icon={AttachMoneyRounded}
              title={'Decomissioned'}>
              {parseInt(this.costStats.broken) + parseInt(this.costStats.lost)}
            </HorizontalStatCard>
          </Grid>
        </Grid>
      );
    }
  }

  /** Renders the status field */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderStatus = (rowIndex: any, name: any, value: string, row: any) => {
    return <Chip color="primary" label={value && value.toUpperCase()} />;
  };

  renderActionsColumn = ({ row }: any) => {
    const options = [
      {
        label: 'Ping request',
        action: () => this.createPingRequest(row.id),
      },
    ];
    return <ActionsMenu options={options} position={PositionMenu.VERTICAL} />;
  };

  /** The columns definition for the inventory data grid display */
  renderCellStatus = ({ value, row }: any) => {
    const background = badgeConfig[value.toUpperCase() as keyof IBadgeConfig] as ChipStatusColors;

    return <ChipStatusTag label={value.toUpperCase()} color={background} />;
  };

  gridColumns = [
    { headerName: 'Number', field: 'number', minWidth: 200 },
    { headerName: 'Serial', field: 'serial', minWidth: 200 },
    { headerName: 'Description', field: 'modelName', minWidth: 200, flex: 1, sortable: false },
    { headerName: 'Color', field: 'color', minWidth: 100, sortable: false },
    { headerName: 'Vendor', field: 'manufacturer', minWidth: 120, sortable: false },
    { headerName: 'Cost', field: 'cost', minWidth: 120 },
    { headerName: 'Purchased on', field: 'purchasedOn', minWidth: 150, sortable: false },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 150,
      renderCell: this.renderCellStatus,
    },
    {
      headerName: 'Actions',
      field: 'action',
      minWidth: 120,
      renderCell: this.renderActionsColumn,
      sortable: false,
    },
  ];

  filters: models.Filter[] = [
    { display: 'Manufacturer', id: 'manufacturer', label: 'Contains', type: 'text' },
    { display: 'Serial', id: 'serial', label: 'Contains', type: 'text' },
    {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'AVAILABLE', value: models.InventoryStatus.AVAILABLE },
        { label: 'RETURNED', value: models.InventoryStatus.RETURNED },
        { label: 'LOST', value: models.InventoryStatus.LOST },
        { label: 'BROKEN', value: models.InventoryStatus.BROKEN },
        { label: 'IN_USE', value: models.InventoryStatus.IN_USE },
      ],
    },
  ];

  async componentDidMount() {
    this.getInventoryCostStats();
  }

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mt={3}>{this.renderStatsPanel()}</Box>
          <Box mt={3}>
            <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} />
            {this.filtersInitReady && (
              <DataGridInfiniteScroll
                columns={this.gridColumns}
                fetch={this.getInventoryItemsData}
                refetchKey={this.activeFilters}
                disableColumnMenu
                pathname={this.props.location.pathname}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Inventory);
