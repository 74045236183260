/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Divider,
  Icon,
  IconButton as MuiIconButton,
  InputBase,
  List,
  ListItem as MuiListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import IndicatorComponent from 'components/Indicator';
import OutlinedInput from 'components/Input/OutlinedInput';
import LoadingSpinner from 'components/LoadingSpinner';
import { Switch } from 'components/Switch/Switch';
import Title from 'components/Title';
import { observer } from 'mobx-react';
import React from 'react';
import useStyles from './styles';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { humanize } from 'utils/helper';
import TabBar, { ETabVariant, TTabBarProps } from 'components/TabBar/TabBar';
import OptionsMenu from 'components/OptionsMenu';
import { IOptionsMenuProps } from 'components/OptionsMenu/OptionsMenu';

/**
 * A component with which you can build a single panel that's placed on
 * the dashboard. Has no logic, just styles. The component itself has
 * components such as DashPanel.Header and DashPanel.Title, which you
 * include as children to this component to build a panel.
 */
const DashPanel = ({
  children,
  hidePaper = false,
  fullHeight,
  fullWidth,
}: {
  children: React.ReactNode;
  hidePaper?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
}) => {
  const styles = useStyles();

  if (hidePaper) {
    return <div>{children}</div>;
  } else {
    return (
      <Paper
        className={clsx(
          fullHeight && styles.fullHeight,
          fullWidth && styles.fullWidth,
          styles.root,
        )}
        elevation={0}>
        {children}
      </Paper>
    );
  }
};

/**
 * Used to display the title. Should be a direct child of DashPanel.Header. If
 * optional count prop is passed a count badge will be displayed.
 */
DashPanel.Title = observer(
  ({
    children,
    count,
    light,
    panel,
    size = 'small',
  }: {
    children: React.ReactNode;
    count?: number;
    light?: boolean;
    panel?: boolean;
    size?: 'small' | 'medium' | 'large';
  }) => {
    return (
      <Title count={count} size={size} light={light} panel={panel}>
        {children}
      </Title>
    );
  },
);
/**
 * Used to display the header. Usually a child of DashPanel and contains
 * DashPanel.Title and DashPanel.Actions components.
 */
DashPanel.Header = observer(
  ({ children, hideDivider = false }: { children: React.ReactNode; hideDivider?: boolean }) => {
    const { header } = useStyles();
    return (
      <>
        <Box p={3} className={header}>
          {children}
        </Box>

        {!hideDivider && <Divider />}
      </>
    );
  },
);

/**
 * Used to display the search input.
 */
interface SearchInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}
DashPanel.SearchInput = observer(({ value, onChange, placeholder }: SearchInputProps) => {
  const { icon, searchInput } = useStyles();
  return (
    <>
      <MuiListItem className={searchInput}>
        <Icon className={icon}>search</Icon>
        <InputBase
          type="search"
          placeholder={placeholder || 'Search ...'}
          fullWidth
          value={value}
          onChange={onChange}
        />
      </MuiListItem>
      <Divider />
    </>
  );
});

/**
 * Used to display action buttons. Usually a child of DashPanel.Header and
 * contains DashPanel.IconButton instances.
 */
DashPanel.Actions = observer(({ children }: { children: React.ReactNode }) => {
  const { actions } = useStyles();
  return (
    <Box display="flex" flexDirection="row" alignItems="center" className={actions}>
      {children}
    </Box>
  );
});

export interface Tab<T> {
  id: T;
  label: string;
}

/**
 * TabBar component renders a collection of tab objects passed via props. Value
 * prop is used to track currently selected tab.
 * TabBar component should be used with multiple Tab components.
 */
DashPanel.TabBar = observer((props: TTabBarProps) => {
  return (
    <>
      <Box pt={2} pb={2}>
        <TabBar variant={ETabVariant.UNDERLINE} center {...props} />
      </Box>
      <Divider />
    </>
  );
});

interface TabContainerProps {
  children?: React.ReactNode;
  tab: string;
  selectedTab: string;
}
/**
 * This component encapsulates single tabs content which is passed using
 * 'children' prop. 'tab' prop is its custom id and corresponds to 'value'
 * prop of TabBar. To check if this tabs container should be render we also
 * pass it 'selectedTab' prop.
 * TabContainer component should be used with TabBar component.
 */
DashPanel.TabContainer = observer(({ children, tab, selectedTab }: TabContainerProps) => {
  return (
    <Box hidden={selectedTab !== tab} role="tabpanel">
      {children}
    </Box>
  );
});

/**
 * Used to display stuff after the header. Usually contains DashPanel.Row
 * elements and is a child of DashPanel
 */
DashPanel.Body = observer(
  ({ children, disablePadding }: { children: React.ReactNode; disablePadding?: boolean }) => {
    return (
      <Box p={disablePadding ? 0 : 2} position="relative">
        {children}
      </Box>
    );
  },
);

/**
 * Used to display a loading skeleton. Usually used as a direct child of DashPanel.Body
 */
DashPanel.Loading = observer(
  ({
    items = 4,
    size = 'small',
    variant = 'simple',
  }: {
    items?: number;
    size?: 'small' | 'large';
    variant?: 'simple' | 'circleWithTwoLines';
  }) => {
    const { loadingSkeleton } = useStyles();
    if (variant === 'circleWithTwoLines') {
      return (
        <>
          {[...Array(items)].map((_, i) => (
            <div key={i}>
              <Box display="flex" flexDirection="row" padding="8px 16px">
                <Box display="flex" alignItems="center" mr={3}>
                  <Skeleton variant="circle" width={40} height={40} />
                </Box>
                <Box display="flex" flexDirection="column" width="100%">
                  <Skeleton className={loadingSkeleton} height={18} width="35%" />
                  <Skeleton className={loadingSkeleton} height={15} width="55%" />
                </Box>
              </Box>
              <Divider />
            </div>
          ))}
        </>
      );
    }
    return (
      <Box>
        {[...Array(items)].map((_, i) => (
          <Skeleton key={i} variant="text" height={size === 'large' ? 54 : undefined} />
        ))}
      </Box>
    );
  },
);

DashPanel.ListLoading = observer(() => {
  return (
    <Box>
      <Skeleton height={54} variant="text" />
      <Skeleton height={54} variant="text" />
      <Skeleton height={54} variant="text" />
    </Box>
  );
});

interface IconButtonProps {
  icon?: React.ComponentType<SvgIconProps>;
  fontAwesomeIcon?: { icon: IconDefinition; height?: number; fontSize?: number };
  primary?: boolean;
  secondary?: boolean;
  submit?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  tooltip?: string;
  className?: string;
  disableHoverBackground?: boolean;
}
/**
 * Used to display an icon button for actions. Usually used as a child of DashPanel.Actions.
 */
DashPanel.IconButton = observer(
  ({
    icon,
    fontAwesomeIcon,
    primary,
    submit,
    onClick,
    disabled,
    tooltip,
    secondary,
    loading,
    className,
    disableHoverBackground,
  }: IconButtonProps) => {
    const styles = useStyles();
    const IconComponent = icon;
    const fontAwesomeIconProps = fontAwesomeIcon && {
      icon: fontAwesomeIcon.icon,
      height: fontAwesomeIcon?.height || 18,
      fontSize: fontAwesomeIcon.fontSize,
    };

    const size = fontAwesomeIconProps?.height || 21;

    const renderContent = () => {
      if (loading) {
        return <CircularProgress size={size} />;
      }
      if (IconComponent) {
        return <IconComponent fontSize="small" />;
      }
      if (fontAwesomeIconProps) {
        return <FontAwesomeIcon {...fontAwesomeIconProps} />;
      }
    };

    const color = primary ? 'primary' : secondary ? 'secondary' : undefined;
    const iconButton = (
      <MuiIconButton
        className={clsx(className, disableHoverBackground && styles.noHoverBackground)}
        type={submit ? 'submit' : 'button'}
        size="small"
        onClick={onClick}
        disabled={disabled || loading}
        color={color}>
        {renderContent()}
      </MuiIconButton>
    );
    return tooltip && !disabled ? (
      <Tooltip title={tooltip} placement="top" enterDelay={500} key={tooltip || Date.now()}>
        {iconButton}
      </Tooltip>
    ) : (
      iconButton
    );
  },
);

/** Light, uppercased text */
DashPanel.LightText = observer(({ children }: { children: React.ReactNode }) => {
  const { lightText } = useStyles();
  return <span className={lightText}>{children}</span>;
});

/**
 * Used to display a row that then displays a value. Use as a child of
 * DashPanel.Body. Usually receives DashPanel.Label and DashPanel.Value as
 * children.
 */
DashPanel.Row = observer(({ children }: { children: React.ReactNode }) => {
  const { row } = useStyles();
  return <Box className={row}>{children}</Box>;
});

/** Used to display a label of a field. Best used as a direct child of DashPanel.Row */
DashPanel.Label = observer(({ children, mt }: { children: React.ReactNode; mt?: number }) => {
  const { label } = useStyles();
  return (
    <Box mt={mt ? mt : 0.625} className={label}>
      {children}
    </Box>
  );
});

/** Used to display a value of a field. Best used as a direct child of DashPanel.Row */
DashPanel.Value = observer(({ children }: { children: React.ReactNode }) => {
  const { value } = useStyles();
  return <div className={value}>{children}</div>;
});

/** The indicator component from components/Indicator */
DashPanel.Indicator = IndicatorComponent;

/** Used to display a list of DashPanel.ListItem */
DashPanel.List = observer(
  ({ children, height }: { children: React.ReactNode; height?: 'tall' | 'normal' | 'short' }) => {
    const { list, listTall, listShort } = useStyles();
    let listStyle = null;
    if (height === 'tall') {
      listStyle = listTall;
    } else if (height === 'short') {
      listStyle = listShort;
    } else {
      listStyle = list;
    }
    return <List className={listStyle}>{children}</List>;
  },
);

interface ClassListItem {
  icon?: string;
  containerSecondary?: string;
}

export interface IListItemMenuItem {
  label: string | React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  disabled?: boolean;
}

interface ListItemProps {
  /** The icon to display, ifany */
  icon?: React.ReactNode;
  /** The icon to display in the right, ifany */
  rightIcon?: React.ReactNode;
  /** The primary text to display, can be any react node */
  primary: React.ReactNode;
  /** The secondary text */
  secondary?: React.ReactNode;
  /** An optional indicator and a tooltip for it */
  indicator?: { color: 'green' | 'grey' | 'red'; tooltip?: string };
  /** The menu to display, if any */
  menu?: IOptionsMenuProps['items'];
  /** Content on the right, usuall y for actions, if any */
  rightContent?: React.ReactNode;
  /** Whether to show a loading indicator instead of the menu */
  loading?: boolean;
  /** Whether to show a loading indicator instead of the menu */
  onClick?(): void;
  height?: number;
  className?: ClassListItem;
  /** Whether to disable divider */
  disableDivider?: boolean;
}

/** Used to display a list item */
DashPanel.ListItem = observer(
  ({
    icon,
    rightIcon,
    menu,
    primary,
    secondary,
    indicator,
    loading,
    onClick,
    rightContent,
    height,
    disableDivider,
    className,
  }: ListItemProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // If the menu is toggled off, set the anchor element to
    // null so that it doesn't float around needlessly
    React.useEffect(() => {
      if (!menu) {
        setAnchorEl(null);
      }
    }, [menu]);
    const { primaryTextWithIndicator, listItemSecondaryAction } = useStyles();
    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }
    function handleClose(event: React.MouseEvent<HTMLButtonElement>) {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(null);
    }
    const primaryText = indicator ? (
      <div className={primaryTextWithIndicator}>
        {primary} <IndicatorComponent color={indicator.color} tooltip={indicator.tooltip} />
      </div>
    ) : (
      primary
    );
    const classes = useStyles();
    const muiListItemContent = (
      <>
        {icon && (
          <ListItemAvatar className={classes.listItemAvatar}>
            <>{icon}</>
          </ListItemAvatar>
        )}
        <ListItemText
          className={clsx(className?.containerSecondary, classes.listItemText)}
          primary={primaryText}
          secondary={secondary}
        />
        {!menu && rightIcon && (
          <ListItemSecondaryAction style={{ right: 0 }}>
            <ListItemAvatar className={classes.listItemAvatar}>
              <>{rightIcon}</>
            </ListItemAvatar>
          </ListItemSecondaryAction>
        )}
        <ListItemSecondaryAction
          classes={{ root: listItemSecondaryAction }}
          className={listItemSecondaryAction}
          style={{
            paddingLeft: '16px',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
          }}>
          {rightContent}
          {menu && (
            <>
              <OptionsMenu items={menu} />
            </>
          )}
        </ListItemSecondaryAction>
      </>
    );

    if (onClick) {
      return (
        <>
          <MuiListItem style={{ height }} button onClick={onClick}>
            {muiListItemContent}
          </MuiListItem>
          {!disableDivider && <Divider />}
        </>
      );
    }

    return (
      <>
        <MuiListItem>{muiListItemContent}</MuiListItem>
        {!disableDivider && <Divider />}
      </>
    );
  },
);

/** Custom loading spinner component for dash panels */
DashPanel.LoadSpinner = observer(() => {
  return <LoadingSpinner size={24} />;
});

/** Dash wrapper component for switch element */
DashPanel.Switch = observer(
  ({
    checked,
    onChange,
    disabled,
  }: {
    checked: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  }) => {
    return (
      <Switch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    );
  },
);

interface SelectProps {
  items: string[];
  value: string;
  fullWidth?: boolean;
  className?: string;
  noBorder?: boolean;
  inputLabel?: string;
  renderValue?: SelectInputProps['renderValue'];
  icon?: React.ElementType<any>;
  onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}
/** Dash wrapper component for dropdown select element */
DashPanel.Select = observer(
  ({
    items,
    value,
    fullWidth,
    onChange,
    className,
    noBorder,
    icon,
    inputLabel,
    renderValue,
  }: SelectProps) => {
    const { select, noBorderSelect } = useStyles();
    const styles = noBorder ? noBorderSelect : clsx(select, fullWidth && 'fullWidth', className);
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        {noBorder ? (
          <Select
            renderValue={renderValue}
            value={value}
            IconComponent={icon}
            onChange={onChange}
            className={styles}>
            {items.map((item) => (
              <MenuItem key={item} value={item}>
                {humanize(item)}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <OutlinedInput fullWidth value={value} label={inputLabel} onChange={onChange} select>
            {items.map((item) => (
              <MenuItem key={item} value={item}>
                {humanize(item)}
              </MenuItem>
            ))}
          </OutlinedInput>
        )}
      </Box>
    );
  },
);

export default DashPanel;
