import { Box } from '@material-ui/core';
import OutlinedInput from 'components/Input/OutlinedInput';
import { IFilterValue, IntervalOptions } from 'models';
import { ChangeEvent } from 'react';

interface FilterBarRangeFieldProps {
  interval: IntervalOptions;
  onChange: (value: IFilterValue) => void;
  value: IFilterValue[];
}

export const FilterBarRangeField = ({ interval, onChange, value }: FilterBarRangeFieldProps) => {
  const handleOnchange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange({ id: target.value, name: target.name });
  };

  return (
    <>
      {interval.type === 'number' && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Box mr={2}>
            <OutlinedInput
              value={value.find((it) => it.name === interval.from.value)?.id}
              onChange={handleOnchange}
              label={interval.from.label}
              name={interval.from.value}
              fullWidth
            />
          </Box>
          <Box>
            <OutlinedInput
              value={value.find((it) => it.name === interval.to.value)?.id}
              onChange={handleOnchange}
              label={interval.to.label}
              name={interval.to.value}
              fullWidth
            />
          </Box>
        </Box>
      )}
    </>
  );
};
