import { getErrorMsg } from 'api';
import { Account, AccountUser, LocationUser } from 'models';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Api from 'api';
import { rootStore } from 'containers/App/App';
import { ManagerScope, OwnerScope } from 'stores/UserStore';

type AccountId = number;
interface IAccountSection {
  account: Account;
  accountUser?: AccountUser;
  locationUsers: LocationUser[];
}

interface IWorkspaceContextProps {
  error: boolean;
  locationUsers: LocationUser[] | null;
  accountSections: IAccountSection[] | null;
  filteredLocationUsers: LocationUser[] | null;
  getUserLocations: (() => Promise<void>) | null;
}
const WorkspaceContext = React.createContext<IWorkspaceContextProps>({
  error: false,
  locationUsers: null,
  accountSections: null,
  filteredLocationUsers: null,
  getUserLocations: null,
});

export const useWorkspaceContext = () => {
  return useContext(WorkspaceContext);
};

interface IWorkspaceProvider {
  userId: number;
  children: React.ReactNode;
}
const WorkspaceProvider = ({ userId, children }: IWorkspaceProvider) => {
  const [filteredAccountUsers, setFilteredAccountUsers] = useState<AccountUser[] | null>(null);
  const [locationUsers, setLocationUsers] = useState<LocationUser[] | null>(null);
  const [filteredLocationUsers, setFilteredLocationUsers] = useState<LocationUser[] | null>(null);
  const accountSections = useMemo(
    () => getAccountSections(filteredLocationUsers, filteredAccountUsers),
    [filteredLocationUsers, filteredAccountUsers],
  );

  const [error, setError] = useState(false);

  useEffect(() => {
    getUserLocations();
    getUserAccounts();
  }, []);

  const { toastStore, userStore } = rootStore;
  const { isAdmin, isOwnerScope, isManagerScope, isGlobalOwnerScope } = userStore;

  /** Fetches the LocationUser objects for this user */
  const getUserLocations = async () => {
    try {
      setError(false);
      const resp = await Api.core.getUserLocationsV1(userId);
      setLocationUsers(resp?.data?.data || null);
      filterLocationUsers(resp?.data?.data || null);
    } catch (error) {
      setError(true);
      toastStore.error(getErrorMsg(error));
    }
  };

  /** Fetches the AccountUser objects for this user */
  const getUserAccounts = async () => {
    // If the user viewing this is an admin/owner or the user themselves, fetch their accounts.
    if (
      isAdmin ||
      isOwnerScope ||
      isGlobalOwnerScope ||
      isManagerScope ||
      userStore.authUser.id === userId
    ) {
      const resp = await Api.core.getUserAccounts(userId);
      filterAccountUsers(resp?.data?.data || null);
    }
  };

  /**
   * The AccountUser objects filtered by scope.
   */
  const filterAccountUsers = (accountUsers: AccountUser[] | null): AccountUser[] | undefined => {
    if (!accountUsers) {
      return undefined;
    }

    let filteredAccountUsers: AccountUser[] | null = accountUsers;
    const { scope } = userStore;
    if (isOwnerScope) {
      filteredAccountUsers = accountUsers?.filter(
        (accountUser) => accountUser.accountId === (scope as OwnerScope).accountId,
      );
    }
    if (isAdmin) {
      filteredAccountUsers = accountUsers;
    }
    setFilteredAccountUsers(filteredAccountUsers);
  };

  const filterLocationUsers = (locationUsers: LocationUser[] | null) => {
    if (!locationUsers) return;

    let _filteredLocationUsers = locationUsers;
    const { scope } = userStore;
    if (isOwnerScope) {
      _filteredLocationUsers = locationUsers.filter(
        (locationUser) => locationUser.location!.accountId === (scope as OwnerScope).accountId,
      );
    }

    if (isManagerScope) {
      _filteredLocationUsers = locationUsers.filter(
        (locationUser) => locationUser.locationId === (scope as ManagerScope).locationId,
      );
    }

    setFilteredLocationUsers(_filteredLocationUsers);
  };

  return (
    <WorkspaceContext.Provider
      value={{ error, locationUsers, accountSections, filteredLocationUsers, getUserLocations }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

const getAccountSections = (
  filteredLocationUsers: LocationUser[] | null,
  filteredAccountUsers: AccountUser[] | null,
) => {
  if (!filteredLocationUsers || !filteredAccountUsers) return null;

  const initialAccountSections: [AccountId, IAccountSection][] = filteredAccountUsers?.map(
    (accountUser) => [
      accountUser.accountId,
      { account: accountUser.account!, accountUser: accountUser, locationUsers: [] },
    ],
  );

  // Map of accounts with nested locations where user works;
  const sectionMap: Map<AccountId, IAccountSection> = new Map(initialAccountSections);
  for (const locationUser of filteredLocationUsers) {
    const location = locationUser.location!;
    const accountId = location.accountId;

    // If this LocationUser's account doesn't already exist in the map,
    // add it and this LocationUser
    if (!sectionMap.has(accountId)) {
      sectionMap.set(accountId, {
        account: location.account!,
        locationUsers: [locationUser],
      });
      // Otherwise, just push this LocationUser to this section
    } else {
      const section = sectionMap.get(accountId)!;
      section.locationUsers.push(locationUser);
    }
  }
  return Array.from(sectionMap.values()).reverse();
};

export default WorkspaceProvider;
