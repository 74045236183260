import React from 'react';
import { observer } from 'mobx-react';
import useWorkspace from './useWorkspace';
import { User } from 'models';
import { Box, Grid } from '@material-ui/core';
import UserLocationPanel from 'components/UserLocationPanel';
import EmploymentPanel from 'components/EmploymentPanel';
import Button from 'components/Button/Button';
import AddManagedLocationsDialog from './AddManagedLocationsDialog';
import { useDialogContext } from './WorkspaceDialogContext';
import useStyles from './styles';
import { Skeleton } from '@mui/material';
import ErrorFallback from 'components/ErrorFallback/ErrorFallback';

interface IWorkspaceProps {
  userId: number;
  user?: User;
}
const spacing = 3;
const Workspace = ({ userId, user }: IWorkspaceProps) => {
  const {
    isManagerScope,
    isLocationManager,
    currentLocationUser,
    hasManageEmployeeIdPermission,
    accountSections,
    error,
    showLoader,
    getUserLocations,
  } = useWorkspace();

  if (error) {
    return (
      <Box width={'100%'} mt={9}>
        <ErrorFallback onRefresh={getUserLocations || undefined} />
      </Box>
    );
  }

  if (showLoader) {
    return <LoadingCardSkeletons />;
  }

  if (isManagerScope && !hasManageEmployeeIdPermission) {
    return (
      <Grid container spacing={3}>
        <Grid item md={6} sm={12}>
          <UserLocationPanel>{currentLocationUser}</UserLocationPanel>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {accountSections?.map((accountSection) => (
        <Box key={accountSection.account.id} mb={1.5}>
          <Grid container spacing={spacing} key={accountSection.account.id}>
            <Grid item md={6} sm={12}>
              <EmploymentPanel
                userId={userId}
                account={accountSection.account}
                accountUser={accountSection.accountUser || null}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid container spacing={3}>
                {accountSection.locationUsers.map((locationUser) => (
                  <Grid item md={12} sm={12} key={locationUser.id}>
                    <UserLocationPanel>{locationUser}</UserLocationPanel>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ))}
      <AddManagedLocationsButton show={isLocationManager} />
      <AddManagedLocationsDialog user={user} />
    </>
  );
};

interface IAddManagedLocationButtonProps {
  show?: boolean;
}
const AddManagedLocationsButton = ({ show }: IAddManagedLocationButtonProps) => {
  const { button } = useStyles({ spacing });
  const { setOpenDialog } = useDialogContext();
  const handleSetOpen = () => {
    setOpenDialog && setOpenDialog(true);
  };
  if (!show) return null;
  return (
    <Button
      className={button}
      classes={{ root: button }}
      variant="contained"
      color="primary"
      onClick={handleSetOpen}
      fullWidth>
      Add managed locations
    </Button>
  );
};

const LoadingCardSkeletons = () => {
  const body = (
    <Grid container spacing={3}>
      {Array.from({ length: 4 }).map((_, i) => (
        <Grid key={i} item md={6} sm={12}>
          <Skeleton variant="rectangular" height={220} style={{ borderRadius: 16 }} />
        </Grid>
      ))}
    </Grid>
  );

  return <>{body}</>;
};

export default observer(Workspace);
