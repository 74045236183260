import { GridColDef, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro';
import Api, { getErrorMsg, RequestMetaData } from 'api';
import clsx from 'clsx';
import { Checkbox } from 'components/Checkbox/Checkbox';
import DataGridCell from 'components/DataGridCell/DataGridCell';
import { ExternalActionsProps } from 'components/DataGridInfiniteScroll/InterfaceDataGridPro';
import { useStores } from 'containers/App/App';
import _ from 'lodash';
import * as models from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { paths } from 'routes';
import { adaptForDataGridPro } from 'services';
import { getFullName, humanize } from 'utils/helper';
import { useStyles } from './styles';
import { EManagerPermission } from 'types';
import { Box, Tooltip } from '@mui/material';

const allPermissions = [
  EManagerPermission.VIEW_REFUNDS,
  EManagerPermission.MANAGE_REFUND_REQUESTS,
  EManagerPermission.VIEW_USER_TIPS,
  EManagerPermission.MANAGE_EMPLOYEE_IDS,
  EManagerPermission.VIEW_LOCATION_TIPS,
  EManagerPermission.VIEW_REPORTS,
  EManagerPermission.APPROVE_TALENTS,
  EManagerPermission.VIEW_STATS,
];

//TODO: Implement getHeaders api function or remove it if we decide to hardcode the headers
const getHeaders = async (): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(allPermissions);
    }, 0);
  });
};

function annotatePermissions(permission: models.IManagerPermissionsResponse) {
  return {
    id: permission.userId,
    userId: permission.userId,
    user: getFullName(permission?.firstName, permission?.lastName),
    permissions: permission.permissions,
  };
}

const usePermissions = () => {
  const apiRef = useGridApiRef();

  const { toastStore } = useStores();

  const { changedCell } = useStyles();

  const { accountId } = useParams<{ accountId: string }>();

  const [refetchKey, setRefetchKey] = useState(Date.now());

  // These are changed permissions. Their initial value is empty array.
  const [permissions, setPermissions] = useState<models.IUpdateManagerPermissions[]>([]);

  const [updating, setUpdating] = useState(false);

  const [headers, setHeaders] = useState<string[]>([]);

  const [accountName, setAccountName] = useState<string>('');

  useEffect(() => {
    const get = async () => {
      const headers = await getHeaders();
      setHeaders(headers);
    };
    get();
  }, []);

  useEffect(() => {
    const getAccount = async () => {
      try {
        const { data } = await Api.core.getAccount(Number(accountId));
        setAccountName(data.data?.name || '');
      } catch (error) {
        toastStore.error(getErrorMsg(error));
      }
    };
    getAccount();
  }, [setAccountName, toastStore, accountId]);

  const getChangedPermission = useCallback(
    (id: number, name: string) => {
      const userPermissions = permissions.find((p) => {
        return p.userId === id;
      });
      return userPermissions?.permissions.find((p) => p.name === name);
    },
    [permissions],
  );

  const updatePermission = useCallback(
    (id: number, name: string, value: boolean) => {
      setPermissions((previousPermissions) => {
        // Create a deep copy of the permissions array to avoid mutating the original state
        const clonedPermissions = _.cloneDeep(previousPermissions);
        // const clonedPermissions = _.cloneDeep(permissions);

        // Find user permissions object by userId
        const userPermissions = clonedPermissions.find((permission) => permission.userId === id);

        // If no user permissions for this id, add a new object with the permission
        if (!userPermissions) {
          clonedPermissions.push({ userId: id, permissions: [{ name, enabled: value }] });
        } else {
          // Check if the permission name already exists for this user
          const permissionIndex = userPermissions.permissions.findIndex(
            (perm) => perm.name === name,
          );

          if (permissionIndex !== -1) {
            // Permission exists, so we need to handle deletion based on the permissions array length
            if (userPermissions.permissions.length === 1) {
              // If this is the only permission, delete the entire user permissions object
              const userIndex = clonedPermissions.findIndex(
                (permission) => permission.userId === id,
              );
              clonedPermissions.splice(userIndex, 1);
            } else {
              // If there are multiple permissions, delete just the specific permission
              userPermissions.permissions.splice(permissionIndex, 1);
            }
          } else {
            // If the permission doesn't exist and the new value is true, add the permission
            userPermissions.permissions.push({ name, enabled: value });
          }
        }

        // Update the state with the new permissions array
        return clonedPermissions;
      });
    },
    [setPermissions],
  );

  const handleUpdateRow = useCallback(
    (
      field: string,
      enabled: boolean,
      row: GridRenderCellParams<models.IManagerPermissions>['row'],
    ) => {
      apiRef.current.updateRows([
        {
          ...row,
          permissions: row.permissions.map((p) => {
            return p.name === field ? { ...p, enabled } : p;
          }),
        },
      ]);
    },
    [],
  );

  const renderPermissionCheckbox = useCallback(
    (params: GridRenderCellParams<models.IManagerPermissions>) => {
      const {
        row: { userId, permissions },
        field,
      } = params;

      const permission = permissions.find((p) => p.name === field);

      // Find permission value from the row's original permissions
      let defaultEnabled = permissions.find((p) => p.name === field)?.enabled || false;

      // Check for updated permission in `permissions` state
      let checked = getChangedPermission(userId, field)?.enabled;

      // If not in permissions state, fall back to the original permission value
      if (typeof checked === 'undefined') {
        checked = defaultEnabled;
      }

      return (
        <Tooltip title={permission?.description || ''}>
          <Box component="span">
            <Checkbox
              checked={checked}
              onChange={(e) => {
                handleUpdateRow(field, e.target.checked, params.row);
                updatePermission(userId, field, e.target.checked);
              }}
            />
          </Box>
        </Tooltip>
      );
    },
    [getChangedPermission, updatePermission],
  );

  const generateColumns = (): GridColDef[] => {
    const permissionHeaders = headers.map((header) => ({
      headerName: humanize(header),
      field: header,
      width: 150,
      minWidth: 150,
      flex: 1,
      sortable: false,
      align: 'center',
      cellClassName: (params: GridRenderCellParams) => {
        // Check if the permission has been changed. If yes, change the cell color
        const permission = getChangedPermission(params.row.userId, header);
        return clsx(!!permission && changedCell);
      },
      renderCell: renderPermissionCheckbox,
    })) as GridColDef[];

    return [
      {
        headerName: 'User',
        field: 'user',
        width: 200,
        type: 'string',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row: { user, userId } }: GridRenderCellParams) => (
          <DataGridCell.Route value={user} path={paths.userDetails(userId).info()} />
        ),
      },
      ...permissionHeaders,
    ];
  };

  const listManagerPermissions = adaptForDataGridPro(
    async (rmd: RequestMetaData) => await Api.core.listUserManagerPermissions(Number(accountId)),
    annotatePermissions,
  );

  const resetPermissions = useCallback(() => {
    setRefetchKey(Date.now());
    setPermissions([]);
  }, []);

  const saveChangedPermissions = useCallback(async () => {
    setUpdating(true);
    try {
      await Api.core.updateAccountManagerPermissions(Number(accountId), { updates: permissions });
      toastStore.success('Permissions updated successfully');
      resetPermissions();
    } catch (error) {
      toastStore.error(getErrorMsg(error));
    } finally {
      setUpdating(false);
    }
  }, [accountId, permissions, toastStore, resetPermissions]);

  const actions: ExternalActionsProps | undefined = useMemo(
    () =>
      permissions.length
        ? {
            onSave: {
              name: 'Save Changes',
              loading: updating,
              variant: 'contained',
              action: () => {
                saveChangedPermissions();
              },
            },

            onCancel: {
              action: () => {
                resetPermissions();
              },
            },
          }
        : undefined,
    [permissions, saveChangedPermissions, resetPermissions, updating],
  );

  return {
    apiRef,
    accountName,
    refetchKey,
    actions,
    generateColumns,
    listManagerPermissions,
  };
};

export default usePermissions;
