import React from 'react';
import styles from '../styles';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core';
import { observable, makeObservable, action } from 'mobx';
import { inject, WithUserStore, WithSettingStore } from 'stores';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import moment from 'moment-timezone';
import { adaptForDataGridPro } from 'services/datagrid';
import { RequestMetaData } from 'api';
import { Box, Link } from '@material-ui/core';
import { paths } from 'routes';
import { DATE_TYPE, formatDateTimeInEST } from 'utils/helper';
import Api from 'api';
import { DrawStatus, Filter } from 'models';
import { numericStringToUsd } from 'services';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { v4 as uuidv4 } from 'uuid';
import FilterBar from 'components/FilterBar/FilterBar';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';

interface IDrawStatusColor {
  [key: string]: string;
}

const drawStatusColor = {
  CREATED: ChipStatusColors.PURPLE,
  PENDING: ChipStatusColors.YELLOW,
  PROCESSED: ChipStatusColors.GREEN,
  FAILED: ChipStatusColors.RED,
  CANCELLED: ChipStatusColors.RED,
  VOIDED: ChipStatusColors.GREY,
} as IDrawStatusColor;

type DrawsProps = WithStyles<typeof styles> &
  WithUserStore &
  WithSettingStore &
  RouteComponentProps;

@inject('userStore', 'settingStore')
@observer
class Draws extends React.Component<DrawsProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);
  }
  @observable datagridKey = Date.now();

  @observable activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  public fetchDraws = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.tips.getDraws({
        ...rmd,
        filters: {
          ...this.activeFilters,
        },
      }),
    (draw: any) => ({
      id: uuidv4(),
      ...draw,
      amount: numericStringToUsd(draw.amount),
      status: draw.status.toUpperCase(),
      date: formatDateTimeInEST(draw.createdAt, DATE_TYPE.FULL),
      location: draw.drawSource.location.name,
      locationId: draw.drawSource.location.id,
    }),
  );

  renderLocation = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.locationDetails(row.locationId)}>
        {row.location}
      </Link>
    );
  };

  renderMoneyCell = ({ value }: any) => {
    return <Box className={this.props.classes.moneyCell}>{value}</Box>;
  };

  renderStatus = ({ row }: any) => {
    const color = drawStatusColor[
      row.status.toUpperCase() as keyof IDrawStatusColor
    ] as ChipStatusColors;
    return <ChipStatusTag label={row.status} color={color} />;
  };

  gridColumns = [
    {
      headerName: 'Locations',
      field: 'location',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderLocation,
    },
    {
      headerName: 'Debited At',
      field: 'date',
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    {
      headerName: 'Processor',
      field: 'processor',
      minWidth: 200,
      flex: 1,
      sortable: false,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderMoneyCell,
    },
    {
      headerName: 'Status',
      field: 'lastDrawStatus',
      minWidth: 150,
      sortable: false,
      renderCell: this.renderStatus,
    },
  ];

  statusValues = (): { label: string; value: string }[] => {
    const statusItems = Object.keys(DrawStatus).map((key: any) => {
      return { label: key, value: (DrawStatus as any)[key as keyof DrawStatus] };
    });

    return statusItems;
  };

  filtersDraws: Filter[] = [
    { display: 'Locations', id: 'location', label: 'Contains', type: 'text' },
    { display: 'Location ID', id: 'locationId', label: 'Contains', type: 'text' },
    { display: 'Account', id: 'account', label: 'Contains', type: 'text' },
    { display: 'Account ID', id: 'accountId', label: 'Contains', type: 'text' },
    {
      display: 'Status',
      id: 'status',
      label: 'Contains',
      type: 'select',
      items: this.statusValues(),
    },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <>
        <Box mt={3}>
          <FilterBar
            filters={this.filtersDraws}
            onChange={this.handleFiltersOnChange}
            showDateRange
          />
        </Box>
        <Box mt={3}>
          {this.filtersInitReady && (
            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.fetchDraws}
              refetchKey={this.activeFilters}
              sortDirection={'DESC'}
              disableColumnMenu
              pathname={this.props.location.pathname}
            />
          )}
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(Draws);
