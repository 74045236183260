/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import {
  observable,
  action,
  reaction,
  flow,
  IReactionDisposer,
  makeObservable,
  computed,
} from 'mobx';
import { observer } from 'mobx-react';

import { paths } from 'routes';
import { AxiosResponse } from 'axios';
import Api, { ApiResponse, getErrorMsg, RequestMetaData } from 'api';
import { adaptForDataGridPro, setTitle } from 'services';

import { Box, Grid, Link } from '@material-ui/core';
import { AccountGroupOutline } from 'mdi-material-ui';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithToastStore, WithAnalyticsStore, WithSettingStore } from 'stores';

import { Filter } from 'models';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import moment from 'moment-timezone';

import styles from '../styles';
import { HorizontalStatCard } from 'containers/UserDetails';
import { AssuredWorkloadRounded, Percent } from '@mui/icons-material';

const PAGE_TITLE = 'Conversions by Campaigns';

/** Stats panel data interface */
interface Stats {
  count: number;
  percent: number;
  total: number;
}

/** Single campaign conversion row */
interface CampaignConversion {
  id: number;
  name: string;
  code: string;
  createdAt: string;
  count: string;
  percentTotal: string;
  percentCampaigns: string;
}

function annotateConversions(c: CampaignConversion) {
  return {
    ...c,
    count: parseInt(c.count),
    percentTotal:
      parseFloat(c.percentTotal) !== 0 ? `${parseFloat(c.percentTotal).toFixed(2)}%` : '',
    percentCampaigns:
      parseFloat(c.percentCampaigns) !== 0 ? `${parseFloat(c.percentCampaigns).toFixed(2)}%` : '',
  };
}

/** Here we define what kind of props this component takes */
interface ByCampaignProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithAnalyticsStore,
    WithSettingStore,
    RouteComponentProps {}

@inject('toastStore', 'analyticsStore', 'settingStore')
@observer
class ByCampaign extends React.Component<ByCampaignProps> {
  constructor(props: ByCampaignProps) {
    super(props);
    makeObservable(this);
    // Fetch stats when selected date range changes
    this.disposers.push(
      reaction(
        () => this.activeFilters.fromDate || this.activeFilters.toDate,
        () => {
          this.fetchStats();
          this.activeFilters = { ...this.activeFilters };
        },
      ),
    );
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  @observable public stats?: Stats;

  @action.bound public fetchStats = flow(function* (this: ByCampaign) {
    const extraReqData = {
      fromDate: this.activeFilters.fromDate as string,
      toDate: this.activeFilters.toDate as string,
    };
    try {
      const resp: AxiosResponse<ApiResponse<Stats>> =
        yield Api.analytics.conversion.byCampaigns.statsData(
          extraReqData.fromDate,
          extraReqData.toDate,
        );
      if (resp && resp.data) {
        this.stats = resp.data.data;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public fetchCampaignConversions = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      if (!this.filtersInitReady) {
        return Promise.resolve({ data: [] } as AxiosResponse);
      }

      return await Api.analytics.conversion.byCampaigns.tableData({
        ...rmd,
        filters: { ...this.activeFilters },
      });
    },
    annotateConversions,
  );

  renderCellCode = ({ row }: any) => (
    <Link component={RouterLink} to={paths.campaignsByCode(row.code)}>
      {row.code}
    </Link>
  );

  gridColumns = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Code',
      field: 'code',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellCode,
      sortable: false,
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    { headerName: 'Count', field: 'count', minWidth: 150, flex: 1 },
    {
      headerName: '% Campaigns',
      field: 'percentCampaigns',
      minWidth: 220,
      flex: 1,
    },
    {
      headerName: '% Total',
      field: 'percentTotal',
      minWidth: 150,
      flex: 1,
    },
  ];

  filters: Filter[] = [{ display: 'Name', id: 'name', label: 'Contains', type: 'text' }];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  @computed private get dateRange(): DateRangeExternalPicker.IDateRange | undefined {
    return this.activeFilters.fromDate && this.activeFilters.toDate
      ? { fromDate: this.activeFilters.fromDate, toDate: this.activeFilters.toDate }
      : { toDate: new Date().toString() };
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
    this.fetchStats();
  }

  /** Before unmounting the component, dispose of all reactions created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    return (
      <>
        <Box mt={3}>
          <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} showDateRange />
        </Box>
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <HorizontalStatCard
                icon={AccountGroupOutline}
                duration={1}
                title="Count"
                color="primary"
                dateRange={this.dateRange}>
                {this.stats && this.stats.count}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <HorizontalStatCard
                icon={Percent}
                duration={1}
                title="% of all signups"
                color="yellow"
                suffix="%"
                dateRange={this.dateRange}
                decimals={2}
                separator={`.`}>
                {this.stats && this.stats.percent}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <HorizontalStatCard
                icon={AssuredWorkloadRounded}
                duration={1}
                title="Total signups"
                color="secondary"
                dateRange={this.dateRange}>
                {this.stats && this.stats.total}
              </HorizontalStatCard>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchCampaignConversions}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(ByCampaign);
