import { useLocation, useHistory } from 'react-router-dom';
import FilterBarLayout from './FilterBarLayout';
import { Filter, IFilterValue, SearchKey } from 'models';
import { FilterBarGraphProps } from './FilterBarComponents/FilterBarGraph';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsMount } from 'hooks/useIsMount';
import { useStores } from 'containers/App/App';
import { IDateRange } from 'components/DateRangeExternalPicker';

export interface FilterBarProps {
  filters: Filter[];
  onChange: (filters: Record<string, unknown>) => void;
  showDateRange?: boolean;
  dateRangeLocalStoragePath?: string;
  locationMap?: {
    isActive: boolean;
  };
  graph?: FilterBarGraphProps;
  syncUrl?: boolean;
}

const FilterBar = ({
  filters: filtersProps,
  onChange,
  showDateRange,
  dateRangeLocalStoragePath,
  locationMap,
  graph,
  syncUrl = true,
}: FilterBarProps) => {
  const { settingStore } = useStores();
  const { setDate, getDate } = settingStore;

  const [filters, setFilters] = useState<Filter[]>(filtersProps);
  const [newFilters, setNewFilters] = useState<Filter[]>([]);
  const [virtualUrl, setVirtualUrl] = useState<Record<string, unknown>[]>();

  const [dateRange, setDateRange] = useState<IDateRange>();
  const [isHistoryReplaced, setIsHistoryReplaced] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const isMount = useIsMount();

  const localStoragePath = useMemo(
    (): string => dateRangeLocalStoragePath || location.pathname,
    [dateRangeLocalStoragePath, location.pathname],
  );

  /**
   * Get query parameters from the URL or virtualUrl
   */
  const getQueryParameters = useCallback((): Record<string, any>[] | undefined => {
    if (!syncUrl) {
      return virtualUrl;
    }

    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get('filtersData');

    return data ? JSON.parse(decodeURIComponent(data)) : undefined;
  }, [location.search, syncUrl, virtualUrl]);

  /**
   * Set query parameters to the URL or virtualUrl
   */
  const setQueryParameters = useCallback(
    (queryFilters: Record<string, unknown>) => {
      if (!syncUrl) {
        if (!Object.keys(queryFilters).length) {
          setVirtualUrl(undefined);
          return;
        }

        setVirtualUrl([queryFilters]);
        return;
      }

      const serializedArray = encodeURIComponent(
        JSON.stringify([{ ...queryFilters, ...dateRange }]),
      );

      history.push({
        pathname: location.pathname,
        search: `?filtersData=${serializedArray}`,
      });
    },
    [dateRange, history, location.pathname, syncUrl],
  );

  /**
   * Build UI filters if they have value in URL
   */
  const getNewFilters = useCallback(() => {
    const params = getQueryParameters();

    if (!params) {
      setNewFilters(filters.map((it) => ({ ...it, value: undefined })));
      return;
    }

    const newList: Filter[] = [];
    for (const filter of filters) {
      const thisParam: IFilterValue = params[0][filter.id] || params[0][`${filter.id}s`];

      if (!thisParam) {
        const key = params[0]['filterName'];

        // RANGE
        if (filter.id === key) {
          const myParams =
            filter.type === 'range'
              ? {
                  fromAmount: params[0].fromAmount,
                  toAmount: params[0].toAmount,
                  filterName: params[0].filterName,
                }
              : {
                  radius: params[0].radius,
                  zip: params[0].zip,
                  filterName: params[0].filterName,
                };

          filter.value = Object.entries(myParams)
            .filter((it) => it[0] !== 'filterName')
            .map(([key, value]) => ({
              id: value,
              name: key,
            }));
          newList.push(filter);
          continue;
        }
        newList.push({ ...filter, value: undefined });
        continue;
      }

      // AUTOCOMPLETE & SELECT
      filter.value = !Array.isArray(thisParam.id)
        ? [
            {
              id: thisParam.id,
              name: thisParam.name,
            },
          ]
        : [
            ...thisParam.id.map((id, index) => ({
              id: id,
              name: thisParam.name && thisParam.name[index],
            })),
          ];

      newList.push(filter);
    }

    setNewFilters(newList);
  }, [filters, getQueryParameters]);

  /**
   * Create filters struture from active filters so it can be stored in URL or virtualUrl
   */
  const prepareQueryParams = useMemo((): Record<string, unknown> => {
    /** List of filters that have values set */
    const activeFilters: Pick<Filter, 'id' | 'value' | 'options' | 'type'>[] = filters
      .filter((f) => f.value)
      .map((f) => ({
        id: f.id,
        value: f.value,
        options: f.options,
        type: f.type,
      }));

    return activeFilters.reduce((data, activeFilter) => {
      let extraValue: Record<string, any> = {};

      if (!activeFilter.value) {
        return { ...data };
      }

      const isMore = [...activeFilter.value].length > 1;
      const keyName = isMore ? `${activeFilter.id}s` : activeFilter.id;

      if (activeFilter.type === 'tags') {
        // AUTOCOMPLETE
        const keySearch: SearchKey = activeFilter.options?.displayField.keySearch || { name: 'id' };
        const mapValue = [...activeFilter.value].map(
          (item) => item[keySearch.name as keyof IFilterValue],
        );
        const mapName = [...activeFilter.value].map((item) => item['name']);
        extraValue = {
          [keyName]: {
            id: isMore ? mapValue : mapValue[0],
            name: isMore ? mapName : mapName[0],
          },
        };
      } else if (['select', 'autocomplete', 'text'].includes(activeFilter.type)) {
        // SELECT, AUTOCOMPLETE, TEXT
        extraValue = {
          [activeFilter.id]: {
            id: activeFilter.value[0].id,
          },
        };

        const name = activeFilter.value[0].name;
        if (name) {
          extraValue[activeFilter.id].name = name;
        }
      } else {
        // RANGE
        for (const val of activeFilter.value) {
          const key = val.name || '';
          extraValue[key] = val.id;
        }
        extraValue['filterName'] = activeFilter.id;
      }

      return { ...data, ...extraValue };
    }, {});
  }, [filters]);

  /**
   * Compare dates, either update new date or set flag that url is changed
   */
  const checkIfDateRangeIsChanged = useCallback(
    ({ fromDate, toDate, dateRangeType }: IDateRange) => {
      const isSameDateRange: boolean =
        fromDate === dateRange?.fromDate &&
        toDate === dateRange?.toDate &&
        dateRangeType === dateRange?.dateRangeType;

      if (!isSameDateRange) {
        setDateRange({ fromDate, toDate, dateRangeType });
      } else {
        setIsHistoryReplaced(true);
      }
    },
    [dateRange?.fromDate, dateRange?.toDate, dateRange?.dateRangeType],
  );

  /**
   * Pass data to backend
   */
  const sendDataToBE = useCallback(
    (queryFilters: Record<string, any>[]) => {
      const readyData: Record<string, unknown> = {};
      for (const qFilter of queryFilters) {
        for (const [key, value] of Object.entries(qFilter)) {
          // String is used for inputs with single values, like radio buttons
          if (typeof value === 'string') {
            // If there is a key "filterName", it is a range
            if (key !== 'filterName') {
              readyData[key] = value;
            }
          } else {
            readyData[key] = value.id;
          }
        }
      }

      delete readyData.dateRangeType;
      onChange(readyData);
      getNewFilters();
    },
    [getNewFilters, onChange],
  );

  /**
   * Use date from local storage to update URL or virtualUrl
   */
  const implementDateFromLocalStorage = useCallback(
    (queryFilters: Record<string, any>[]) => {
      const dateFromLocalStorage = getDate(localStoragePath);
      setIsHistoryReplaced(true);
      setDateRange(dateFromLocalStorage);

      if (!syncUrl) {
        setVirtualUrl([{ ...dateFromLocalStorage, ...queryFilters[0] }]);
        return;
      }

      const serializedArrayDate = encodeURIComponent(
        JSON.stringify([{ ...dateFromLocalStorage, ...queryFilters[0] }]),
      );
      const newSearch = `?filtersData=${serializedArrayDate}`;
      history.replace({
        pathname: location.pathname,
        search: newSearch,
      });
    },
    [getDate, history, localStoragePath, location.pathname, syncUrl],
  );

  /**
   * Handle URL or virtualUrl changes
   */
  const URLChangeHandler = useCallback(() => {
    const queryFilters = getQueryParameters() || [];

    if (showDateRange) {
      if (queryFilters[0]?.dateRangeType) {
        checkIfDateRangeIsChanged({ ...queryFilters[0] });
      } else {
        implementDateFromLocalStorage(queryFilters);
        return;
      }
    }

    sendDataToBE(queryFilters);
  }, [
    getQueryParameters,
    showDateRange,
    sendDataToBE,
    checkIfDateRangeIsChanged,
    implementDateFromLocalStorage,
  ]);

  /**
   * External date range config
   */
  const externalDateRange = useMemo(
    () =>
      showDateRange && dateRange
        ? {
            onChange: (range: IDateRange) => {
              setIsHistoryReplaced(false);
              setDateRange(range);
            },
            value: dateRange,
          }
        : undefined,
    [dateRange, showDateRange],
  );

  /**
   * When URL or virtualUrl change
   */
  useEffect(() => {
    URLChangeHandler();
  }, [location.search, virtualUrl]);

  /**
   * When filters change
   */
  useEffect(() => {
    if (!isMount) {
      setQueryParameters(prepareQueryParams);
    }
  }, [prepareQueryParams]);

  /**
   * When date changes
   */
  useEffect(() => {
    if (!dateRange) {
      return;
    }

    setDate(localStoragePath, dateRange);

    if (isHistoryReplaced) {
      return;
    }

    const filtersData = getQueryParameters();
    if (syncUrl && !filtersData) {
      return;
    }

    const existingFilters = filtersData ? filtersData?.[0] : virtualUrl?.[0];
    const newFiltersData: Record<string, any> | undefined = {
      ...existingFilters,
      ...dateRange,
    };

    if (!syncUrl) {
      setVirtualUrl([newFiltersData]);
    } else {
      const serializedArray = encodeURIComponent(JSON.stringify([newFiltersData]));
      history.push({
        pathname: location.pathname,
        search: `?filtersData=${serializedArray}`,
      });
    }
  }, [dateRange]);

  return (
    <FilterBarLayout
      filters={newFilters}
      setFilters={setFilters}
      externalDateRange={externalDateRange}
      locationMap={locationMap}
      graph={graph}
    />
  );
};

export default FilterBar;
