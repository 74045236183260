import { useEffect, useMemo } from 'react';
import { Link as RouterLink, Route, Switch, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import DashboardLayout from 'containers/DashboardLayout';
import TabBar from 'components/TabBar/TabBar';
import Hardware from './Hardware';
import Inventory from './Inventory';
import Kiosks from './Kiosks';
import styles from './styles';

import { setTitle } from 'services';
import Title from 'components/Title';
import { paths } from 'routes';

const PAGE_TITLE = 'Inventory';

export enum DevicesTab {
  HARDWARE = 'hardware',
  ITEMS = 'items',
  CONNECTED_DEVICES = 'connected-devices',
}

const Devices = () => {
  const { pathname } = useLocation();

  const tabs = useMemo(
    () => [
      {
        label: 'Hardware',
        component: RouterLink,
        to: paths.devices().hardware(),
        selected: pathname.includes(DevicesTab.HARDWARE),
      },
      {
        label: 'Items',
        component: RouterLink,
        to: paths.devices().items(),
        selected: pathname.includes(DevicesTab.ITEMS),
      },
      {
        label: 'Connected Devices',
        component: RouterLink,
        to: paths.devices().connectedDevices(),
        selected: pathname.includes(DevicesTab.CONNECTED_DEVICES),
      },
    ],
    [pathname],
  );

  useEffect(() => {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }, []);

  return (
    <DashboardLayout>
      <Title mb={3} size={'large'}>
        {PAGE_TITLE}
      </Title>
      <TabBar tabs={tabs} />

      <Switch>
        <Route path={paths.devices().hardware()} component={Hardware} />
        <Route path={paths.devices().items()} component={Inventory} />
        <Route path={paths.devices().connectedDevices()} component={Kiosks} />
      </Switch>
    </DashboardLayout>
  );
};

export default withStyles(styles)(Devices);
