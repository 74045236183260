import { ClickAwayListener, Paper, Typography, IconButton, Box } from '@material-ui/core';
import Button from 'components/Button/Button';
import { Close } from 'mdi-material-ui';
import React from 'react';
import { default as DialogButton } from 'components/Button/Dialog/Button';
import theme from 'containers/App/theme';
import { Filter, IFilterValue } from 'models';

interface FilterBarItemsWrapperProps {
  children: React.ReactNode;
  mobileView?: boolean;
  filterValue?: string | IFilterValue[];
  selectedFilter: Filter;
  setFilterValue?: () => void;
  clearSelectedFilter: () => void;
}

export const FilterBarItemsWrapper = ({
  children,
  filterValue,
  selectedFilter,
  setFilterValue,
  clearSelectedFilter,
  mobileView,
}: FilterBarItemsWrapperProps) => {
  if (!selectedFilter) {
    return null;
  }

  if (mobileView) {
    return (
      <Box display={'flex'} flexDirection={'column'} height={'100%'}>
        {children}
        <Button
          style={{ marginTop: 'auto' }}
          disabled={!filterValue}
          onClick={setFilterValue}
          variant="contained">
          Apply
        </Button>
      </Box>
    );
  }

  return (
    <ClickAwayListener onClickAway={clearSelectedFilter}>
      <Box sx={filterItemsWrapperStyling}>
        <Paper className="filterPanel">
          <Box
            p={2}
            className="filterPanelHeader"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between">
            <Typography className="headerTitle">{selectedFilter.display}</Typography>
            <IconButton className="closeIcon" size="small" onClick={clearSelectedFilter}>
              <Close className="closeIconStyles" />
            </IconButton>
          </Box>
          <Box p={2} pt={3}>
            {children}
            <Box mt={4} display="flex" flexDirection="row" justifyContent="flex-end">
              <DialogButton
                type="submit"
                variant="contained"
                disabled={!filterValue}
                onClick={setFilterValue}>
                APPLY
              </DialogButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </ClickAwayListener>
  );
};

const filterItemsWrapperStyling = {
  display: 'block',
  '& .filterPanel': {
    position: 'absolute',
    top: 52,
    left: 18,
    zIndex: 10,
    overflow: 'hidden',
    minWidth: theme.spacing(40),
    maxWidth: theme.spacing(87.5),
    filter: 'drop-shadow(0px 6px 24px #DDDDDD)',
  },
  '& .filterPanelHeader': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .headerTitle': {
    color: theme.palette.text.primary,
    fontSize: 24,
  },
  '& .closeIcon': {
    color: theme.palette.text.primary,
  },
  '& .closeIconStyles': {
    width: 22,
    height: 22,
    paddingRight: theme.spacing(0.5),
  },
};
