import React, { ReactNode } from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import Api, { RequestMetaData } from 'api';
import { paths } from 'routes';
import { startCase } from 'lodash';

import moment from 'moment-timezone';

import { Payout } from 'models';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { IconButton, Box, Tooltip, Link, Chip } from '@material-ui/core';
import { Eye } from 'mdi-material-ui';

import * as models from 'models';

import { numericStringToUsd } from 'services/currency';

import { adaptForDataGridPro } from 'services/datagrid';

import styles from './../styles';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import FilterBar from 'components/FilterBar';

/** Define props for this component */
type InitiationDetailsPayoutsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & {
    initiationId: number;
    payoutCompletedNode: ReactNode;
  };

function annotatePayoutsList(payout: Payout) {
  return {
    ...payout,
    talentName: `${payout.talent.firstName} ${payout.talent.lastName}`,
    processor:
      payout.processor && payout.transactionId
        ? `${startCase(payout.processor)} - ${payout.transactionId.slice(0, 4)}`
        : '',
    amount: payout.amount && numericStringToUsd(payout.amount.toString()),
    status: payout.status.toUpperCase(),
    sentAt: moment(payout.createdAt).tz('America/New_York'),
  };
}

/**
 * Show list of payouts and chunks belonging to a single payment. Payment reference is
 * passed via match params and list is displayed with datagrid.
 */
@observer
class InitiationDetailsPayouts extends React.Component<InitiationDetailsPayoutsProps> {
  public constructor(props: InitiationDetailsPayoutsProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @action.bound public fetchPayouts = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    return await Api.tips.getInitiationPayouts(this.props.initiationId, {
      ...rmd,
      filters: { ...this.activeFilters },
    });
  }, annotatePayoutsList);

  renderTalentCell = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.userDetails(row.talent.id).root()}>
        {row.talent.firstName} {row.talent.lastName}
      </Link>
    );
  };

  renderProcessorCell = ({ row }: any) => (
    <Link component={'a'} href={paths.dwollaTransaction(row.transactionId || '')}>
      {row.processor}
    </Link>
  );

  renderActions = ({ row }: any) => {
    return (
      <IconButton color="primary" component={RouterLink} to={paths.payoutsTransactions(row.id)}>
        <Tooltip title="See transactions">
          <Eye fontSize="small" />
        </Tooltip>
      </IconButton>
    );
  };

  renderStatus = ({ value }: any) => {
    const { chipCompleted, chipProcessed, chipPending, chipFailed } = this.props.classes;
    const colors: Record<any, any> = {
      COMPLETED: chipCompleted,
      PROCESSED: chipProcessed,
      PENDING: chipPending,
      FAILED: chipFailed,
    };

    return <Chip label={value} style={{ width: '100%' }} classes={{ root: colors[value] }} />;
  };

  gridPayoutsColumns = [
    {
      headerName: 'Talent',
      field: 'search',
      width: 200,
      flex: 1,
      renderCell: this.renderTalentCell,
    },

    {
      headerName: 'Sent At',
      field: 'sentAt',
      width: 120,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('h:mm A'),
    },
    {
      headerName: 'Processor',
      field: 'processor',
      width: 200,
      flex: 1,
      renderCell: this.renderProcessorCell,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      width: 150,
      flex: 1,
    },
    { headerName: 'Status', field: 'status', width: 180, renderCell: this.renderStatus },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      width: 110,
      renderCell: this.renderActions,
    },
  ];

  filters: models.Filter[] = [
    {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'COMPLETED', value: 'completed' },
        { label: 'PROCESSED', value: 'processed' },
        { label: 'PENDING', value: 'pending' },
        { label: 'FAILED', value: 'failed' },
      ],
    },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <>
        <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} />
        <Box mt={2} mb={2}>
          {this.props.payoutCompletedNode}
        </Box>

        {this.filtersInitReady && (
          <DataGridInfiniteScroll
            columns={this.gridPayoutsColumns}
            fetch={this.fetchPayouts}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(InitiationDetailsPayouts);
