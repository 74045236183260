/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithManagerPermissionsStore, WithSettingStore, WithUserStore } from 'stores';
import { Tip, Source, Customer } from 'models';

import Api, { RequestMetaData } from 'api';
import moment from 'moment-timezone';

import { Box, Tooltip } from '@material-ui/core';
import { Cash, CreditCard, CallSplit } from 'mdi-material-ui';

import styles from './styles';

import { adaptForDataGridPro } from 'services/datagrid';

import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import FilterBar from 'components/FilterBar';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import { RouteComponentProps } from 'react-router-dom';
import { EManagerPermission } from 'types';

interface IColors {
  [key: string]: ChipStatusColors;
}

const colors = {
  CONFIRMED: ChipStatusColors.GREEN,
  PENDING: ChipStatusColors.PURPLE,
  VOID: ChipStatusColors.GREY,
} as IColors;
interface TipsProps
  extends WithStyles<typeof styles>,
    WithManagerPermissionsStore,
    WithUserStore,
    RouteComponentProps,
    WithSettingStore {
  userId: number;
  customerShortName?: boolean;
}

const SETTING_STORE_KEY = '/users/tips';

/** Displays tips using datagrid table */
@inject('userStore', 'settingStore', 'managerPermissionsStore')
@observer
class Tips extends React.Component<TipsProps> {
  constructor(props: TipsProps) {
    super(props);
    makeObservable(this);
  }
  static defaultProps = {
    customerShortName: false,
  };

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @observable tips?: Tip[];

  @computed get isAdmin(): boolean {
    return Boolean(this.props.userStore!.authUser.isAdmin);
  }

  @computed get isOwner(): boolean {
    return this.props.userStore!.scope.kind === 'owner';
  }

  @computed get isGlobalOwner(): boolean {
    return this.props.userStore!.scope.kind === 'global_owner';
  }

  /**
   * We are using dynamic columns, based on scope of the current user. Non-admins
   * can only see confirmed tips while admins can also see tips with other statuses
   * (confirmed, pending, void), so we need to add and extra column for admins.
   */

  @computed get gridColumns() {
    const gridColumns = [
      {
        headerName: 'Date',
        field: 'createdAt',
        minWidth: 150,
        flex: 1,
        valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
      },
      {
        headerName: 'Time',
        field: 'time',
        minWidth: 120,
        flex: 1,
        valueGetter: ({ value }: any) => value && moment(new Date(value)).format('h:mm A'),
        sortable: false,
      },
      {
        headerName: 'Source',
        field: 'source',
        minWidth: 120,
        flex: 1,
        renderCell: this.renderSource,
      },
      {
        headerName: 'Customer',
        field: 'customerName',
        minWidth: 150,
        flex: 1,
      },
      { headerName: 'Service Amount', field: 'serviceAmount', minWidth: 180, flex: 1 },
      {
        headerName: 'Tip Amount',
        field: 'net',
        minWidth: 150,
        flex: 1,
      },
    ];

    if (this.isAdmin) {
      gridColumns.push({
        headerName: 'Status',
        field: 'status',
        minWidth: 200,
        flex: 1,
        renderCell: this.renderStatus,
      });
    }

    return gridColumns;
  }

  /** Annotates tips with extra data */
  @action.bound private annotateTips = (tip: Tip) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const formatCustomerName = (customer: Pick<Customer, 'firstName' | 'lastName'>) =>
      this.props.customerShortName
        ? `${customer.firstName} ${customer.lastName ? customer.lastName.charAt(0) : ''}.`
        : `${customer.firstName} ${customer.lastName}`;

    return {
      id: tip.id,
      createdAt: tip.createdAt,
      time: tip.createdAt,
      source: tip.source,
      status: tip.status.toUpperCase(),
      serviceAmount: formatter.format(parseFloat(tip.serviceAmount)),
      customerName: tip.customer ? formatCustomerName(tip.customer) : '',
      net: formatter.format(parseFloat(tip.net)),
      paymentStatus: tip.payment && tip.payment.status,
    };
  };

  @action.bound public fetchTips = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const userStore = this.props.userStore;
    const accountId = userStore!.currentAccount && userStore!.currentAccount.id;
    const extraReqData = {
      userId: this.props.userId,
      status: this.isAdmin ? '' : 'confirmed',
      fromDate: this.activeFilters.fromDate,
      toDate: this.activeFilters.toDate,
    };

    const fetchGetTips =
      this.isOwner && accountId
        ? () => Api.tips.getTipsForAccount(accountId, rmd, extraReqData)
        : this.isGlobalOwner && !accountId
        ? () => Api.tips.getGloballyOwnedTips(rmd, extraReqData)
        : () => this.getTips(rmd, extraReqData);

    return await fetchGetTips();
  }, this.annotateTips);

  @action.bound private getTips = async (
    rmd: RequestMetaData,
    extraData?: Record<string, unknown>,
  ) => {
    if (
      this.props.userStore?.isManagerScope &&
      this.props.managerPermissionsStore?.hasPermission(EManagerPermission.VIEW_USER_TIPS)
    ) {
      const accountId = this.props.managerPermissionsStore.accountId;
      return Api.tips.getTips(rmd, { ...extraData, accountId });
    }

    return Api.tips.getTips(rmd, extraData);
  };

  renderSource = ({ row }: any) => {
    const source = row.source as Source;

    const SourceIcon = () => {
      if (source === 'cash') {
        return (
          <Tooltip placement="right-end" title="Cash">
            <Cash color="disabled" />
          </Tooltip>
        );
      }
      if (source === 'card') {
        return (
          <Tooltip placement="right-end" title="Credit Card">
            <CreditCard color="disabled" />
          </Tooltip>
        );
      }
      if (source === 'split') {
        return (
          <Tooltip placement="right-end" title="Split Tip">
            <CallSplit color="disabled" />
          </Tooltip>
        );
      }
      return null;
    };

    return <SourceIcon />;
  };

  /** Renders the status chip */
  renderStatus = ({ value, row }: any) => {
    const color = colors[value.toUpperCase() as keyof IColors];

    if (value === 'VOID' && row.paymentStatus) {
      return (
        <Tooltip title={row.paymentStatus} placement="top">
          <ChipStatusTag label={value} color={color} />
        </Tooltip>
      );
    }
    return <ChipStatusTag label={value} color={color} />;
  };

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <>
        <Box>
          <FilterBar
            filters={[]}
            onChange={this.handleFiltersOnChange}
            showDateRange
            dateRangeLocalStoragePath={SETTING_STORE_KEY}
          />
          {this.filtersInitReady && (
            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.fetchTips}
              refetchKey={this.activeFilters}
              disableColumnMenu
              pathname={this.props.location.pathname}
            />
          )}
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(Tips);
