import { Grid } from '@mui/material';

import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
} from 'react-hook-form';

import { IFormFieldsConfig } from './FormsConfig';
import OutlinedInput from 'components/Input/OutlinedInput';
import { EInput } from 'models/Input';
import AutocompleteSearch from 'components/AutocompleteSearch';
import TagsAutocomplete from 'components/TagInputComponent/TagsAutocomplete';
import { Box } from '@material-ui/core';
import theme from 'containers/App/theme';

interface FormBuilderProps {
  formFieldsConfig: IFormFieldsConfig[];
  control: Control<FieldValues, unknown>;
  errors: FieldErrors<FieldValues>;
  marginBottom?: number;
  spacing?: number;
}

export const FormBuilder = ({
  formFieldsConfig,
  control,
  errors,
  spacing,
  marginBottom = 16,
}: FormBuilderProps) => (
  <Grid
    container
    direction={'row'}
    spacing={spacing || 1}
    sx={{ marginBottom: `${marginBottom}px` }}>
    {formFieldsConfig.map((formItem: IFormFieldsConfig) => (
      <Grid item key={formItem.name} {...(formItem.size || { xs: 12 })}>
        {/* @ts-ignore */}
        <Controller
          key={formItem.label}
          name={formItem.name}
          control={control}
          rules={formItem.rules}
          render={({ field }: { field: ControllerRenderProps<FieldValues, string> }) => {
            if (formItem.type === EInput.AUTOCOMPLETE) {
              return (
                <AutocompleteSearch
                  {...field}
                  onChange={(value: any) => {
                    field.onChange({ target: { value } });
                  }}
                  label={formItem.label}
                  disabled={formItem.disabled}
                  autoFocus={formItem.autoFocus}
                  error={!!errors[formItem.name]}
                  fetch={formItem.fetch!}
                  getOptionLabel={formItem.getLabel!}
                />
              );
            }

            // TODO: Update props to be dynamic, if needed
            if (formItem.type === EInput.TAGS_AUTOCOMPLETE) {
              return (
                <TagsAutocomplete
                  disableCloseOnSelect={false}
                  selectedTags={(value: any) => {
                    field.onChange({ target: { value } });
                  }}
                  tags={formItem.tagsSelected || []}
                  label={formItem.label}
                  fetchOnInit
                  required={formItem.required}
                  options={{
                    fetch: formItem.fetch,
                    displayField: {
                      keySearch: { name: 'id' },
                      value: 'name',
                    },
                    haveMultiple: true,
                  }}
                />
              );
            }

            return (
              <OutlinedInput
                {...field}
                label={formItem.label}
                autoFocus={formItem.autoFocus}
                error={!!errors[formItem.name]}
                control={control}
                required={formItem.required}
                disabled={formItem.disabled}
                fullWidth={formItem.fullWidth}
                variant={formItem.variant}
                select={formItem.optionsList || formItem.optionsNodes ? true : undefined}
                className={formItem.className}
                options={formItem.optionsList}>
                {formItem.optionsNodes}
              </OutlinedInput>
            );
          }}
        />
        {formItem.note && (
          <Box fontSize={14} color={theme.palette.text.secondary}>
            {formItem.note}
          </Box>
        )}
      </Grid>
    ))}
  </Grid>
);
