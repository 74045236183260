import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Api, { getErrorMsg } from 'api';

import styles from './styles';
import GoogleMap from 'components/GoogleMap';
import LocationMarker from 'components/LocationMarker';
import { Coordinates } from 'types/coordinates';
import { action, observable, flow, toJS, computed, makeObservable } from 'mobx';
import { Filter, Location } from 'models';
import { observer } from 'mobx-react';
import { has } from 'lodash';
import { inject, WithToastStore } from 'stores';

import { Box, Drawer } from '@material-ui/core';

import DashboardLayout from 'containers/DashboardLayout';
import LocationsFilterDrawer, * as LocationsFilterDrawer_1 from './LocationsFilterDrawer';
import FilterBar from 'components/FilterBar/FilterBar';
import Title from 'components/Title';

/** Here we define what kind of props this component takes */
interface LocationsMapProps extends WithStyles<typeof styles>, WithToastStore {
  center?: Coordinates;
  zoom?: number;
}

const initialFilters: LocationsFilterDrawer_1.LocationFilters = { proximity: {} };
@inject('toastStore')
@observer
class LocationsMap extends React.Component<LocationsMapProps> {
  constructor(props: LocationsMapProps) {
    super(props);
    makeObservable(this);
  }

  private usaLocation = {
    center: {
      lat: 37.0902405,
      lng: -95.7128906,
    },
    zoom: 4.75,
  };

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** Whether the filters are shown */
  @observable public showFilterDrawer = false;

  @observable locations: Location[] = [];

  /** The current filters */
  @observable public filters: LocationsFilterDrawer_1.LocationFilters = initialFilters;

  /** Whether some filters have been modified */
  @computed public get filtersModified() {
    return Boolean(
      this.filters.account || this.filters.proximity.radius || this.filters.proximity.zip,
    );
  }

  /** Filter locations */
  @computed public get filteredLocations() {
    const listAccounts: number[] = [];
    if (has(this.activeFilters, 'accountName')) {
      listAccounts.push(Number(this.activeFilters['accountName']));
    } else if (has(this.activeFilters, 'accountNames')) {
      listAccounts.push(
        ...(this.activeFilters['accountNames'] as string[]).map((it) => Number(it)),
      );
    }
    const listAccountsLength = !!listAccounts.length;

    if (this.filters.account || Object.keys(this.activeFilters).length > 0) {
      const res = this.locations.filter((location: Location) => {
        if (listAccountsLength && location.account) {
          return listAccounts.includes(location.account.id);
        } else {
          const account = location.account ? location.account.name : '';
          const filtersAccount = this.filters.account ? this.filters.account.name : '';
          return account === filtersAccount;
        }
      });

      return res;
    }

    return this.locations;
  }

  @action.bound public fetchLocations = flow(function* (this: LocationsMap) {
    // const { zip, radius } = this.filters.proximity;
    const { proximity }: any = this.activeFilters;

    try {
      // Check proximity filters to see if we should get locations by proximity ...
      if (proximity) {
        const resp = yield Api.core.getLocationsByProximity(proximity.zip, proximity.radius);
        this.locations = resp.data.data;
      } else {
        // ... and if no proximity filter is set get all the locations
        const resp = yield Api.core.getLocationsMap();
        this.locations = resp.data.data;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Shows the filters drawer */
  @action.bound public showFilters() {
    this.showFilterDrawer = true;
  }

  /** Hides the filters drawer */
  @action.bound public hideFilters() {
    this.showFilterDrawer = false;
  }

  componentDidMount() {
    this.fetchLocations();
  }

  @action.bound public updateFilters(filters: LocationsFilterDrawer_1.LocationFilters) {
    this.filters = toJS(filters);
    this.hideFilters();
    this.fetchLocations();
  }

  @action.bound public resetFilters() {
    this.filters = toJS(initialFilters);
    this.hideFilters();
    this.fetchLocations();
  }

  private searchAccounts = async (query: string) => {
    const { data } = await Api.core.searchAccountsNameOrCode({ filters: { name: query } });
    const filteredData = data.data.map(
      (location: { id: string; name: string; address: string; [key: string]: string }) => {
        return { id: location.id, name: location.name, address: location.address };
      },
    );
    return filteredData;
  };

  @computed public get renderLocations() {
    return (
      this.filteredLocations &&
      this.filteredLocations.map(
        (loc) =>
          loc.lat &&
          loc.long && <LocationMarker key={loc.id} lat={+loc.lat} lng={+loc.long} location={loc} />,
      )
    );
  }

  /** List of available filters for FilterBar component */
  filterOptions: Filter[] = [
    {
      display: 'Accounts',
      id: 'accountName',
      label: 'Contains',
      type: 'tags',
      options: {
        fetch: this.searchAccounts,
        displayField: {
          value: 'name',
          additional: {
            value: 'address',
          },
          keySearch: { name: 'id' },
        },
      },
    },
    {
      display: 'Proximity',
      id: 'proximity',
      label: 'By Proximity',
      type: 'fields',
      fields: [
        { id: 'zip', label: 'Zip Code', type: 'text' },
        { id: 'radius', label: 'Miles', type: 'text' },
      ],
    },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    const res: Record<string, any> = {};
    if (filters.zip && filters.radius) {
      res.proximity = {
        zip: filters.zip,
        radius: filters.radius,
      };
    }
    if (filters.accountName) {
      res.accountName = filters.accountName;
    }

    this.activeFilters = res;
    this.filtersInitReady = true;
  }

  render() {
    const { classes } = this.props;
    return (
      <DashboardLayout>
        <Box className={classes.titleContainer}>
          <Title mb={3}>Locations</Title>
        </Box>
        <Box pb={10}>
          <FilterBar
            filters={this.filterOptions}
            onChange={this.handleFiltersOnChange}
            locationMap={{ isActive: true }}
          />
          {this.filtersInitReady && (
            <GoogleMap
              width="100%"
              height={800}
              center={this.usaLocation.center}
              zoom={this.usaLocation.zoom}>
              {this.renderLocations}
            </GoogleMap>
          )}
        </Box>

        <Drawer
          open={this.showFilterDrawer}
          onClose={this.hideFilters}
          anchor="right"
          variant="temporary">
          <LocationsFilterDrawer
            onClose={this.hideFilters}
            onReset={this.resetFilters}
            filters={toJS(this.filters)}
            showReset={this.filtersModified}
            onChange={this.updateFilters}
          />
        </Drawer>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(LocationsMap);
