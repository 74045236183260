import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Drawer,
  Typography,
  DialogContentText,
} from '@material-ui/core';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import FilterBar from 'components/FilterBar';
import Overlay from 'components/Overlay';
import PaymentDetailsDrawer from 'components/PaymentDetailsDrawer';
import React, { useState } from 'react';
import { IActionDialog, useRefundRequestsTable } from './useRefundRequestsTable';
import { useLocation } from 'react-router-dom';
import Button from 'components/Button/Dialog/Button';
import { useStyles } from './styles';
import OutlinedInput from 'components/Input/OutlinedInput';

export function RefundRequestsTable() {
  const { pathname } = useLocation();

  const [
    paymentReference,
    actionDialog,
    updateRejectionReason,
    getColumns,
    getFilters,
    activeFilters,
    dateRangeLocalStoragePath,
    getRefundRequests,
    setActiveFilters,
    closeDetails,
  ] = useRefundRequestsTable();

  const [filtersInitReady, setFiltersInitReady] = useState(false);

  return (
    <Box>
      <FilterBar
        filters={getFilters()}
        onChange={(filters) => {
          setActiveFilters(filters);
          setFiltersInitReady(true);
        }}
        dateRangeLocalStoragePath={dateRangeLocalStoragePath}
        showDateRange
      />
      {filtersInitReady && (
        <DataGridInfiniteScroll
          fetch={getRefundRequests}
          columns={getColumns()}
          refetchKey={activeFilters}
          pathname={pathname}
        />
      )}
      <RefundActionDialog
        actionDialog={actionDialog}
        updateRejectionReason={updateRejectionReason}
      />
      <DetailsDrawer closeDetails={closeDetails} paymentReference={paymentReference} />
    </Box>
  );
}

function RefundActionDialog({
  actionDialog,
  updateRejectionReason,
}: {
  actionDialog?: IActionDialog;
  updateRejectionReason: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { dialogActions, dialogContent, dialogTitle, ...classes } = useStyles();
  return (
    <Dialog open={actionDialog ? true : false} className={classes.dialog}>
      {actionDialog && (
        <>
          <Overlay transparent display={actionDialog.loading}>
            <CircularProgress color="primary" />
          </Overlay>
          <DialogTitle className={dialogTitle} disableTypography>
            <Typography align="left" style={{ fontSize: 28 }}>
              {actionDialog.title}{' '}
            </Typography>
          </DialogTitle>
          <DialogContent className={dialogContent}>
            <DialogContentText>{actionDialog.message}</DialogContentText>
            {actionDialog.type === 'reject' && (
              <Box mt={3}>
                <form>
                  <OutlinedInput
                    label={'Reason'}
                    fullWidth
                    value={actionDialog.rejectionReason || ''}
                    onChange={updateRejectionReason}
                  />
                </form>
              </Box>
            )}
          </DialogContent>
          <DialogActions className={dialogActions}>
            <Button
              variant="outlined"
              onClick={actionDialog.cancel}
              data-cy="cancel-custom-address">
              Cancel
            </Button>
            <Button
              disabled={confirmButtonDisabled(actionDialog)}
              variant="contained"
              onClick={() => actionDialog.action(actionDialog)}
              data-cy="submit-custom-address">
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

function confirmButtonDisabled(actionDialog: IActionDialog): boolean {
  if (!actionDialog.rejectionReason && actionDialog.type === 'reject') {
    return true;
  }
  return false;
}

interface IDetailsDrawer {
  closeDetails: () => void;
  paymentReference?: string;
}
function DetailsDrawer({ closeDetails, paymentReference }: IDetailsDrawer) {
  return (
    <Drawer open={Boolean(paymentReference)} anchor="right" variant="temporary">
      <PaymentDetailsDrawer
        onClose={closeDetails}
        paymentReference={paymentReference}
        linkTipCount={true}
        hideActions={true}
      />
    </Drawer>
  );
}
