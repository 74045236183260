import Dialog from 'components/Dialog/Dialog';
import { ILicenseBillingGeneralDialogProps } from '../models';
import * as models from 'models';
import useManageLicenseBillingGroupDialog from './useManageLicenseBillingGroupDialog';
import { Autocomplete, Box, Tooltip, Typography } from '@mui/material';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import CreditCardIcon from 'components/CreditCardIcon';
import manageLicenseBillingGroupDialogStyles from './styles';
import { Checkbox } from 'components/Checkbox/Checkbox';

export interface IManageLicenseBillingGroupDialog extends ILicenseBillingGeneralDialogProps {
  accountId: number;
  licenses: models.License[];
  billingEntity: models.IBillingEntity | null;
  paymentMethods: models.PaymentMethod[];
  setBillingEntity: React.Dispatch<React.SetStateAction<models.IBillingEntity | null>>;
  getBillingEntities: VoidFunction;
  getLicensesForAccount: VoidFunction;
}

const getBillingGroupName = (license: models.License): string => {
  const name = license?.billingEntity?.name ?? '';
  const code = license?.billingEntity?.code ?? 'N/A';

  return name ? `${name} (${code})` : code;
};

const ManageLicenseBillingGroupDialog = (props: IManageLicenseBillingGroupDialog) => {
  const { paymentMethod, creditCardIcon } = manageLicenseBillingGroupDialogStyles;
  const { billingEntity, licenses } = props;

  const {
    isPaymentMethodStep,
    selectedPaymentMethod,
    selectedPaymentMethodLabel,
    dialogProps,
    name,
    options,
    handleChangeName,
    handleUpdateLicense,
    handleChangePaymentMethod,
  } = useManageLicenseBillingGroupDialog(props);

  return (
    <Dialog
      {...dialogProps}
      content={
        <Box display={'flex'} position={'relative'} flexDirection={'column'} gap={2}>
          {isPaymentMethodStep ? (
            <>
              <OutlinedInput
                fullWidth
                label={'Name (Optional)'}
                name="name"
                value={name}
                onChange={handleChangeName}
              />

              <Autocomplete
                onChange={(e, option) => handleChangePaymentMethod(option!.id)}
                disableClearable
                renderInput={(params) => (
                  <OutlinedInput {...params} label="Payment Method" name="paymentMethod" />
                )}
                value={selectedPaymentMethod || ({ label: '' } as any)}
                options={options}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                renderOption={(props, option) => (
                  <Box {...props} component={'li'} sx={paymentMethod}>
                    <Box display={'flex'} alignItems={'center'} gap={2}>
                      <CreditCardIcon style={creditCardIcon} brand={option.brand} />
                      <Typography variant="body1">{option.label}</Typography>
                    </Box>
                    <Typography component={'span'} ml={'auto'} variant="subtitle1">
                      {option.expirationText}
                    </Typography>
                  </Box>
                )}
              />
            </>
          ) : (
            <>
              <Typography variant={'body2'}>
                Update licenses that will be billed with&nbsp;
                <Typography component={'span'} variant="body2" fontWeight={700}>
                  {selectedPaymentMethodLabel}
                </Typography>
              </Typography>
              {licenses
                ?.filter((license) => license.isBillable)
                .map((license) => {
                  const canTransfer = license.canTransfer;
                  const checked = !!billingEntity?.licenses?.find(
                    (_license) => _license.id === license.id,
                  );
                  const disabled = !canTransfer || checked;
                  const billingGroupName = getBillingGroupName(license);

                  const { name, displayName } = license.location;

                  let tooltip = '';
                  if (disabled) {
                    tooltip = !canTransfer
                      ? 'Moving this license is disabled due to negative balance'
                      : 'Licenses can only be moved to another license. Clicking on "checked" checkbox would cause this license to be without a billing group. If you wish to move this license to another billing group, go to "update" option for billing group to which you would like to move this license and do the action there.';
                  }

                  return (
                    <Box key={license.id} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title={tooltip}>
                        <Box component={'span'}>
                          <Checkbox
                            value={license.id}
                            disablePadding
                            name={'licenseId'}
                            disabled={disabled}
                            checked={checked}
                            onChange={handleUpdateLicense}
                          />
                        </Box>
                      </Tooltip>

                      <Box ml={2}>
                        <Typography p={0}>
                          {name}
                          {displayName && (
                            <Tooltip title={`Location's display name`}>
                              <Typography component={'span'} variant="body2">
                                {' '}
                                | {displayName}
                              </Typography>
                            </Tooltip>
                          )}
                        </Typography>
                        <Typography p={0} variant="subtitle1">
                          {license.product.name || 'Product N/A'}
                        </Typography>

                        <Typography component={'span'} variant="subtitle1">
                          Billing group: {billingGroupName}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </>
          )}
        </Box>
      }
    />
  );
};

export default ManageLicenseBillingGroupDialog;
