import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import LocationSearch from 'components/LocationSearch';
import AccountSearch from 'components/AccountSearch';
import { observer } from 'mobx-react';
import { inject, WithUserStore, WithToastStore, ScopeType, UserScopes } from 'stores';
import DP from 'components/DashPanel';
import styles from '../styles';
import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import * as models from 'models';
import { ReportType } from '../Reports';
import { downloadCsvFile } from 'utils/helper';
import Api, { getErrorMsg, RequestMetaData } from 'api';
import { AxiosResponse } from 'axios';
import DateRangePicker, * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Dialog/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface IDownloadCSVReport {
  title: string;
  scope?: ScopeType;
  reportType?: ReportType;
  onClose: () => void;
  accountFilter?: boolean;
  locationFilter?: boolean;
  hardwareTypeFilter?: boolean;
}

type DownloadCSVReportProps = IDownloadCSVReport &
  WithStyles<typeof styles> &
  WithUserStore &
  WithToastStore;

@inject('userStore', 'toastStore')
@observer
class DownloadCSVReport extends React.Component<DownloadCSVReportProps> {
  constructor(props: DownloadCSVReportProps) {
    super(props);
    makeObservable(this);
  }
  @observable downloading = false;
  @observable public dateRange = DateRangeExternalPicker.getDateRange(
    DateRangeExternalPicker.EDateRangeType.LAST_MONTH,
  );
  @observable public hardwareType: models.HardwareType | string | null = null;

  @observable public location?: models.Location =
    this.props.scope === UserScopes.MANAGER
      ? this.props.userStore?.currentManagedLocation
      : undefined;
  @computed public get locationId() {
    return this.location ? this.location.id : undefined;
  }

  @observable public accounts?: models.Account[];

  @observable public account?: models.Account;
  @computed public get accountId() {
    return this.account ? this.account.id : undefined;
  }

  @computed private get scope() {
    return this.props.userStore!.scope.kind;
  }

  @action.bound public updateDateRange(range: DateRangeExternalPicker.IDateRange) {
    this.dateRange = range;
  }

  @action.bound private setAccount(account: models.Account | null) {
    this.location = undefined;
    if (account) this.account = account;
  }

  @action.bound private setLocation(location: models.Location | null) {
    if (location) this.location = location;
  }

  @action.bound private setHardwareType(hardwareType: models.HardwareType | string | null) {
    this.hardwareType = hardwareType;
  }

  @action.bound private init() {
    const userStore = this.props.userStore!;
    if (this.scope === models.UserTypes.OWNER) {
      this.account = userStore.currentAccount;
    }
    if (this.scope === models.UserTypes.MANAGER) {
      this.location = userStore.currentManagedLocation!;
    }
  }

  @action.bound addSelectedAccount() {
    if (this.accounts && this.account) {
      const accountAlreadyAdded = this.accounts.find(
        (account: models.Account) => this.account?.id === account.id,
      );
      if (!accountAlreadyAdded) this.accounts = [...this.accounts, this.account];
    } else {
      if (this.account) this.accounts = [this.account];
    }
    this.account = undefined;
  }

  @action.bound downloadCSV = async (
    request: (rmd: RequestMetaData, extraData?: any) => any,
    name: string,
  ) => {
    try {
      this.downloading = true;
      const locationId = this.location && this.location.id;
      const accountIds = this.accounts && this.getAccountIds();
      const accountId = this.account && this.account.id;
      const hardwareType = this.hardwareType && this.hardwareType;
      const rmd = {
        filters: {
          fromDate: this.dateRange && this.dateRange.fromDate,
          toDate: this.dateRange && this.dateRange.toDate,
        },
      };
      const extraData = {
        locationId,
        accountId,
        accountIds,
        hardwareType,
      };
      const resp: AxiosResponse<Blob> = await request(rmd, extraData);
      if (resp && resp.data) {
        downloadCsvFile(resp.data, name);
      }
    } catch (e) {
      this.props.toastStore!.push({
        message: getErrorMsg(e), //'Unable to download requested report',
        type: 'error',
      });
    } finally {
      this.downloading = false;
      this.props.onClose();
    }
  };

  getAccountIds() {
    if (this.accounts && this.accounts.length > 1) {
      return this.accounts.map((account: models.Account) => account.id);
    }
    if (this.accounts?.length === 1) {
      this.account = this.accounts[0];
    }
    return undefined;
  }

  reportName = (name: string) => {
    const from = this.dateRange && this.dateRange.fromDate ? ` ${this.dateRange.fromDate}` : '';
    const to = this.dateRange && this.dateRange.toDate ? ` to ${this.dateRange.toDate}` : '';
    return `${name} ${from}${to}.csv`;
  };

  handleDownloadCSV = async () => {
    switch (this.props.reportType) {
      case ReportType.PAYROLL_RUN_REPORT: {
        const reportName = this.reportName('payroll run report');
        await this.downloadCSV(Api.analytics.reports.payrollRun, reportName);
        break;
      }
      case ReportType.TIPPY_SALON_REPORT: {
        const reportName = this.reportName('salon list');
        await this.downloadCSV(Api.analytics.reports.tippySalonList, reportName);
        break;
      }
      case ReportType.REBATE_REPORT: {
        const reportName = this.reportName('rebate report');
        await this.downloadCSV(Api.analytics.ledger.downloadRebateReportCSV, reportName);
        break;
      }
      case ReportType.DEVICES_INVENTORY: {
        const reportName = this.reportName('inventory report');
        await this.downloadCSV(Api.analytics.reports.devicesInventoryList, reportName);
        break;
      }
    }
  };

  closeDownloadReport = () => {
    this.props.onClose();
  };

  filterLocations = () => {
    return this.props.userStore!.managedLocations.filter((location) => {
      return this.location?.id !== location.id;
    });
  };

  componentDidMount() {
    this.init();
  }

  renderSelectedAccounts() {
    if (!this.accounts) return;
    return this.accounts.map((account: models.Account) => (
      <Chip
        key={account.id}
        color="primary"
        style={{ marginLeft: '1px', marginRight: '1px', marginTop: '2px' }}
        label={account.name}
        onDelete={() => {
          this.accounts = this.accounts?.filter(
            (_account: models.Account) => _account.id !== account.id,
          );
        }}
      />
    ));
  }

  renderAccountOption = (account: models.Account) => {
    return (
      <Box flexDirection="column">
        <Box component="span">{account.name}</Box>
        <Box component="span" className={this.props.classes.address}>
          {account.address}
        </Box>
      </Box>
    );
  };

  renderAccountFilter() {
    const reportType = this.props.reportType;
    const isAdmin = this.props.userStore?.scope.kind === 'admin';
    let renderAccountOption;
    let selectedAccounts;
    let clearSelection;
    let fetch;
    if (reportType === ReportType.REBATE_REPORT && !isAdmin) {
      clearSelection = {
        onClear: () => this.addSelectedAccount(),
        clearDelay: 500,
      };
      fetch = (search: any) => Api.core.getAccounts({ filters: { name: search } });
      renderAccountOption = this.renderAccountOption;
      selectedAccounts = this.accounts;
    }
    return (
      <AccountSearch
        placeholder="Account name"
        onChange={this.setAccount}
        renderOption={renderAccountOption}
        selectedAccounts={selectedAccounts}
        clearSelection={clearSelection}
        fetch={fetch}
      />
    );
  }

  renderLocationFilter() {
    if (this.props.scope === UserScopes.MANAGER) {
      return (
        <Autocomplete
          options={this.props.userStore!.managedLocations}
          value={this.location}
          getOptionLabel={(option) => option.name}
          renderOption={(location: models.Location) => (
            <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
              <Box>{location.name}</Box>
              <Box>{location.code}</Box>
            </Box>
          )}
          filterOptions={this.filterLocations}
          onChange={(e, location) => this.setLocation(location)}
          renderInput={(params) => <OutlinedInput {...params} fullWidth name="Location name" />}
        />
      );
    }
    return (
      <LocationSearch
        value={this.props.userStore?.currentManagedLocation}
        placeholder="Location name"
        accountId={this.accountId}
        onChange={this.setLocation}
      />
    );
  }

  renderHardwareTypeFilter() {
    return (
      <OutlinedInput
        label={'Hardware type'}
        value={this.hardwareType}
        fullWidth
        onChange={(e) => this.setHardwareType(String(e.target.value))}
        select>
        {Object.values(models.HardwareType).map((value, index) => {
          return (
            <MenuItem value={value} key={index}>
              {value}
            </MenuItem>
          );
        })}
      </OutlinedInput>
    );
  }

  render() {
    const { title, reportType, classes, accountFilter, locationFilter, hardwareTypeFilter } =
      this.props;
    return (
      <Dialog
        PaperProps={{ elevation: 0 }}
        open={reportType ? true : false}
        onClose={this.closeDownloadReport}
        fullWidth
        maxWidth={'sm'}
        className={classes.payrollRunDialog}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4" component="h1" display="inline">
              {title}{' '}
            </Typography>
            <Box width="40px" ml="auto" display="flex" flexDirection="row" alignItems="center">
              {this.downloading && <DP.LoadSpinner />}
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb={2} className={classes.dateRangeField} width={100}>
            <DateRangePicker
              predefinedRange={this.dateRange}
              onChange={this.updateDateRange}
              isFormField
              position="right"
            />
          </Box>
          {accountFilter && <Box mb={2}>{this.renderAccountFilter()}</Box>}
          {this.accounts && (
            <Box style={{ display: 'flex', flexWrap: 'wrap' }}>{this.renderSelectedAccounts()}</Box>
          )}
          {locationFilter && <Box mb={2}>{this.renderLocationFilter()}</Box>}
          {hardwareTypeFilter && <Box mb={3}>{this.renderHardwareTypeFilter()}</Box>}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant={'outlined'} onClick={this.closeDownloadReport}>
            Cancel
          </Button>
          <Button
            variant={'contained'}
            color="primary"
            onClick={this.handleDownloadCSV}
            disabled={this.downloading}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default withStyles(styles)(DownloadCSVReport);
