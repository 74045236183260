import React from 'react';
import { action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import TalentApprovalPanel from 'components/TalentApprovalPanel';

// api import for tips report email and download for QA testing:
import Api from 'api';

import { inject, WithUserStore, WithModalStore, WithToastStore } from 'stores';

import styles from './styles';
import { RouteComponentProps } from 'react-router-dom';
import ReferralPanel from 'components/ReferralPanel';

type ManagerHomeProps = WithStyles<typeof styles> &
  WithUserStore &
  WithModalStore &
  WithToastStore &
  RouteComponentProps;

/**
 * The manager dashboard, displayed when they log in.
 */
@inject('userStore', 'modalStore', 'toastStore')
@observer
class ManagerHome extends React.Component<ManagerHomeProps> {
  constructor(props: ManagerHomeProps) {
    super(props);
    makeObservable(this);
  }
  @computed public get locationId(): number | undefined {
    return this.props.userStore!.currentManagedLocation?.id;
  }

  @action.bound public fetchPendingApproval = flow(function* (this: ManagerHome) {
    if (!this.locationId) {
      return [];
    }
    const resp = yield Api.core.getLocationTalent(this.locationId, {
      filters: { locationUserStatus: 'pending_approval' },
      pagination: { take: 1000, skip: 0 },
    });
    return resp.data.data;
  });

  @action.bound public fetchRejected = flow(function* (this: ManagerHome) {
    if (!this.locationId) {
      return [];
    }
    const resp = yield Api.core.getLocationTalent(this.locationId, {
      filters: { locationUserStatus: 'rejected' },
      pagination: { take: 1000, skip: 0 },
    });
    return resp.data.data;
  });

  @action.bound public approveUser(userId: number, locationId: number) {
    return Api.core.updateLocationUser(locationId, { userId, status: 'active' });
  }

  @action.bound public rejectUser(userId: number, locationId: number) {
    return Api.core.updateLocationUser(locationId, { userId, status: 'rejected' });
  }

  @action.bound public undoApproveOrReject(userId: number, locationId: number) {
    return Api.core.updateLocationUser(locationId, { userId, status: 'pending_approval' });
  }

  @action.bound public approveAll() {
    if (!this.locationId) {
      return Promise.resolve();
    }
    return Api.core.approveAll.onLocation(this.locationId);
  }

  render() {
    const currentLocationName =
      this.props.userStore!.currentManagedLocation &&
      this.props.userStore!.currentManagedLocation.name;
    return (
      <>
        {this.locationId && (
          <TalentApprovalPanel
            fetch={this.fetchPendingApproval}
            fetchRejected={this.fetchRejected}
            approveAll={this.approveAll}
            approve={this.approveUser}
            reject={this.rejectUser}
            undo={this.undoApproveOrReject}
            title={`Waiting for approval to join ${currentLocationName}`}
          />
        )}
        {/* Referral Panel (LD flags) */}
        <ReferralPanel />
      </>
    );
  }
}

export default withStyles(styles)(ManagerHome);
