/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { observable, computed, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import { Box, Link } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithToastStore } from 'stores';

import { paths } from 'routes';

import { Parser } from 'json2csv';
import { AxiosResponse } from 'axios';
import Api, { ApiResponse, RequestMetaData } from 'api';
import { downloadCsvFile } from '../../../utils/helper';

import { states, adaptForDataGridPro, usdToNumericString, setTitle } from 'services';

import * as models from 'models';

import styles from '../styles';

import _ from 'lodash';
import Chart from 'components/Chart';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import FilterBar from 'components/FilterBar';

const PAGE_TITLE = 'Revenue';

const SETTING_STORE_KEY = 'revenue/state';

type StatesRevenueProps = WithStyles<typeof styles> & RouteComponentProps & WithToastStore;

/**
 * Container that displays overall Tippy revenue data for a selected date
 * range using a chart and two tables, each under its own tab (states, locations).
 * Chart shows overall Tippy revenue. States table is a list of all states that
 * have any revenue for the selected date range. Locations table is a list of
 * all registered Tippy salons and their respective revenue values.
 */
@inject('toastStore')
@observer
class StatesRevenue extends React.Component<StatesRevenueProps> {
  constructor(props: StatesRevenueProps) {
    super(props);
    makeObservable(this);
  }

  @observable private chartActive = false;

  /** Chart data */
  @observable private chartData?: models.ChartData;

  /** State revenues passed to datagrid as data prop */
  @observable private revenuesByStates?: models.StateRevenue[] = [];

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @observable private isMobile = window.matchMedia('(max-width:680px)').matches;

  @action.bound private annotateRevenues = (revenues: any) => {
    return {
      id: uuidv4(),
      state: states[revenues.label],
      label: revenues.label,
      gross: usdToNumericString(revenues.gross),
      net: usdToNumericString(revenues.net),
      shipping: usdToNumericString(revenues.shipping),
      subscription: usdToNumericString(revenues.subscription),
    };
  };

  @action.bound public fetchRevenues = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const data = await Api.analytics.getRevenues('state', {
      ...rmd,
      filters: { ...this.activeFilters },
    });

    this.revenuesByStates = data.data.data;
    return data;
  }, this.annotateRevenues);

  /** Fetch aggregated data for tip analytics chart */
  @action.bound public fetchChartData = flow(function* (this: StatesRevenue) {
    this.chartData = undefined;
    const extraReqData = { ...this.activeFilters };
    const resp: AxiosResponse<ApiResponse<models.ChartData>> =
      yield Api.analytics.getRevenuesChartData(extraReqData);
    if (resp?.data?.data) {
      this.chartData = resp.data.data;
    }
  });

  @action.bound private onChartActive() {
    this.chartActive = !this.chartActive;
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
    window.matchMedia('(max-width:680px)').addEventListener('change', (e) => {
      this.isMobile = e.matches;
    });
  }

  getCountryCode = (filters: Record<any, any>) => {
    if (filters.state) {
      const selectedState = _.startCase(_.toLower(filters.state as string));
      return Object.keys(states).find((key) => {
        if (states[key] === selectedState) return key;
      });
    }
  };

  /** Render state row items as links to state specific revenue */
  renderCellState = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.analytics().stateRevenue(row.label)}>
        {row.state}
      </Link>
    );
  };

  /** Download CSV report */
  @action downloadCsvReport = async () => {
    try {
      const csvFields = [
        { label: 'State', value: 'label' },
        { label: 'Gross', value: 'gross' },
        { label: 'Net', value: 'net' },
        { label: 'Subscription', value: 'subscription' },
        { label: 'Shipping', value: 'shipping' },
      ];
      const parser = new Parser({ fields: csvFields });
      //@ts-ignore
      let csv = parser.parse(this.revenuesByStates);
      const text = this.activeFilters.fromDate
        ? `from ${this.activeFilters.fromDate} to ${this.activeFilters.toDate}`
        : '';
      csv = `Revenue by state report ${text} \n\n${csv}`;
      downloadCsvFile(csv, 'revenue_by_state.csv');
      return;
    } catch (error: any) {
      this.props.toastStore!.push({
        message: 'Unable to download requested report',
        type: 'error',
      });
    }
  };

  exportElements = [
    {
      name: 'Download as CSV',
      action: this.downloadCsvReport,
    },
  ];

  columns = [
    {
      headerName: 'State',
      field: 'label',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellState,
    },
    { headerName: 'Gross', field: 'gross', minWidth: 150, flex: 1 },
    { headerName: 'Net', field: 'net', minWidth: 150, flex: 1 },
    {
      headerName: 'Subscription',
      field: 'subscription',
      minWidth: 200,
      flex: 1,
    },
    { headerName: 'Shipping', field: 'shipping', minWidth: 150, flex: 1 },
  ];

  /** List of available filters for FilterBar component */
  filters: models.Filter[] = [{ display: 'State', id: 'state', label: 'Contains', type: 'text' }];

  @computed public get tabs() {
    const pathStates = paths.analytics().statesRevenue();
    const pathLocations = paths.analytics().locationsRevenue();

    return [
      {
        label: 'States',
        component: RouterLink,
        to: pathStates,
        selected: this.props.location.pathname === pathStates,
      },
      {
        label: 'Locations',
        component: RouterLink,
        to: pathLocations,
        selected: this.props.location.pathname === pathLocations,
      },
    ];
  }

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    const countryCode = this.getCountryCode(filters);
    if (countryCode) {
      filters = { ...filters, state: countryCode };
    }
    this.activeFilters = filters;
    this.fetchChartData();
    this.filtersInitReady = true;
  }

  render() {
    return (
      <>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={this.handleFiltersOnChange}
            showDateRange
            dateRangeLocalStoragePath={SETTING_STORE_KEY}
            graph={
              !this.isMobile
                ? {
                    isActive: this.chartActive,
                    onActive: this.onChartActive,
                  }
                : undefined
            }
          />
        </Box>
        {this.chartActive && !this.isMobile && (
          <Chart data={this.chartData} singleYAxis singleYAxisFormat="currency" />
        )}
        <Box mt={3}>
          {this.filtersInitReady && (
            <DataGridInfiniteScroll
              columns={this.columns}
              fetch={this.fetchRevenues}
              refetchKey={this.activeFilters}
              disableColumnMenu
              actions={{
                onExport: this.exportElements,
              }}
              pathname={this.props.location.pathname}
            />
          )}
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(StatesRevenue);
