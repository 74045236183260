import { faChevronLeft, faX } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, Divider, IconButton, Typography } from '@mui/material';
import { rootStore } from 'containers/App/App';
import { observer } from 'mobx-react';

export enum EMobileWrapperDialogStep {
  FILTERS = 'filters',
  ADD_DATE_FILTER = 'add_date_filter',
  DATE_FILTER = 'date_filter',
  ADD_FILTER = 'add_filter',
  FILTER = 'filter',
}

export const MobileViewWrapperDialog = observer(
  ({
    title,
    open,
    back,
    onClose,
    children,
  }: {
    title?: string;
    open: boolean;
    onClose: (e: any) => void;
    back?: () => void;
    children: JSX.Element;
  }) => {
    const mobileView = rootStore.uiStore.mobileView;
    const width = mobileView ? '100vw' : 'auto';
    const height = mobileView ? '100vh' : 'auto';
    const canGoBack = !!back;

    if (!mobileView) return children;

    return (
      <Dialog fullScreen open={open}>
        <Box width={width} height={height} display={'flex'} flexDirection={'column'}>
          <Box p={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'}>
              {canGoBack && (
                <IconButton onClick={back}>
                  <FontAwesomeIcon icon={faChevronLeft} fontSize={16} />
                </IconButton>
              )}
              <Typography style={{ fontSize: 24, fontWeight: 400, marginLeft: canGoBack ? 8 : 0 }}>
                {title}
              </Typography>
            </Box>
            <FontAwesomeIcon icon={faX} onClick={onClose} />
          </Box>
          <Divider />
          <Box flexGrow={1} p={3}>
            <Box sx={{ overflowY: 'auto', height: '100%' }}>{children}</Box>
          </Box>
        </Box>
      </Dialog>
    );
  },
);

export default MobileViewWrapperDialog;
