import React from 'react';
import { observer } from 'mobx-react';
import NotificationItem from '../NotificationItem/NotificationItem';
import { rootStore } from 'containers/App/App';
import { paths } from 'routes';
import { Box, Typography } from '@material-ui/core';
import { editPanelName } from 'types';

function gotoMyAccount() {
  const { notificationStore, routerStore } = rootStore;

  notificationStore!.setForceClose(true);
  notificationStore!.setOpenNotification(false);
  routerStore!.history.push(`${paths.myAccount().profileEditable(editPanelName.PERSONAL_DATA)}`);
}

const MissingDobItem = () => {
  return (
    <NotificationItem
      title={'Missing Date of Birth Info'}
      body={
        <Box>
          <Typography variant="body1">
            {`You need to add your Date of Birth to complete`}
          </Typography>
          <Typography variant="body1">{`your profile`}</Typography>
        </Box>
      }
      buttons={[{ label: 'Add', dataCy: 'complete-profile-button', onClick: gotoMyAccount }]}
    />
  );
};

export default observer(MissingDobItem);
