import { Theme } from '@mui/material';
import { dynamicPadding } from 'utils/commonStyles';

interface IStyleProps {
  theme: Theme;
  mobileView?: boolean;
}

export const verifyTokenStyles = ({ theme, mobileView }: IStyleProps) => {
  return {
    wrapper: {
      height: '100dvh',
      width: '100dvw',
      display: 'flex',
      position: 'absolute',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      ...dynamicPadding,
      backgroundColor: theme.palette.common.white,
    },
    innerWrapper: {
      width: mobileView ? 'auto' : 600,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(3),
    },
  };
};
