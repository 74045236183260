import theme from 'containers/App/theme';
import React from 'react';

export const scrollbarStyles: any = {
  '&::-webkit-scrollbar': {
    display: 'none',
    width: '0 !important',
  },
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
};

export const noUserSelect: any = {
  '-webkit-user-select': 'none' /* Safari */,
  '-ms-user-select': 'none' /* IE 10 and IE 11 */,
  userSelect: 'none',
};

export const dynamicPadding = {
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(6.25),
    paddingRight: theme.spacing(6.25),
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
};

export const hideScrollbar = {
  '&::-webkit-scrollbar': {
    display: 'none',
    width: '0 !important',
  },
  scrollbarWidth: 'none',
} as React.CSSProperties;

export const underline = {
  '&::before': {
    content: '""',
    width: '85%',
    top: 'calc(100% - 2px)',
    height: 1,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
  },
};

export const underlineTextButton = {
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: 2,
    backgroundColor: theme.palette.primary.main,
    top: `calc(100% + 2px)`,
    left: 0,
  },
} as any;
