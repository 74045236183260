import React, { Component } from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import moment from 'moment-timezone';
import { startCase, isEmpty } from 'lodash';

import { Typography, IconButton, Box, Tooltip, Link, Chip } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Eye } from 'mdi-material-ui';

import DashboardLayout from 'containers/DashboardLayout';
import DP from 'components/DashPanel';
import { adaptForDataGridPro } from 'services/datagrid';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import FilterBar from 'components/FilterBar';

import Api, { RequestMetaData } from 'api';

import { inject, WithToastStore } from 'stores';
import { paths } from 'routes';

import { Chunk, Filter, Payout } from '../../models/';

import { numericStringToUsd } from 'services/currency';

import styles from './styles';
interface MatchParams {
  chunkId: string;
  id: string;
}

function annotatePayoutsList(payout: Payout) {
  return {
    ...payout,
    talentName: `${payout.talent.firstName} ${payout.talent.lastName}`,
    processor:
      payout.processor && payout.transactionId
        ? `${startCase(payout.processor)} - ${payout.transactionId.slice(0, 4)}`
        : '',
    amount: payout.amount && numericStringToUsd(payout.amount.toString()),
    status: payout.status.toUpperCase(),
    sentAt: moment(payout.createdAt).tz('America/New_York'),
  };
}

type ChunkDetailsProps = WithStyles<typeof styles> &
  WithToastStore &
  RouteComponentProps<MatchParams>;
@inject('toastStore')
@observer
class ChunkDetails extends Component<ChunkDetailsProps> {
  constructor(props: ChunkDetailsProps) {
    super(props);
    makeObservable(this);
  }
  @observable private chunk: Chunk | undefined = undefined;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** Fetch chunk */
  @action.bound public async fetchChunk() {
    // get initiation id and chunkd id from route params
    const { id, chunkId } = this.props.match.params;
    const resp = await Api.tips.getChunkById(id, chunkId);
    const { data } = resp.data;

    if (!data) {
      this.props.toastStore!.error(`Could not fetch payments`);
    } else {
      this.chunk = data;
    }
  }

  /** Fetches payouts for initiation id */
  @action.bound public fetchPayouts = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const { id, chunkId } = this.props.match.params;

    return await Api.tips.getInitiationPayouts(parseInt(id), {
      ...rmd,
      filters: {
        chunkId: chunkId,
        ...this.activeFilters,
      },
    });
  }, annotatePayoutsList);

  componentDidMount() {
    this.fetchChunk();
  }

  renderTalentCell = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.userDetails(row.talent.id).root()}>
        {row.talent.firstName} {row.talent.lastName}
      </Link>
    );
  };
  renderProcessorCell = ({ row }: any) => (
    <Link component={'a'} href={paths.dwollaTransaction(row.transactionId || '')}>
      {row.processor}
    </Link>
  );
  renderActions = ({ row }: any) => {
    return (
      <>
        <IconButton color="primary" component={RouterLink} to={paths.payoutsTransactions(row.id)}>
          <Tooltip title="See transactions">
            <Eye fontSize="small" />
          </Tooltip>
        </IconButton>
      </>
    );
  };

  renderStatus = ({ value, row }: any) => {
    const { chipCompleted, chipProcessed, chipPending, chipFailed } = this.props.classes;
    const colors: Record<any, any> = {
      COMPLETED: chipCompleted,
      PROCESSED: chipProcessed,
      PENDING: chipPending,
      FAILED: chipFailed,
    };

    return <Chip label={value} style={{ width: '100%' }} classes={{ root: colors[value] }} />;
  };

  gridColumns = [
    {
      headerName: 'Talent',
      field: 'search',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderTalentCell,
    },
    {
      headerName: 'Processor',
      field: 'processor',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderProcessorCell,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: 'Sent A',
      field: 'sentAt',
      minWidth: 200,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('hh:mm A'),
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 180,
      sortable: false,
      renderCell: this.renderStatus,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      minWidth: 120,
      sortable: false,
      renderCell: this.renderActions,
    },
  ];

  filters: Filter[] = [
    {
      display: 'Amount',
      id: 'amount',
      label: 'Contents',
      type: 'range',
      interval: {
        from: { label: 'From Amount', value: 'fromAmount' },
        to: { label: 'To Amount', value: 'toAmount' },
        type: 'number',
      },
    },
  ];

  renderSummaryCard() {
    let cardStatus = '',
      cardDate = '',
      cardProcessorId = '',
      cardAmount = '',
      cardTotal = 0;

    if (this.chunk) {
      cardStatus = this.chunk.status.toUpperCase();
      cardDate = moment(this.chunk.createdAt).format('MMM Do YYYY, h:mm:ss a');
      cardTotal = this.chunk.count;
      cardAmount = numericStringToUsd(this.chunk.amount.toString());
      cardProcessorId = this.chunk.processorId;
    }

    return (
      <Box mt={2} mb={2}>
        <DP>
          <DP.Body>
            <DP.Row>
              <DP.Label>Created on</DP.Label>
              <DP.Value>{cardDate}</DP.Value>
            </DP.Row>
            <DP.Row>
              <DP.Label>Status</DP.Label>
              <DP.Value>{cardStatus}</DP.Value>
            </DP.Row>
            <DP.Row>
              <DP.Label>Amount</DP.Label>
              <DP.Value>{cardAmount}</DP.Value>
            </DP.Row>
            <DP.Row>
              <DP.Label>Count</DP.Label>
              <DP.Value>{cardTotal}</DP.Value>
            </DP.Row>
            <DP.Row>
              <DP.Label>Processor id</DP.Label>
              <DP.Value>{cardProcessorId}</DP.Value>
            </DP.Row>
          </DP.Body>
        </DP>
      </Box>
    );
  }

  renderTable() {
    return (
      <>
        {this.filtersInitReady && (
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchPayouts}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        )}
        <Box mt={1} ml={2}>
          <Typography variant="subtitle2">All times are displayed in EST/DST timezone</Typography>
        </Box>
      </>
    );
  }

  renderProcessorResponse() {
    const processor = this.chunk && this.chunk.processorResponse;
    return (
      <Box mt={3}>
        {processor && (
          <DP>
            <DP.Header>
              <DP.Title>Processor response</DP.Title>
            </DP.Header>
            <DP.Body>
              <DP.Row>
                <DP.Label>Created At</DP.Label>
                <DP.Value>{moment(processor.created).format('MMM Do YYYY, h:mm:ss a')}</DP.Value>
              </DP.Row>
              <DP.Row>
                <DP.Label>Status</DP.Label>
                <DP.Value>
                  {processor.status === String ? processor.status.toUpperCase() : processor.status}
                </DP.Value>
              </DP.Row>
              {!isEmpty(processor.metadata) && (
                <DP.Row>
                  <DP.Label>Metadata</DP.Label>
                  <DP.Value>{JSON.stringify(processor.metadata)}</DP.Value>
                </DP.Row>
              )}
              {!isEmpty(processor.errors) && (
                <>
                  <DP.Row>
                    <DP.Label>Error</DP.Label>
                    <DP.Value>
                      {processor.errors[0].message} [code: {'  '}
                      {processor.errors[0].code} ]
                    </DP.Value>
                  </DP.Row>
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {processor.errors.map((e: any, i: number) => {
                    return (
                      i > 0 && (
                        <DP.Row key={i}>
                          <DP.Label>{''}</DP.Label>
                          <DP.Value>
                            {e.message} [code: {e.code}]
                          </DP.Value>
                        </DP.Row>
                      )
                    );
                  })}
                </>
              )}
            </DP.Body>
          </DP>
        )}
      </Box>
    );
  }

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <DashboardLayout>
        <Typography variant="h4" component="h1" gutterBottom>
          Chunk {this.chunk && this.chunk.id}
        </Typography>
        <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} />
        {this.renderSummaryCard()}
        {this.renderTable()}
        {this.renderProcessorResponse()}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(ChunkDetails);
