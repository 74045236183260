/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';

import { inject, WithToastStore } from 'stores';

import { AxiosResponse } from 'axios';
import Api, { ApiResponse, RequestMetaData } from 'api';
import { adaptForDataGridPro } from 'services';

import * as models from 'models';

import Chart from 'components/Chart';

import { downloadCsvFile } from '../../../utils/helper';

import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import {
  getGlobalOwnerFetch,
  getAdminFetch,
  ETipsReportTab,
  getGlobalOwnerCsvFetch,
  getAdminCsvFetch,
  annotateTipsPerTab,
  getColumnsOptions,
  getFilterOptions,
  TipsReportChildProps,
} from './common';

const SETTING_STORE_KEY = '/tips_report/location';

/** Here we define what kind of props this component takes */
type TipsReportByLocationProps = WithToastStore & RouteComponentProps & TipsReportChildProps;

/**
 * Container for displaying and downloading tip reports.
 * Accessible to admin, owner and manager scope.
 */
@inject('toastStore')
@observer
class TipsReportByLocation extends React.Component<TipsReportByLocationProps> {
  constructor(props: TipsReportByLocationProps) {
    super(props);
    makeObservable(this);
  }

  /** Chart data */
  @observable private chartData?: models.ChartData;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @observable public chartActive = false;

  /** Annotates tip report fields with extra data based on selected tab */
  @action.bound private annotateTips = (tip: models.TipReport) =>
    annotateTipsPerTab(tip, ETipsReportTab.LOCATION);

  @action.bound public fetchTips = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    if (!this.filtersInitReady) {
      return Promise.resolve({ data: [] } as AxiosResponse);
    }

    const locationIds = rmd.filters?.locationId ? rmd.filters.locationId : this.props.locationId;
    const accountIds = rmd.filters?.accountId ? rmd.filters.accountId : this.props.accountId;

    let fetch = this.getFetch();

    return await fetch({
      ...rmd,
      filters: {
        ...this.activeFilters,
        locationIds,
        accountIds,
      },
    });
  }, this.annotateTips);

  getFetch(): (
    rmd?: RequestMetaData,
    extraData?: Record<string, unknown>,
  ) => Promise<AxiosResponse<any, any>> {
    let fetch = Api.analytics.stats.tipsStats.byTalent;
    if (this.props.isGlobalOwner) {
      fetch = getGlobalOwnerFetch(ETipsReportTab.LOCATION);
    } else if (this.props.isOwner) {
      fetch = Api.analytics.stats.tipsStats.byTalent;
    } else if (this.props.isAdmin) {
      fetch = getAdminFetch(ETipsReportTab.LOCATION);
    }
    return fetch;
  }

  /** Fetch aggregated data for tip analytics chart */
  @action.bound public fetchChartData = flow(function* (this: TipsReportByLocation) {
    yield Api.analytics.stats.chartReport
      .getTipsChartData({
        filters: { ...this.activeFilters },
      })
      .then((resp: AxiosResponse<ApiResponse<models.ChartData>>) => {
        this.chartData = resp.data.data;
      });
  });

  initChart() {
    this.chartData = undefined;
    this.fetchChartData();
  }

  /** This endpoint is for locations and accounts report download */
  @action.bound private downloadCsvReport(reportFor: string) {
    const extraReqData = { ...this.activeFilters };

    let reportFunction = Api.analytics.stats.tipsStats.byTalent;
    if (this.props.isGlobalOwner) {
      reportFunction = getGlobalOwnerCsvFetch(ETipsReportTab.LOCATION);
    } else if (this.props.isAdmin) {
      reportFunction = getAdminCsvFetch(ETipsReportTab.LOCATION);
    }

    return async () => {
      try {
        const resp: AxiosResponse<Blob> = await reportFunction({}, extraReqData);
        if (resp && resp.data) {
          downloadCsvFile(resp.data, `${reportFor}_tips_report.csv`);
        }
      } catch (e: any) {
        this.props.toastStore!.push({
          message: 'Unable to download requested report',
          type: 'error',
        });
      }
    };
  }

  @action.bound private async toggleChart() {
    this.chartActive = !this.chartActive;
    this.initChart();
  }

  exportElements = [
    {
      name: 'Download as CSV',
      action: () => {
        this.downloadCsvReport(ETipsReportTab.LOCATION)();
      },
    },
  ];

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.props.isAdmin && this.initChart();
    this.filtersInitReady = true;
  }

  render() {
    const showChart = this.props.isAdmin && !this.props.isMobile;
    return (
      <>
        <FilterBar
          filters={getFilterOptions({
            tab: ETipsReportTab.LOCATION,
            isGlobalOwner: this.props.isGlobalOwner,
            isAdmin: this.props.isAdmin,
          })}
          onChange={this.handleFiltersOnChange}
          showDateRange
          graph={
            showChart
              ? {
                  isActive: this.chartActive,
                  onActive: this.toggleChart,
                }
              : undefined
          }
          dateRangeLocalStoragePath={SETTING_STORE_KEY}
        />
        {showChart && this.chartActive && (
          <Box mt={3}>
            <Chart data={this.chartData} />
          </Box>
        )}
        <Box mt={3}>
          <DataGridInfiniteScroll
            columns={getColumnsOptions({
              tab: ETipsReportTab.LOCATION,
              isAdmin: this.props.isAdmin,
              isOwner: this.props.isOwner,
              isGlobalOwner: this.props.isGlobalOwner,
            })}
            fetch={this.fetchTips}
            refetchKey={this.activeFilters}
            disableColumnMenu
            actions={{
              onExport: this.exportElements,
            }}
            pathname={this.props.location.pathname}
          />
        </Box>
      </>
    );
  }
}

export default TipsReportByLocation;
