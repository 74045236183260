/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, computed, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Box, Typography, Paper } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore, WithToastStore, WithAnalyticsStore } from 'stores';

import { AxiosResponse } from 'axios';
import Api, { ApiResponse, getErrorMsg } from 'api';
import * as api from 'api';

import * as models from 'models';

import DashboardLayout from 'containers/DashboardLayout';

import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';

import Chart from 'components/Chart';

import styles from '../styles';

interface LocationRevenueMatchParams {
  locationId: string;
}

type LocationRevenueProps = WithStyles<typeof styles> &
  RouteComponentProps<LocationRevenueMatchParams> &
  WithUserStore &
  WithAnalyticsStore &
  WithToastStore;

/**
 * Container that displays revenue data for a specific location and selected
 * date range. Chart shows overall revenue for the location. Location id is
 * passed through match params which is then used as a filter on both api
 * fetch calls (table and chart data) along with selected date range.
 */
@inject('userStore', 'analyticsStore')
@observer
class LocationRevenue extends React.Component<LocationRevenueProps> {
  public constructor(props: LocationRevenueProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  @observable public matchParams: LocationRevenueMatchParams;

  @observable public location?: models.Location;

  @computed public get locationId(): number {
    return parseInt(this.matchParams.locationId);
  }

  @computed public get locationName(): string | undefined {
    return this.location && this.location.name;
  }

  /** Chart data */
  @observable private chartData?: models.ChartData;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  @action.bound async fetchLocation(rmd: api.RequestMetaData) {
    const res: any = await Api.core.getLocation(this.locationId, {
      ...rmd,
      filters: {
        ...this.activeFilters,
      },
    });

    return {
      rows: [res.data.data],
      totalElements: [res.data.data].length,
    };
  }

  @action.bound public fetchChartData = flow(function* (this: LocationRevenue) {
    const extraReqData = {
      locationId: this.locationId,
    };
    try {
      const resp: AxiosResponse<ApiResponse<models.ChartData>> =
        yield Api.analytics.getRevenuesChartData(extraReqData);
      if (resp && resp.data && resp.data.data) {
        this.chartData = resp.data.data;
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  gridColumns = [
    { headerName: 'Name', field: 'name', minWidth: 150, flex: 1 },
    { headerName: 'Account', field: 'accountId', minWidth: 150, flex: 1 },
    { headerName: 'Address', field: 'address', minWidth: 150, flex: 1 },
    { headerName: 'City', field: 'city', minWidth: 150, flex: 1 },
    { headerName: 'Code', field: 'code', minWidth: 150, flex: 1 },
    { headerName: 'State', field: 'state', minWidth: 150, flex: 1 },
    { headerName: 'Zip', field: 'zip', minWidth: 150, flex: 1 },
  ];

  filters: models.Filter[] = [
    { display: 'Account', id: 'account', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'location', label: 'Contains', type: 'text' },
  ];

  componentDidMount() {
    this.fetchChartData();
  }

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <DashboardLayout>
        <Typography variant="h3" component="h1" gutterBottom>
          {this.locationName}
        </Typography>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
          />
        </Box>
        <>
          <div style={{ maxWidth: '100%' }}>
            <Chart data={this.chartData} />
            <Box mt={3}>
              <Paper>
                {this.filtersInitReady && (
                  <DataGridInfiniteScroll
                    columns={this.gridColumns}
                    fetch={this.fetchLocation}
                    refetchKey={this.activeFilters}
                    disableColumnMenu
                    pathname={this.props.location.pathname}
                  />
                )}
              </Paper>
            </Box>
          </div>
        </>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(LocationRevenue);
