/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import { Box, Link, Tooltip } from '@material-ui/core';
import { ThumbUpOutline, ThumbDownOutline } from 'mdi-material-ui';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';

import { inject, WithUserStore, WithToastStore, WithAnalyticsStore } from 'stores';

import Api, { RequestMetaData } from 'api';
import { adaptForDataGridPro } from 'services';
import { downloadCsvFile } from '../../utils/helper';
import { paths } from 'routes';
import styles from './styles';
import { v4 as uuidv4 } from 'uuid';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { Filter } from 'models';

/** Here we define what kind of props this component takes */
type UnderperformingTalentProps = WithStyles<typeof styles> & // Adds the classes prop
  WithUserStore &
  WithAnalyticsStore &
  RouteComponentProps &
  WithToastStore; // Adds the userStore prop

/**
 * Container for displaying underperforming talent
 */
@inject('userStore', 'toastStore', 'analyticsStore')
@observer
class UnderperformingTalent extends React.Component<UnderperformingTalentProps> {
  constructor(props: UnderperformingTalentProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private filtersInitReady = false;

  /** Watch for change on table filters and build req meta data */
  @observable private dataGridFilters = {};

  @action.bound private downloadCsvReport = async () => {
    try {
      const res = await Api.analytics.performance.getUnderperformingTalentReport({
        filters: { ...this.dataGridFilters },
      });
      if (res && res.data) {
        downloadCsvFile(res.data, 'talent_performance_report.csv');
      }
    } catch (error: any) {
      this.props.toastStore!.push({
        message: 'Unable to download requested report',
        type: 'error',
      });
    }
  };

  @action.bound private setFilters = (filters: any) => {
    this.dataGridFilters = filters.reduce((data: any, dataGridFilter: any) => {
      return { ...data, [dataGridFilter.key]: dataGridFilter.value };
    }, {});
  };
  /** Render account as links to accounts */
  renderCellAccount = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.accountDetails(row.accountId).root()}>
        {row.accountName}
      </Link>
    );
  };

  /** Render account as links to accounts */
  renderCellLocation = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.locationDetails(row.locationId)}>
        {row.locationName}
      </Link>
    );
  };

  renderCellTalent = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.userDetails(row.userId).root()}>
        {row.firstName} {row.lastName}
      </Link>
    );
  };

  renderCellTip = ({ value }: any) => {
    const isUnderPerformed = value < 2;
    const statusIcon = isUnderPerformed ? (
      <Tooltip title="Less than 2 tips per day">
        <ThumbDownOutline fontSize="small" color="error" />
      </Tooltip>
    ) : (
      <ThumbUpOutline fontSize="small" color="primary" />
    );

    return (
      <span className={this.props.classes.underPerformed}>
        <span className={this.props.classes.underPerformedIcon}>{statusIcon}</span>
        <span>{value}</span>
      </span>
    );
  };

  exportElements = [
    {
      name: 'Download as CSV',
      action: () => {
        this.downloadCsvReport();
      },
    },
  ];

  gridColumns = [
    {
      headerName: 'Talent',
      field: 'talent',
      minWidth: 250,
      flex: 1,
      renderCell: this.renderCellTalent,
    },
    {
      headerName: 'Location',
      field: 'locationName',
      minWidth: 250,
      flex: 1,
      renderCell: this.renderCellLocation,
    },
    {
      headerName: 'Account',
      field: 'accountName',
      minWidth: 250,
      flex: 1,
      renderCell: this.renderCellAccount,
    },
    {
      headerName: 'Tips',
      field: 'tipCount',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellTip,
    },
  ];

  filters: Filter[] = [
    { display: 'Location', id: 'locationName', label: 'Contains', type: 'text' },
    { display: 'Account', id: 'accountName', label: 'Contains', type: 'text' },
  ];

  @action.bound private annotateUnderperformingTalent = (underperformingTalent: any) => {
    return {
      id: uuidv4(),
      ...underperformingTalent,
    };
  };

  @action.bound public fetchUnderperformingTalent = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      return await Api.analytics.performance.getUnderperformingTalent({
        ...rmd,
        filters: {
          ...this.activeFilters,
        },
      });
    },
    this.annotateUnderperformingTalent,
  );

  @action.bound public handleFiltersOnChange(filters: Record<string, unknown>) {
    this.activeFilters = filters;
    this.filtersInitReady = true;
  }

  render() {
    return (
      <Box>
        <Box mt={3}>
          <FilterBar filters={this.filters} onChange={this.handleFiltersOnChange} />
        </Box>
        {this.filtersInitReady && (
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchUnderperformingTalent}
            refetchKey={this.activeFilters}
            disableColumnMenu
            actions={{
              onExport: this.exportElements,
            }}
            pathname={this.props.location.pathname}
          />
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(UnderperformingTalent);
