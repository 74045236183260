import React from 'react';
import { observable, flow, action, makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { inject, WithUserStore, WithToastStore } from 'stores';
import { User } from 'models';

import Api, { getErrorMsg } from 'api';
import { Address, editPanelName } from 'types';

import EmailPanel from 'components/EmailPanel/EmailPanel';

import PasswordPanel from 'components/PasswordPanel';
import UserDetailsPanel from 'components/UserDetailsPanel';
import AddressPanel from 'components/AddressPanel';
import ProfilePicturePanel from 'components/ProfilePicturePanel';
import ProfessionPanel from 'components/ProfessionPanel';

import styles from './styles';
import PhoneNumberPanel from 'components/PhoneNumberPanel/PhoneNumberPanel';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'qs';

interface ProfileProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithToastStore,
    RouteComponentProps {
  user?: User;
}

/** Displays the workspace settings for the user with :userId */
@inject('userStore', 'toastStore')
@observer
class Profile extends React.Component<ProfileProps> {
  constructor(props: ProfileProps) {
    super(props);
    this.reactions = [];
    makeObservable(this);
  }
  // Address panel API update call
  @action.bound public updateUser = flow(function* (this: Profile, addr: Address) {
    try {
      yield Api.core.updateUser(this.props.userStore!.authUser.id, addr);
      this.props.toastStore!.success('Address updated!');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @observable hasLocationUser = this.props.userStore!.locationUsers.length;

  @observable editablePanel = '';

  /** The editable psnel as captured by the URL params */
  @computed public get editablePanelFromQs(): string {
    // Get the editable panle  from the query string
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    // Return the editable panel  as a string
    return query?.editablePanel as string;
  }

  // Controlled field props for UserDetailsPanel
  private personalDataFields: { name: keyof User; editable: boolean }[] = [
    { name: 'firstName', editable: true },
    { name: 'lastName', editable: true },
    { name: 'nickname', editable: true },
    { name: 'dob', editable: true },
  ];

  removeEditablePanelParam = () => {
    // Remove the 'editable panel' parameter from the URL
    const { history, location } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    delete query.editablePanel;
    const newSearch = qs.stringify(query, { addQueryPrefix: true });
    history.replace({ ...location, search: newSearch });
  };

  checkEditablePanelParam = () => {
    this.editablePanel = this.editablePanelFromQs;
    this.removeEditablePanelParam();
  };

  disableEditablePanel = () => {
    this.editablePanel = '';
  };

  // /** Mobx reactions */
  @observable private reactions: (() => void)[];

  async componentDidUpdate() {
    if (this.editablePanelFromQs) {
      this.checkEditablePanelParam();
    }
  }

  async componentDidMount() {
    this.checkEditablePanelParam();
  }

  render() {
    return (
      <Grid container direction={'row'} spacing={3}>
        <Grid item xs={12} sm={12} md={3}>
          <Grid container direction={'column'} spacing={3}>
            <Grid item sm={12}>
              <EmailPanel />
            </Grid>
            <Grid item sm={12}>
              <PhoneNumberPanel />
            </Grid>
            <Grid item sm={12}>
              <PasswordPanel />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Grid container direction={'column'} spacing={3}>
            <Grid item sm={12}>
              <UserDetailsPanel
                editOnMount={this.editablePanel === editPanelName.PERSONAL_DATA}
                disableEditablePanel={this.disableEditablePanel}
                title="Personal Data"
                editable={true}
                fields={this.personalDataFields}>
                {this.props.userStore!.user}
              </UserDetailsPanel>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Grid container direction={'column'} spacing={3}>
            <Grid item sm={12}>
              <AddressPanel onUpdateAddress={this.updateUser}>
                {this.props.userStore!.user}
              </AddressPanel>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Grid container direction={'column'} spacing={3}>
            <Grid item sm={12}>
              <ProfilePicturePanel>{this.props.userStore!.user}</ProfilePicturePanel>
            </Grid>
            {this.hasLocationUser ? (
              <Grid item sm={12}>
                <ProfessionPanel>{this.props.userStore!.user}</ProfessionPanel>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Profile);
