import React from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import Api, { RequestMetaData, getErrorMsg } from 'api';
import { paths } from 'routes';
import moment from 'moment-timezone';

import * as models from 'models';

import theme from 'containers/App/theme';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
} from '@material-ui/core';

import { Tooltip, Link } from '@material-ui/core';
import { Cash, CreditCard, CallSplit, CreditCardRefund, SwapVertical } from 'mdi-material-ui';

import { inject, WithUserStore, WithModalStore, WithToastStore } from 'stores';

import DashboardLayout from 'containers/DashboardLayout';
import PaymentDetailsDrawer from 'components/PaymentDetailsDrawer';

import RefundForm from './RefundForm';
import { adaptForDataGridPro, numericStringToUsd, usdToNumericString } from 'services';
import validatorjs from 'validatorjs';
import DP from 'components/DashPanel';

import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import Title from 'components/Title/Title';
import Button from 'components/Button/Dialog/Button';
import styles from './styles';
import { isValueInArray } from 'utils/helper';
import { ACL } from 'types';
import OutlinedInput from 'components/Input/OutlinedInput';
import { MAX_TRANSFER_REQUEST_REASON_LENGTH } from 'containers/Transfers/useTransferDialogs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutocompleteSelect from 'components/UsersAutocomplete';
import {
  faEye,
  faMoneyBillTransfer,
  faMoneyCheckDollarPen,
} from '@fortawesome/pro-regular-svg-icons';

enum MODAL_TYPE {
  REFUND = 'refund',
  TRANSFER = 'transfer',
}

/** Mobx form */
type MobxForm = any;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
  }),
};

interface FormHooks {
  onSuccess: (form: MobxForm) => void;
  onClear: (form: MobxForm) => void;
}
interface RefundingTip {
  tipId: number;
  total: string;
  name?: string;
  lastFour?: string;
  brand?: string;
}

/** The variables that are matched in the URL (match.params) */
interface PaymentTipsMatchParams {
  paymentReference: string;
}

/** Define props for this component */
type PaymentTipsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps<PaymentTipsMatchParams> & // Adds the router props (history, match, location)
  WithModalStore &
  WithToastStore &
  WithUserStore; // Adds the userStore prop

function annotateTips(paymentTip: Partial<models.Tip>) {
  return {
    ...paymentTip,
    talentName: paymentTip.user ? `${paymentTip.user.firstName} ${paymentTip.user.lastName}` : '',
    service: paymentTip.serviceAmount ? numericStringToUsd(paymentTip.serviceAmount) : '',
    gross: paymentTip.gross ? numericStringToUsd(paymentTip.gross) : '',
    net: paymentTip.net ? numericStringToUsd(paymentTip.net) : '',
    location: paymentTip.location ? paymentTip.location.name : '',
    level: paymentTip.level ? paymentTip.level!.toUpperCase() : '',
    date: paymentTip.createdAt ? moment(paymentTip.createdAt).format('MMM DD, YYYY') : '',
    source: paymentTip.source ? paymentTip.source : '',
  };
}

/**
 * Show list of tips belonging to a single payment. Payment reference is
 * passed via match params and list is displayed with datagrid.
 */
@inject('userStore', 'modalStore', 'toastStore')
@observer
class PaymentTips extends React.Component<PaymentTipsProps> {
  /** The form object for payment refund */
  @observable public transferForm: MobxForm;

  @observable public canTransferTip = this.props.userStore?.hasPermission(ACL.TRANSFER_TIP);

  @observable public refetchGridData = Date.now();

  public transferFormFields = [
    {
      name: 'user',
      placeholder: 'To user',
      hooks: {
        onChange: (field: any) => {
          // on change fetch primary wallet for selected user id
          this.fetchPrimaryWallet(field.value);
        },
      },
    },
    {
      name: 'reason',
      label: 'Reason',
    },
  ];

  public constructor(props: PaymentTipsProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
    this.transferForm = new MobxReactForm(
      { fields: this.transferFormFields },
      { plugins, hooks: this.transferHooks },
    );
  }

  public transferHooks: FormHooks = {
    onSuccess: () => this.submitTransferTip(),
    onClear: () => {
      this.transferForm.clear();
      this.closeModal();
    },
  };

  @observable public matchParams: PaymentTipsMatchParams;

  @observable public customer?: {
    firstName?: string;
    lastName?: string;
    lastFour?: string;
    brand?: string;
  };

  /**
   * refundingTip is set when user clicks on a tip specific refund
   * button and if it is set we render a modal with refund form
   */
  @observable public refundingTip: RefundingTip | null = null;
  /**
   * transferingTip is set when user opens transfer modal
   */
  @observable public transferingTip: Record<string, any> | null = null;
  /** primary wallet for user that tip will be transfer from */
  @observable public sourceUserPrimaryWallet: any = null;
  /** primary wallet for user that tip will be transfer to */
  @observable public destinationUserPrimaryWallet: any = null;

  @observable public paymentDetails?: models.Payment;

  /** Get the payment reference from match params */
  @computed public get paymentReference(): string {
    return this.matchParams.paymentReference;
  }

  @observable public tips?: Partial<models.Tip>[];
  /** Users on specific location */
  @observable public locationUsers?: [];

  @action.bound public getTips = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const resp = await Api.tips.getPaymentTips(this.paymentReference, { ...rmd });
    if (resp?.data?.data) {
      resp.data.data = await Promise.all(
        resp.data.data.map(async (tip: models.Tip) => {
          try {
            const { data } = await Api.tips.getTransferTipRequest(tip.id);
            const canCreateTransferRequest = !data?.data;
            return { ...tip, canCreateTransferRequest };
          } catch (error) {
            return tip;
          }
        }),
      );
    }
    return resp;
  }, annotateTips);

  // loading state while fetching primary wallets
  @observable public loadingWallet = false;

  @action.bound public getPaymentDetails = flow(function* (this: PaymentTips) {
    try {
      const resp = yield Api.tips.getPaymentDetails(this.paymentReference);
      this.paymentDetails = resp?.data?.data;
      if (this.paymentDetails) {
        this.customer = this.paymentDetails.customer;
      }
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public fetchUsersFromTip = flow(function* (this: PaymentTips, tip: models.Tip) {
    try {
      const locationId = tip.locationId;
      const userResp = yield Api.core.getAllLocationUsers(locationId);
      if (userResp && userResp.data && userResp.data.data) {
        const users = userResp.data.data;
        this.locationUsers = users.map((locationUser: any) => locationUser.user);
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public fetchPrimaryWallet = flow(function* (
    this: PaymentTips,
    userId: number,
    sourceUser = false,
  ) {
    try {
      if (!sourceUser) {
        /** set loading to true only when fetching wallet for destination user
         * since primary wallet for source user is initialized when modal opens and we don't need
         * loading indicator for that, because form is displayed when destination user
         * wallet is loaded
         */
        this.loadingWallet = true;
      }
      // make api call to get balance for destination user
      const resp = yield Api.tips.getPrimaryWallet(userId);
      if (resp) {
        // set wallet for source user
        if (sourceUser) {
          this.sourceUserPrimaryWallet = resp.data && resp.data.data;
        }
        // set wallet for destination user
        else {
          this.destinationUserPrimaryWallet = resp.data && resp.data.data;
        }
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      if (this.loadingWallet) {
        this.loadingWallet = false;
      }
    }
  });

  @action.bound public submitTransferTip = async () => {
    try {
      if (this.transferingTip) {
        const tipId = this.transferingTip.id;
        const toUserId = this.transferForm.$('user').value.id;
        if (!this.canTransferTip) {
          const reason = this.transferForm.$('reason').value;
          await Api.tips.createTransferTipRequest(tipId, { toUserId, reason });
          this.props.toastStore?.success('Tip transfer request created successfully');
        } else {
          await Api.tips.transferTip(tipId, toUserId);
          this.props.toastStore!.success('Tip successfully transfered');
        }
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.closeModal();
      this.refetchData();
    }
  };

  @observable public paymentDetailsOpen = false;

  @observable public modalOpen = false;

  /** Form to render within a modal  */
  @observable public formType = '';

  @action.bound public openPaymentDetails() {
    this.paymentDetailsOpen = true;
  }

  @action.bound public closePaymentDetails() {
    this.paymentDetailsOpen = false;
  }

  /** Set refunding tip */
  @action.bound public setRefundingTip = (tip: models.Tip) => {
    if (this.customer) {
      // tip.gross comes preformatted with '$' character
      // remove all non numeric characters, excluding '.' and '-'
      const total = tip.gross.replace(/[^\d.-]/g, '');
      this.refundingTip = {
        tipId: tip.id,
        total: total,
        name: `${this.customer.firstName} ${this.customer.lastName}`,
        lastFour: this.customer.lastFour,
        brand: this.customer.brand,
      };
    }
  };

  /** Set transfer tip */
  @action.bound public setTransferTip = (tip: models.Tip) => {
    this.transferingTip = tip;
    // fetch primary wallet for source user
    this.fetchPrimaryWallet(tip.userId, true);
  };

  @action.bound public closeRefundModal = () => {
    this.refundingTip = null;
  };

  @action.bound public openModal = (identifier: string, tip: models.Tip) => {
    if (!this.customer) {
      return;
    }
    this.modalOpen = true;
    this.formType = identifier;
    // set tip
    if (identifier === MODAL_TYPE.REFUND) {
      this.setRefundingTip(tip);
    }
    if (identifier === MODAL_TYPE.TRANSFER) {
      this.fetchUsersFromTip(tip);
      this.setTransferTip(tip);
    }
  };

  @action.bound public closeModal = () => {
    this.modalOpen = false;
    this.resetData();
  };

  @action.bound public resetData = () => {
    this.formType = '';
    this.refundingTip = null;
    this.transferingTip = null;
    this.sourceUserPrimaryWallet = null;
    this.destinationUserPrimaryWallet = null;
    this.transferForm.clear();
  };

  @action.bound public refetchData = () => {
    this.refetchGridData = Date.now();
    this.getPaymentDetails();
  };

  @action.bound setUser(user: models.User | null) {
    if (!user) {
      return;
    }
    this.transferForm.$('user').set(user);
    this.fetchPrimaryWallet(this.transferForm.$('user').value.id);
  }

  @computed get getGridColumns() {
    if (this.paymentDetails?.processor.includes('developer_app')) {
      return this.gridColumns.filter(
        (column) => !isValueInArray(['action-refund-tip', 'action-transfer-tip'], column.field),
      );
    }

    // Admins without ACL.TRANSFER_TIP permission should not see refund tip action
    if (!this.canTransferTip) {
      return this.gridColumns.filter(
        (column) => !isValueInArray(['action-refund-tip'], column.field),
      );
    }

    return this.gridColumns;
  }

  componentDidMount() {
    this.getPaymentDetails();
  }

  renderTalentCell = ({ row }: any): JSX.Element => {
    return (
      <Link component={RouterLink} to={paths.userDetails(row.userId).info()}>
        {row.talentName}
      </Link>
    );
  };

  renderLocationCell = ({ row }: any): JSX.Element => (
    <Link component={RouterLink} to={paths.locationDetails(row.locationId)}>
      {row.location}
    </Link>
  );

  renderAmountCell = ({ value, row }: any): JSX.Element => {
    const { classes } = this.props;
    /** display a negative amount for source user,
       display a positive amount for destination user */
    const talentName = this.transferingTip && this.transferingTip.talentName;
    // check for source user
    if (talentName === row.name) {
      return <span className={classes.negativeBalance}> - {value}</span>;
    } else return <span className={classes.positiveBalance}>+ {value}</span>;
  };

  renderNewBalanceCell = ({ value, row }: any): JSX.Element => {
    const { classes } = this.props;
    /** Calculate new wallet balance */
    const tip = this.transferingTip && usdToNumericString(this.transferingTip.net);
    const talentName = this.transferingTip && this.transferingTip.talentName;
    /** for source user subtract tip from balance, otherwise add tip to balace */
    const parsedValue = parseFloat(value);
    const parsedTip = parseFloat(tip!);
    const calculatedBalance =
      talentName === row.name ? parsedValue - parsedTip : parsedValue + parsedTip;

    if (calculatedBalance === 0) {
      return <span> {numericStringToUsd(String(calculatedBalance))}</span>;
    } else if (calculatedBalance < 0)
      return (
        <span className={classes.negativeBalance}>
          {numericStringToUsd(String(calculatedBalance))}
        </span>
      );
    else
      return (
        <span className={classes.positiveBalance}>
          {numericStringToUsd(String(calculatedBalance))}
        </span>
      );
  };

  renderSource = ({ row }: any): JSX.Element => {
    const source = row.source as models.Source;

    const SourceIcon = () => {
      if (source === 'cash') {
        return (
          <Tooltip placement="right-end" title="Cash">
            <Cash color="disabled" />
          </Tooltip>
        );
      }
      if (source === 'card') {
        return (
          <Tooltip placement="right-end" title="Credit Card">
            <CreditCard color="disabled" />
          </Tooltip>
        );
      }
      if (source === 'split') {
        return (
          <Tooltip placement="right-end" title="Split Tip">
            <CallSplit color="disabled" />
          </Tooltip>
        );
      }
      if (source === 'refund') {
        return (
          <Tooltip placement="right-end" title="Refund">
            <CreditCardRefund color="disabled" />
          </Tooltip>
        );
      }
      if (source === 'correction') {
        return (
          <Tooltip placement="right-end" title="Correction">
            <SwapVertical color="disabled" />
          </Tooltip>
        );
      }
      return null;
    };

    return <SourceIcon />;
  };

  @computed public get loading() {
    return this.tips === undefined || !this.paymentDetails;
  }

  gridColumns = [
    {
      headerName: 'Talent',
      field: 'talentName',
      minWidth: 180,
      flex: 1,
      renderCell: this.renderTalentCell,
      sortable: false,
    },
    {
      headerName: 'Location',
      field: 'location',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderLocationCell,
      sortable: false,
    },
    { headerName: 'Service', field: 'service', minWidth: 120, flex: 1, sortable: false },
    { headerName: 'Gross', field: 'gross', minWidth: 100, flex: 1, sortable: false },
    { headerName: 'Net', field: 'net', minWidth: 100, flex: 1, sortable: false },
    { headerName: 'Level', field: 'level', minWidth: 100, flex: 1, sortable: false },
    {
      headerName: 'Date',
      field: 'date',
      minWidth: 125,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
      sortable: false,
    },
    {
      headerName: ' ',
      field: 'action-refund-tip',
      minWidth: 50,
      type: 'actions',
      filterable: false,
      renderCell: ({ row }: any) => (
        <Tooltip placement="right-end" title={'Refund'}>
          <IconButton size="small" onClick={() => this.openModal(MODAL_TYPE.REFUND, row)}>
            <FontAwesomeIcon
              color={theme.palette.primary.main}
              icon={faMoneyBillTransfer}
              fontSize={18}
            />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      headerName: ' ',
      field: 'action-transfer-tip',
      minWidth: 50,
      type: 'actions',
      filterable: false,
      renderCell: ({ row }: any) => {
        const disabled = !row?.canCreateTransferRequest;

        return (
          <Tooltip
            placement="right-end"
            title={disabled ? 'Transfer tip request already exists' : 'Transfer tip'}>
            <Box component={'span'}>
              <IconButton
                size="small"
                onClick={() => this.openModal(MODAL_TYPE.TRANSFER, row)}
                disabled={disabled}>
                <FontAwesomeIcon
                  color={theme.palette.primary.main}
                  icon={faMoneyCheckDollarPen}
                  fontSize={18}
                />
              </IconButton>
            </Box>
          </Tooltip>
        );
      },
    },
  ];

  gridBalanceColumns = [
    { headerName: 'Talent', field: 'name', minWidth: 150, flex: 1 },
    {
      headerName: 'Tip amount',
      field: 'amount',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderAmountCell,
    },
    {
      headerName: 'New Balance',
      field: 'walletBalance',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderNewBalanceCell,
    },
  ];

  renderNewBalance(): JSX.Element {
    // A user object that tip will be sent from
    const sourceUser = {
      id: this.transferingTip && this.transferingTip.id,
      name: this.transferingTip && this.transferingTip.talentName,
      amount: this.transferingTip && this.transferingTip.net,
      walletBalance: this.sourceUserPrimaryWallet && this.sourceUserPrimaryWallet.balance,
    };
    // need additional data (firstName, lastName) for destination user from locationUsers
    const locationUser: any =
      this.locationUsers &&
      this.locationUsers.find(
        (locUser: Record<string, any>) => locUser.id === this.destinationUserPrimaryWallet.userId,
      );
    // A user object that tip will be sent to
    const destinationUser = {
      id: locationUser && locationUser.id,
      name: locationUser && `${locationUser.firstName} ${locationUser.lastName}`,
      amount: this.transferingTip && this.transferingTip.net,
      walletBalance: this.destinationUserPrimaryWallet && this.destinationUserPrimaryWallet.balance,
    };
    return (
      <>
        {this.sourceUserPrimaryWallet && this.destinationUserPrimaryWallet && (
          <DataGridInfiniteScroll
            columns={this.gridBalanceColumns}
            data={[sourceUser, destinationUser]}
            disableColumnMenu
            pathname={this.props.location.pathname}
            disableToolbar
          />
        )}
      </>
    );
  }

  renderRefundTipForm(): JSX.Element | null {
    return (
      this.refundingTip && (
        <Box minWidth={380}>
          <DialogTitle disableTypography>
            <Typography style={{ fontSize: 28 }}>{`Issue refund to ${
              this.refundingTip.name || 'Cardholder'
            }`}</Typography>
          </DialogTitle>
          <DialogContent style={{ paddingBottom: theme.spacing(3) }}>
            <RefundForm {...this.refundingTip} closeModal={this.closeModal} />
          </DialogContent>
        </Box>
      )
    );
  }

  // Filter users that current user is not in the list for transfer tip and sort them by firstName/lastName
  usersList = (): models.User[] => {
    if (!this.locationUsers) {
      return [];
    }

    return this.locationUsers
      .filter(
        (usr: models.User) => this.transferingTip !== null && usr.id !== this.transferingTip.userId,
      )
      .sort(
        (a: models.User, b: models.User) =>
          (a.firstName || '').localeCompare(b.firstName || '') ||
          (a.lastName || '').localeCompare(b.lastName || ''),
      );
  };

  renderTransferTipForm(): JSX.Element | null {
    const { classes } = this.props;
    if (!this.transferingTip) return null;
    const users: models.User[] = this.usersList();

    const selectedUser = this.transferForm.$('user').value;
    const reason = this.transferForm.$('reason').value;
    const disabled = this.canTransferTip
      ? !selectedUser
      : !selectedUser || !reason || reason.length < 5;
    return (
      <form onSubmit={this.transferForm.onSubmit}>
        <Box className={classes.dialogWidth} p={3}>
          <DialogTitle disableTypography style={{ padding: 0 }}>
            <Typography style={{ fontSize: 28 }}>Transfer tip</Typography>
          </DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <Box mb={3} mt={3}>
              You will {!this.canTransferTip && 'create tip'} transfer{' '}
              {!this.canTransferTip && 'request for'}{' '}
              <span className={classes.tipAmount}>{this.transferingTip.net}</span> tip from{' '}
              {this.transferingTip.talentName}
            </Box>

            <Typography style={{ marginBottom: theme.spacing(2) }} variant="body2">
              Select new recipient
            </Typography>
            {this.locationUsers && <AutocompleteSelect users={users} onChange={this.setUser} />}

            {!this.canTransferTip && (
              <>
                <Typography
                  style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }}
                  variant="body2">
                  Define a reason
                </Typography>
                <Box>
                  <OutlinedInput
                    disabled={this.transferForm.submitting}
                    fullWidth
                    {...this.transferForm.$('reason').bind()}
                    multiline
                    inputProps={{ maxLength: MAX_TRANSFER_REQUEST_REASON_LENGTH }}
                  />
                </Box>
              </>
            )}
          </DialogContent>
          {this.loadingWallet ? (
            <DP.ListLoading />
          ) : (
            <DialogContent style={{ padding: 0, marginTop: theme.spacing(4) }}>
              {/* we need both wallets for displaying balance */}
              {this.sourceUserPrimaryWallet &&
                this.destinationUserPrimaryWallet &&
                this.renderNewBalance()}
            </DialogContent>
          )}
          <DialogActions style={{ padding: 0, paddingTop: 32 }}>
            <Button variant="outlined" onClick={this.transferForm.onClear}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={disabled}
              loading={this.transferForm.submitting}>
              Transfer
            </Button>
          </DialogActions>
        </Box>
      </form>
    );
  }

  render(): JSX.Element {
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Title mb={3}>Payment {this.paymentReference}</Title>
          <IconButton onClick={this.openPaymentDetails}>
            <FontAwesomeIcon color={theme.palette.primary.main} icon={faEye} fontSize={21} />
          </IconButton>
        </Box>
        <>
          <DataGridInfiniteScroll
            refetchKey={this.refetchGridData}
            columns={this.getGridColumns}
            fetch={this.getTips}
            noRowsMessage={
              <Typography variant="body1">
                Sorry, no tips found for payment reference{' '}
                <Typography component={'span'}>{this.paymentReference}</Typography>
              </Typography>
            }
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
          <Dialog open={this.modalOpen} onClose={this.closeModal}>
            {this.formType === MODAL_TYPE.REFUND && this.renderRefundTipForm()}
            {this.formType === MODAL_TYPE.TRANSFER && this.renderTransferTipForm()}
          </Dialog>
        </>
        <Drawer
          open={Boolean(this.paymentDetailsOpen)}
          onClose={this.closePaymentDetails}
          anchor="right"
          variant="temporary">
          <PaymentDetailsDrawer
            onClose={this.closePaymentDetails}
            paymentReference={this.paymentReference}
            linkTipCount={false}
          />
        </Drawer>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(PaymentTips);
