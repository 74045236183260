import React from 'react';
import { observer } from 'mobx-react';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import Api, { RequestMetaData } from 'api';
import { adaptForDataGridPro } from 'services';
import { useLocation } from 'react-router-dom';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import { annotateWalletTransactions } from 'containers/UserDetails/TransactionTable';
import { gridColumns } from './transactionTableHelpers';

interface ITransactionsTableProps {
  walletId?: number;
  dateRange?: DateRangeExternalPicker.IDateRange;
}

const dateRangeDefault = { fromDate: '', toDate: '' };

const TransactionsTable = ({ walletId, dateRange = dateRangeDefault }: ITransactionsTableProps) => {
  const location = useLocation();

  if (!walletId) return null;
  const getTransactions = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const { fromDate, toDate } = dateRange;
    return await Api.tips.getWalletTransactions(walletId, {
      ...rmd,
      filters: { fromDate, toDate },
    });
  }, annotateWalletTransactions);

  return (
    <DataGridInfiniteScroll
      fetch={getTransactions}
      refetchKey={dateRange}
      columns={gridColumns}
      pathname={location.pathname}
    />
  );
};

export default observer(TransactionsTable);
