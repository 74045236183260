import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, MenuItem, Select, Chip } from '@material-ui/core';
import clsx from 'clsx';
import { Filter, IFilterValue } from 'models';
import { FilterBarChipsList } from '../FilterBarComponents/FilterBarChips/FilterBarChipsList';
import { FilterBarFields } from '../FilterBarComponents/FilterBarFields';
import Button from 'components/Button/Button';

interface FilterBarDesktopProps {
  filters: Filter[];
  selectedFilter?: Filter;
  filterValue?: IFilterValue[];
  hasFilters: boolean;
  showClearFilters: boolean;
  visibleFilters: Filter[];
  setFilter: (filterId: string) => void;
  setFilters: (filters: Filter[]) => void;
  clearAllFilters: () => void;
  setFilterValue: (filter?: IFilterValue[]) => void;
  setSelectedFilter: (filter?: Filter) => void;
  clearSelectedFilter: () => void;
}

export const FilterBarDesktop = ({
  filters,
  selectedFilter,
  filterValue,
  hasFilters,
  showClearFilters,
  visibleFilters,
  setFilter,
  setFilters,
  clearAllFilters,
  setFilterValue,
  setSelectedFilter,
  clearSelectedFilter,
}: FilterBarDesktopProps) => (
  <>
    {/* Filter select, lists only non-active filter: */}
    {hasFilters && (
      <Select
        disabled={filters.length === 0}
        className="select"
        displayEmpty
        native={false}
        onChange={(e) => setFilter(e.target.value as string)}
        disableUnderline
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          getContentAnchorEl: null,
          style: {
            visibility: 'visible',
          },
        }}
        value=""
        renderValue={() => (
          <Chip
            clickable={false}
            className={clsx('filterChip', 'addFilterChip')}
            icon={<FontAwesomeIcon icon={faPlus} fontSize={18} />}
            label={<Typography className="selectLabel">Add a filter</Typography>}
          />
        )}>
        {visibleFilters.map((filter) => (
          <MenuItem key={filter.id} value={filter.id}>
            {filter.display}
          </MenuItem>
        ))}
      </Select>
    )}

    {/* Active filters: */}
    <FilterBarChipsList
      filters={filters}
      onOpenFilter={(filter: Filter) => {
        setSelectedFilter(filter);
        filter.value && setFilterValue(filter.value);
      }}
      onRemoveFilter={(id: string) =>
        setFilters(
          filters.map((filter) => (filter.id === id ? { ...filter, value: undefined } : filter)),
        )
      }
    />

    {/* Filter details panel:  */}
    {selectedFilter && (
      <FilterBarFields
        filters={filters}
        selectedFilter={selectedFilter}
        setFilters={setFilters}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        clearSelectedFilter={clearSelectedFilter}
        mobileView={false}
      />
    )}

    {/* Clear all filters button:  */}
    {showClearFilters && (
      <Button
        variant="text"
        size="small"
        color="primary"
        className="clearFilters"
        onClick={clearAllFilters}>
        Clear all filters
      </Button>
    )}
  </>
);
