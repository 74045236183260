import { Box, ButtonBase, Link, Typography } from '@mui/material';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import CircularIconFrame from 'components/CircularIconFrame/CircularIconFrame';
import SignupLayout from 'containers/SignupLayout/SignupLayout';
import { checkContainerStyles } from './styles';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import * as models from 'models/OsType';
import appStoreImg from '../../images/custom_download_on_appstore.svg';
import playStoreImg from '../../images/custom_get_it_on_google_play.svg';
import { observer } from 'mobx-react';
import useSignupSuccess, { TOsType } from './useSignupSuccess';
import StoreQRCodes from 'components/StoreQRCodes/StoreQRCodes';
import { useStores } from 'containers/App/App';

const SignUpSuccess = () => {
  const {
    signupLayoutProps,
    showDefaultSuccessScreen,
    showQrCodes,
    showStoreLink,
    storeButtonProps,
    storeQrCodesProps,
  } = useSignupSuccess();

  return (
    <CarouselScreenWrapper>
      <SignupLayout {...signupLayoutProps}>
        <Box mb={4} sx={checkContainerStyles}>
          {showDefaultSuccessScreen && (
            <>
              <CircularIconFrame icon={faCheck} iconHeight={77} color="green" />
              <SuccessText />
            </>
          )}
          {showQrCodes && <StoreQRCodes {...storeQrCodesProps} additionalInfo={additionalInfo} />}
          {showStoreLink && <StoreButton {...storeButtonProps} />}
        </Box>
      </SignupLayout>
    </CarouselScreenWrapper>
  );
};

interface IStoreButtonProps {
  osType: TOsType;
  goToStore: VoidFunction;
}
const StoreButton = ({ osType, goToStore }: IStoreButtonProps) => {
  const src = osType === models.EOsType.ANDROID ? playStoreImg : appStoreImg;
  return (
    <>
      <CircularIconFrame icon={faCheck} iconHeight={77} color="green" />

      <SuccessText />

      <Typography sx={{ mt: 4 }} variant="body2" align="center">
        If you haven’t already, download the Tippy App and complete your personal profile, enter in
        your company code and create your digital wallet to receive tips.
      </Typography>

      <ButtonBase sx={{ mt: 4, borderRadius: 3 }} onClick={goToStore}>
        <Box component="img" draggable={false} src={src} />
      </ButtonBase>

      <ContinueToDashboard />
    </>
  );
};

const SuccessText = () => (
  <Typography sx={{ mt: 5 }} variant="body2" align="center">
    You have successfully setup your account!
  </Typography>
);

const ContinueToDashboard = () => {
  const { userStore } = useStores();
  return (
    <Typography sx={{ mt: 5 }} variant="body2" align="center" alignItems={'center'}>
      Or Login to {''}
      <Link onClick={() => userStore.logout('/')}>Dashboard</Link>
    </Typography>
  );
};

const additionalInfo = (
  <>
    <SuccessText />

    <Typography sx={{ mt: 3 }} variant="body2" align="center">
      If you haven’t already, scan the QR code to download the Tippy App and create your digital
      wallet to receive tips.{' '}
    </Typography>

    <ContinueToDashboard />
  </>
);

export default observer(SignUpSuccess);
